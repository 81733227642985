import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from '../state'
import { UserAccount, defaultUserState } from '../UserContext'

export default function KYCRoute(): JSX.Element {

    const [user/*, setUser*/] = useState<UserAccount>(defaultUserState)
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const [showInfo, setShowInfo] = useState<boolean>(false)

    useEffect(() => {
        if(user.email !== "") {
            //setUser(user)
            actions.getBasicAccountProfile()
        }
      }, [user])

    /*function handleSetPermissions(e: React.MouseEvent) {
        actions.setInvestorPermissions() 
    } */ 

    useEffect(() => {
        fetch('https://dexx.instance.kyc-chain.com/api/v2/invitations')
            .then(function(response){ return response.status; })
            .then(function(data) {
                const items = data;
            })
    })
    
    return (<>
            <script src="https://www.google.com/recaptcha/api.js" async defer></script>
            <div className="grid pl-30 pr-30 mx-20">
                <div className="text-white uppercase text-center font-urbanist text-4xl mb-5 mt-5">
                    <div className="">
                        <div className="text-center">Complete the kyc
                        <div onClick={() => {setShowInfo(!showInfo)}} 
                            className={"cursor-pointer border-solid border-white w-8 h-8 lowercase "+
                                "rounded-full text-xl border-2 text-center float-right font-semibold mr-5"}>
                                i</div></div>
                    </div>
                    <div className={" text-xl mt-10 font-medium "+(showInfo ? " hover:animate-bounce" : " hidden")}>
                        During the process you will need:
                        <ul className="font-normal normal-case">
                            <li>Your Passport (or ID)</li>
                            <li>A webcam</li>
                            <li>...</li>
                            <li>...</li>
                        </ul>
                    </div>
                </div>
                <div className="m-5 text-center w-full h-160 border-background border-4 border-solid">
                
                    <iframe src={"https://kyc.dexx.finance/"} className={"w-full border-solid border-blue h-full border-4"} 
                        data-sitekey="6LfSDHwiAAAAAPqPGGs5FyJe7MC5W_dUZd-iYMtV"/>
                </div>
                <div></div>
            </div>
    </>)
}
