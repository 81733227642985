import { ActionType } from '../action-types'
import { Actions } from '../actions'

interface createAccountState {
    loading: boolean
    payload?: any[]
}

export const defaultCreateAccountState: createAccountState = {
    loading: false
}

const createAccountReducer = (state: createAccountState = defaultCreateAccountState, action: Actions): createAccountState => {
    switch(action.type) {
            case ActionType.CREATE_USER_ACCOUNT_REQUEST:
            return {
                loading: true,
            }
        case ActionType.CREATE_USER_ACCOUNT_FAILURE:
            return {
                loading: false,
                payload: action.error
            }
        case ActionType.CREATE_USER_ACCOUNT_SUCCESS:
            return {
                loading: false,
                payload: action.payload
            }
        case ActionType.CLEAR_ALL:
            return {
                loading: false,
            }
        default:
            return state
    }
}
export default createAccountReducer
