import LightTable from "./light/LightTable";

export function InvestorBreakdownCountriesTable(props: {countryList: any}): JSX.Element {

    const headers = ["Country", "Total Quantity", "Total Value"]
    //const headersDetail = ["Investor", "Total Quantity", "Total Value"]

    function tokensList2list(l: any[]) {
        const countries = Object.keys(l);
        const countryvalues = Object.values(l);
        let arrayCountry:any[] = []
        if(countries) {
            countries.forEach((country, i) => {
                let obj = countryvalues[i]
                let details = obj.entries && obj.entries.length ? obj.entries.length : 0
                arrayCountry.push([country+" ("+details+")", obj.total_quantity, obj.total_value])
                /*if(details > 0){
                    <>
                        <div className="py-1">
                            <LightTable headers={headersDetail} rows={tokensList2list(obj.entries)} />
                        </div>
                    </>
                }*/
            })
            return arrayCountry
        }
        return []
    }
    return <>
        <div className="py-1">
            <LightTable headers={headers} rows={tokensList2list(props.countryList.rows)} />
        </div>
    </>
}

export function InvestorBreakdownDetailedTable(props: {users: any}): JSX.Element {
    function tokensList2list(l: any[]) {
        let arrayUsers:any[] = []
        if(l) {
            l.forEach((user, i) => {
                arrayUsers.push([user.user_email, user.quantity, user.value])
            })
        }
        //arrayUsers.push(["claudiomello@gmail.com", "2", "5"])
        //arrayUsers.push(["lohnfiore@gmail.com", "3", "7.5"])
        return arrayUsers
    }
    return <>
        <div className="py-1">
            <LightTable 
                headers={["Investor", "Quantity", "Value"]} 
                rows={tokensList2list(props.users)} />
        </div>
    </>
}