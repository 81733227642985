import { ActionType } from "../action-types"
import { Dispatch } from "redux"
import { Actions } from "../actions"
import { PoolState } from "../../PoolContext"
import DexxClient, { client } from "../../client"
import { strUserInvestor, strLoginInfo, UserAccount } from "../../UserContext"
import { AMMTrade } from "../../components/TradeInterface"

export const signInCallback = (idToken: string, setFun?: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        let res = await client.signInCallback(idToken).then(result => {
            return result[0]
        })
        if(setFun) setFun(res)
    } catch (e) {
        console.log(e)
    }
}

export const signOutCallback = (idToken: string, setFun?: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        let res = await client.signOutCallback(idToken).then(result => {
            return result[0]
        })
        if(setFun) setFun(res)
    } catch (e) {
        console.log(e)
    }
}

export const getPools = () => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({ type: ActionType.GET_POOLS_REQUEST })
        const client: DexxClient = new DexxClient()
        let res = await client.getPairList().then(result => {
            return result[1].pool
        })
        dispatch({ type: ActionType.GET_POOLS_SUCCESS, payload: res })
    } catch (e) {
        dispatch({ type: ActionType.GET_POOLS_FAILURE, error: e })
    }
}

export const getPool = (poolID: string) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({ type: ActionType.GET_POOL_REQUEST })
        const client: DexxClient = new DexxClient()
        let res = await client.getPool(poolID).then(result => {
            return result[1].pool
        })
        dispatch({ type: ActionType.GET_POOL_SUCCESS, payload: res })
    } catch (e) {
        dispatch({ type: ActionType.GET_POOL_FAILURE, error: e })
    }
}

export const setPools = (newPool: PoolState) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({ type: ActionType.SET_POOLS_REQUEST })
        const client: DexxClient = new DexxClient()
        let res = await client.setPairList(newPool).then(result => {
            return result
        })
        dispatch( {type: ActionType.SET_POOLS_SUCCESS, payload: res} )
    } catch (e) {
        dispatch( {type: ActionType.SET_POOLS_FAILURE, error: e} )
    }
}

export const sendTrade = (trade: AMMTrade) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({ type: ActionType.SEND_TRADE_REQUEST })
        const client: DexxClient = new DexxClient()
        let res = await client.sendAMMTrade(trade).then(result => {
            return result
        })
        dispatch( {type: ActionType.SEND_TRADE_SUCCESS, payload: res} )
    } catch (e) {
        dispatch( {type: ActionType.SEND_TRADE_FAILURE, error: e} )
    }
}

export const updatePool = (newPool: PoolState) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({ type: ActionType.UPDATE_POOL_REQUEST })
        const client: DexxClient = new DexxClient()
        let res = await client.updatePool(newPool).then(result => {
            return result
        })
        dispatch( {type: ActionType.UPDATE_POOL_SUCCESS, payload: res} )
    } catch (e) {
        dispatch( {type: ActionType.UPDATE_POOL_FAILURE, error: e} )
    }
}

/*export const getPoolTokens = () => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({ type: ActionType.GET_POOL_TOKENS_REQUEST })
        let res = await client.getPoolTokenList().then(result => {
            return result[1].pools
        })
        dispatch( {type: ActionType.GET_POOL_TOKENS_SUCCESS, payload: res} )
    } catch (e) {
        dispatch( {type: ActionType.GET_POOL_TOKENS_FAILURE, error: e} )
    }
}*/

//signup (set basic info)
export const createUserAccount = (newUser: UserAccount) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.CREATE_USER_ACCOUNT_REQUEST})
        let res = await client.createUserAccount(newUser).then(result => {
            return result
        })
        dispatch({type: ActionType.CREATE_USER_ACCOUNT_SUCCESS, payload: res})
    } catch (e) {
        dispatch( {type: ActionType.CREATE_USER_ACCOUNT_FAILURE, error: e} )
    }
}

//signup (email verification)
export const emailVerification = () => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.EMAIL_VERIFICATION_REQUEST})
        let res = await client.emailVerification().then(result => {
            return result
        })
        dispatch({type: ActionType.EMAIL_VERIFICATION_SUCCESS, payload: res})
    } catch (e) {
        dispatch( {type: ActionType.EMAIL_VERIFICATION_FAILURE, error: e} )
    }
}

//sign up (set additional info)
export const setAccountDetails = (newUser: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.SET_ACCOUNT_REQUEST})
        let res = await client.setAccountDetails(newUser).then(result => {
            return result
        })
        dispatch({type: ActionType.SET_ACCOUNT_SUCCESS, payload: res})
    } catch (e) {
        dispatch( {type: ActionType.SET_ACCOUNT_FAILURE, error: e} )
    }
}

//sign up (edit additional info)
export const updateAccountDetails = (newUser: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.UPDATE_ACCOUNT_REQUEST})
        let res = await client.updateAccountDetails(newUser).then(result => {
            return result
        })
        dispatch({type: ActionType.UPDATE_ACCOUNT_SUCCESS, payload: res})
    } catch (e) {
        dispatch( {type: ActionType.UPDATE_ACCOUNT_FAILURE, error: e} )
    }
}

//sign up (set investor permissions)
export const setInvestorPermissions = () => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.SET_INVESTOR_PERMISSIONS_REQUEST})
        let res = await client.setInvestorPermissions().then(result => {
            return result
        })
        dispatch({type: ActionType.SET_INVESTOR_PERMISSIONS_SUCCESS, payload: res})
    } catch (e) {
        dispatch( {type: ActionType.SET_INVESTOR_PERMISSIONS_FAILURE, error: e} )
    }
}

//set issuer permissions
export const setIssuerPermissions = () => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.SET_ISSUER_PERMISSIONS_REQUEST})
        let res = await client.setIssuerPermissions().then(result => {
            return result
        })
        dispatch({type: ActionType.SET_ISSUER_PERMISSIONS_SUCCESS, payload: res})
    } catch (e) {
        dispatch( {type: ActionType.SET_ISSUER_PERMISSIONS_FAILURE, error: e} )
    }
}

//set issuer additional info (kyc)
/*export const setIssuerInfo = (newUser: UserAccount) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.SET_ISSUER_KYC_DETAILS_REQUEST})
        let res = await client.setIssuerInfo(newUser).then(result => {
            return result
        })
        dispatch({type: ActionType.SET_ISSUER_KYC_DETAILS_REQUEST, payload: res})
    } catch (e) {
        dispatch( {type: ActionType.SET_ISSUER_KYC_DETAILS_FAILURE, error: e} )
    }
}*/

//deprecated in the future
export const editUserInvestor = (newUser: strUserInvestor) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.EDIT_USER_INVESTOR_REQUEST})
        let res = await client.editInvestorProfile(newUser).then(result => {
            return result
        })
        dispatch({type: ActionType.EDIT_USER_INVESTOR_SUCCESS, payload: res})
    } catch (e) {
        dispatch( {type: ActionType.EDIT_USER_INVESTOR_FAILURE, error: e} )
    }
}

//signin
export const SignInUserAccount = (accessingUser: strLoginInfo) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.SIGNIN_USER_ACCOUNT_REQUEST})
        let res = await client.SignInUserAccount(accessingUser).then(result => {
            return result
        })
        dispatch({type: ActionType.SIGNIN_USER_ACCOUNT_SUCCESS, payload: res})
    } catch (e) {
        dispatch( {type: ActionType.SIGNIN_USER_ACCOUNT_FAILURE, error: e} )
    }
}

export const getBasicAccountProfile = () => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.GET_BASIC_ACCOUNT_PROFILE_REQUEST})
        let res = await client.getBasicAccountProfile().then(result => {
            return result[1]
        })
        dispatch({type: ActionType.GET_BASIC_ACCOUNT_PROFILE_SUCCESS, payload: res})
    } catch (e) {
        dispatch( {type: ActionType.GET_BASIC_ACCOUNT_PROFILE_FAILURE, error: e} )
    }
}

export const getAccountDetails = () => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.GET_ACCOUNT_PROFILE_REQUEST})
        let res = await client.getAccountDetails().then(result => {
            //if(JSON.stringify(result) === '[404,null]' || JSON.stringify(result) === '[403,null]'){
            //    dispatch( {type: ActionType.GET_ACCOUNT_PROFILE_FAILURE, error: result} )
            //    return result
            //}                
            //else 
            return result[1]
        })
        dispatch({type: ActionType.GET_ACCOUNT_PROFILE_SUCCESS, payload: res})
    } catch (e) {
        dispatch( {type: ActionType.GET_ACCOUNT_PROFILE_FAILURE, error: e} )
    }
}

export const getIssuerKYCDetails = () => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.GET_ISSUER_KYC_DETAILS_REQUEST})
        let res = await client.getIssuerKYCDetails().then(result => {
            return result[1]
        })
        dispatch({type: ActionType.GET_ISSUER_KYC_DETAILS_SUCCESS, payload: res})
    } catch (e) {
        dispatch( {type: ActionType.GET_ISSUER_KYC_DETAILS_FAILURE, error: e} )
    }
}

/*export const getInvestorProfile = () => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.GET_INVESTOR_PROFILE_REQUEST})
        let res = await client.getAccountProfile().then(result => {
            return result[1]
        })
        dispatch({type: ActionType.GET_INVESTOR_PROFILE_SUCCESS, payload: res})
    } catch (e) {
        dispatch( {type: ActionType.GET_INVESTOR_PROFILE_FAILURE, error: e} )
    }
}*/

export const getTokenInfo = (uuid: string, setFun: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.GET_TOKEN_INFO_REQUEST})
        let res = await client.getTokenInfo(uuid).then(result => {
            return result[1].token
        })
        setFun(res)
        dispatch({type: ActionType.GET_TOKEN_INFO_SUCCESS, payload: res})
    } catch (e) {
        dispatch({type: ActionType.GET_TOKEN_INFO_FAILURE, error: e})
    }
}

export const getAllAssetCategories = (setFun?: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.GET_ALL_ASSET_CATEGORIES_REQUEST})
        let res = await client.getAllAssetCategories().then(result => {
            return result[1]
        })
        if(setFun) setFun(await res)
        dispatch({type: ActionType.GET_ALL_ASSET_CATEGORIES_SUCCESS, payload: res})
    } catch (e) {
        dispatch({type: ActionType.GET_ALL_ASSET_CATEGORIES_FAILURE, error: e})
    }
}

export const getAssetTypesByCategory = (uuid:string, setFun?: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.GET_ASSET_TYPE_BY_CATEGORY_REQUEST})
        let res = await client.getAssetTypesByCategory(uuid).then(result => {
            return result[1]
        })
        if(setFun) setFun(await res.asset_types)
        dispatch({type: ActionType.GET_ASSET_TYPE_BY_CATEGORY_SUCCESS, payload: res})
    } catch (e) {
        dispatch({type: ActionType.GET_ASSET_TYPE_BY_CATEGORY_FAILURE, error: e})
    }
}

export const getAssetCurrencies = (setFun: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.GET_ASSET_CURRENCIES_REQUEST})
        let res = await client.getAssetCurrencies().then(result => {
            return result[1]
        })
        setFun(await res.currency_cds)
        dispatch({type: ActionType.GET_ASSET_CURRENCIES_SUCCESS, payload: res})
    } catch (e) {
        dispatch({type: ActionType.GET_ASSET_CURRENCIES_FAILURE, error: e})
    }
}

export const getAssetInfo = (uuid: string, setFun?: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.GET_ASSET_INFO_REQUEST})
        let res = await client.getAssetInfo(uuid).then(result => {
            return result[1].asset
        })
        if(setFun) setFun(await res)
        dispatch({type: ActionType.GET_ASSET_INFO_SUCCESS, payload: res})
    } catch (e) {
        dispatch({type: ActionType.GET_ASSET_INFO_FAILURE, error: e})
    }
}

export const resetAssetInfo = () => async (dispatch: Dispatch<Actions>) => {
    dispatch({type: ActionType.RESET_ASSET_INFO, payload: undefined})
}

export const getTransactions = () => async (dispatch: Dispatch<Actions>) => {
    try{
        dispatch({type: ActionType.GET_TRANSACTIONS_REQUEST})
        let res = await client.getTransactions().then(result => {
            return result[1]
            
        })
        dispatch({type: ActionType.GET_TRANSACTIONS_SUCCESS, payload: res})
    } catch (e) {
        dispatch({type: ActionType.GET_TRANSACTIONS_FAILURE, error: e})
    }
}
/*export const SignOutUserInvestor = () => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.SIGNOUT_USER_INVESTOR_REQUEST})
        let res = await client.SignOutUserInvestor().then(result => {
            return result
        })
        dispatch({type: ActionType.SIGNOUT_USER_INVESTOR_SUCCESS, payload: res})
    } catch (e) {
        dispatch( {type: ActionType.SIGNOUT_USER_INVESTOR_FAILURE, error: e} )
    }
}*/
export const SignOutUserInvestor = () => async (dispatch: Dispatch<Actions>) => {
//  try {
        dispatch({type: ActionType.CLEAR_ALL, payload: undefined})
//  } catch (e) {
//        dispatch( {type: ActionType.CLEAR_ALL, error: e} )
//  }
}
export const getBalance = () => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.GET_BALANCE_REQUEST})
        let res = await client.getBalance().then(result => {
            return result[1]
        })
        dispatch({type: ActionType.GET_BALANCE_SUCCESS, payload: res})
    } catch (e) {
        dispatch( {type: ActionType.GET_BALANCE_FAILURE, error: e} )
    }
}

export const getActualBalance = (setFunction?: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.GET_BALANCE_REQUEST})
        let res = await client.getBalance().then(result => {
            return result[1]
        })
        if(setFunction) setFunction(res)
        dispatch({type: ActionType.GET_BALANCE_SUCCESS, payload: res})
    } catch (e) {
        dispatch( {type: ActionType.GET_BALANCE_FAILURE, error: e} )
    }
}

export const getIssuerBalance = (setFunction?: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.GET_ISSUER_BALANCE_REQUEST})
        let res = await client.getIssuerBalance().then(result => {
            return result[1]
        })
        if(setFunction) setFunction(res)
        dispatch({type: ActionType.GET_ISSUER_BALANCE_SUCCESS, payload: res})
    } catch (e) {
        dispatch( {type: ActionType.GET_ISSUER_BALANCE_FAILURE, error: e} )
    }
}

export const getAllAssets = () => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.GET_ALL_ASSETS_REQUEST})
        let res = await client.getAllAssets().then(result => {
            return result[1]
        })
        dispatch({type: ActionType.GET_ALL_ASSETS_SUCCESS, payload: res})
    } catch (e){
        dispatch( {type: ActionType.GET_ALL_ASSETS_FAILURE, error: e})
    }
} 

export const getMediaFile = (hashname: string) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.GET_FILE_REQUEST})
        let res = await client.getMediaFile(hashname).then(result => {
            //console.log(result)
            return result[1].url
        })
        dispatch({type: ActionType.GET_FILE_SUCCESS, payload: res})
    } catch (e){
        dispatch({type: ActionType.GET_FILE_FAILURE, error: e})
    }
}

export const uploadMedia = 
    (hashname: string, file: File, type: number) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.GET_FILE_UPLOAD_LINK_REQUEST})
        let res = await client.getMediaUploadLink(hashname).then(result => {
            return result[1].url
        })
        let res_ = await client.uploadFile(res, file).then(result => {
            console.log(result)
            return result
        })
        dispatch({type: ActionType.GET_FILE_UPLOAD_LINK_SUCCESS, payload: res_, extra: [hashname, type]})
    } catch (e){
        dispatch({type: ActionType.GET_FILE_UPLOAD_LINK_FAILURE, error: e})
    }
}

export const addMedia = (filename: string, asset_uuid: string, type: string, group?: string) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.ADD_MEDIA_REQUEST})
        let res = await client.setAssetMedia(
            group ? 
            {asset_uuid: asset_uuid, filename: filename, media_type_name: type, document_group_name: group}: 
            {asset_uuid: asset_uuid, filename: filename, media_type_name: type}).then(result => {
                return result
        })
        dispatch({type: ActionType.ADD_MEDIA_SUCCESS, payload: res })
    } catch (e){
        dispatch({type: ActionType.ADD_MEDIA_FAILURE, error: e})
    }
}

export const removeMedia = (filename: string) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.REMOVE_MEDIA_REQUEST})
        let res = await client.deleteAssetMedia(filename).then(result => {
                return result
        })
        dispatch({type: ActionType.REMOVE_MEDIA_SUCCESS, payload: res })
    } catch (e){
        dispatch({type: ActionType.REMOVE_MEDIA_FAILURE, error: e})
    }
}

export const getAssetMedia = (uuid: string, setFun: any) =>async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.GET_ASSET_MEDIA_REQUEST})
        let res = await client.getAssetDocument(uuid).then(result => {
            return result[1]
        })
        setFun(res.asset_media)
        dispatch({type: ActionType.GET_ASSET_MEDIA_SUCCESS, payload: res})
    } catch (e){
        dispatch({type: ActionType.GET_ASSET_MEDIA_FAILURE, error: e})
    }
}

export const getAllMediaTypes = () => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.GET_ALL_MEDIA_TYPES_REQUEST})
        let res = await client.getAllMediaTypes().then(result => {
            return result
        })
        dispatch({type: ActionType.GET_ALL_MEDIA_TYPES_SUCCESS, payload: res})
    } catch (e){
        dispatch({type: ActionType.GET_ALL_MEDIA_TYPES_FAILURE, error: e})
    }
}

export interface MediaFile {
    filename: string,
    media_type_name: string,
    document_group_name?: string
}

export const getDocuments = (docsName: any, setFunction: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.GET_DOCUMENTS_REQUEST})
        let tmp = docsName.filter((d: MediaFile) => 
            d.media_type_name === "Document")
        let res = tmp.map(async (d: MediaFile) => 
            await client.getMediaFile(d.filename).then(result => {return {url: result[1].url, filename: d.filename}}))
        setFunction(await Promise.all(res))
        dispatch({type: ActionType.GET_DOCUMENTS_SUCCESS, payload: await Promise.all(res)})
    } catch (e){
        dispatch({type: ActionType.GET_DOCUMENTS_FAILURE, error: e})
    }
}

export const getImages = (docsName: any, setFunction: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.GET_IMAGES_REQUEST})
        let tmp = docsName.filter((d: MediaFile) => 
            d.media_type_name === "Image")
        let res = tmp.map(async (d: MediaFile) => 
            await client.getMediaFile(d.filename).then(result => {return {url: result[1].url, filename: d.filename}}))
        setFunction(await Promise.all(res))
        dispatch({type: ActionType.GET_IMAGES_SUCCESS, payload: await Promise.all(res)})
    } catch (e){
        dispatch({type: ActionType.GET_IMAGES_FAILURE, error: e})
    }
}

export const getVideos = (docsName: any, setFunction: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.GET_VIDEOS_REQUEST})
        let tmp = docsName.filter((d: MediaFile) => 
            d.media_type_name === "Video")
        let res = tmp.map(async (d: MediaFile) => 
            await client.getMediaFile(d.filename).then(result => {return {url: result[1].url, filename: d.filename}}))
        setFunction(await Promise.all(res))
        dispatch({type: ActionType.GET_VIDEOS_SUCCESS, payload: await Promise.all(res)})
    } catch (e){
        dispatch({type: ActionType.GET_VIDEOS_FAILURE, error: e})
    }
}

export const getIssuedTokens = () => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.GET_ISSUED_TOKENS_REQUEST})
        let res = await client.getIssuedTokens().then(result => {
            return result[1]
        })
        dispatch({type: ActionType.GET_ISSUED_TOKENS_SUCCESS, payload: res})
    } catch (e){
        dispatch({type: ActionType.GET_ISSUED_TOKENS_FAILURE, error: e})
    }
}

export const getIssuedAssets = () => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.GET_ISSUED_ASSETS_REQUEST})
        let res = await client.getIssuedAssets().then(result => {
            return result[1]
        })
        dispatch({type: ActionType.GET_ISSUED_ASSETS_SUCCESS, payload: res})
    } catch (e){
        dispatch({type: ActionType.GET_ISSUED_ASSETS_FAILURE, error: e})
    }
}

export const createAsset = (asset: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.CREATE_ASSET_REQUEST})
        let res = await client.createAsset(asset).then(result => {
            return result
        })
        dispatch({type: ActionType.CREATE_ASSET_SUCCESS, payload: res})
    } catch (e) {
        dispatch({type: ActionType.CREATE_ASSET_FAILURE, error: e})
    }
}

export const submitAsset = (asset: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.SUBMIT_ASSET_REQUEST})
        let res = await client.submitAsset(asset).then(result => {
            return result
        })
        dispatch({type: ActionType.SUBMIT_ASSET_SUCCESS, payload: res})
    } catch (e) {
        dispatch({type: ActionType.SUBMIT_ASSET_FAILURE, error: e})
    }
}

export const createToken = (token: any, setFun: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.CREATE_TOKEN_REQUEST})
        let res = await client.createToken(token).then(result => {
            return result
        })
        setFun(res)
        dispatch({type: ActionType.CREATE_TOKEN_SUCCESS, payload: res})
    } catch (e) {
        dispatch({type: ActionType.CREATE_TOKEN_FAILURE, error: e})
    }
}

/*export const submitToken = (token: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.SUBMIT_TOKEN_REQUEST})
        let res = await client.submitToken(token).then(result => {
            return result
        })
        dispatch({type: ActionType.SUBMIT_TOKEN_SUCCESS, payload: res})
    } catch (e) {
        dispatch({type: ActionType.SUBMIT_TOKEN_FAILURE, error: e})
    }
}*/

export const getBlockchainTransactionDetails = (trx_identifier: string, setFun: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.GET_BLOCKCHAIN_TRX_DETAILS_REQUEST})
        let res = await client.getBlockchainTransactionDetails(trx_identifier).then(result => {
            return result
        })
        setFun(await Promise.all(res))
        dispatch({type: ActionType.GET_BLOCKCHAIN_TRX_DETAILS_SUCCESS, payload: res})
    } catch(e) {
        dispatch({type: ActionType.GET_BLOCKCHAIN_TRX_DETAILS_FAILURE, error: e})
    }
}
/*MINE*/
export const getAllPrimarySales = (setFunction: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.GET_ALL_PRIMARY_SALES_REQUEST})
        let res = await client.getAllPrimarySales().then(result => {
            return result[1]
        })
        setFunction(/*await Promise.all*/(res))
        dispatch({type: ActionType.GET_ALL_PRIMARY_SALES_SUCCESS, payload: res})
    } catch (e){
        dispatch( {type: ActionType.GET_ALL_PRIMARY_SALES_FAILURE, error: e})
    }
} 

export const getIssuerPrimarySales = (setFunction: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.GET_ISSUER_PRIMARY_SALES_REQUEST})
        let res = await client.getIssuerPrimarySales().then(result => {
            return result[1]
        })
        //setFunction(await Promise.all(res))
        setFunction(await Promise.all(res.primary_sales))
        dispatch({type: ActionType.GET_ISSUER_PRIMARY_SALES_SUCCESS, payload: res})
    } catch (e){
        dispatch( {type: ActionType.GET_ISSUER_PRIMARY_SALES_FAILURE, error: e})
    }
} 

export const getPrimarySale = (uuid: string, setFunction: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.GET_PRIMARY_SALE_REQUEST})
        let res = await client.getPrimarySale(uuid).then(result => {
            return result[1].primary_sale
        })
        //setFunction(await Promise.all(res))
        setFunction(res)
        dispatch({type: ActionType.GET_PRIMARY_SALE_SUCCESS, payload: res})
    } catch (e){
        dispatch( {type: ActionType.GET_PRIMARY_SALE_FAILURE, error: e})
    }
} 

export const submitPrimarySale = (primarySale: any, setFunction: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.SUBMIT_PRIMARY_SALE_REQUEST})
        let res = await client.submitPrimarySale(primarySale).then(result => {
            return result
        })
        //setFunction(await Promise.all(res))
        setFunction(res)
        dispatch({type: ActionType.SUBMIT_PRIMARY_SALE_SUCCESS, payload: res})
    } catch (e) {
        dispatch({type: ActionType.SUBMIT_PRIMARY_SALE_FAILURE, error: e})
    }
}

export const startPrimarySale = (uuid: string, setFunction: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.START_PRIMARY_SALE_REQUEST})
        let res = await client.startPrimarySale(uuid).then(result => {
            console.log(result)
            return result
        })
        setFunction(res)
        dispatch({type: ActionType.START_PRIMARY_SALE_SUCCESS, payload: res})
    } catch (e){
        dispatch( {type: ActionType.START_PRIMARY_SALE_FAILURE, error: e})
    }
} 

export const pausePrimarySale = (uuid: string, setFunction: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.PAUSE_PRIMARY_SALE_REQUEST})
        let res = await client.pausePrimarySale(uuid).then(result => {
            return result
        })
        setFunction(res)
        dispatch({type: ActionType.PAUSE_PRIMARY_SALE_SUCCESS, payload: res})
    } catch (e){
        dispatch( {type: ActionType.PAUSE_PRIMARY_SALE_FAILURE, error: e})
    }
} 

export const resumePrimarySale = (uuid: string, setFunction: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.RESUME_PRIMARY_SALE_REQUEST})
        let res = await client.resumePrimarySale(uuid).then(result => {
            return result
        })
        setFunction(res)
        dispatch({type: ActionType.RESUME_PRIMARY_SALE_SUCCESS, payload: res})
    } catch (e){
        dispatch( {type: ActionType.RESUME_PRIMARY_SALE_FAILURE, error: e})
    }
} 

export const stopPrimarySale = (uuid: string, setFunction: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.STOP_PRIMARY_SALE_REQUEST})
        let res = await client.stopPrimarySale(uuid).then(result => {
            return result
        })
        setFunction(res)
        dispatch({type: ActionType.STOP_PRIMARY_SALE_SUCCESS, payload: res})
    } catch (e){
        dispatch( {type: ActionType.STOP_PRIMARY_SALE_FAILURE, error: e})
    }
} 
/*SAMU*/
export const getDocumentGroups = (setFun: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        let res = await client.getDocumentGroups().then(result => {
            return result[1].document_groups
        })
        setFun(res)
    } catch(e) {
        return []
    }
}

export const bankDeposit = (amount: string, setFun: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        let res = await client.bankDeposit(amount).then(result => {
            return result[0]
        })
        setFun(res)
    } catch(e) {
        return []
    }
}

export const bankWithdraw = (info: any, setFun: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        let res = await client.bankWithdraw(info).then(result => {
            return result[0]
        })
        setFun(res)
    } catch(e) {
        return []
    }
}

export const submitBuyPS = (primarySale: any, setFunction: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.SUBMIT_BUY_PRIMARY_SALE_REQUEST})
        let res = await client.submitBuyPS(primarySale).then(result => {
            return result
        })
        setFunction(res)
        dispatch({type: ActionType.SUBMIT_BUY_PRIMARY_SALE__SUCCESS, payload: res})
    } catch (e) {
        dispatch({type: ActionType.SUBMIT_BUY_PRIMARY_SALE_ERROR, error: e})
    }
}

export const getBankTransactions = (setFunction: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.GET_BANK_TRANSACTIONS_REQUEST})
        let res = await client.getBankTransactions().then(result => {
            return result
        })
        setFunction(res[1].bank_transactions)
        dispatch({type: ActionType.GET_BANK_TRANSACTIONS_SUCCESS, payload: res})
    } catch (e) {
        dispatch({type: ActionType.GET_BANK_TRANSACTIONS_ERROR, error: e})
    }
}

export const getFeesFromCategory = (category: string, setFunction: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.GET_FEES_CATEGORY_REQUEST})
        let res = await client.getFeesFromCategory(category).then(result => {
            return result
        })
        setFunction(res[1].primary_sale_fees)
        dispatch({type: ActionType.GET_FEES_CATEGORY_SUCCESS, payload: res})
    } catch (e) {
        dispatch({type: ActionType.GET_FEES_CATEGORY_ERROR, error: e})
    }
}

export const getTokenStats = (uuid: string, setFunction: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.GET_TOKEN_STATS_REQUEST})
        let res = await client.getTokenStats(uuid).then(result => {
            return result[1]
        })
        setFunction(res)
        dispatch({type: ActionType.GET_TOKEN_STATS_SUCCESS, payload: res})
    } catch (e) {
        dispatch({type: ActionType.GET_TOKEN_STATS_ERROR, error: e})
    }
}
export const getIssuerInfo = () => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.GET_ISSUER_INFO_REQUEST})
        let res = await client.getIssuerInfo().then(result => {
            return result[1]
        })
        //setFunction(res[1].issuer_info)
        dispatch({type: ActionType.GET_ISSUER_INFO_SUCCESS, payload: res})
    } catch (e) {
        dispatch({type: ActionType.GET_ISSUER_INFO_ERROR, error: e})
    }
}

export const setIssuerInfo = (info: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.SET_ISSUER_INFO_REQUEST})
        let res = await client.setIssuerInfo(info).then(result => {
            return result
        })
        //console.log(res)
        //setFunction(res)
        dispatch({type: ActionType.SET_ISSUER_INFO_SUCCESS, payload: res})
    } catch (e) {
        dispatch({type: ActionType.SET_ISSUER_INFO_ERROR, error: e})
    }
}
