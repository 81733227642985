import { ActionType } from "../action-types"
import { Actions } from "../actions"

interface submitTokenState {
    loading: boolean
    payload?: any
}

export const defaultSubmitTokenState: submitTokenState = {
    loading: false
}

const submitTokenReducer = (state: submitTokenState = defaultSubmitTokenState, action: Actions): submitTokenState => {
    switch (action.type) {
        case ActionType.SUBMIT_TOKEN_REQUEST:
            return {
                loading: true
            }
        case ActionType.SUBMIT_TOKEN_FAILURE:
            return {
                loading: false,
                payload: action.error
            }
        case ActionType.SUBMIT_TOKEN_SUCCESS:
            return {
                loading: false,
                payload: action.payload
            }
        case ActionType.CLEAR_ALL:
            return {
                loading: false
            }
        default:
            return state
    }
}

export default submitTokenReducer