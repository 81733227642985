import { Actions } from "../actions";
import { ActionType } from "../action-types"

interface uploadMediaState {
    loading: boolean
    payload?: any
    extra?: any[]
}

const defaultUploadMediaState: uploadMediaState = {
    loading: false,
}

const uploadMediaReducer = (state: uploadMediaState = defaultUploadMediaState, action: Actions): uploadMediaState => {
    switch (action.type) {
        case (ActionType.GET_FILE_UPLOAD_LINK_REQUEST):
            return {
                loading: true
            }
        case (ActionType.GET_FILE_UPLOAD_LINK_FAILURE):
            return {
                loading: false,
                payload: action.error
            }
        case (ActionType.GET_FILE_UPLOAD_LINK_SUCCESS):
            return {
                loading: false,
                payload: action.payload,
                extra: action.extra
            }
        case (ActionType.GET_FILE_UPLOAD_LINK_DONE):
            return {
                loading: false
            }
        case (ActionType.CLEAR_ALL):
            return {
                loading: false,
                payload: undefined
            }
        default:
            return state
    }
}

export default uploadMediaReducer