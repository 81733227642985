import Card from "./Card";

export default function CardsCollection(props: {tokensList: any[]}) : JSX.Element {

    const list = props.tokensList ? props.tokensList.filter((t) => t.status !== "Ended") : []
    
    return <>
        {list.length > 0 ?
        <div className="flex flex-wrap place-content-center p-2">
            {list.map((m: any, i: number) => <Card assetInfo={m} key={i} id={i}/>)}
        </div> : <div className="p-3"><div className=" flex place-content-center p-2 text-tertiary font-roboto font-thin text-xl h-96">
                No primary sales available
            </div></div>}
    </>
}