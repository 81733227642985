import { ActionType } from '../action-types'
import { Actions } from '../actions'
import { defaultPoolState, PoolState } from '../../PoolContext'

interface poolState {
    loading: boolean
    payload?: PoolState
}

const defaultRequestPoolState: poolState = {
    loading: false,
    payload: defaultPoolState
}

const poolReducer = (state: poolState = defaultRequestPoolState, action: Actions): poolState => {
    switch(action.type) {
        case ActionType.GET_POOL_REQUEST:
            return {
                loading: true
            }
        case ActionType.GET_POOL_FAILURE:
            return {
                loading: false,
                payload: action.error
            }
        case ActionType.GET_POOL_SUCCESS:
            return {
                loading: false,
                payload: action.payload
            }
        case ActionType.CLEAR_ALL:
            return {
                loading: false,
                payload: undefined
            }
        default:
            return state
    }
}

export default poolReducer