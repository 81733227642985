export class TimezoneDate extends Date{
    utcDate: Date;
    offset: number;

    constructor(date: string, offset: number, addOffset?: boolean) {
        if(date.length <= 0){
            super()
            this.utcDate = new Date()
        }            
        else {
            let targetDate = new Date(date)
            if(addOffset) {
                var tzDifference = offset * 60 + targetDate.getTimezoneOffset();
                super(targetDate)
                this.utcDate = new Date(targetDate.getTime() - tzDifference * 60 * 1000)
            } else {
                var tzDifference = offset * 60 + targetDate.getTimezoneOffset();
                super(targetDate.getTime() + tzDifference * 60 * 1000);
                this.utcDate = targetDate
            }
        }
        this.offset = offset;
    }

    addLeadingZero(s: string): string {
        return (s+"").length > 1 ? s : "0"+s
    }

    // ISO string of the date with the offset
    toString(): string {
        let tmp = super.getFullYear()+"-"+
            this.addLeadingZero((super.getMonth()+1)+"")+"-"+
            this.addLeadingZero(super.getDate()+"")+"T"+
            this.addLeadingZero(super.getHours()+"")+":"+
            this.addLeadingZero(super.getMinutes()+"")
        return tmp
    }

    // ISO string of the UTC date
    toUTCString(): string {
        let newDate = this.utcDate
        let tmp = newDate.toISOString()
        return tmp
    }
}