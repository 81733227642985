import arrow from "../media/arrow3.png"
import { useState } from "react"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import { State } from "../state"
import { getProfileUrl } from "../utils"

export default function MenuRoute(): JSX.Element {
    
    const descriptions = [
        "Here you can see a recap of your account information",
        "Here you can see a recap of your investments, cash balance and liquidity pools",
        "Here you can manage and create assets and tokens",
        "Here you can invest"]
    const[current, setCurrent] = useState(0)
    const basicAccountState = useSelector((state: State) => state.basicAccount)
    const detailAccountState = useSelector((state: State) => state.detailAccount)
    var isInvestor = basicAccountState.payload ? basicAccountState.payload.is_investor : false
    var isIssuer = basicAccountState.payload ? basicAccountState.payload.is_issuer : false
    const profileUrl = getProfileUrl(basicAccountState, detailAccountState)

    const [arrows, setArrows] = useState([false, false, false, false, false])

    function showArrow(idx : number) {
        var tmp = [false, false, false, false, false]
        tmp[idx] = true
        setArrows(tmp)
    }

    function hideArrow() {
        var tmp = [false, false, false, false, false]
        setArrows(tmp)
    }

    function ListItem(props: {n: number, link: string, label: string}): JSX.Element {
        return <li onClick={() => setCurrent(props.n)} className="align-middle">
            <Link to={props.link} className="text-tertiary flex md:justify-end align-middle items-center h-12 
                hover:text-succ2 hover:underline hover:underline-offset-8"
                onMouseEnter={() => {showArrow(props.n); setCurrent(props.n)}}
                onMouseLeave={() => hideArrow()}>
                {props.label}<img src={arrow} className={"h-4 ml-1 "+(arrows[props.n] ? " animate-arrow visible" : " invisible")} alt="->"/>
            </Link>
        </li>
    }

    return <>
        <div className="grid md:grid-cols-2 font-roboto">
            <div className="pr-8">
                <ul className=" font-light text-2xl md:text-right leading-10 tracking-wide list-none">
                    <ListItem n={0} link={profileUrl} label="My profile " />
                    {isInvestor ? <ListItem n={1} link={"/portfolio"} label={"My investor portfolio "}/> : <></>}
                    {isIssuer ? <ListItem n={2} link="/issuer/portfolio" label="My issuer dashboard "/> : <></>}
                    {isInvestor ? <ListItem n={3} link={"/invest"} label={"Invest "} /> : <></>}
                    <li className="text-gray flex md:justify-end align-middle items-center h-12">
                        Borrow <img src={arrow} className={false ? "animate-arrow visible" : "invisible"} alt="->"/></li>
                    <li className="text-gray flex md:justify-end align-middle items-center h-12">
                        Farming <img src={arrow} className={false ? "animate-arrow visible" : "invisible"} alt="->"/></li>
                    <li className="text-gray flex md:justify-end align-middle items-center h-12">
                        Loyalty Program <img src={arrow} className={false ? "animate-arrow visible" : "invisible"} alt="->"/></li>
                    <li className="text-gray flex md:justify-end align-middle items-center h-12">
                        Education <img src={arrow} className={false ? "animate-arrow visible" : "invisible"} alt="->"/></li>
                    <li className="text-gray flex md:justify-end align-middle items-center h-12">
                        F.A.Q. <img src={arrow} className={false ? "animate-arrow visible" : "invisible"} alt="->"/></li>
                </ul>
            </div>
            <div className="flex items-center align-middle p-8">
                <div className="flex flex-col">
                    <h4 className="text-tertiary">What's inside</h4>
                    <p className="text-tertiary">
                        {descriptions[current]}
                    </p>
                </div>
            </div>
        </div>
    </>
}