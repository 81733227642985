import React, { useState } from 'react'
import { AssetInfo, PrimarySaleGet, SecurityToken, defaultBalance } from '../PoolContext'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../state'
import './TradeInterface.css'
import {euroFormat, quantityFormat} from "../utils"

import { LightButton } from './light/LightButton'
import Popup from './Popup'
import LabelAndInput from './LabelAndInput'

export interface PSInterfaceProps {
    asset: AssetInfo
    token: SecurityToken
    primary_sale: PrimarySaleGet
}

export default function BuyPSInterface(props: PSInterfaceProps) {
    
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)

    const [buyAmountPS, setBuyAmountPS] = useState<number>(0)
    const [buyCalculatedPricePS, setBuyCalculatedPricePS] = useState<number>(0)
    const [balance, setBalance] = useState(defaultBalance)
    const [response, setResponse] = useState<any[]>([0, undefined])
    const [submitted, setSubmitted] = useState(false)
    const [fees, setFees] = useState<any>(null)

    const [showPopUp, setShowPopUp] = useState<boolean>(false)
    const [titlePopUp, setTitlePopUp] = useState<string>("")
    const [msgPopUp, setMsgPopUp] = useState<string>("")
    
    function handleClosePopUp(){
        setBuyAmountPS(0)
        setBuyCalculatedPricePS(0)
        setShowPopUp(false)
        setTitlePopUp("")
        setMsgPopUp("")
    }
    
    useEffect(() => {
        actions.getActualBalance(setBalance)
        actions.getFeesFromCategory(props.primary_sale.asset_category, setFees)
    }, [])

    useEffect(() => {
        actions.getFeesFromCategory(props.primary_sale.asset_category, setFees)
    }, [props.primary_sale])
       
    function handleBuyPS(qty: string){ 
        try {
            let qtyFloat = qty.length <= 0 ? 0 : parseFloat(qty)
            setBuyAmountPS(qtyFloat);
            setBuyCalculatedPricePS(qtyFloat * (props.primary_sale.price))
        } catch(e) {
            setBuyAmountPS(0)
            setBuyCalculatedPricePS(0)
        }
    }

    console.log(fees)

    // CONFIRM BUY PS
    function handleBuyPSCommit(e: React.MouseEvent) {
        let currentPrice = /*parseFloat*/(props.primary_sale.price)

        if (buyAmountPS > props.primary_sale.total_supply) {
            setTitlePopUp("Warning")
            setMsgPopUp("Not enough liquidity!")
            setShowPopUp(true)
        } else {
            if (balance.payment_tokens ) {
                if (parseFloat(balance.payment_tokens.total) < buyAmountPS * currentPrice) {
                    setTitlePopUp("Warning")
                    setMsgPopUp("Not enough funds!")
                    setShowPopUp(true)
                } else {
                    if(buyAmountPS > 0) {
                        let buyFromPS = {
                            uuid: props.primary_sale.uuid,
                            amount: buyAmountPS
                        }
                        actions.submitBuyPS(buyFromPS, setResponse)
                        actions.getActualBalance(setBalance)
                        setSubmitted(true)
                    }
                }
            }
        }
    }
    
    useEffect(() => {
        if(response[0] !== 0){
            if(response[0] === 204 && submitted){
                setSubmitted(false)
                setTitlePopUp("Success")
                setMsgPopUp("The transaction has been sent!")
                setShowPopUp(true)
            } else if(submitted){
                setSubmitted(false)
                setTitlePopUp("Warning")
                if(response[1] && response[1].error) {
                    setMsgPopUp(response[1].error)
                } else
                    setMsgPopUp("Transaction has not been sent" )
                setShowPopUp(true)
            }
        }

    }, [response, submitted])

    return (<>
        <Popup show={showPopUp} title={titlePopUp} msg={msgPopUp} btnOk={true} close={handleClosePopUp}  ></Popup>
        <div>
            <div className="bg-tertiary min-h-144 font-roboto p-8 rounded-xl">
                <div className="flex flex-wrap gap-4 justify-between align-middle self-center">
                    <div className="flex text-primary font-semibold self-center">STATUS: <br/>{props.primary_sale.status}</div>
                    <div className='text-4xl font-bold text-primary text-center'>
                        BUY FROM <br /> PRIMARY SALE
                    </div>
                    <div className="flex text-primary font-semibold self-center">BALANCE: <br/>
                        {balance.payment_tokens.total ? "€"+euroFormat(balance.payment_tokens.total) : ""}</div>
                </div>
                <div className='grid grid-cols-2 mt-5'>
                    <LabelAndInput label={'Token'} value={props.primary_sale.asset_name} fun={undefined} 
                        type={'no-input'} color="blue"/>
                    <LabelAndInput label={'Ticker'} value={props.primary_sale.token_ticker} fun={undefined} 
                        type={'no-input'} color="blue"/>
                    <LabelAndInput label={'Max Amount'} type={'no-input'} color="blue" fun={undefined} 
                        value={quantityFormat(props.primary_sale.total_supply-props.primary_sale.shares_issued)} />
                    <LabelAndInput label={'Price'} value={"€"+euroFormat(props.primary_sale.price)} fun={undefined} 
                        type={'no-input'} color="blue"/>
                    <LabelAndInput label={'Quantity'} value={buyAmountPS} fun={handleBuyPS}
                        type={'number'} color="blue"/>
                    <LabelAndInput label={'Calculated Price'} value={"€"+buyCalculatedPricePS.toFixed(2)} fun={undefined} 
                        type={'no-input'} color="blue"/>
                    
                    <div className='col-span-2 text-tertiary bg-primary p-5'>
                        <label className=" text-lg font-semibold">Review</label>
                        <div className="text-base">Estimated Value Purchase: 
                            <strong>{fees && fees.Buyer ? " €"+(buyCalculatedPricePS * (1+fees.Buyer/100)).toFixed(2) : ""}</strong>
                        </div>
                        <div className=" text-base">Estimated Fees: 
                            <strong>{fees && fees.Buyer ? " €"+(buyCalculatedPricePS * fees.Buyer / 100).toFixed(5) : ""}</strong>
                        </div>
                    </div>
                    <div className={" col-span-2 flex content-center w-full pt-5 justify-center text-center place-items-center "}>
                        {props.primary_sale.status === "Started" ? 
                            <LightButton label="Confirm" className="rounded-full px-10 py-2" onClick={handleBuyPSCommit}/> : ""}
                    </div>
                </div>
            </div>
        </div> </>)
}

