import { ActionType } from "../action-types"
import { Actions } from "../actions"

interface submitAssetState {
    loading: boolean
    payload?: any
}

export const defaultSubmitAssetState: submitAssetState = {
    loading: false
}

const submitAssetReducer = (state: submitAssetState = defaultSubmitAssetState, action: Actions): submitAssetState => {
    switch (action.type) {
        case ActionType.SUBMIT_ASSET_REQUEST:
            return {
                loading: true
            }
        case ActionType.SUBMIT_ASSET_FAILURE:
            return {
                loading: false,
                payload: action.error
            }
        case ActionType.SUBMIT_ASSET_SUCCESS:
            return {
                loading: false,
                payload: action.payload
            }
        case ActionType.CLEAR_ALL:
            return {
                loading: false
            }
        default:
            return state
    }
}

export default submitAssetReducer