import React, { useEffect, useState } from "react";
import LabelAndInput from "./LabelAndInput"
import { defaultSportResult } from "../AssetContext";

export default function SportResultRow(props: {info?: any, className?: string, rows: any, setRows: any, idx: number}): JSX.Element {
    
    var values = props.info ? props.info : defaultSportResult

    const [rank, setRank] = useState(values.rank)
    const [year, setYear] = useState(values.year)
    const [rider, setRider] = useState(values.rider)
    const [height, setHeight] = useState(values.height)
    const [competition, setCompetition] = useState(values.competition)

    useEffect(() => {
        
        let tmp :any[] = []
        if(props.rows) tmp = [...props.rows]
        tmp[props.idx] = {"rank": rank, "year": parseInt(year), "rider": rider, "height": height, "competition": competition}
        props.setRows(tmp)

    }, [rank, year, rider, height, competition])

    useEffect(() => {
        setYear(values.year)
        setRank(values.rank)
        setRider(values.rider)
        setHeight(values.height)
        setCompetition(values.competition)
    }, [props.info])
    
    return <>
        <div className={props.className+" font-urbanist py-2 tracking-wider grid grid-cols-12"}>
            <LabelAndInput label={"Rank"} value={rank} fun={setRank} type={"text"} className={"col-span-1"} />
            <LabelAndInput label={"Year"} value={year} fun={setYear} type={"number"} className={"col-span-1"}/>
            <LabelAndInput label={"Rider"} value={rider} fun={setRider} type={"text"} className={"col-span-3"}/>
            <LabelAndInput label={"Height"} value={height} fun={setHeight} type={"text"} className={"col-span-1"} />
            <LabelAndInput label={"Competition"} value={competition} fun={setCompetition} type="text" className="col-span-6" />
        </div>
    </>
}