import { Balance, defaultBalance } from "../../PoolContext"
import { ActionType } from "../action-types"
import { Actions } from "../actions"

interface balanceState {
    loading: boolean
    payload?: Balance
}

const defaultBalanceState: balanceState = {
    loading: false,
    payload: defaultBalance
}

const balanceReducer = (state: balanceState = defaultBalanceState, action: Actions): balanceState =>  {
    switch (action.type) {
        case(ActionType.GET_BALANCE_REQUEST):
            return {
                loading: true,
                payload: defaultBalance
            }
        case(ActionType.GET_BALANCE_FAILURE):
            return {
                loading: false,
                payload: action.error
            }
        case(ActionType.GET_BALANCE_SUCCESS):
            return {
                loading: false,
                payload: action.payload
            }
            case(ActionType.CLEAR_ALL):
            return {
                loading: false,
                payload: undefined
            }
        default:
            return state
    }
}

export default balanceReducer