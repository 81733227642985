import React, { useEffect, useState } from "react"
import { UserAccount, UserContext } from "../UserContext"
import { bindActionCreators } from "redux";
import { actionCreators, persistor, State } from "../state";
import { useHistory  } from 'react-router-dom'
import LabelAndInput from "../components/LabelAndInput";
import { LightButton } from "../components/light/LightButton";
import {checkRegexEmail, checkStrongPassword} from "../utils"
import { useDispatch, useSelector } from "react-redux";
import Popup from '../components/Popup'

export default function SignUpRoute(): JSX.Element {
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    let history = useHistory();
    const createAccountStatus = useSelector((state: State) => state.createAccount)

    const[email, setEmail] = useState<string>("")
    const[password, setPassword] = useState<string>("")
    const[passwordConfirm, setPasswordConfirm] = useState<string>("")
    const[msgShown, setMsgShown] = useState<boolean>(false)

    const [policyChecked, setPolicyChecked] = React.useState(false);
    const handleChangePolicy = () => { setPolicyChecked(!policyChecked); };

    //const [issuerChecked, setIssuerChecked] = React.useState(false);
    //const handleChangeIssuer = () => { setIssuerChecked(!issuerChecked); };

    const [showPopUp, setShowPopUp] = useState<boolean>(false)
    const [titlePopUp, setTitlePopUp] = useState<string>("")
    const [msgPopUp, setMsgPopUp] = useState<string>("")
    function handleClosePopUp(){
        setTitlePopUp("")
        setMsgPopUp("")
        setShowPopUp(false)
    }

    useEffect(() => {
        if(createAccountStatus.payload && !msgShown) {
            switch(JSON.stringify(createAccountStatus.payload)) {
                case "[204,null]":
                    setMsgShown(true)
                    if(email.length > 0) history.push('/verify-email')
                    break
                case '[400,{"error":"Email already exists"}]':
                    setEmail("")
                    setPassword("")
                    setPasswordConfirm("")
                    setTitlePopUp("Warning")
                    setMsgPopUp("Error during the registration. The email already exist in the server")
                    setShowPopUp(true)
                    break
                case '[400,{"error":"Bad password requirements"}]':
                    setPassword("")
                    setPasswordConfirm("")
                    setTitlePopUp("Warning")
                    setMsgPopUp("Password must meet complexity requirements")
                    setShowPopUp(true)
                    break
                default:
                    setEmail("")
                    setPassword("")
                    setPasswordConfirm("")
                    setTitlePopUp("Process Error")
                    setMsgPopUp("Error during the registration")
                    setShowPopUp(true)
            }
            setMsgShown(true)
            persistor.purge()
        }
    }, [createAccountStatus.payload])

    function handleSubmitSignUpAccount(e: React.MouseEvent) {
        const newUser: UserAccount = {
            email: email,
            password: password,
            confirm_password: passwordConfirm,
            is_issuer: false,
            is_investor: false,
            is_employee: false,
            timezone_value: "",
            timezone_offset: 0
        }

        if(!checkRegexEmail(email)){
            setTitlePopUp("Warning")
            setMsgPopUp("Email format is incorrect")
            setShowPopUp(true)
        } else {
            if(!checkStrongPassword(email, password)){
                setTitlePopUp("Warning")
                setMsgPopUp("Password must meet complexity requirements")
                setShowPopUp(true)
            } else {
                if(password !== passwordConfirm){
                    setTitlePopUp("Warning")
                    setMsgPopUp("Password and password confirmation don't match")
                    setShowPopUp(true)
                }
                else {
                    if(!policyChecked){
                        setTitlePopUp("Warning")
                        setMsgPopUp("You must accept Privacy Policy")
                        setShowPopUp(true)
                    } else {
                        actions.createUserAccount(newUser)
                    }
                }
            }
        }
        setMsgShown(false)
    }

    function labelAndCheckbox(label: string, value: any, fun: any, col: string) {
        return <>
        <span className={"checkbox " + col}>
            <input type="checkbox" checked={value} onChange={fun}/>
            <span className="wrapper" onClick={fun}>
                <span className="knob"></span>
            </span>
            <label className="white-label">{label}</label>
        </span>
        </>
    }

    let leftTitle = ["Welcome!","Please provide your","e-mail and password to","begin your account", "set up"]
    return (
        <>
            <Popup show={showPopUp} title={titlePopUp} msg={msgPopUp} btnOk={true} close={handleClosePopUp} ></Popup>
            <div className="w-full grid md:grid-cols-12 grid-cols-1">
                <div className="grid col-span-5 text-white justify-center items-center uppercase
                        font-jost font-thin md:text-5xl text-4xl tracking-wide md:pl-40 pl-2">
                    <div>
                        {leftTitle.map((i, index) => <div key={index}>{i}</div>)}
                    </div>
                </div>
                <div className="grid col-span-7 px-10 justify-center items-center">
                    <LabelAndInput label="E-mail" value={email} fun={setEmail} type="text" />
                    <LabelAndInput label="Password" value={password} fun={setPassword} type="password" passwordTip={true}/>
                    <LabelAndInput label="Confirm Password" value={passwordConfirm} fun={setPasswordConfirm} type="password" />

                    {labelAndCheckbox("  I accept privacy policy", policyChecked, handleChangePolicy, "w-full py-3")}
                    {/*labelAndCheckbox("  I would also like to issue or list securities", issuerChecked, handleChangeIssuer, "col-12")*/}

                    <div>
                        <LightButton label={"submit"} onClick={handleSubmitSignUpAccount} alternate={true} className="rounded-2xl px-10 py-2 mt-10 "/>
                    </div>
                </div>
            </div>
        </>)
}