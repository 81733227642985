import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import LabelAndDescription from "../components/LabelAndDescription";
import { LightButton } from "../components/light/LightButton";
import TabsMenu from "../components/TabsMenu";
import { TitleColor1 } from "../components/typography/Typogtaphy";
import PrettyJSON from "../components/utils/PrettyJSON";
import { actionCreators } from "../state";

export default function BlockchainLookup(props: any): JSX.Element {
    const hash = props.match.params.trx ? ""+props.match.params.trx : ""
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const t = {
        hash: hash,
        status: 1,
        price: 0.1,
        amount: 20,
        transaction_type: "BUY",
        transaction_category: "TRADE",
        timestamp: "12:12:12.12121212",
        order_type: "A",
        from: "0xe5bd3f293239adef9f13eba80f327a5076ae418b",
        to: "0xf7ac69e81386706328c7307e1557b4758f314b8d"
    }
    const [getTrxDetails, setGetTrxDetails] = useState([])
    const [trxDetails, setTrxDetails] = useState({
        transaction: {
            status: 0,
            response: {
                header: {
                    txn_id: "",
                    txn_type: ""
                },
                payload: {
                    amount: "",
                    price: "",
                    timestamp: "",
                    txn_category: "",
                    txn_type: ""
                }
            }
        }
    })

    useEffect(() => {
        actions.getBlockchainTransactionDetails("bd0f2f43-9d0c-42e1-a106-e661d83aaeb0", setGetTrxDetails)
    }, [])

    useEffect(() => {
        if(getTrxDetails.length > 0)
            setTrxDetails(getTrxDetails[1])
    }, [getTrxDetails])

    function showOverview() {
        return <>
            {
            <div className="grid px-5 lg:grid-cols-2">
                <LabelAndDescription label={"Hash"} description={hash} alternate={true}/>
                <LabelAndDescription label={"transaction type (TBC)"} description={trxDetails.transaction.response.header.txn_type} alternate={true}/>
                <LabelAndDescription label={""} description={""} alternate={true} />
                <LabelAndDescription label={""} description={""} alternate={true} />
                <LabelAndDescription label={""} description={""} alternate={true} />
                {(trxDetails.transaction.response.payload ? <>
                        <LabelAndDescription label={"price"} description={trxDetails.transaction.response.payload.price+"€"} alternate={true}/>
                        <LabelAndDescription label={"amount"} description={trxDetails.transaction.response.payload.amount.toString()} alternate={true}/>
                        <LabelAndDescription label={"transaction type"} description={trxDetails.transaction.response.payload.txn_type} alternate={true}/>
                        <LabelAndDescription label={"transaction category"} description={trxDetails.transaction.response.payload.txn_category}  alternate={true}/>
                        <LabelAndDescription label={"timestamp"} description={trxDetails.transaction.response.payload.timestamp}  alternate={true}/>
                    </> :
                    <></>)}        
            </div>}
        </>
    }
    
    function showJson() {
        return (
            <div className="grid px-5">
                <div className="flex place-items-center font-roboto text-tertiary font-thin m-5 text-lg">
                    Download
                    <a
                        href={`data:text/json;charset=utf-8,${encodeURIComponent(
                        JSON.stringify(trxDetails)
                        )}`}
                        download={getTrxDetails.length+".json"}
                    >
                        <LightButton label={"Json"} className="ml-5 rounded-xl px-5 bg-transparent border-succ2" alternate={true}/>
                    </a> 
                </div>
                <div className="m-2 text-sm">
                    <PrettyJSON json={trxDetails}/>
                    
                </div>
            </div>
        )
    }

    return <>
        <div className="mx-20 min-h-80 p-10 ">
            <div className="text-4xl uppercase text-primary font-roboto my-10 text-center">
                <TitleColor1>Transaction details</TitleColor1>
            </div>
            <div className={"font-code-pro"}>
                <div className="flex uppercase text-tertiary font-semibold h-6 place-items-center place-content-center">
                    Status:
                    {(trxDetails.transaction.status === 200) ? <div className="h-6 w-fit px-3 rounded-xl bg-succ2
                        text-green-800 normal-case ml-5 flex place-items-center">
                        <div className="h-2 w-2 bg-green-800 rounded-xl mr-2 "></div>
                        Success
                    </div> :
                    ((trxDetails.transaction.status) ?
                    <div className="h-6 w-fit px-3 rounded-xl bg-error1 text-red-800 normal-case ml-5 flex place-items-center">
                        <div className="h-2 w-2 bg-red-800 rounded-xl mr-2 mt-1"></div>
                        Fail
                    </div> : 
                    <div className="h-6 w-fit px-3 rounded-xl bg-warning text-error1 normal-case ml-5 flex place-items-center">
                        <div className="h-2 w-2 bg-error1 rounded-xl mr-2 mt-1"></div>
                        Loading
                    </div>)
                    }
                </div>
                    <TabsMenu headers={["Overview", "Json"]} contents={[showOverview(), showJson()]} />
                <div>

                </div>
            </div>
        </div>
        
    </>
}