import { bindActionCreators } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { LightButton } from "../components/light/LightButton";
import LightTable from "../components/light/LightTable";
import { actionCreators, State } from "../state";
import { euroFormat, formatDate, formatExpiringDate } from "../utils";
import { Link } from "react-router-dom"
import {TabsMenuWithFilters} from "../components/TabsMenu";
import { Asset } from "../AssetContext";
import { PrimarySaleGet } from "../PoolContext";
import { HeaderButton } from "../components/HeaderButton";

export default function IssuerDashboard(): JSX.Element {
    interface IssuedTokens {
        initialized_tokens: any[],
        in_process_tokens: any[],
        submitted_tokens: any[],
        rejected_tokens: any[],
        denied_tokens: any[],
        approved_tokens: any[],
        listed_tokens: any[]
    }
    interface IssuedAssets {
        initialized_assets: any[],
        in_process_assets: any[],
        submitted_assets: any[],
        approved_assets: any[],
        denied_assets: any[],
        rejected_assets: any[],
        tokenization_in_process_assets: any[],
        tokenized_assets: any[]
    }
    var defaultIssuedTokens: IssuedTokens = {
        initialized_tokens: [],
        in_process_tokens: [],
        submitted_tokens: [],
        rejected_tokens: [],
        denied_tokens: [],
        approved_tokens: [],
        listed_tokens: []
    }
    var defaultIssuedAssets: IssuedAssets = {
        initialized_assets: [],
        in_process_assets: [],
        submitted_assets: [],
        denied_assets: [],
        rejected_assets: [],
        approved_assets: [],
        tokenization_in_process_assets: [],
        tokenized_assets: []
    }

    let history = useHistory();
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const detailAccountState = useSelector((state: State) => state.detailAccount)
    const userCtx = detailAccountState.payload
    const issuedTokensState = useSelector((state: State) => state.issuedTokens)
    const issuedAssetsState = useSelector((state: State) => state.issuedAssets)

    const [issuedTokens, setIssuedToken] = useState(defaultIssuedTokens)
    const [issuedAssets, setIssuedAssets] = useState(defaultIssuedAssets)
    const [issuedPrimarySales, setIssuedPrimarySales] = useState<PrimarySaleGet[]>([])
    
    /* FILTERS */
    const [showFilters, setShowFilters] = useState(false)
    const [showInProcessAssets, setShowInProcessAssets] = useState(true)
    const [showSubmittedAssets, setShowSubmittedAssets] = useState(true)
    const [showRejectedAssets, setShowRejectedAssets] = useState(true)
    const [showDeniedAssets, setShowDeniedAssets] = useState(true)
    const [showApprovedAssets, setShowApprovedAssets] = useState(true)
    const [showTokenizationSubmittedAssets, setShowTokenizationSubmittedAssets] = useState(true)
    const [showTokenizedAssets, setShowTokenizedAssets] = useState(true)
    
    const [showTokens, setShowTokens] = useState(true)
    //const [showInitializedTokens, setShowInitializedTokens] = useState(true)
    const [showInProcessTokens, setShowInProcessTokens] = useState(true)
    const [showSubmittedTokens, setShowSubmittedTokens] = useState(true)
    const [showRejectedTokens, setShowRejectedTokens] = useState(true)
    const [showDeniedTokens, setShowDeniedTokens] = useState(true)
    const [showApprovedTokens, setShowApprovedTokens] = useState(true)
    const [showListedTokens, setShowListedTokens] = useState(true)
    
    const [showPrimarySales, setShowPrimarySales] = useState(true)
    const [showPSIncoming, setShowPSIncoming] = useState(true)
    const [showPSActive, setShowPSActive] = useState(true)
    const [showPSExpired, setShowPSExpired] = useState(true)
    const [filteredPS, setFilteredPS] = useState<PrimarySaleGet[]>([])

    const hdsExpiringTokens = ["cat", "type", "ticker", "total supply", "max circulating perc.", "max circulating supply", "available tokens", "tokens sold", "proposed price", "currency", "expiring", "txn id"]
    const hdsTokens = ["cat", "type", "ticker", "total supply", "max circulating perc.", "max circulating supply", "available tokens", "tokens sold", "proposed price", "currency", "txn id"]
    const hdsSubmittedTokens = ["cat", "type", "ticker", "total supply", "max circulating perc.", "max circulating supply", "proposed price", "currency"]
    const hdsExpiringAssets = ["cat", "type", "issuer", "name", "currency", "expiring"]
    const hdsAssets = ["cat", "type", "issuer", "name", "currency"]
    const hdsTokenizedAssets = ["cat", "type", "issuer", "name"]
    const hdsPrimarySales = ["ticker", "status", "start", "end", "listed supply", "circulating supply", "on sale", "price"]

    let arrayTmp = window.location.href.split("#")
    let tabToshow = arrayTmp[1] && arrayTmp[1] !== "" ? arrayTmp[1] : ""
    
    const offset = userCtx?.timezone_offset ? userCtx.timezone_offset : 0

    useEffect(() => {
        actions.getIssuedTokens()
        actions.getIssuedAssets()
        actions.getIssuerPrimarySales(setIssuedPrimarySales)
    }, [])

    useEffect(() => {
        if (issuedTokensState.payload)
            setIssuedToken(issuedTokensState.payload)
    }, [issuedTokensState.payload])

    useEffect(() => {
        if (issuedAssetsState.payload)
            setIssuedAssets(issuedAssetsState.payload)
    }, [issuedAssetsState.payload])

    useEffect(() => {
        let filtered = [...issuedPrimarySales]
        let array_status:String[] = []
        if(showPSIncoming) array_status.push("Initialized")
        if(showPSActive) array_status.push("Started","Stopped")
        if(showPSExpired) array_status.push("Ended")
        if(showPSIncoming || showPSActive || showPSExpired) 
            filtered = filtered.filter((i) => {return array_status.includes(i.status)})
        setFilteredPS(filtered)
    }, [showPSIncoming, showPSActive, showPSExpired, issuedPrimarySales])

    function addBtns(row: any[], btnLabels: string[], btnUrls: string[], uuid: any) {
        let tmp = btnLabels.map((b, i) => {return <div><TableButton label={b} link={btnUrls[i] + uuid} key={i}/></div>})
        return [...row, ...tmp]
    }
    function tokenExpiringRows(l: any[], btnUrls: string[], btnLabels: string[]): any[] {
        return l ?
            l.map(t => {
                let a = [t.asset_class, t.asset_type, t.ticker, t.total_supply, t.pct_tokenized, 
                    (t.total_supply * t.pct_tokenized / 100), t.total_available !== undefined ? t.total_available : t.total_supply, 
                    euroFormat(t.shares_issued), euroFormat(t.price === undefined ? t.proposed_price : t.price), t.currency_cd, 
                    <Link to={"/blockchain/transaction/"+t.trx_chain_id}>{t.trx_chain_id}</Link>, formatExpiringDate(t.date_created, offset)]
                return addBtns(a, btnLabels, btnUrls, t.asset_uuid)}
            ) : []
    }

    function tokenRows(l: any[], btnUrls: string[], btnLabels: string[]): any[] {
        return l ?
            l.map(t => {
                let a = [t.asset_class, t.asset_type, t.ticker,t.total_supply, 
                    t.pct_tokenized, (t.total_supply * t.pct_tokenized / 100), 
                    t.total_available !== undefined ? t.total_available : t.total_supply, euroFormat(t.shares_issued),
                    euroFormat(t.price === undefined ? t.proposed_price : t.price), t.currency_cd,
                    <Link to={"/blockchain/transaction/"+t.trx_chain_id}>{t.trx_chain_id}</Link>]
                return addBtns(a, btnLabels, btnUrls, t.asset_uuid)}
            ) : []
    }
    function tokenSubmittedRows(l: any[], btnUrls: string[], btnLabels: string[]): any[] {
        return l ?
            l.map(t => {
                let a = [t.asset_class, t.asset_type, t.ticker,t.total_supply, t.pct_tokenized, 
                    (t.total_supply * t.pct_tokenized / 100), 
                    euroFormat(t.price === undefined ? t.proposed_price : t.price), t.currency_cd]
                return addBtns(a, btnLabels, btnUrls, t.asset_uuid)}
            ) : []
    }

    function assetExpiringRows(l: Asset[], btnLabels: string[], btnUrls: string[]): any[] {
        return l ?
            l.map(t => {
                let a = [t.asset_category, t.asset_type, t.issuer_name, t.name, t.currency_cd, formatExpiringDate(t.date_created, offset)]
                return addBtns(a, btnLabels, btnUrls, t.uuid)}
                ) : []
    }

    function assetRows(l: Asset[], btnLabels: string[], btnUrls: string[]): any[] {
        return l ? l.map(t => addBtns([t.asset_category, t.asset_type, t.issuer_name, t.name, t.currency_cd], btnLabels, btnUrls, t.uuid)) : []
    }

    function assetTokenizedRows(l: Asset[], btnLabels: string[], btnUrls: string[]): any[] {
        return l ?
            l.map(t => addBtns([t.asset_category, t.asset_type, t.issuer_name, t.name], btnLabels, btnUrls,t.uuid)) : []
    }

    function primarySaleRows(l: any[], btnUrls: string[], btnLabels: string[]): any[] {
        return l ?
            l.map(t => addBtns([t.token_ticker, t.status, formatDate(t.date_start, offset), formatDate(t.date_end, offset), 
                euroFormat(t.total_supply), euroFormat(t.shares_issued), euroFormat(t.total_supply - t.shares_issued), 
                euroFormat(t.price)],btnLabels, btnUrls, t.uuid)
            ) : []
    }

    function TableButton(props: { label: string, link: string }): JSX.Element {
        return <>
            <Link className="grid justify-center" to={props.link}><LightButton label={props.label} className="px-2 rounded m-1 font-normal" /></Link>
        </>
    }

    function AssetFilters(): JSX.Element {
        return <>
            <div className={"col-span-4 font-urbanist py-2 tracking-wider"}>   
                    <label className="w-full text-white uppercase text-sm py-4">
                        <input type="checkbox" checked={showInProcessAssets ? true: false} className={"mr-4"}
                        onChange={() => { setShowInProcessAssets(!showInProcessAssets); }} />
                        In Process Assets
                    </label>
            </div>
            <div className={"col-span-4 font-urbanist py-2 tracking-wider"}>   
                    <label className="w-full text-white uppercase text-sm py-4">
                        <input type="checkbox" checked={showSubmittedAssets ? true: false} className={"mr-4"}
                        onChange={() => { setShowSubmittedAssets(!showSubmittedAssets); }} />
                        Submitted Assets
                    </label>
            </div>
            <div className={"col-span-4 font-urbanist py-2 tracking-wider"}>   
                    <label className="w-full text-white uppercase text-sm py-4">
                        <input type="checkbox" checked={showRejectedAssets ? true: false} className={"mr-4"}
                        onChange={() => { setShowRejectedAssets(!showRejectedAssets); }} />
                        Pending Notifications
                    </label>
            </div>
            <div className={"col-span-4 font-urbanist py-2 tracking-wider"}>   
                    <label className="w-full text-white uppercase text-sm py-4">
                        <input type="checkbox" checked={showDeniedAssets ? true: false} className={"mr-4"}
                        onChange={() => { setShowDeniedAssets(!showDeniedAssets); }} />
                        Denied Assets
                    </label>
            </div>
            <div className={"col-span-4 font-urbanist py-2 tracking-wider"}>   
                    <label className="w-full text-white uppercase text-sm py-4">
                        <input type="checkbox" checked={showApprovedAssets ? true: false} className={"mr-4"}
                        onChange={() => { setShowApprovedAssets(!showApprovedAssets); }} />
                        Approved Assets
                    </label>
            </div>
            <div className={"col-span-4 font-urbanist py-2 tracking-wider"}>   
                    <label className="w-full text-white uppercase text-sm py-4">
                        <input type="checkbox" checked={showTokenizationSubmittedAssets ? true: false} className={"mr-4"}
                        onChange={() => { setShowTokenizationSubmittedAssets(!showTokenizationSubmittedAssets); }} />
                        Tokenization Submitted Assets
                    </label>
            </div>
            <div className={"col-span-4 font-urbanist py-2 tracking-wider"}>   
                    <label className="w-full text-white uppercase text-sm py-4">
                        <input type="checkbox" checked={showTokenizedAssets ? true: false} className={"mr-4"}
                        onChange={() => { setShowTokenizedAssets(!showTokenizedAssets); }} />
                        Tokenized Assets
                    </label>
            </div>
        </>
    }

    function TokenFilters(): JSX.Element {
        return <>
            <div className={"col-span-6 font-urbanist py-2 tracking-wider text-center"}>   
                    <label className="w-full text-white uppercase text-sm py-4">
                        <input type="checkbox" checked={showTokens ? true: false} className={"mr-4"}
                        onChange={() => { setShowTokens(!showTokens); }} />
                        Show tokens
                    </label>
            </div>
            <div className={"col-span-4 font-urbanist py-2 tracking-wider text-center"}>   
                    <label className="w-full text-white uppercase text-sm py-4">
                        <input type="checkbox" checked={showPrimarySales ? true: false} className={"mr-4"}
                        onChange={() => { setShowPrimarySales(!showPrimarySales); }} />
                        Show Primary Sales
                    </label>
            </div>
            {showTokens || showPrimarySales ? (<div className="m-5 col-span-12"></div>) : "" }
            {
                showTokens ? (<>
                    <div className={"col-span-4 font-urbanist py-2 tracking-wider"}>   
                            <label className="w-full text-white uppercase text-sm py-4">
                                <input type="checkbox" checked={showInProcessTokens ? true: false} className={"mr-4"}
                                onChange={() => { setShowInProcessTokens(!showInProcessTokens); }} />
                                In Process Tokens
                            </label>
                    </div>
                    <div className={"col-span-4 font-urbanist py-2 tracking-wider"}>   
                            <label className="w-full text-white uppercase text-sm py-4">
                                <input type="checkbox" checked={showSubmittedTokens ? true: false} className={"mr-4"}
                                onChange={() => { setShowSubmittedTokens(!showSubmittedTokens); }} />
                                Submitted Tokens
                            </label>
                    </div>
                    <div className={"col-span-4 font-urbanist py-2 tracking-wider"}>   
                            <label className="w-full text-white uppercase text-sm py-4">
                                <input type="checkbox" checked={showRejectedTokens ? true: false} className={"mr-4"}
                                onChange={() => { setShowRejectedTokens(!showRejectedTokens); }} />
                                Pending Notifications
                            </label>
                    </div>
                    <div className={"col-span-4 font-urbanist py-2 tracking-wider"}>   
                            <label className="w-full text-white uppercase text-sm py-4">
                                <input type="checkbox" checked={showDeniedTokens ? true: false} className={"mr-4"}
                                onChange={() => { setShowDeniedTokens(!showDeniedTokens); }} />
                                Denied Tokens
                            </label>
                    </div>
                    <div className={"col-span-4 font-urbanist py-2 tracking-wider"}>   
                            <label className="w-full text-white uppercase text-sm py-4">
                                <input type="checkbox" checked={showApprovedTokens ? true: false} className={"mr-4"}
                                onChange={() => { setShowApprovedTokens(!showApprovedTokens); }} />
                                Approved Tokens
                            </label>
                    </div>
                    <div className={"col-span-4 font-urbanist py-2 tracking-wider"}>   
                            <label className="w-full text-white uppercase text-sm py-4">
                                <input type="checkbox" checked={showListedTokens ? true: false} className={"mr-4"}
                                onChange={() => { setShowListedTokens(!showListedTokens); }} />
                                Listed Tokens
                            </label>
                    </div>
                </>) : ""
            }
            
            {showTokens && showPrimarySales ? (<div className="m-5 col-span-12"></div>) : "" }

            {
                showPrimarySales ? (<>
                    <div className={"col-span-4 font-urbanist py-2 tracking-wider"}>   
                            <label className="w-full text-white uppercase text-sm py-4">
                                <input type="checkbox" checked={showPSIncoming ? true: false} className={"mr-4"} 
                                onChange={() => { setShowPSIncoming(!showPSIncoming); }}/>
                                Primary Sales - Incoming
                            </label>
                    </div>
                    <div className={"col-span-4 font-urbanist py-2 tracking-wider"}>   
                            <label className="w-full text-white uppercase text-sm py-4">
                                <input type="checkbox" checked={showPSActive ? true: false} className={"mr-4"} 
                                onChange={() => { setShowPSActive(!showPSActive); }}/>
                                Primary Sales - Active
                            </label>
                    </div>
                    <div className={"col-span-4 font-urbanist py-2 tracking-wider"}>   
                            <label className="w-full text-white uppercase text-sm py-4">
                                <input type="checkbox" checked={showPSExpired ? true: false} className={"mr-4"}
                                onChange={() => { setShowPSExpired(!showPSExpired); }} />
                                Primary Sales - Expired
                            </label>
                    </div>
                </>) : ""
            }
            <div className="m-5 col-span-12"></div>
        </>
    }

    function AssetsTables(toBeShown: boolean[]): JSX.Element {
        const [tbs, setTbs] = useState<boolean[]>(toBeShown)
        function handleClick(id: number) {
            var tmp = tbs.map((b: boolean, i: number) => i === id ? !b : b)
            setTbs(tmp)
        }
        function ColoredTab(props: {label: string, color: string, index: number, headers: any, rows: any}): JSX.Element {
            return <div>
                <HeaderButton name={props.label} click={() => handleClick(props.index)}
                        className={" port-link cursor-pointer pl-10 bg-"+(props.index % 2 === 0 ? "primary" : "transparent")}
                        color1={props.color} color2="white" shown={tbs[props.index]}/>
                {tbs[props.index] ? <>
                    {props.rows.length > 0 ?
                    <LightTable headers={props.headers} rows={props.rows}
                        colorHd={"gradient-to-r from-"+props.color+" to-gray3"}
                        color1={"gradient-to-r from-"+props.color+"_lighter  to-gray1"}
                        color2={"gradient-to-r from-"+props.color+"_light to-gray2"} /> :
                    <div className="bg-white">
                        <div className={"font-roboto uppercase text-gray pl-10 py-2 bg-"+props.color+"_light"}>None</div>
                    </div>}
                    
                </> : ""}
            </div>
        }
        return <>
            {showInProcessAssets && issuedAssets.in_process_assets ?
                <ColoredTab label={"In process Assets ("+issuedAssets.in_process_assets.length+")"} 
                    color={"seafoam"} index={0} headers={[...hdsExpiringAssets, ...["", ""]]} 
                    rows={assetExpiringRows(issuedAssets.in_process_assets, 
                        ["modify", "add info"], ["/issuer/asset/manage/", "/issuer/extra-info/"])} />
                : ""
            }
            
            {showSubmittedAssets && issuedAssets.submitted_assets ? 
                <ColoredTab label={"Submitted Assets ("+issuedAssets.submitted_assets.length+")"} 
                    color={"succ4"} index={1} headers={hdsAssets} 
                    rows={assetRows(issuedAssets.submitted_assets, [], [])} />
                : ""
            }

            {showRejectedAssets && issuedAssets.rejected_assets ?
                <ColoredTab label={"Pending Notifications ("+issuedAssets.rejected_assets.length+")"} 
                    color={"seafoam"} index={2} headers={[...hdsExpiringAssets, ...["", ""]]} 
                    rows={assetExpiringRows(issuedAssets.rejected_assets, ["modify", "add info"], 
                        ["/issuer/asset/manage/", "/issuer/extra-info/"])} />
                : ""
            }
            {showDeniedAssets && issuedAssets.denied_assets ? 
                <ColoredTab label={"Denied assets ("+issuedAssets.denied_assets.length+")"} 
                    color={"succ4"} index={3} headers={hdsExpiringAssets} 
                    rows={assetExpiringRows(issuedAssets.denied_assets, [], [])} />
                 : ""
            }
            {showApprovedAssets && issuedAssets.approved_assets ?
                <ColoredTab label={"Approved assets ("+issuedAssets.approved_assets.length+")"} 
                    color={"seafoam"} index={4} headers={[...hdsExpiringAssets, ...[""]]} 
                    rows={assetRows(issuedAssets.approved_assets, ["tokenize"], ["/issuer/asset/tokenize/"])} />
                : ""
            }
            {showTokenizationSubmittedAssets && issuedAssets.tokenization_in_process_assets ?
                <ColoredTab label={"Tokenization In Process assets ("+issuedAssets.tokenization_in_process_assets.length+")"} 
                    color={"succ4"} index={5} headers={[...hdsAssets, ...[""]]} 
                    rows={assetRows(issuedAssets.tokenization_in_process_assets, ["detail"], ["/issuer/asset/tokenize/"])} />
                : ""
            }
            {showTokenizedAssets && issuedAssets.tokenized_assets ?
                <ColoredTab label={"Tokenized assets ("+issuedAssets.tokenized_assets.length+")"} 
                    color={"seafoam"} index={6} headers={hdsTokenizedAssets} 
                    rows={assetTokenizedRows(issuedAssets.tokenized_assets, [], [])} />
                : ""
            }
        </>
    }

    function TokensTables(toBeShown: boolean[]): JSX.Element {
        const [tbs, setTbs] = useState<boolean[]>(toBeShown)
        function handleClick(id: number) {
            var tmp = tbs.map((b: boolean, i: number) => i === id ? !b : b)
            setTbs(tmp)
        }
        function ColoredTab(props: {label: string, color: string, index: number, headers: any, rows: any}): JSX.Element {
            return <div>
                <HeaderButton name={props.label} click={() => handleClick(props.index)}
                        className={" port-link cursor-pointer pl-10 bg-"+(props.index % 2 === 0 ? "primary" : "transparent")}
                        color1={props.color} color2="white" shown={tbs[props.index]}/>
                {tbs[props.index] ? <>
                    {props.rows.length > 0 ?
                    <LightTable headers={props.headers} rows={props.rows}
                        colorHd={"gradient-to-r from-"+props.color+" to-gray3"}
                        color1={"gradient-to-r from-"+props.color+"_lighter  to-gray1"}
                        color2={"gradient-to-r from-"+props.color+"_light to-gray2"} /> :
                    <div className="bg-white">
                        <div className={"font-roboto uppercase text-gray pl-10 py-2 bg-"+props.color+"_light"}>None</div>
                    </div>}
                    
                </> : ""}
            </div>
        }
        return <>
            {showTokens && showInProcessTokens && issuedTokens.in_process_tokens ? 
                <ColoredTab label={"In process tokens ("+issuedTokens.in_process_tokens.length+")"} 
                    color={"seafoam"} index={0} headers={hdsExpiringTokens} 
                    rows={tokenExpiringRows(issuedTokens.in_process_tokens, 
                    ["/issuer/asset/tokenize/"], ["modify"])} />
                : ""
            }
            {showTokens && showSubmittedTokens && issuedTokens.submitted_tokens ? 
                <ColoredTab label={"Sumbitted tokens ("+issuedTokens.submitted_tokens.length+")"} 
                    color={"succ4"} index={1} headers={hdsSubmittedTokens} 
                    rows={tokenSubmittedRows(issuedTokens.submitted_tokens, ["/issuer/tokens/"], ["details"])} />
                : ""
            }
            {showTokens && showRejectedTokens && issuedTokens.rejected_tokens ? 
                <ColoredTab label={"Pending Notifications ("+issuedTokens.rejected_tokens.length+")"} 
                    color={"seafoam"} index={2} headers={hdsExpiringTokens} 
                    rows={tokenExpiringRows(issuedTokens.rejected_tokens, ["/issuer/asset/tokenize/"], ["modify"])} />
                : ""
            }
            {showTokens && showDeniedTokens && issuedTokens.denied_tokens ? 
                <ColoredTab label={"Denied tokens ("+issuedTokens.denied_tokens.length+")"} 
                    color={"succ4"} index={3} headers={hdsExpiringTokens} 
                    rows={tokenExpiringRows(issuedTokens.denied_tokens, ["/issuer/tokens/"], ["details"])} />
                : ""
            }
            {showTokens && showApprovedTokens && issuedTokens.approved_tokens? 
                <ColoredTab label={"Approved tokens ("+issuedTokens.approved_tokens.length+")"} 
                    color={"seafoam"} index={4} headers={hdsTokens} 
                    rows={tokenRows(issuedTokens.approved_tokens, ["/issuer/tokens/"], ["details"])} />
                : ""
            }
            {(showTokens && issuedTokens.listed_tokens && showListedTokens) || showPrimarySales  ? 
                <ColoredTab label={"Listed tokens ("+issuedTokens.listed_tokens?.length+")"} 
                    color={"succ4"} index={5} headers={[...hdsTokens, ...["", ""]]} 
                    rows={tokenRows(issuedTokens.listed_tokens, ["/issuer/tokens/","/issuer/primary-sale/"], 
                        ["details","P. S. setup"])} />
                : ""
            }
            {showPrimarySales && filteredPS ? 
                <ColoredTab label={"Primary Sales ("+filteredPS.length+")"} 
                    color={"seafoam"} index={6} headers={[...hdsPrimarySales,...[""]]} 
                    rows={primarySaleRows(filteredPS, ["/issuer/primary-sale/"], ["P. S. details"])} />
                : ""
            }
        </>
    }

    return <>
        <div className="p-5 lg:px-10">
            <div className="m-10"></div>
            <div className="flex">
                <div className="flex">
                    <LightButton className="mx-5 rounded-full bg-transparent px-10 font-base"
                        alternate={true} label={"NEW Asset"} onClick={() => { history.push("/issuer/asset/new") }} />
                    <LightButton className={"mx-5 rounded-full bg-tertiary px-16 hover:border-tertiary "} 
                        label={"Filter"} onClick={() =>setShowFilters(!showFilters)} />
                </div>
            </div>
            <div className="m-10"></div>

            <div className="mx-5 w-full">
                <TabsMenuWithFilters headers={["Assets", "Tokens"]} 
                    filters={[AssetFilters(), TokenFilters()]} showFilters={showFilters}
                    contents={[AssetsTables(new Array(7).fill(false)), TokensTables(new Array(7).fill(false))]}
                    tabToShow={tabToshow} />
            </div>

            <div className="m-10"></div>


        </div>
    </>
}