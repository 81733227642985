import { useEffect, useState} from "react"
import { defaultUserState, UserAccount } from '../UserContext'
import { useHistory  } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux"
import { bindActionCreators } from "redux";
import { actionCreators, State } from "../state";
import LabelAndInput from "../components/LabelAndInput"
import { LightButton } from "../components/light/LightButton"
import Popup from '../components/Popup'
import {formValidationClearErrors, formValidationCheckObject, removeTimezoneOffset} from '../utils'
import { PageDescription } from "../components/typography/Typogtaphy";
import { ITimezoneOption } from "react-timezone-select";

export default function AdditionalInfoRoute(): JSX.Element {

    let history = useHistory()

    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)

    const actionEdit : boolean = history.location.state !== undefined && history.location.state.action !== undefined && history.location.state.action === "edit"
    const detailAccountState = useSelector((state: State) => state.detailAccount)
    const [user, setUser] = useState<UserAccount>(defaultUserState)

    // Fields for registration:
    const[firstName, setFirstName] = useState<string>("")
    const[middleName, setMiddleName] = useState<string>("")
    const[lastName, setLastName] = useState<string>("")
    const[streetNumber, setStreetNumber] = useState<string>("")
    const[streetName, setStreetName] = useState<string>("")
    const[birthDate, setBirthDate] = useState<string>("")
    const[telephone, setTelephone] = useState<string>("")
    const[city, setCity] = useState<string>("")
    const[postalCode, setPostalCode] = useState<string>("")
    const[countryN, setCountry] = useState<string>("")
    const[taxResidence, setTaxResidence] = useState<string>("")
    const [timezone, setTimezone] = useState<ITimezoneOption>(
        {value: Intl.DateTimeFormat().resolvedOptions().timeZone, label: "", offset: 0})

    const [showPopUp, setShowPopUp] = useState<boolean>(false)
    const [titlePopUp, setTitlePopUp] = useState<string>("")
    const [msgPopUp, setMsgPopUp] = useState<string>("")
    const userCtx = detailAccountState.payload
    
    //const [submitted, setSubmitted] = useState<boolean>(false)
    
    function handleClosePopUp(){
        setTitlePopUp("")
        setMsgPopUp("")
        setShowPopUp(false)
    }
    
    function changeIfExists(field: string | undefined | null): string {
        return field ? field : ""
    }

    useEffect(() => {

        if(detailAccountState.payload) {
            if(userCtx) {
                setFirstName(changeIfExists(userCtx.first_name))
                setMiddleName(changeIfExists(userCtx.middle_name))
                setLastName(changeIfExists(userCtx.last_name))
                setBirthDate(changeIfExists(userCtx.date_of_birth))
                setTelephone(changeIfExists(userCtx.telephone))
                setStreetName(changeIfExists(userCtx.street_name))
                setStreetNumber(changeIfExists(userCtx.street_number))
                setCountry(changeIfExists(userCtx.country))
                setCity(changeIfExists(userCtx.city))
                setPostalCode(changeIfExists(userCtx.postal_code))
                setTaxResidence(changeIfExists(userCtx.tax_residence))
                setTimezone(userCtx.timezone_value ? 
                    {value: userCtx.timezone_value, offset: userCtx.timezone_offset, label: ""} : 
                    {value: Intl.DateTimeFormat().resolvedOptions().timeZone, offset: 0, label: ""})
            }
        }

    }, [detailAccountState.payload])

    useEffect(() => {
        if (detailAccountState.payload) {
            //if (submitted) {
                if (detailAccountState.type === 'set' && JSON.stringify(detailAccountState.payload) === '[200,204]') {
                    actions.getAccountDetails()
                    history.push('/account/almost-done')
                } else if(detailAccountState.type === 'set') {
                    setTitlePopUp("Error")
                    setMsgPopUp('An error occurred')
                    setShowPopUp(true)
                }
                //setSubmitted(false)
            //}
            if(detailAccountState.type === 'get'){
                var entireUser = {...user, ...detailAccountState.payload};
                setUser(entireUser)
            }
        }
    }, [detailAccountState.payload])

    function handleEditProfile(e: React.MouseEvent) {
        formValidationClearErrors()
        const newUser = {
            first_name: firstName,
            middle_name: middleName,
            last_name: lastName,
            street_name: streetName,
            street_number: streetNumber,
            date_of_birth: removeTimezoneOffset(birthDate, timezone.offset ? timezone.offset : 0),
            telephone: telephone !== '' && telephone.substring(0, 1) !== '+' ? '+'+telephone : telephone,
            city: city,
            postal_code: postalCode,
            country: countryN,
            tax_residence: taxResidence,
            is_issuer: false,
            is_investor: false,
            is_employee: false,
            timezone_value: timezone.value,
            timezone_offset: timezone.offset
        }
        let count = formValidationCheckObject(newUser)
        if(count === 0){
            //setSubmitted(true)
            if(!actionEdit) actions.setAccountDetails(newUser)
            else actions.updateAccountDetails(newUser)
            //actions.getAccountDetails()
            //history.push("/account/almost-done")
        } else {
            setTitlePopUp("Warning")
            setMsgPopUp("Highlighted fields are required!")
            setShowPopUp(true)
        }
    }

    return (<>
            <Popup show={showPopUp} title={titlePopUp} msg={msgPopUp} btnOk={true} close={handleClosePopUp} ></Popup>
            <div className="grid md:grid-cols-4">
                <div className="grid justify-items-center md:mt-40 mt-20 text-center mx-5 md:mx-10">
                    <PageDescription title={"Welcome!"} 
                        description={"Continue with your account creation or explore the platform"} />
                </div>
                <div className="grid md:px-20 px-5 grid-cols-12 mt-10 col-span-3 lg:col-span-2" >
                <LabelAndInput id={"firstName"} label={"First"} value={firstName} fun={setFirstName} type={"text"} className={"col-span-12"} required/>
                    <LabelAndInput label={"Middle"} value={middleName} fun={setMiddleName} type={"text"} className={"col-span-12"} />
                    <LabelAndInput id={"lastName"} label={"Last"} value={lastName} fun={setLastName} type={"text"} className={"col-span-12"} required/>
                    <LabelAndInput id={"telephone"} label={"Cell Phone"} value={telephone} fun={setTelephone} type={"phone"} className={"col-span-6"} required/>
                    <LabelAndInput id={"dateOfBirth"} label={"Date of birth"} value={birthDate} fun={setBirthDate} type={"date"} className={"col-span-6"} required/>
                    <LabelAndInput id={"streetName"} label={"Street"} value={streetName} fun={setStreetName} type={"text"} className={"col-span-8"} required/>
                    <LabelAndInput id={"streetNumber"} label={"Civic number"} value={streetNumber} fun={setStreetNumber} type={"text"} className={"col-span-4"} required/>
                    <LabelAndInput id={"city"} label="City" value={city} fun={setCity} type="text" className="col-span-6" required/>
                    <LabelAndInput id={"postalCode"} label="Postal code" value={postalCode} fun={setPostalCode} type="text" className="col-span-6" required />
                    <LabelAndInput id={"country"} label={"Country"} value={countryN} fun={setCountry} type={"select"} className="col-span-6"
                        options={["", "Italy", "Switzerland", "USA"].map((v) => {return {value: v, text: v}})}  required/>
                    <LabelAndInput id={"taxResidence"} label={"Country Tax"} value={taxResidence} fun={setTaxResidence} type={"select"} className="col-span-6"
                        options={["", "Italy", "Switzerland", "USA"].map((v) => {return {value: v, text: v}})}  required/>
                    <LabelAndInput label={"Timezone"} value={timezone.value.toUpperCase()} fun={setTimezone} type={"timezone"} className="col-span-12" />
                    <div className="grid col-span-12 mt-5 justify-items-center">
                        <div className="flex flex-wrap gap-4">
                            <div className="">
                                <LightButton label={"Submit"} onClick={handleEditProfile}
                                    alternate={true} className="rounded-full px-5"/>
                            </div>
                            <div className="">
                                <LightButton onClick={() => {history.push("/menu")}} className="rounded-full px-5"
                                    label="Explore the platform" alternate={true}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </>)
}
