import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { defaultHorseExtraInfo, FileSrc, HorseExtraInfo, PrizeEarnings, SportResult } from "../../AssetContext";
import { actionCreators } from "../../state";
import LabelAndInput from "../LabelAndInput";
import PrizeEarningsRow from "../PrizeEarningsRow";
import SportResultRow from "../SportResultRow";
import { TitleColor1 } from "../typography/Typogtaphy";
import UpdatableTable from "../UpdatableTable";
import UploadFilesForm from "../UploadFilesForm";

export default function ExtraInfoEquestrianForm(props: {assetInfo: any, setUpdateFun: any, media: any,
        tbr: any[], setTbr: any, tbu: any[], setTbu: any}): JSX.Element {

    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)

    // Asset ExtraInfo fields
    const[assetName, setAssetName] = useState<string>("")
    const[horseName, setHorseName] = useState<string>("")
    const[horseAge, setHorseAge] = useState<string>("")
    const[horseSire, setHorseSire] = useState<string>("")
    const[horseColor, setHorseColor] = useState<string>("")
    const[horseRider, setHorseRider] = useState<string>("")
    const[horseGender, setHorseGender] = useState<string>("")
    const[horseMadame, setHorseMadame] = useState<string>("")
    const[horseBreeder, setHorseBreeder] = useState<string>("")
    const[horseEstValue, setHorseEstValue] = useState<string>("")
    const[horseDiscipline, setHorseDiscipline] = useState<string>("")
    const[horseFeiNumber, setHorseFeiNumber] = useState<string>("")
    const[horseBestResult, setHorseBestResult] = useState<string>("")
    const[horseDescr, setHorseDescr] = useState<string>("")
    const[horseDateOfBirth, setHorseDateOfBirth] = useState<string>("")
    const[horseTotalWinning, setHorseTotalWinning] = useState<string>("")
    const[horseRatingScuderia, setHorseRatingScuderia] = useState<string>("")

    const [sportResults, setSportResults] = useState<SportResult[]>([])
    const [prizeEarnings, setPrizeEarnings] = useState<PrizeEarnings[]>([])

    const[imagesSrc, setImagesSrc] = useState<{url:string, filename: string}[]>([])
    const[videosSrc, setVideosSrc] = useState<{url:string, filename: string}[]>([])
    const[documentsSrc, setDocumentsSrc] = useState<{url:string, filename: string}[]>([])
    const[images, setImages] = useState<FileSrc[]>([])
    const[videos, setVideos] = useState<FileSrc[]>([])
    const[documents, setDocuments] = useState<FileSrc[]>([])
    const[download, setDownload] = useState<boolean>(true)

    var newEquestrianInfo: HorseExtraInfo = {
        age: parseInt(horseAge),
        name: horseName,
        sire: horseSire,
        color: horseColor,
        rider: horseRider,
        gender: horseGender,
        madame: horseMadame,
        breeder: horseBreeder,
        est_value: horseEstValue,
        discipline: horseDiscipline,
        fei_number: horseFeiNumber,
        best_result: horseBestResult,
        description: horseDescr,
        date_of_birth: parseInt(horseDateOfBirth),
        sport_results: sportResults,
        total_winning: horseTotalWinning,
        prize_earnings: prizeEarnings,
        rating_scuderia: horseRatingScuderia
    }
    useEffect(() => {
        if(props.media && download) {
            actions.getDocuments(props.media, setDocumentsSrc)
            actions.getImages(props.media, setImagesSrc)
            actions.getVideos(props.media, setVideosSrc)
            setDownload(false)
        }
    }, [props.media])

    useEffect(() => {
        setImages(imagesSrc.map((u) => {return {name: u.filename, uploaded: true, src: u.url}}))
    }, [imagesSrc])

    useEffect(() => {
        setVideos(videosSrc.map((u) => {return {name: u.filename, uploaded: true, src: u.url}}))
    }, [videosSrc])

    useEffect(() => {
        setDocuments(documentsSrc.map((u) => {return {name: u.filename, uploaded: true, src: u.url}}))
    }, [documentsSrc])


    useEffect(() => {
        if(props.assetInfo.payload && props.assetInfo.payload.extra_info) {
            let horse1 = defaultHorseExtraInfo
            if(props.assetInfo.payload.extra_info.equestrian) {
                horse1 = props.assetInfo.payload.extra_info.equestrian.sub_assets ?
                    props.assetInfo.payload.extra_info.equestrian.sub_assets[0] :
                    defaultHorseExtraInfo
            }
            setAssetName(props.assetInfo.payload.name)
            if(horse1) {
                setHorseName(horse1.name)
                setHorseAge(horse1.age.toString())
                setHorseSire(horse1.sire)
                setHorseColor(horse1.color)
                setHorseRider(horse1.rider)
                setHorseGender(horse1.gender)
                setHorseMadame(horse1.madame)
                setHorseBreeder(horse1.breeder)
                setHorseEstValue(horse1.est_value)
                setHorseDiscipline(horse1.discipline)
                setHorseFeiNumber(horse1.fei_number)
                setHorseBestResult(horse1.best_result)
                setHorseDescr(horse1.description)
                setHorseDateOfBirth(horse1.date_of_birth.toString())
                setHorseTotalWinning(horse1.total_winning)
                setHorseRatingScuderia(horse1.rating_scuderia)
                setSportResults(horse1.sport_results)
                setPrizeEarnings(horse1.prize_earnings)
            }
        }
    }, [props.assetInfo, props.assetInfo.loading])

    function updateEI() {
        var subAsset :any[] = []
        subAsset.push(newEquestrianInfo)
        const assetExtraInfo = {
            equestrian: {
                sub_assets: subAsset
            }
        }
        return assetExtraInfo
    }

    useEffect(() => {
        props.setUpdateFun(updateEI)
    }, [assetName, horseName, horseAge, horseSire, horseColor,  horseRider,  horseGender, horseMadame,
        horseBreeder, horseEstValue, horseDiscipline, horseFeiNumber,  horseBestResult, horseDescr,
        horseDateOfBirth,  horseTotalWinning, horseRatingScuderia, sportResults, prizeEarnings])
    return <>
        <div className="grid md:px-20 px-5 grid-cols-12 mt-10" >

            <div className="col-span-12 text-lg"><TitleColor1>/ Horse Information</TitleColor1></div>
            <LabelAndInput id={"assetName"} label={"Asset Name"} value={assetName} fun={setAssetName} type={"disabled"} className={"col-span-6"} required={true}/>
            <LabelAndInput id={"name"} label={"Horse Name"} value={horseName} fun={setHorseName} type={"text"} className={"col-span-6"} required={true}/>
            <LabelAndInput id={"sire"} label={"Sire"} value={horseSire} fun={setHorseSire} type={"text"} className={"col-span-6"} required={true}/>
            <LabelAndInput id={"madame"} label={"Madame"} value={horseMadame} fun={setHorseMadame} type={"text"} className={"col-span-6"} required={true}/>
            <LabelAndInput id={"age"} label={"Age"} value={horseAge} fun={setHorseAge} type={"number"} className={"col-span-2"} required={true}/>
            <LabelAndInput id={"dateOfBirth"} label={"Year of Birth"} value={horseDateOfBirth} fun={setHorseDateOfBirth} type="number" className="col-span-3" required={true}/>
            <LabelAndInput id={"color"} label={"Color"} value={horseColor} fun={setHorseColor} type={"text"} className={"col-span-4"} required={true}/>
            <LabelAndInput id={"gender"} label={"Gender"} value={horseGender} fun={setHorseGender} type={"text"} className={"col-span-3"} required={true}/>
            <LabelAndInput id={"rider"} label={"Rider"} value={horseRider} fun={setHorseRider} type={"text"} className={"col-span-6"} required={true}/>
            <LabelAndInput id={"breeder"} label={"Breeder"} value={horseBreeder} fun={setHorseBreeder} type="text" className="col-span-6" required={true}/>
            <LabelAndInput id={"description"} label={"Description"} value={horseDescr} fun={setHorseDescr} type="textarea" rows={3} className="col-span-12" required={true}/>
            <LabelAndInput id={"discipline"} label={"Discipline"} value={horseDiscipline} fun={setHorseDiscipline} type="text" className="col-span-4" required={true} />
            <LabelAndInput id={"feiNumber"} label={"Fei Number"} value={horseFeiNumber} fun={setHorseFeiNumber} type={"text"} className={"col-span-2"} required/>
            <LabelAndInput id={"bestResult"} label={"Best Result"} value={horseBestResult} fun={setHorseBestResult} type="text" className="col-span-6" required={true} />
            <LabelAndInput id={"estValue"} label={"Estimate Value"} value={horseEstValue} fun={setHorseEstValue} type="text" className="col-span-4" required={true}/>
            <LabelAndInput id={"totalWinning"} label={"Total Winning"} value={horseTotalWinning} fun={setHorseTotalWinning} type="text" className="col-span-4" required={true} />
            <LabelAndInput id={"ratingScuderia"} label={"Rating Scuderia"} value={horseRatingScuderia} fun={setHorseRatingScuderia} type="select" className="col-span-4"
                options={[{value: "", text: ""}, {value: "1 Star", text: "1 Star"}, {value: "2 Star", text: "2 Star"}, {value: "3 Star", text: "3 Star"},
                    {value: "4 Star", text: "4 Star"}, {value: "5 Star", text: "5 Star"}]} required={true}/>
            
            <div className="col-span-12 text-lg mt-5"><TitleColor1>/ Sport Results</TitleColor1></div>
            <div id="children-sportResult" className="col-span-12">
                <UpdatableTable rowsJSX={sportResults.map((r, i) => {
                        return <SportResultRow key={i} info={r} rows={sportResults} setRows={setSportResults} idx={i}/>
                    })}
                    rows={sportResults} setRows={setSportResults}
                    newRow={{"rank": "", "year": 0, "rider": "", "height": "", "competition": ""}}/>
            </div>

            <div className="col-span-12 text-lg mt-5"><TitleColor1>/ Prize Earnings</TitleColor1></div>
            <div id="children-sportResult" className="col-span-12">
                <UpdatableTable rowsJSX={prizeEarnings.map((r, i) => {
                        return <PrizeEarningsRow key={i} info={r} rows={prizeEarnings} setRows={setPrizeEarnings} idx={i}/>
                    })}
                    rows={prizeEarnings} setRows={setPrizeEarnings}
                    newRow={{"year": 0, "prize": ""}}/>
            </div>
            <div className="col-span-12 mt-5">
                <UploadFilesForm 
                    images={{names: images, setNames: setImages,
                            tbr: props.tbr, tbu: props.tbu, setTbr: props.setTbr, setTbu: props.setTbu}}
                    videos={{names: videos, setNames: setVideos,
                            tbr: props.tbr, tbu: props.tbu, setTbr: props.setTbr, setTbu: props.setTbu}}
                    documents={{names: documents, setNames: setDocuments,
                            tbr: props.tbr, tbu: props.tbu, setTbr: props.setTbr, setTbu: props.setTbu}}/>
            </div>
        </div>
    </>
}