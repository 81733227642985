import { Link } from 'react-router-dom'
import { euroFormat, quantityFormat } from "../utils";
import LightTable from "./light/LightTable";

export default function TokenDescriptionTable(props: {tokensList: {tokens : any[]}, assetLinks?: boolean}): JSX.Element {

    const headers = ["CAT", "TYPE", "Ticker", "Name", "Total Supply", "Price", "Txn Id"]
    //const alignment = ["text-center", "text-center", "text-center", "text-center", "text-right", "text-right", "text-center", "text-center", "text-center"]

    function tokensList2list(l: any[]) {
        if(l) {
            var tmp = l.map((a) => [a.asset_class, a.asset_type, a.ticker, a.asset_name,
                euroFormat(a.total_supply), euroFormat(a.price), /*a.currency_cd, euroFormat(a.capitalization), "%"+euroFormat(a.seven_day_change)*/
                <Link to={"/blockchain/transaction/"+a.trx_chain_id}>{a.trx_chain_id}</Link>,
            ])
            return props.assetLinks ? tmp.map((a, i) => a.map((b) => <Link to={"/asset/"+l[i].asset_uuid}>{b}</Link>)) : tmp.map((a, i) => a.map((b) => b))
        }
        return []
    }

    return <>
        <div className="py-1">
            <LightTable headers={headers} rows={tokensList2list(props.tokensList.tokens)} colorHd="primary text-tertiary" noBorders={true} ></LightTable>
        </div>
    </>
}

export function TokenDescriptionTable4PS(props: {tokensList: {tokens : any[]}, assetLinks?: boolean}): JSX.Element {
    const headers = ["CAT", "TYPE", "Ticker", "Name", "Total Supply", "Proposed Price", "Max circulating perc.", "Available tokens"]

    function tokensList2list(l: any[]) {
        if(l) {
            var tmp = l.map((a) => [a.asset_class, a.asset_type, a.ticker, a.asset_name,
                euroFormat(a.total_supply), euroFormat(a.price), a.pct_tokenized+"%",quantityFormat(a.total_available)
            ])
            return props.assetLinks ? tmp.map((a, i) => a.map((b) => <Link to={"/asset/"+l[i].asset_uuid}>{b}</Link>)) : tmp.map((a, i) => a.map((b) => b))
        }
        return []
    }

    return <>
        <div className="py-1">
            <LightTable headers={headers} rows={tokensList2list(props.tokensList.tokens)} colorHd="primary text-tertiary" noBorders={true} ></LightTable>
        </div>
    </>
}