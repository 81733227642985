import { bindActionCreators } from "@reduxjs/toolkit"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import LabelAndInput from "../components/LabelAndInput"
import { LightButton } from "../components/light/LightButton"
import { defaultAsset } from "../AssetContext"
import { defaultPrimarySaleGet, PrimarySaleSet, defaultSecurityToken, defaultBalance } from "../PoolContext"
import { actionCreators, State } from "../state"
import Popup from '../components/Popup'
import { euroFormat } from "../utils"
import Play from '@mui/icons-material/PlayArrow';
import Stop from '@mui/icons-material/Stop';
import Pause from '@mui/icons-material/Pause';
import Restart from '@mui/icons-material/RestartAlt';
import Button from '@mui/material/Button';
import { BigTitle} from "../components/typography/Typogtaphy"
import { TokenDescriptionTable4PS } from "../components/TokenDescriptionTable"
import { TimezoneDate } from "../TimezoneDate"

export default function IssuerPrimarySaleRoute(props: any): JSX.Element {

    const uuid = props.match.params.uuid
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)

    const [asset, setAsset] = useState(defaultAsset)
    const [token, setToken] = useState(defaultSecurityToken)
    const [primarySaleDetails, setPSDetails] = useState(defaultPrimarySaleGet)
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [startDateOffset, setStartDateOffset] = useState<TimezoneDate>(new TimezoneDate("", 0))
    const [endDateOffset, setEndDateOffset] = useState<TimezoneDate>(new TimezoneDate("", 0))
    const [primarySaleAmount, setPrimarySaleAmount] = useState(0)
    const [primarySalePrice, setPrimarySalePrice] = useState(0)
    const [primarySaleUuid, setPrimarySaleUuid] = useState("")
    const [balance, setBalance] = useState(defaultBalance)
    const [response, setResponse] = useState<any[]>([0, undefined])
    const [startPS, setStartPS] = useState(null)
    const [pausePS, setPausePS] = useState(null)
    const [resumePS, setResumePS] = useState(null)
    const [endPS, setEndPS] = useState(null)
    const [feesDescr, setFeesDescr] = useState<any>(null)
    const [disableIfStarted, setDisableIfStarted] = useState<boolean>(false)
    const [submitted, setSubmitted] = useState(false)
    const detailAccountState = useSelector((state: State) => state.detailAccount)
    const userCtx = detailAccountState.payload
    const offset = userCtx?.timezone_offset ? userCtx.timezone_offset : 0

    var newPrimarySale: PrimarySaleSet = {
        token_uuid: token.uuid,
        total_supply: primarySaleAmount.toString(),
        price: primarySalePrice.toString(),
        start_date: startDateOffset ? startDateOffset.toUTCString() : "",
        end_date: endDateOffset ? endDateOffset.toUTCString() : "",
        open: false,
    }

    const [showPopUp, setShowPopUp] = useState<boolean>(false)
    const [titlePopUp, setTitlePopUp] = useState<string>("")
    const [msgPopUp, setMsgPopUp] = useState<string>("")
    const [confirmPopUp, setConfirmPopUp] = useState<boolean>(false)
    const [actionPopUp, setActionPopUp] = useState<string>("")
    
    function handleClosePopUp(){
        setTitlePopUp("")
        setMsgPopUp("")
        setConfirmPopUp(false)
        setActionPopUp("")
        setShowPopUp(false)
    }

    useEffect(() => {
        actions.getPrimarySale(uuid, setPSDetails)
        actions.getActualBalance(setBalance)
    }, [])

    useEffect(() => {
        //console.log(primarySaleDetails)

        if(primarySaleDetails.uuid !== ''){
            actions.getAssetInfo(primarySaleDetails.asset_uuid, setAsset)
            actions.getTokenInfo(primarySaleDetails.asset_uuid, setToken)

            setStartDate(primarySaleDetails.date_start)
            setEndDate(primarySaleDetails.date_end)
            setStartDateOffset(new TimezoneDate(primarySaleDetails.date_start, offset))
            setEndDateOffset(new TimezoneDate(primarySaleDetails.date_end, offset))
            //setPrimarySaleAmount(parseFloat(primarySale.token_amount !== undefined ? primarySale.token_amount : "0"))
            setPrimarySaleAmount(primarySaleDetails.total_supply !== undefined ? primarySaleDetails.total_supply : 0)
            //setPrimarySalePrice(parseFloat(primarySale.token_price !== undefined ? primarySale.token_price : "0"))
            setPrimarySalePrice(primarySaleDetails.price !== undefined ? primarySaleDetails.price : 0)
            setPrimarySaleUuid(uuid)

            if(primarySaleDetails.status !== "Initialized") setDisableIfStarted(true)
        
        } else {
            if(primarySaleDetails.uuid === ''){
                actions.getAssetInfo(uuid, setAsset)
                actions.getTokenInfo(uuid, setToken)
            }
        }

    }, [primarySaleDetails])

    useEffect(() => {
        actions.getFeesFromCategory(asset.asset_category, setFeesDescr)
    }, [asset])

    function handleSubmit() {
        let t = newPrimarySale
        if(primarySaleUuid !== "") t.uuid = primarySaleUuid
        setSubmitted(true)
        actions.submitPrimarySale(t, setResponse)
    }

    function handleAction(type: string) {
        setTitlePopUp("Confirm your action")
        setMsgPopUp("Are you sure you want to " + type + " the Primary Sale?")
        setConfirmPopUp(true)
        setActionPopUp(type)
        setShowPopUp(true)
        setSubmitted(false)
    }

    function handleConfirmation() {
        handleClosePopUp()

        switch(actionPopUp) {
            case "start":
                actions.startPrimarySale(primarySaleUuid, setStartPS)
                break
            case "pause":
                actions.pausePrimarySale(primarySaleUuid, setPausePS)
                break
            case "resume":
                actions.resumePrimarySale(primarySaleUuid, setResumePS)
                break
            case "stop":
                actions.stopPrimarySale(primarySaleUuid, setEndPS)
                break
            default:
        }

        setSubmitted(true)
    }
    
    function handleRefresh() {
        setShowPopUp(true)
        setSubmitted(false)
        if(actionPopUp === ""){
            //history.push("/issuer/dashboard/#Tokens" )
        } else { // play/pause/resume/stop
            actions.getPrimarySale(primarySaleUuid, setPSDetails)
            actions.getActualBalance(setBalance)
        }
        
    }

    useEffect(() => {
        if(submitted && response) {
            switch(response[0]){
                case 200:
                    setTitlePopUp("Success")
                    setMsgPopUp("Submitted")
                    break
                default:
                    setTitlePopUp("Process Error")
                    setMsgPopUp("Error submitting the Primary Sale")
                    setMsgPopUp(response[1].error)
            }
            handleRefresh()
        }
    }, response)

    useEffect(() => {
        if(submitted && startPS) {
            switch(startPS[0]){
                case 200:
                    setTitlePopUp("Success")
                    setMsgPopUp("Primary Sale started!")
                    break
                default:
                    setTitlePopUp("Process Error")
                    setMsgPopUp("Error starting the Primary Sale")
            }
            handleRefresh()
        }
    }, [startPS])

    useEffect(() => {
        if(submitted && pausePS) {
            switch(pausePS[0]){
                case 200:
                    setTitlePopUp("Success")
                    setMsgPopUp("Primary Sale paused!")
                    break
                default:
                    setTitlePopUp("Process Error")
                    setMsgPopUp("Error pausing the Primary Sale")
                    setShowPopUp(true)
                    setSubmitted(false)
            }
            handleRefresh()
        }
    }, [pausePS])

    useEffect(() => {
        if(submitted && resumePS) {
            switch(resumePS[0]){
                case 200:
                    setTitlePopUp("Success")
                    setMsgPopUp("Primary Sale resumed!")
                    break
                default:
                    setTitlePopUp("Process Error")
                    setMsgPopUp("Error resuming the Primary Sale")
            }
            handleRefresh()
        }
    }, [resumePS])

    useEffect(() => {
        if(submitted && endPS) {
            switch(endPS[0]){
                case 200:
                    setTitlePopUp("Success")
                    setMsgPopUp("Primary Sale stopped!")
                    break
                default:
                    setTitlePopUp("Process Error")
                    setMsgPopUp("Error stopping the Primary Sale")
            }
            handleRefresh()
        }
    }, [endPS])

    const issuerFees = feesDescr && feesDescr.Seller? "ISSUER: a fee equal to the "+feesDescr.Seller+"% of the amount will be charged for each txn" : ""
    const investorFees = feesDescr && feesDescr.Buyer?"INVESTOR: a fee equal to the "+feesDescr.Buyer+"% of the amount will be charged for each txn" : ""

    return (<>
        <Popup show={showPopUp} title={titlePopUp} msg={msgPopUp} btnOk={true} close={handleClosePopUp} 
            btnConfirm={confirmPopUp} handleConfirm={handleConfirmation}/>
        <div>
            <BigTitle title={asset.name}/>
            <div className="flex place-content-center mb-5 justify-between flex-wrap gap-4 md:mx-10">
                <div className=" flex align-middle">
                    <div className=" text-succ2 text-center text-xl font-normal uppercase">
                        { startDate === "" || endDate === "" ? "Primary Sale Initialization" : "Primary sale " + 
                            (primarySaleDetails.status ? primarySaleDetails.status : "")}
                    </div>
                </div>
                <div className=" text-blue text-center flex flex-wrap gap-4">
                    <div className=" border-1">
                        {primarySaleDetails.status === "Initialized" ? 
                            <Button variant="contained" color="secondary" onClick={() => handleAction("start")} 
                                startIcon={<Play className="cursor-pointer" />}>
                                Start
                            </Button>
                        : 
                            <Button variant="contained" color="secondary" disabled={true}
                                startIcon={<Play color="disabled" />}>
                                Start
                            </Button>
                        }
                    </div>
                    <div className=" border-1">
                        {primarySaleDetails.status === "Started" ? 
                            <Button variant="contained" color="secondary" onClick={() => handleAction("pause")}
                                startIcon={<Pause  className="cursor-pointer" />}>
                                Pause
                            </Button>
                        : 
                            <Button variant="contained" color="secondary" disabled={true}
                                startIcon={<Pause color="disabled" />}>
                                Pause
                            </Button>
                        }
                    </div>
                    <div className=" border-1">
                        {primarySaleDetails.status === "Stopped" ? 
                            <Button variant="contained" color="secondary" onClick={() => handleAction("resume")} 
                                startIcon={<Restart className="cursor-pointer" />}>
                                Resume
                            </Button>
                        : 
                            <Button variant="contained" color="secondary" disabled={true}
                                startIcon={<Restart color="disabled" />}>
                                Resume
                            </Button>
                        }
                    </div>
                    <div className=" border-1">
                        {primarySaleDetails.status === "Started" || primarySaleDetails.status === "Stopped" ? 
                            <Button variant="contained" color="secondary" onClick={() => handleAction("stop")} 
                                startIcon={<Stop className="cursor-pointer" />}>
                                Close
                            </Button>
                        : 
                            <Button variant="contained" color="secondary" disabled={true}
                                startIcon={<Stop color="disabled" />}>
                                Close
                            </Button>
                    }
                    </div>
                </div>
                <div className=" text-succ2 text-xl tracking-wider font-normal flex place-content-center">
                    BALANCE: 
                        {balance && balance.payment_tokens && balance.payment_tokens.total ? " €"+euroFormat(balance.payment_tokens.total) : ""}
                </div>
            </div>
            <TokenDescriptionTable4PS tokensList={{
                tokens: [token]
            }} />
        </div>
        <div className="grid justify-center xl:mx-36  font-roboto"> 
            <div className="grid md:grid-cols-2 p-10">

                <div className="mx-2">
                    <LabelAndInput label={"Start Date"} value={startDateOffset?.toString()} 
                        fun={(d: string) => {setStartDateOffset(new TimezoneDate(d, offset, true))}}
                        type={disableIfStarted ? "datetime-local-disabled" : "datetime-local"} className="mr-1.5 uppercase" color="white"/>
                    <div className="grid grid-cols-80/20 gap-3">
                        <LabelAndInput label={"Quantity"} value={(primarySaleAmount)}
                            fun={(amount: string) => 
                                {
                                    let a: number = 0
                                    let b = false
                                    try {
                                        if(amount.length > 0){
                                            a = (Number(amount))
                                            b = true
                                        }
                                    } catch(e) {}
                                    if((a && b) || a === 0)
                                        if(a > Number(token.total_available)) 
                                            amount = token.total_available
                                        setPrimarySaleAmount(parseFloat(amount ? amount : "0"))
                            }} 
                            type={disableIfStarted ? "disabled" : "number"} className="mx-1.5" color="white" /*maxValue={parseFloat(token.total_available)}*//>
                        <div className="flex place-content-center self-center">
                            <LightButton label={"MAX"} alternate={true} className="px-2 py-1" 
                                onClick={() => disableIfStarted ? null : setPrimarySaleAmount(parseFloat(token.total_available))}/>    
                        </div>
                    </div>
                </div>
                <div className="mx-2">
                    <LabelAndInput label={"End Date"} value={endDateOffset?.toString()} 
                        fun={(d: string) => {setEndDateOffset(new TimezoneDate(d, offset, true))}} type={"datetime-local"}
                        className="mr-1.5 uppercase" color="white"/>
                    <LabelAndInput label={"Price"} value={primarySalePrice !== 0 ? (primarySalePrice) : (token.proposed_price)}
                        fun={setPrimarySalePrice} 
                        type={disableIfStarted ? "disabled" : "float"} step="0.01" className="mx-1.5" color="white"/>
                </div>

            </div>
            <div className="text-primary p-5 bg-tertiary font-roboto text-base mb-5 font-normal tracking-wider">
                <div className="text-center p-2 uppercase">Fees recap</div>
                <div className="p-2">{issuerFees}</div>
                <div className="p-2">{investorFees}</div>
            </div>
            <div className="flex justify-center mb-5 flex-wrap gap-4">
                <Link to="/issuer/dashboard/#Tokens" className="">
                    <LightButton label={"back"} alternate={true} className="float-left rounded-full py-2 px-5 w-fit"/></Link>
                {
                    primarySaleDetails.status !== "Ended" ? 
                    <LightButton label={"submit"} alternate={true} className="rounded-full py-2 px-5 w-fit" onClick={() => handleSubmit()}/>
                    : <></>
                }
            </div>
        </div>
    </>)
}
