import { ActionType } from '../action-types'
import { Actions } from '../actions'
import { UserContext, UserAccount, defaultUserState } from "../../UserContext"

interface profileState {
    loading: boolean
    payload?: UserAccount
}

const defaultState: profileState = {
    loading: false,
    payload: defaultUserState
}

const profileReducer = (state: profileState = defaultState, action: Actions): profileState => {
    switch(action.type) {
        
        /*case ActionType.GET_BASIC_ACCOUNT_PROFILE_REQUEST:
            return {
                loading: true
            }
        case ActionType.GET_BASIC_ACCOUNT_PROFILE_FAILURE:
            return {
                loading: false,
                payload: action.error
            }
        case ActionType.GET_BASIC_ACCOUNT_PROFILE_SUCCESS:
            return {
                loading: false,
                payload: action.payload.account
            }*/
        
        /*case ActionType.GET_ACCOUNT_PROFILE_REQUEST:
            return {
                loading: true
            }
        case ActionType.GET_ACCOUNT_PROFILE_FAILURE:
            return {
                loading: false,
                payload: action.error
            }
        case ActionType.GET_ACCOUNT_PROFILE_SUCCESS:
            return {
                loading: false,
                payload: action.payload.account
            }*/
            
            
        /*case ActionType.SET_ACCOUNT_REQUEST:
            return {
                loading: true
            }
        case ActionType.SET_ACCOUNT_FAILURE:
            return {
                loading: false,
                payload: action.error
            }
        case ActionType.SET_ACCOUNT_SUCCESS:
            console.log(action.payload)
            return {
                loading: false,
                payload: action.payload
            }*/
        
        
        case ActionType.CLEAR_ALL:
            return {
                loading: false,
                payload: undefined
            }
        default:
            return state
    }
}

export default profileReducer