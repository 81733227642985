import { ActionType } from '../action-types'
import { Actions } from '../actions'

interface getAllMediaTypesState {
    loading: boolean
    payload?: any 
}

export const defaultGetAllMediaTypesState: getAllMediaTypesState = {
    loading: false
}

const getAllMediaTypesReducer = (state: getAllMediaTypesState = defaultGetAllMediaTypesState, action: Actions): getAllMediaTypesState => {
    switch(action.type) {
            case ActionType.GET_ALL_MEDIA_TYPES_REQUEST:
            return {
                loading: true,
            }
        case ActionType.GET_ALL_MEDIA_TYPES_FAILURE:
            return {
                loading: false,
                payload: action.error
            }
        case ActionType.GET_ALL_MEDIA_TYPES_SUCCESS:
            return {
                loading: false,
                payload: action.payload
            }
        default:
            return state
    }
}
export default getAllMediaTypesReducer