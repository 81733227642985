import { Actions } from "../actions";
import { defaultTransactionsList, Transaction, TransactionList } from "../../PoolContext"
import { ActionType } from "../action-types"

interface transactionsState {
    loading: boolean
    payload?: TransactionList
}

const defaultTransactionsState: transactionsState = {
    loading: false,
    payload: defaultTransactionsList
}

const transactionsReducer = (state: transactionsState = defaultTransactionsState, action: Actions): transactionsState => {
    switch (action.type) {
        case (ActionType.GET_TRANSACTIONS_REQUEST):
            return {
                loading: true
            }
        case (ActionType.GET_TRANSACTIONS_FAILURE):
            return {
                loading: false,
                payload: action.error
            }
        case (ActionType.GET_TRANSACTIONS_SUCCESS):
            return {
                loading: false,
                payload: action.payload
            }
        case (ActionType.CLEAR_ALL):
            return {
                loading: false,
                payload: undefined
            }
        default:
            return state
    }
}

export default transactionsReducer