import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { Provider } from "react-redux"
import { store, persistor } from "./state/index"
import { BrowserRouter as Router } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { Authenticator } from '@aws-amplify/ui-react'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Authenticator.Provider>
        <Router>
          <App />
        </Router>
      </Authenticator.Provider>
    </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)