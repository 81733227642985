import { ActionType } from '../action-types'
import { Actions } from '../actions'

interface permissionState {
    loading: boolean
    payload?: []
}

const defaultState: permissionState = {
    loading: false,
    payload: [],
}

const permissionsReducer = (state: permissionState = defaultState, action: Actions): permissionState => {
    switch(action.type) {
        
        case ActionType.SET_INVESTOR_PERMISSIONS_REQUEST:
            return {
                loading: true,
            }
        case ActionType.SET_INVESTOR_PERMISSIONS_FAILURE:
            return {
                loading: false,
                payload: action.error,
            }
        case ActionType.SET_INVESTOR_PERMISSIONS_SUCCESS:
            return {
                loading: false,
                payload: action.payload,
            }
        
        case ActionType.SET_ISSUER_PERMISSIONS_REQUEST:
            return {
                loading: true,
            }
        case ActionType.SET_ISSUER_PERMISSIONS_FAILURE:
            return {
                loading: false,
                payload: action.error,
            }
        case ActionType.SET_ISSUER_PERMISSIONS_SUCCESS:
            return {
                loading: false,
                payload: action.payload,
            }
        case ActionType.CLEAR_ALL:
            return {
                loading: false,
                payload: undefined,
            }

        default:
            return state
    }
}

export default permissionsReducer

