import React, { useEffect, useState } from "react";
import LabelAndInput from "./LabelAndInput"

export default function ArtLocationsRow(props: {info?: string, className?: string, rows: any, setRows: any, idx: number}): JSX.Element {
    
    var value = props.info ? props.info : ""

    const [location, setLocation] = useState(value)
    

    useEffect(() => {
        
        let tmp :any[] = []
        if(props.rows) tmp = [...props.rows]
        tmp[props.idx] = location
        props.setRows(tmp)

    }, [location])

    useEffect(() => {
        setLocation(value)
    }, [props.info])
    
    return <>
        <div className={props.className+" font-urbanist py-2 tracking-wider grid grid-cols-12"}>
            <LabelAndInput label={""} value={location} fun={setLocation} type={"text"} className={"col-span-12"} />
        </div>
    </>
}