import { FooterItem } from './NavbarItem'

export default function Footer() : JSX.Element {
    return <>
        <div className="flex place-content-center mb-2">
            <FooterItem id="about" to="/about" label="About" className=""/>
            <FooterItem id="support" to="/support"  label="Support" className=""/>
            <FooterItem id="privacy-policy" to="/privacy-policy" label="Privacy" className=""/>
        </div>
    </>
}