import { AssetsList } from "../../PoolContext"
import { ActionType } from "../action-types"
import { Actions } from "../actions"

interface assetsState {
    loading: boolean
    payload?: AssetsList
}

const defaultAssetState = {
    loading: false,
    payload: {tokens: []}
}

const assetsReducers = (state: assetsState = defaultAssetState, action: Actions): assetsState => {
    switch (action.type) {
        case ActionType.GET_ALL_ASSETS_REQUEST:
            return {
                loading: true
            }
        case ActionType.GET_ALL_ASSETS_SUCCESS:
            return {
                loading: false,
                payload: action.payload
            }
        case ActionType.GET_ALL_ASSETS_FAILURE:
            return {
                loading: true,
                payload: action.error
            }
        case ActionType.CLEAR_ALL:
            return {
                loading: false,
                payload: undefined
            }
        default:
            return state
    }
}

export default assetsReducers
