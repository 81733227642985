import { ActionType } from '../action-types'
import { Actions } from '../actions'
import { UserAccount, defaultUserState } from "../../UserContext"

interface basicAccountState {
    loading: boolean
    payload?: UserAccount
}

const defaultState: basicAccountState = {
    loading: false,
    payload: defaultUserState
}

const basicAccountReducer = (state: basicAccountState = defaultState, action: Actions): basicAccountState => {
    switch(action.type) {
        
        case ActionType.GET_BASIC_ACCOUNT_PROFILE_REQUEST:
            return {
                loading: true
            }
        case ActionType.GET_BASIC_ACCOUNT_PROFILE_FAILURE:
            return {
                loading: false,
                payload: action.error
            }
        case ActionType.GET_BASIC_ACCOUNT_PROFILE_SUCCESS:
            return {
                loading: false,
                payload: {
                    email: action.payload.account.email,
                    is_issuer: action.payload.account.is_issuer,
                    is_investor: action.payload.account.is_investor,
                    is_employee: action.payload.account.is_employee,
                    kyc_submitted: action.payload.account.kyc_submitted,
                    unique_code: action.payload.account.bank_unique_code
                }
            }
        case ActionType.CLEAR_ALL:
            return {
                loading: false,
            }
        default:
            return state
    }
}

export default basicAccountReducer