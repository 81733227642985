import { combineReducers } from "redux";
import loginReducer from "./loginReducer";
import profileReducer from "./profileReducer";
import poolReducer from "./poolReducer";
import poolsReducer from "./poolsReducer";
import poolTokenReducer from "./poolTokenReducer";
import securityTokenReducer from "./securityTokenReducer";
import tokenReducer from "./tokenReducer";
import transactionsReducer from "./transactionsReducer";
import balanceReducer from "./balanceReducer";
import assetsReducer from "./assetsReducer";
import documentsReducer from "./documentsReducer";
import basicAccountReducer from "./basicAccountReducer";
import detailAccountReducer from "./detailAccountReducer";
import issuedTokensReducer from "./issuedTokensReducer";
import assetInfoReducer from "./assetInfoReducer";
import permissionsReducer from "./permissionsReducer";
import kycAccountReducer from "./kycAccountReducer";
import createAccountReducer from "./createAccountReducer";
import emailVerificationReducer from "./emailVerificationReducer";
import videosReducer from "./videosReducer";
import imagesReducer from "./imagesReducer";
import assetMediaReducer from "./assetMediaReducer";
import assetCategoriesReducer from "./assetCategoriesReducer";
import assetTypeByCategoriesReducer from "./assetTypeByCategoriesReducer";
import currenciesReducer from "./currenciesReducer";
import issuedAssetsReducer from "./issuedAssetsReducer";
import createTokenReducer from "./createTokenReducer";
import submitTokenReducer from "./submitTokenReducer";
import createAssetReducer from "./createAssetReducer";
import submitAssetReducer from "./submitAssetReducer";
import uploadMediaReducer from "./uploadMediaReducer";
import getAllMediaTypesReducer from "./getAllMediaTypesReducer";
//import primarySalesReducer from "./primarySalesReducer";
import submitPrimarySaleReducer from "./submitPrimarySaleReducer";
import issuerInfoReducer from "./issuerInfoReducer";

// TBR doc, imgs, vid, typesbycat


const rootReducer = combineReducers({
    login: loginReducer,
    profile: profileReducer,
    pool: poolReducer,
    pools: poolsReducer,
    token: tokenReducer,
    poolToken: poolTokenReducer,
    securityToken: securityTokenReducer,
    assetInfo: assetInfoReducer,
    transactions: transactionsReducer,
    balance: balanceReducer,
    assets: assetsReducer,
    basicAccount: basicAccountReducer,
    detailAccount: detailAccountReducer,
    issuedTokens : issuedTokensReducer,
    issuedAssets: issuedAssetsReducer,
    kycAccount: kycAccountReducer,
    permissions: permissionsReducer,
    createAccount: createAccountReducer,
    emailVerification: emailVerificationReducer,
    createAsset: createAssetReducer,
    submitAsset: submitAssetReducer,
    createToken: createTokenReducer,
    submitToken: submitTokenReducer,
    uploadMedia: uploadMediaReducer,
    mediaTypes: getAllMediaTypesReducer,
    //primarySalesReducer: primarySalesReducer,
    submitPrimarySale: submitPrimarySaleReducer,
    issuerInfo: issuerInfoReducer
})

export default rootReducer
export type State = ReturnType<typeof rootReducer>