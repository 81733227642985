import {createContext, useContext} from 'react'

export interface Asset {
    uuid: number
    symbol: string
    name: string
    asset_type: string
    total_supply: number
    share_price_initial: number
}

export interface Token {
    asset_uuid: number,
    ticker: string,
    total_supply: number,
    pct_tokenized: number,
    proposed_price: number,
    p2p_flag: boolean,
    lp_flag: boolean,
    uuid?: string,
    shares_issued?: number,
    to_be_listed: boolean
}

export interface SecurityToken {
    uuid: string,
    asset_uuid: string,
    asset_class: string,
    asset_name: string,
    asset_type: string,
    ticker: string,
    date_created: string,
    total_supply: string,
    shares_issued: string,
    pct_tokenized: string,
    p2p_flag: boolean,
    lp_flag: boolean,
    currency_cd: string,
    seven_day_change: string,
    capitalization: string,
    price: string,
    proposed_price: string,
    to_be_listed: boolean,
    total_available: string
}

export interface AssetInfo {
    uuid: number,
    account_issuer_id: number,
    category_name: string,
    name: string,
    asset_type: string,
    date_created: string,
    currency_cd_name: number,
    issuer_name: string,
    extra_info: any
}

export interface PrimarySaleGet {
    uuid?: string,
    asset_uuid: string,
    token_uuid: string,
    issuer_uuid: string,
    date_start: string,
    date_end: string,
    asset_name: string,
    asset_category: string,
    asset_type: string,
    token_ticker: string,
    total_supply: number,
    price: number,
    shares_issued: number,
    pct_sold: number,
    open: boolean,
    status: string,
    date_created: string,
}

export interface PrimarySaleSet {
    uuid?: string,
    //asset_uuid: string,
    token_uuid: string,
    //issuer_uuid: string,
    start_date: string,
    end_date: string,
    //asset_name: string,
    //asset_category: string,
    //asset_type: string,
    //token_ticker: string,
    total_supply: string,
    price: string,
    //shares_issued: number,
    //pct_sold: number,
    open: boolean
}

export interface strPoolState {
    uuid: string
    token_a: string
    token_b: string
    exchange_rate: number
    description: string
    precision: number
    creator: string
}

export interface PoolState {
    uuid: number
    token_a: Asset
    token_b: Asset
    amount_a: number
    amount_b: number
    description: string
    precision: number
    creator: string
}

export interface PoolListState {
    pools: PoolState[]
}

export interface Transaction {
    /*uuid?: string
    date_created: string
    asset_category: string
    asset_type: string
    asset_name: string
    asset_uuid?: string
    token_uuid?: string
    ticker: string
    //symbol: string
    txn_type: string
    //status: string
    origin: string
    origin_uuid: string
    quantity: number
    price: string
    fee: string
    extension: string*/
    uuid: number
    timestamp: string
    asset_category: string
    asset_type: string
    asset_name: string
    asset_uuid: string
    ticker: string
    token_uuid: string
    txn_type: string
    origin: string
    origin_uuid: string
    status: string
    quantity: number
    price_per_unit: number
    extension: number
    total_amount: number
    trx_identifier: string
    buyer_fee_pct: number
    buyer_fee_amount: number
}
export interface TransactionList {
    transactions: Transaction[]
}
export interface BankTransaction {
    uuid: string
    account_uuid: string
    user_email: string
    requested_at: string
    executed_at: string
    requested_amount: string
    minted_amount: string
    status: string
    transaction_type: string
    trx_bank_identifier: string
    trx_chain_identifier: string
    unique_code: string
}
export interface BankTransactionList {
    bank_transactions: BankTransaction[]
}

export interface Balance {
    balance: Securities
    liquidity: Liquidity
    payment_tokens: PaymentTokens
    total: string
    pct: string
}

export interface Liquidity {
    liquidity_entries: ClassBalance[]
    total: string
    pct: string
}

export interface Securities {
    security_entries: SecurityEntry[]
    total: string
    pct: string
}

export interface PaymentTokens {
    currency: string
    total: string
    pct: string
}

export interface SecurityEntry {
    class: string
    class_balance: ClassBalance[]
    total: string
    pct: string
}

export interface ClassBalance {
    asset_uuid: number
    token_uuid: number
    token_symbol?: string,
    symbol: string,
    currency_cd: string[]
    pct: string
    quantity: string
    current_price: string
    lp_name: string
    currency?: string
}

export interface AssetsList {
    tokens: SecurityToken[]
}

export interface PrimarySalesList {
    primary_sales: PrimarySaleGet[]
}

export function getCurrentPrice(pool: PoolState): number {
    return pool.amount_a === 0 ?
        pool.token_a.share_price_initial :
        Number(pool.amount_b) / Number(pool.amount_a)
}

export function getPoolByTicker(poolList: PoolState[], ticker: string): PoolState {
    for (let i = 0; i < poolList.length; i++)
        if (ticker === poolList[i].token_a.symbol) return poolList[i]
    return defaultPoolState
}

export function getSecurityBalanceBySymbol(securities: SecurityEntry[], sym: string): number {
    for (var secClass of securities) {
        for (var sec of secClass.class_balance) {
            if (sec.token_symbol === sym) {
                return parseFloat(sec.quantity)
            }
        }
    }
    return 0
}

export const defaultPoolState: PoolState = {
    uuid: 0,
    token_a: {uuid: 0, symbol: "", name: "", asset_type: "", share_price_initial: 0, total_supply: 0},
    token_b: {uuid: 0, symbol: "", name: "", asset_type: "", share_price_initial: 0, total_supply: 0},
    amount_a: 0,
    amount_b: 0,
    description: "",
    precision: 0,
    creator: ""
}

export const defaultTokenState: Asset = {
    uuid: 0,
    symbol: "",
    name: "",
    asset_type: "",
    share_price_initial: 0,
    total_supply: 0
}

export const defaultSecurityToken: SecurityToken = {
    uuid: "0",
    asset_uuid: "0",
    asset_class: "",
    asset_name: "",
    asset_type: "",
    ticker: "",
    date_created: "2022-04-14T00:00:00Z",
    total_supply: "0",
    shares_issued: "0",
    pct_tokenized: "0",
    p2p_flag: false,
    lp_flag: false,
    currency_cd: "EUR",
    seven_day_change: "0",
    capitalization: "0",
    price: "0",
    proposed_price: "0",
    to_be_listed: false,
    total_available: "0"
}

export const defaultAssetInfo: AssetInfo = {
    uuid: 0,
    account_issuer_id: 0,
    category_name: "",
    name: "",
    asset_type: "",
    date_created: "",
    currency_cd_name: 0,
    issuer_name: "",
    extra_info: {}
}

export const defaultPrimarySaleGet: PrimarySaleGet = {
    uuid: "",
    asset_uuid: "",
    token_uuid: "",
    issuer_uuid: "",
    date_start: "",
    date_end: "",
    asset_name: "",
    asset_category: "",
    asset_type: "",
    token_ticker: "",
    total_supply: 0,
    price: 0,
    shares_issued: 0,
    pct_sold: 0,
    open: false,
    status: "",
    date_created: ""
}
export const defaultPrimarySaleSet: PrimarySaleSet = {
    uuid: "",
    //asset_uuid: "",
    token_uuid: "",
    //issuer_uuid: "",
    start_date: "",
    end_date: "",
    //asset_name: "",
    //asset_category: "",
    //asset_type: "",
    //token_ticker: "",
    total_supply: "",
    price: "",
    //shares_issued: 0,
    //pct_sold: 0,
    open: false
}
export const defaultBalance: Balance = {
    balance: {
        security_entries: [],
        total: "0",
        pct: "0"
    },
    liquidity: {
        liquidity_entries: [],
        total: "0",
        pct: "0"
    },
    payment_tokens: {
        currency: "EUR",
        total: "0",
        pct: "0"
    },
    total: "0",
    pct: "100"
}

export const defaultAssetsList: AssetsList = {tokens: []}
export const defaultPrimarySalesList: PrimarySalesList = {primary_sales: []}

export const defaultPoolListState: PoolState[] = []
export const defaultTransactionsList: TransactionList = {"transactions" : []}
export const defaultBankTransactionsList: BankTransactionList = {"bank_transactions" : []}

export const SecurityTokenContext = createContext<SecurityToken>(defaultSecurityToken)
export const PoolListContext = createContext<PoolState[]>(defaultPoolListState)
export const TransactionContext = createContext<TransactionList>(defaultTransactionsList)
export const BalanceContext = createContext<Balance>(defaultBalance)
export const AssetsContext = createContext<AssetsList>(defaultAssetsList)
export const usePoolListContext = () => useContext(PoolListContext)