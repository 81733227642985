import { ActionType } from "../action-types"
import { Actions } from "../actions"

interface issuedTokensState {
    loading: boolean
    payload?: any
}

const defaultIssuedTokensState = {
    loading: false,
    payload: {
        "initialized_tokens": [],
        "in_process_tokens": [],
        "submitted_tokens": [],
        "rejected_tokens": [],
        "denied_tokens": [],
        "approved_tokens": [],
        "listed_tokens": []
    }
}

const issuedTokensReducer = (state: issuedTokensState = defaultIssuedTokensState, action: Actions): issuedTokensState => {
    switch (action.type) {
        case ActionType.GET_ISSUED_TOKENS_REQUEST:
            return {
                loading: true,
            }
        case ActionType.GET_ISSUED_TOKENS_SUCCESS:
            return {
                loading: false,
                payload: action.payload
            }
        case ActionType.GET_ISSUED_TOKENS_FAILURE:
            return {
                loading: false,
                payload: action.error
            }
        case ActionType.CLEAR_ALL:
            return {
                loading: false,
                payload: undefined
            }
        default:
            return state
    }
}

export default issuedTokensReducer