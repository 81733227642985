import React, { useContext, useEffect, useState} from "react"
import { useDispatch, useSelector } from "react-redux"
import { bindActionCreators } from "redux";
import { defaultAsset, Asset, AssetCreation } from "../AssetContext";
import { actionCreators, State } from "../state";
import { useHistory  } from 'react-router-dom'
import LabelAndInput from "../components/LabelAndInput"
import { LightButton } from "../components/light/LightButton";
import Popup from '../components/Popup'
import {formValidationClearErrors, formValidationCheckObject} from '../utils'
import { PageDescription, TitleColor1 } from "../components/typography/Typogtaphy";

export default function IssueAssetRoute(props: any): JSX.Element {

    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)

    const createAsset = useSelector((state: State) => state.createAsset)
    const [asset, setAsset] = useState<Asset>(defaultAsset)

    const [assetTypesByCat, setAssetTypesByCat] = useState<any>([])

    const [assetCurrencies, setAssetCurrencies] = useState<any>([])

    // Asset Fields:
    const[assetUuid, setAssetUuid] = useState(/*props.match.params.uuid ? parseInt(props.match.params.uuid) : */"0")
    if(assetUuid === "0" && props.match.params.uuid)
        setAssetUuid(props.match.params.uuid)
    /*else
        setAssetUuid(-1)*/

    const[assetName, setAssetName] = useState<string>("")
    const[issuerName, setIssuerName] = useState<string>("")
    const[assetCategoryName, setAssetCategoryName] = useState<string>("")
    const[assetTypeName, setAssetTypeName] = useState<string>("")
    const[currencyCode, setCurrencyCode] = useState<string>("")
    const[assetExtraInfo, setAssetExtraInfo] = useState<any>({})

    const[saved, setSaved] = useState(false)

    const [showPopUp, setShowPopUp] = useState<boolean>(false)
    const [titlePopUp, setTitlePopUp] = useState<string>("")
    const [msgPopUp, setMsgPopUp] = useState<string>("")
    function handleClosePopUp(){
        setTitlePopUp("")
        setMsgPopUp("")
        setShowPopUp(false)
    }

    let history = useHistory()

    var newAsset: AssetCreation = {
        //uuid: uuid,
        //account_issuer_uuid: assetName,
        asset_category: assetCategoryName,
        name: assetName,
        asset_type: assetTypeName,
        currency_cd: currencyCode,
        issuer_name: issuerName,
        extra_info: assetExtraInfo,
    }

    function handleExtraInfoRedirect() {
        var extraInfoTemplate = "/issuer/dashboard"
        if(assetUuid !== "0") {
            extraInfoTemplate = "/issuer/extra-info/"+assetUuid
        }
        history.push(extraInfoTemplate)
    }

    useEffect(() => {
        if(history.location && history.location.state && history.location.state.detail) {
            setAssetCategoryName(history.location.state.detail.name)
            actions.getAssetTypesByCategory(history.location.state.detail.name, setAssetTypesByCat)
        }
    }, [history])

    useEffect(() => {
        if(assetUuid && assetUuid !== "0") actions.getAssetInfo(assetUuid, setAsset)
        actions.getAssetCurrencies(setAssetCurrencies)
    }, [])

    useEffect(() => {
        if(saved && createAsset.payload){
            switch(createAsset.payload[0]) {
                case 200:
                    if(createAsset.payload[1].error){
                        setTitlePopUp("Warning")
                        setMsgPopUp(createAsset.payload[1].error)
                        setShowPopUp(true)
                    } else {
                        setAssetUuid(createAsset.payload[1].asset_uuid)
                        setTitlePopUp("Success")
                        setMsgPopUp("Saved!")
                        setShowPopUp(true)
                    }
                    setSaved(false)
                    break
                case 400:
                    if(createAsset.payload[1].error){
                        setTitlePopUp("Warning")
                        setMsgPopUp(createAsset.payload[1].error)
                        setShowPopUp(true)
                    } else {
                        setTitlePopUp("Warning")
                        setMsgPopUp("Invalid data!")
                        setShowPopUp(true)
                    }
                    setSaved(false)
                    break
                case 403:
                    setTitlePopUp("Process Error")
                    setMsgPopUp("Error saving the asset")
                    setShowPopUp(true)
                    setSaved(false)
                    break
                case 500:
                    setMsgPopUp("Server error")
                    setShowPopUp(true)
                    setSaved(false)
                    break
                default:
                    setTitlePopUp("Process Error")
                    setMsgPopUp("Error saving the asset")
                    setShowPopUp(true)
                    setSaved(false)
            }
            actions.getAssetInfo(createAsset.payload[1].asset_uuid, setAsset)
        }

    }, [createAsset.payload])

    useEffect(() => {
        if(asset && assetUuid !== "0") {
            let info: Asset = asset
            setAssetName(info.name)
            setIssuerName(info.issuer_name)
            setAssetCategoryName(info.asset_category)
            setAssetTypeName(info.asset_type)
            setCurrencyCode(info.currency_cd)
            setAssetExtraInfo(info.extra_info)
            actions.getAssetTypesByCategory(info.asset_category, setAssetTypesByCat)
        }
    }, [asset, assetUuid])

    function handleSaveAsset(e: React.MouseEvent) {
        formValidationClearErrors()
        const checkAsset = {
            asset_name: assetName,
            issuer_name: issuerName,
            currency_code: currencyCode,
            asset_type_name: assetTypeName
        }
        let count = formValidationCheckObject(checkAsset)
        if(count === 0){
            let u = newAsset
            if(assetUuid !== "0") u.uuid = assetUuid
            setSaved(true)
            actions.createAsset(newAsset)
        } else {
            setTitlePopUp("Warning")
            setMsgPopUp("Highlighted fields are required!")
            setShowPopUp(true)
        }
    }

    return (<>
            <Popup show={showPopUp} title={titlePopUp} msg={msgPopUp} btnOk={true} close={handleClosePopUp} ></Popup>
            <div className="grid lg:grid-cols-4">
                <div className="grid justify-items-center mt-40 col-span-1">
                    <PageDescription title={"New Asset"} description={""} />
                </div>

                <div className="grid md:px-20 px-5 grid-cols-2 mt-10 lg:col-span-2" >
                    <div className="col-span-2 text-lg mb-2"><TitleColor1>/ Asset Info</TitleColor1></div>

                    <div className={" font-roboto tracking-wider px-1"}>
                        <label className="w-full text-tertiary text-base py-4">Class</label>
                        <input className="w-full py-4 bg-transparent border-t-0 border-l-0 border-r-0 border-solid border-tertiary border-b-1
                            text-tertiary text-base font-urbanist pl-2 leading-none" value={assetCategoryName} disabled />
                    </div>
                    <LabelAndInput required id={"assetTypeName"} label={"Type"} value={assetTypeName} fun={(e: string) => {setAssetTypeName(e)}} 
                        type={"select"} className=""
                        options={assetTypesByCat ?
                            [{value: "", text: ""}].concat(assetTypesByCat.map((type: any) => {return {value: type, text: type}})):
                            [{value: "", text: ""}]}/>
                    <LabelAndInput required id={"assetName"} label={"Asset Name"} value={assetName} fun={setAssetName} type={"text"} className={"col-span-2"}/>
                    <LabelAndInput required id={"issuerName"} label={"Issuer Name"} value={issuerName} fun={setIssuerName} type={"text"} className={""} />
                    <LabelAndInput required id={"currencyCode"} label="Currency" value={currencyCode} fun={(e: string) => setCurrencyCode(e)} 
                        type={"select"} className=""
                        options={assetCurrencies ?
                            [{value: "", text: ""}].concat(assetCurrencies.map((currency: any) => {return {value: currency, text: currency}})) :
                            [{value: "", text: ""}]}/>

                    <div className="flex flex-wrap mt-10 place-content-center gap-4 col-span-2">
                        <div className="mt-5">
                            <LightButton onClick={() => {history.goBack()}} className="rounded-full px-10 py-2"
                                label="Back" alternate={true} />
                        </div>
                        <div className="mt-5">
                            <LightButton label={"Save Asset"} onClick={handleSaveAsset}
                                alternate={true} className="rounded-full px-10 py-2"/>
                        </div>
                        {assetUuid !== "0" ? <div className="mt-5">
                            <LightButton label={"Add Extra info"} onClick={handleExtraInfoRedirect}
                                alternate={true} className="rounded-full px-10 py-2"/>
                        </div> : <></>
                        }
                    </div>
                </div>
            </div>
    </>)
}
