import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { PieChart } from "react-minimal-pie-chart";
import { LightButton, DisabledButton } from "../components/light/LightButton";
import LightTable from "../components/light/LightTable";
import { euroFormat, formatDate } from "../utils";
import { useHistory  } from 'react-router-dom'
import { actionCreators, State } from "../state";
import { defaultBalance, defaultTransactionsList, defaultBankTransactionsList } from "../PoolContext";
import Popup from "../components/Popup";
import DepositWithdrawInterface from "../components/DepositWithdrawInterface";
import { HeaderButton } from "../components/HeaderButton";
import { CSVLink } from 'react-csv';

export default function IssuerPortfolio(): JSX.Element {

    let history = useHistory()
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)

    const [balance, setBalance] = useState(defaultBalance)
    const [showPopUp, setShowPopUp] = useState<boolean>(false)
    const [bankTxnList, setBankTxnList] = useState(defaultBankTransactionsList.bank_transactions)
    const detailAccountState = useSelector((state: State) => state.detailAccount)
    const userCtx = detailAccountState.payload

    const offset = userCtx?.timezone_offset ? userCtx.timezone_offset : 0

    useEffect(() => {
        actions.getBankTransactions(setBankTxnList)
        actions.getIssuerBalance(setBalance)
    }, [])

    const headersBankTxns = ["Trans Type", "Status", "Date Request", "Requested Amount",  "Date Executed", "Minted Amount", "Hash on Blockchain"]
    const rowsBankTxns = []
    
    for (let t of bankTxnList) {
        rowsBankTxns.push([t.transaction_type, t.status, formatDate(t.requested_at, offset), "€"+euroFormat(t.requested_amount),
        t.executed_at ? formatDate(t.executed_at, offset) : "-", t.minted_amount ? "€"+euroFormat(t.minted_amount) : "-",
        <Link to={"/blockchain/transaction/"+t.trx_chain_identifier}>{t.trx_chain_identifier}</Link> ])
    }

    const headers = [/*'Asset / Security tokens', 'liquidity pool', 'transaction history',*/ 'bank transaction history']
    const [toBeShown, setToBeShown] = useState(headers.map((_,i) => false))
    function handleClick(id: number) {
        var tmp = toBeShown.map((b,i) => i === id ? !b : b)
        setToBeShown(tmp)
    }

    function showTable(fileName: string, TableHeaders: string[], TableFormatted: any[], TableRows: any[], gradients: string[]) {
        return (   
        <div className="grid w-full bg-gray3" id={fileName}>
            <CSVLink className="border-solid py-2 px-5 border-crayola rounded-full uppercase text-primary text-sm m-5 
                w-fit align-middle bg-white border-1" 
                filename={fileName} headers={TableHeaders} 
                data={TableFormatted}>Download CSV </CSVLink>
            <LightTable headers={TableHeaders} rows={TableRows} 
                colorHd={gradients[0]}
                color1={gradients[1]}
                color2={gradients[2]}></LightTable>
        </div>)
    }

    return <>
        <Popup show={showPopUp} title={""} msg={""} btnOk={true} close={() =>{setShowPopUp(false)}} 
            body={<DepositWithdrawInterface />}></Popup>
        <div className="">
            <div className="grid md:grid-cols-2 cover my-10">
                <div className="font-urbanist uppercase text-tertiary my-10 text-4xl 
                        flex flex-col text-left place-content-center items-center
                        md:border-solid border-tertiary border-r-1 border-0">
                    <div>
                        <div className=" my-5">&euro;{euroFormat(balance?.balance.total)}
                            <div className="text-seafoam text-xl">Asset / Security tokens</div>
                        </div>
                        <div className=" my-5">&euro;{euroFormat(balance?.liquidity.total)}
                            <div className="text-succ5 text-xl">Liquidity pool position</div>
                        </div>
                        <div className="my-5">&euro;{euroFormat(balance?.payment_tokens.total)}
                            <div className="text-succ4 text-xl">Currency</div>
                        </div>
                    </div>
                </div>
                <div className="md:border-solid border-tertiary border-0 border-l-1 my-10
                        grid grid-cols-2 md:grid-cols-1 lg:grid-cols-2">
                    <div className="flex flex-col justify-items-center self-center mt-16 justify-center text-center">
                        <div className="font-urbanist text-tertiary text-4xl">
                            &euro;{balance ? euroFormat(balance.total) : euroFormat(0)}
                            <div className="font-roboto pt-2 text-tertiary text-2xl">Security token market cap</div>
                        </div>
                        <div className="depositWithdrawActions flex place-content-center">                      
                        <div className=" flex flex-wrap w-full place-content-center gap-4 px-10 mt-10">
                            <Link to="/issuer/dashboard"><LightButton label={"Securities"} alternate={true} 
                                className="rounded-full px-5 py-2"/></Link>
                            <LightButton label={"Issue new"} alternate={true} onClick={() => {history.push("/issuer/asset/new")}}
                                className="rounded-full px-5 py-2"/>
                            <LightButton onClick={() => setShowPopUp(true)} label="deposit / withdraw"
                                    alternate={true} className="rounded-full py-2 px-5"/>
                        </div>
                            <div className="mt-10">
                            </div>
                        </div>
                    </div>
                    <div className="flex place-content-center">
                        <div className="w-48 grid justify-items-center text-tertiary self-center">
                            <div className={"relative text-5xl font-base inset-0 top-30"}>
                                EUR
                            </div>
                            <PieChart className=""
                                data={[
                                    { title: 'Asset/Security Tokens', value: parseFloat(balance?.balance.total), color: '#80C8C4' },
                                    { title: 'Liquidity Pool Position', value: parseFloat(balance?.liquidity.total), color: '#6772CD' },
                                    { title: 'Currency', value: parseFloat(balance?.payment_tokens.total), color: '#4494CE' },
                                ]}
                                lineWidth={4}
                                paddingAngle={10}
                                animate={true}
                                animationDuration={1000}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className=" mt-5">
            <div className="">
                <div>
                    <HeaderButton name={"bank transaction history ("+rowsBankTxns.length+")"} click={() => handleClick(0)}
                        className={" port-link cursor-pointer bg-transparent pl-10"}
                        color1="seafoam" color2="white" shown={toBeShown[3]}/>
                </div>
                {toBeShown[0] ? showTable("history-bank-transactions.csv", headersBankTxns, rowsBankTxns, rowsBankTxns,
                    ["gradient-to-r from-seafoam to-gray3", 
                    "gradient-to-r from-seafoam_lighter  to-gray1",
                    "gradient-to-r from-seafoam_light to-gray2"]) : <></>}
            </div>
        </div>    
    </>
}