import { useState } from "react"
import Popup from "./Popup"

export default function TradePopup(props: {tokenInfo: any}) : JSX.Element {
    // MOCK
    const p2pQuotes = [
        { num: 1, qty: 1500, price: "€0.40" },
        { num: 2, qty: 12000, price: "€0.39" },
        { num: 3, qty: 5000, price: "€0.41" }
    ]

    //
    const [isLP, setIsLP] = useState<boolean>(false)

    //
    const [showPopUp, setShowPopUp] = useState<boolean>(false)
    const [titlePopUp, setTitlePopUp] = useState<string>("")
    const [msgPopUp, setMsgPopUp] = useState<string>("")

    // Response popup
    const [popUpResponse, setPopUpResponse] = useState<boolean>(false)
    const [responseMsg, setResponseMsg] = useState<string>("")
    const [responseTitle, setResponseTitle] = useState<string>("")
    const [response, setResponse] = useState<number>(0)

    function LAI(props: {label: string, value? : any}) {
        const inputClasses = "w-full bg-transparent border-t-0 border-l-0 border-r-0 border-solid border-white border-b-1"+
                    " font-urbanist pl-2 leading-none text-blue border-blue text-md"
        return <>
            <div className="m-4">
                <div className={"w-full bg-transparent font-urbanist pl-2 leading-none text-blue border-blue text-md"}>
                    {props.label}
                </div>
                {props.value ? <input defaultValue={props.value} className={inputClasses}></input> : <></>}
            </div>
            
        </>
    }
    
    return <>
        <Popup show={showPopUp} title={titlePopUp} msg={msgPopUp} btnOk={true} close={() => {setShowPopUp(false)}}/>
        <Popup show={popUpResponse} title={responseTitle} msg={responseMsg} btnOk={true} close={() => {setPopUpResponse(false)}}></Popup>
        <div className=" bg-background ">
            <div className="grid grid-cols-2 justify-between">
                <div className="text-4xl text-white bg-primary font-urbanist uppercase py-5 cursor-pointer px-10"
                    onClick={() => {setIsLP(false)}}>Peer to peer</div>
                <div className="text-4xl text-blue font-urbanist uppercase p-5 cursor-pointer px-10"
                    onClick={() => {setIsLP(true)}}>Liquidity pool</div>
            </div>
            {isLP ? <>
                <div className="bg-background text-blue grid grid-cols-2 p-10">
                    <div className="">
                        <LAI label={"Token"} value={props.tokenInfo.asset_name}  />
                        <LAI label={"Ticker"} value={props.tokenInfo.ticker}  />
                        <LAI label={"Quantity"} value={"0"}  />
                        <LAI label={"Calculated Price"} value={"0"}  />
                        <LAI label={"Extended Price Transaction"} value={"0"}  />         
                    </div>
                    <div className="grid bg-background_dk ">
                        <div className="uppercase font-medium text-lg mt-2 mx-4">Review:</div>
                        <LAI label={"Estimated Value Purchase:"} value={"0"}  />
                        <LAI label={"Estimated Fees:"} value={"0"}  />
                        <div className=" font-medium text-lg mt-2 mx-4">Liquidity pools will be available soon</div>
                        
                    </div>
                    
                </div>
            
            </> : <>
                <div className="bg-primary text-background grid grid-cols-2 px-10 py-5">
                    <div>
                        <div className="px-2">
                            <div className="text-xl uppercase font-medium">available offers:</div>
                            {   
                                p2pQuotes.map((quote, i) => (
                                    <div className="" key={i}>
                                        
                                        <div className=" my-5">
                                            <div className="uppercase text-md"> {"Quote "+quote.num}</div>
                                            <div className="p2p-quote-fields p2p-underline">Quantity
                                            <span className="p2p-quote-values ml-2">{quote.qty}</span></div>
                                            <div className="p2p-quote-fields">Price <span className="p2p-quote-values">{quote.price}</span></div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className=" font-medium text-lg mt-2 mx-4">Peer to peer will be available soon</div>        
                
                </div>
            </>}
        </div>
        
    </>
}