export function LightButton(props: {label: String, onClick?: any, alternate?: boolean, className?: string, fontClasses?: string}): JSX.Element {
    var isAlternate = props.alternate? props.alternate : false

    return <>
        <div className={"font-roboto border border-solid"+
            " not-italic text-center uppercase  cursor-pointer "+
            " tracking-wider py-2 w-fit "+
            (props.fontClasses ? props.fontClasses : "  text-sm ")+
            (isAlternate ? " text-tertiary border-tertiary hover:bg-background hover:text-primary hover:border-primary " : 
                " border-primary text-primary hover:bg-primary hover:text-tertiary ")+
            (props.className ? props.className : "")}
            onClick={props.onClick}>{props.label}</div>
    </>
}

export function DexxButton(props: {label: String, onClick?: any, color1?: string, color2?: string, className?: string, fontClasses?: string}): JSX.Element {
    let c1 = props.color1 ? props.color1 : "primary"
    let c2 = props.color2 ? props.color2 : "tertiary"
    return <>
        <div className={"font-roboto border border-solid"+
            " not-italic text-center uppercase  cursor-pointer "+
            " tracking-wider py-2 w-fit "+
            (props.fontClasses ? props.fontClasses : "  text-sm ")+
            " text-"+c2+" border-"+c2+" hover:bg-"+c2+" hover:text-"+c1+" hover:border-"+c1+" "+
            (props.className ? props.className : "")}
            onClick={props.onClick}>{props.label}</div>
    </>
}

export function DisabledButton(props: {label: String, className?: string, fontClasses?: string}): JSX.Element {

    return <>
        <div className={"font-roboto border border-solid"+
            " not-italic text-center uppercase cursor-none "+
            " tracking-wider py-2 w-fit "+
            (props.fontClasses ? props.fontClasses : "  text-xl ")+
            " border-blue text-blue opacity-25 " +
            (props.className ? props.className : "")}>{props.label}</div>
    </>
}