export function BigTitle(props : {title: string}) : JSX.Element {
    return <>
        <div className="text-succ2 font-medium font-roboto md:text-5xl text-6xl flex place-content-center mb-10 mt-5">{props.title}</div>
    </>
}

export function PageDescription(props : {title: string, description: string}) : JSX.Element {
    return <>
        <div className="font-roboto font-thin text-left">
            <div className="text-succ2 text-6xl">{props.title}</div>
            <div className="text-tertiary text-xl">{props.description}</div>
        </div>
    </>
}

export function TitleColor1(props: {children?: any, className?: string}) {
    return <div className={"text-succ2 font-roboto "+(props.className ? props.className : "")}>{props.children ? props.children : ""}</div>
}

export function TabTitle(props: {children?: any, className?: string}) {
    return <div className={"text-gray4 uppercase font-roboto font-medium text-xl "+
        (props.className ? props.className : "")}>{props.children ? props.children : ""}</div>
}