export default function PrettyJSON(props: {json: object}) {
    return <>
        
        <div className="border-solid border-3 border-primary">
            <div className="bg-primary text-white p-5 border-solid border-3 border-succ2 font-code-pro overflow-auto h-screen overflow-x-auto">
                <p className="whitespace-pre-wrap max-w-3xl">
                    {JSON.stringify(props.json, null, 4)}
                </p>
            </div>
        </div>
        
    </>
}