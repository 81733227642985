import React, { useEffect, useState } from "react"
import { PoolState, defaultPrimarySalesList } from "../PoolContext"
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { actionCreators } from "../state";
import CardsCollection from "../components/utils/CardsCollection";
import { BigTitle, TabTitle } from "../components/typography/Typogtaphy";

export interface AssetRouteProps {
    assetPool: PoolState
    toggleView: React.Dispatch<React.SetStateAction<boolean>>
}

export default function AssetRoute(): JSX.Element {

    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const [primarySalesList, setPrimarySalesList] = useState(defaultPrimarySalesList)
    const [shownList, setShownList] = useState<any[]>([])
    const [activeTab, setActiveTab] = useState<boolean[]>([true, false, false, false])

    useEffect(() => {
        actions.getAllAssets()
        actions.getAllPrimarySales(setPrimarySalesList)
    }, [])

    useEffect(() => {
        if(primarySalesList)
            setShownList(primarySalesList.primary_sales)
    }, [primarySalesList])
    
    function changeView(category: string, id: number) {
        if(primarySalesList){
            var tmp = primarySalesList.primary_sales
            tmp = category === "All" ? tmp : (tmp.filter((ps) => {return ps.asset_category === category}))
            setShownList(tmp)
            var tmp2 = activeTab
            tmp2 = tmp2.map((_, i) => {return (i===id ? true : false)})
            setActiveTab(tmp2)
        } 
    }
    
    function Tab(props: {category: string, id: number}): JSX.Element {
        return <>
            <div onClick={() => {changeView(props.category, props.id)}} className={"cursor-pointer mx-1 " + 
                (activeTab[props.id] ? "text-succ2" : "")}>
                {props.category}</div>
        </>
    }

    return (
        <>
            <div className="h-full">
                <div className="w-full px-10 py-5">
                    <TabTitle className="flex place-content-center">
                        <Tab category="All" id={0}/> /
                        <Tab category="ART" id={1}/> /
                        <Tab category="SPORTEX" id={2}/> /
                        <Tab category="AIF" id={3}/>
                    </TabTitle>
                    <BigTitle title={"PRIMARY MARKET"} />
                    {shownList ? <CardsCollection tokensList={shownList}/> : <></>}
                </div>
            </div>
        </>
    )
}