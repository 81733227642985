import { bindActionCreators } from "@reduxjs/toolkit"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { ArtInvestingExtraInfo, defaultArtInvestingExtraInfo, FileSrc } from "../../AssetContext"
import { actionCreators } from "../../state"
import { MediaFile } from "../../state/action-creators"
import { TitleColor1 } from "../typography/Typogtaphy"
import UploadFilesForm from "../UploadFilesForm"
import ArtLocationsRow from "./../ArtLocationsRow"
import ArtOwnersRow from "./../ArtOwnersRow"
import LabelAndInput from "./../LabelAndInput"
import UpdatableTable from "./../UpdatableTable"

export default function ExtraInfoArtInvestingForm(props: {assetInfo: any, setUpdateFun: any, media: any,
        tbr: any[], setTbr: any, tbu: any[], setTbu: any}): JSX.Element {
    
    const dispatch = useDispatch() 
    const actions = bindActionCreators(actionCreators, dispatch)
    
    // Asset ExtraInfo fields
    const[assetName, setAssetName] = useState<string>("")
    const[artworkName, setArtworkName] = useState<string>("")
    const[artistName, setArtistName] = useState<string>("")
    const[artLocation, setArtLocation] = useState<string[]>([])
    const[artSigned, setArtSigned] = useState<string>("")
    const[artTechnique, setArtTechnique] = useState<string>("")
    const[artSize, setArtSize] = useState<string>("")
    const[artYear, setArtYear] = useState<string>("")
    const[artEstimate, setArtEstimate] = useState<string>("")
    const[artInsurance, setArtInsurance] = useState<string>("")
    const[artOwners, setArtOwners] = useState<string[]>([])
    const[artDescr, setArtDescr] = useState<string>("")
    const[subtype, setSubtype] = useState<string>("")

    const[imagesSrc, setImagesSrc] = useState<{url:string, filename: string}[]>([])
    const[videosSrc, setVideosSrc] = useState<{url:string, filename: string}[]>([])
    const[documentsSrc, setDocumentsSrc] = useState<{url:string, filename: string}[]>([])
    const[images, setImages] = useState<FileSrc[]>([])
    const[videos, setVideos] = useState<FileSrc[]>([])
    const[documents, setDocuments] = useState<FileSrc[]>([])
    const[download, setDownload] = useState<boolean>(true)

    var newArtInvestingInfo: ArtInvestingExtraInfo = {
        artist: artistName,
        artwork_title: artworkName,
        art_location: artLocation,
        signed: artSigned,
        technique: artTechnique,
        size: artSize,
        year: artYear,
        estimate: artEstimate,
        insurance: artInsurance,
        subtype: subtype,
        description: artDescr,
        owners: artOwners,
    }

    useEffect(() => {
        if(props.assetInfo.payload && props.assetInfo.payload.extra_info) {
            let artInvesting = defaultArtInvestingExtraInfo
            if(props.assetInfo.payload.extra_info.art_investing) {
                artInvesting = props.assetInfo.payload.extra_info.art_investing.sub_assets ?
                    props.assetInfo.payload.extra_info.art_investing.sub_assets[0] :
                    defaultArtInvestingExtraInfo
            }
            setAssetName(props.assetInfo.payload.name)
            if(artInvesting) {
                setArtworkName(artInvesting.artwork_title)
                setArtistName(artInvesting.artist)
                setArtLocation(artInvesting.art_location)
                setArtSigned(artInvesting.signed)
                setArtTechnique(artInvesting.technique)
                setArtSize(artInvesting.size)
                setArtYear(artInvesting.year)
                setArtEstimate(artInvesting.estimate)
                setArtInsurance(artInvesting.insurance)
                setArtOwners(artInvesting.owners)
                setArtDescr(artInvesting.description)
                setSubtype(artInvesting.subtype)
            }
        }
    }, [props.assetInfo, props.assetInfo.loading])

    useEffect(() => {
        if(props.media && download) {
            actions.getDocuments(props.media, setDocumentsSrc)
            actions.getImages(props.media, setImagesSrc)
            actions.getVideos(props.media, setVideosSrc)
            setDownload(false)
        }
    }, [props.media])

    useEffect(() => {
        setImages(imagesSrc.map((u) => {return {name: u.filename, uploaded: true, src: u.url}}))
    }, [imagesSrc])

    useEffect(() => {
        setVideos(videosSrc.map((u) => {return {name: u.filename, uploaded: true, src: u.url}}))
    }, [videosSrc])

    useEffect(() => {
        setDocuments(documentsSrc.map((u) => {return {name: u.filename, uploaded: true, src: u.url}}))
    }, [documentsSrc])

    function updateEI() {
        var subAsset :any[] = []
        subAsset.push(newArtInvestingInfo)
        const assetExtraInfo = {
            art_investing: {
                sub_assets: subAsset
            }
        }
        return assetExtraInfo 
    }

    useEffect(() => {
        props.setUpdateFun(updateEI)
    }, [assetName,artworkName, artistName,artLocation, artSigned,artTechnique,artSize,
        artYear,artEstimate, artInsurance,artOwners,artDescr, subtype])
    
    
    return <>
        <div className="grid md:px-20 px-5 grid-cols-12 mt-10" >
            <TitleColor1 className="col-span-12 text-lg">/ Artwork Information</TitleColor1>
                    
            <LabelAndInput id={"assetName"} label={"Asset Name"} value={assetName} fun={setAssetName} type={"disabled"} className={"col-span-6"} required/>
            <LabelAndInput id={"artworkTitle"} label={"Artwork Title"} value={artworkName} fun={setArtworkName} type={"text"} className={"col-span-6"} required/>
            <LabelAndInput id={"artist"} label={"Artist"} value={artistName} fun={setArtistName} type={"text"} className={"col-span-6"} required/>
            <LabelAndInput id={"signed"} label={"Signed"} value={artSigned} fun={setArtSigned} type={"text"} className={"col-span-6"} required/>
            <TitleColor1 className="col-span-12 text-lg mt-5">/ Locations</TitleColor1>
            <div id="children-sportResult" className="col-span-12">
                <UpdatableTable rowsJSX={artLocation ? artLocation.map((l, i) => {
                        return <ArtLocationsRow key={i} info={l} rows={artLocation} setRows={setArtLocation} idx={i}/>
                    }) : []}
                    rows={artLocation} setRows={setArtLocation}
                    newRow={""}/>
            </div>
            <LabelAndInput id={"size"} label={"Size"} value={artSize} fun={setArtSize} type="text" className="col-span-6" required/>
            <LabelAndInput id={"year"} label={"Year"} value={artYear} fun={setArtYear} type="number" className={"col-span-6"} required/>
            <LabelAndInput id={"technique"} label={"Technique"} value={artTechnique} fun={setArtTechnique} type={"text"} className={"col-span-12"} required/>
            <LabelAndInput id={"estimate"} label={"Estimate Value"} value={artEstimate} fun={setArtEstimate} type={"text"} className={"col-span-3"} required/>
            <LabelAndInput id={"insurance"} label={"Insurance"} value={artInsurance} fun={setArtInsurance} type={"text"} className={"col-span-6"} required/>
            <LabelAndInput id={"subtype"} label={"Subtype"} value={subtype} fun={setSubtype} type={"select"} className="col-span-3"
                options={[["", ""], ["basic", "Basic"], ["monthly_payments", "Monthly payments"],
                    ["other", "Other options"]].map((l) => {return {value: l[0], text: l[1]}})} required/>
            
            <TitleColor1 className="col-span-12 text-lg mt-5">/ Owners</TitleColor1>
            <div id="children-sportResult" className="col-span-12">
                <UpdatableTable rowsJSX={artOwners ? artOwners.map((o, i) => {
                        return <ArtOwnersRow key={i} info={o} rows={artOwners} setRows={setArtOwners} idx={i}/>
                    }) : []}
                    rows={artOwners} setRows={setArtOwners}
                    newRow={""}/>
            </div>
            <LabelAndInput id={"description"} label={"Description"} value={artDescr} fun={setArtDescr} type="textarea" rows={3} className="col-span-12" required/>
            <div className="col-span-12 mt-5">
                <UploadFilesForm 
                    images={{names: images, setNames: setImages,
                            tbr: props.tbr, tbu: props.tbu, setTbr: props.setTbr, setTbu: props.setTbu}}
                    videos={{names: videos, setNames: setVideos,
                            tbr: props.tbr, tbu: props.tbu, setTbr: props.setTbr, setTbu: props.setTbu}}
                    documents={{names: documents, setNames: setDocuments,
                            tbr: props.tbr, tbu: props.tbu, setTbr: props.setTbr, setTbu: props.setTbu}}/>
            </div>
        </div>
    </>
}