import { FileSrc } from "../AssetContext";
import LARF from "./ListAddRemoveFile";
import { TitleColor1 } from "./typography/Typogtaphy";

type FileType = {
    names: FileSrc[]
    setNames: any,
    tbr: any[],
    setTbr: any,
    tbu: any[],
    setTbu: any
}

export default function UploadFilesForm(props: {images?: FileType, videos?: FileType, documents?: FileType, removeFileFunction?: any}): JSX.Element {
    return <>
        <div className="grid">
            {props.images ? <>
                <TitleColor1 className="text-lg">/ Pictures</TitleColor1>
                {<LARF names={props.images.names} setNames={props.images.setNames} type="img"
                    tbr={props.images.tbr} setTbr={props.images.setTbr}
                    tbu={props.images.tbu} setTbu={props.images.setTbu}/>}
            </> : <></>}
            {props.videos ? <>
                <TitleColor1 className="text-lg">/ Videos</TitleColor1>
                <LARF names={props.videos.names} setNames={props.videos.setNames} type="vid"
                    tbr={props.videos.tbr} setTbr={props.videos.setTbr}
                    tbu={props.videos.tbu} setTbu={props.videos.setTbu}/>
            </> : <></>}
            {props.documents ? <>
                <TitleColor1 className="text-lg">/ Documentation</TitleColor1>
                <LARF names={props.documents.names} setNames={props.documents.setNames} type="doc"
                    tbr={props.documents.tbr} setTbr={props.documents.setTbr}
                    tbu={props.documents.tbu} setTbu={props.documents.setTbu}/>
            </> : <></>}
        </div>
    </>
}