//import LightTable from "./light/LightTable";
import { PieChart } from "react-minimal-pie-chart";
import { TitleColor1 } from "./typography/Typogtaphy";
import { InvestorBreakdownDetailedTable } from "./TokenTables"


export default function PieAndList(props: {tokenStats: any}): JSX.Element {

    const countries = props.tokenStats ? Object.keys(props.tokenStats.rows) : []
    const countryvalues = props.tokenStats ? Object.values(props.tokenStats.rows) : []
    const randomColors = [{name: "primary", code: "#0A3C60"}, {name: "secondary", code: "#6C8FA9"}, {name: "tertiary", code: "#CFD6DB"}, {name: "succ1", code: "#8CD9CF"}, {name: "succ2", code: "#7ADFAE"}, {name: "succ2_light", code: "#7ADFAEcc"}, {name: "succ2_lighter", code: "#7ADFAE80"}, {name: "succ3", code: "#5CBBD0"}, {name: "succ4", code: "#4494CE"}, {name: "succ4_light", code: "#4494CEcc"}, {name: "succ4_lighter", code: "#4494CE80"}, {name: "succ5", code: "#6772CD"}, {name: "succ5_light", code: "#6772CDcc"}, {name: "succ5_lighter", code: "#6772CD80"}, {name: "succ6", code: "#4B58CE"}, {name: "succ7", code: "#AC4BCE"}, {name: "succ8", code: "#C887DF"}, {name: "succ8_light", code: "#C887DFcc"}, {name: "succ8_lighter", code: "#C887DF80"}, {name: "warning", code: "#FFCF74"}, {name: "warning_light", code: "#FFCF74CC"}, {name: "warning_lighter", code: "#FFCF7480"}, {name: "error1", code: "#E95C7B"}, {name: "error1_light", code: "#E95C7Bcc"}, {name: "error1_lighter", code: "#E95C7B80"}, {name: "error2", code: "#FFB37C"}, {name: "error2_light", code: "#FFB37Ccc"}, {name: "error2_lighter", code: "#FFB37C80"}, {name: "gray1", code: "#F5F5F5"}, {name: "gray2", code: "#EEEEEE"}, {name: "gray3", code: "#E0E0E0"}, {name: "gray4", code: "#BDBDBD"}, {name: "crayola", code: "#A1B3C8"}, {name: "seafoam", code: "#80C8C4"}, {name: "seafoam_light", code: "#80c8c4cc"}, {name: "seafoam_lighter", code: "#80c8c480"}, {name: "peach", code: "#FFB37C"}, {name: "wild_blue_yonder", code: "#7A8EC0"}, {name: "blue", code: "#0A3E62"}, {name: "background", code: "#E5E5E5"}, {name: "background_dk", code: "#E0E0E0"}, {name: "gray", code: "#607791"}, {name: "light_gray", code: "#dedede"}, {name: "light_blue", code: "#1890E3"}]
    let arrayCountry:any[] = []
    let arrayPie:any[] = []
    if(countries) {
        countries.forEach((country, i) => {
            let obj:any = countryvalues[i]
            let details = obj.entries && obj.entries.length ? obj.entries.length : 0
            let perc = (100*obj.total_quantity/props.tokenStats.total_quantity)
            var color = randomColors[Math.floor(Math.random()*randomColors.length)]
            let countryInfo = {
                "label": country+" ("+details+")",
                "qty": obj.total_quantity,
                "val": obj.total_value,
                "perc": perc,
                "col": color.name
            }
            arrayCountry.push(countryInfo)
            arrayPie.push({title: country, value: perc, color: color.code})
        })
    }
    /*arrayCountry = [
        {"label": "Italy (1)", "qty": "15", "val": "37.5", "perc": "75", "col": "error1"},
        {"label": "Brasil (1)", "qty": "2", "val": "5", "perc": "10", "col": "succ2"},
        {"label": "USA (1)", "qty": "3", "val": "7.5", "perc": "15", "col": "warning"}
    ]
    arrayPie = [
        {title: "Italy", value: 37.5, color: "#E95C7B"},
        {title: "Brasil", value: 5, color: "#7ADFAE"},
        {title: "USA", value: 7.5, color: "#FFCF74"}
    ]*/

    return <>
        <div className="">
            <div className="grid md:grid-cols-2 cover my-10">
                <div className="font-urbanist uppercase text-tertiary my-10 text-4xl 
                        flex flex-col text-left place-content-center items-center
                        md:border-solid border-tertiary border-r-1 border-0">
                    <div>
                    { arrayCountry.map((country, i) => {
                        return (<>
                            <div className=" my-5">&euro;{country.val}
                                <div className={"text-"+country.col+" text-xl"}>{country.label}</div>
                            </div>
                        </>)
                    })}
                    </div>
                </div>
                <div className="md:border-solid border-tertiary border-0 border-l-1 my-10
                        grid grid-cols-2 md:grid-cols-1 lg:grid-cols-2">
                    <div className="flex flex-col justify-items-center self-center mt-16 justify-center text-center">
                        <div className="font-urbanist text-tertiary text-4xl">
                            &euro;{props.tokenStats ? props.tokenStats.total_value : ""}
                            <div className="font-roboto pt-2 text-tertiary text-2xl">
                                Total quantity: {props.tokenStats ? props.tokenStats.total_quantity : ""}
                            </div>
                        </div>
                    </div>
                    <div className="flex place-content-center">
                        <div className="w-48 grid justify-items-center text-tertiary self-center">
                            <div className={"relative text-5xl font-base inset-0 top-30"}>
                            &euro;
                            </div>
                            <PieChart className=""
                                data={arrayPie}
                                lineWidth={4}
                                paddingAngle={10}
                                animate={true}
                                animationDuration={1000}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="grid grid-cols-6 mt-20">
            <div></div>
            <div className="col-span-4">
                { countries.map((country, i) => 
                {
                    let obj:any = countryvalues[i]
                    return <> 
                        <div className="font-roboto font-bold text-2xl uppercase text-succ2 mb-5"><TitleColor1>{country}</TitleColor1></div>
                        <InvestorBreakdownDetailedTable users={obj.entries} />
                    </>
                })}
            </div>
            <div></div>
        </div>
    </> 
}