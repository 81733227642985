import { useState } from 'react'
import { State } from '../state'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { useSelector } from 'react-redux'
import { actionCreators } from '../state'
import LabelAndInput from './LabelAndInput'
import { LightButton } from './light/LightButton'
import Popup from './Popup'
import { defaultUserState } from '../UserContext'
import { TitleColor1 } from './typography/Typogtaphy'

export default function DepositWithdrawInterface() {
    useEffect(() => {
        actions.getBalance()
    }, [])
    const detailAccountState = useSelector((state: State) => state.basicAccount)
    const [user, setUser] = useState<any>(defaultUserState)
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const [amountD, setAmountD] = useState<number>(0)
    const [amountW, setAmountW] = useState(0)
    const [iban, setIban] = useState("")
    const [swift, setSwift] = useState("")
    
    const [showPopUp, setShowPopUp] = useState<boolean>(false)
    const [isDeposit, setIsDeposit] = useState<boolean>(true)
    
    // Response popup
    const [popUpResponse, setPopUpResponse] = useState<boolean>(false)
    const [responseMsg, setResponseMsg] = useState<string>("")
    const [responseTitle, setResponseTitle] = useState<string>("")
    const [response, setResponse] = useState<number>(0)
    
    useEffect(() => {
        if(detailAccountState.payload) {
            setUser(detailAccountState.payload)
        }
    }, [detailAccountState])

    function handleDeposit() {
        actions.bankDeposit(amountD.toString(), setResponse)
        
    }
    
    function handleWithdraw() {
        actions.bankWithdraw({"amount": amountW.toString(), "swift": swift, "iban": iban}, setResponse)
    }

    function handleSetAmountD(amount: any) {
        let a: number = 0
        let b = false
        try {
            if(amount.length > 0){
                a = (Number(amount.replace(",", ".")))
                b = true
            }
        } catch(e) {} 
        if((a && b) || a === 0)
            setAmountD(amount)
    }

    function handleSetAmountW(amount: any) {
        let a: number = 0
        let b = false
        try {
            if(amount.length > 0){
                a = (Number(amount.replace(",", ".")))
                b = true
            }
        } catch(e) {}
        if((a && b) || a === 0)
            setAmountW(amount)
    }

    useEffect(() => {
        switch(response) {
            case 200:
            case 204:
                setResponseMsg("Success")
                setPopUpResponse(true)
                setResponse(0)
                break
            case 0:
                break
            default:
                setResponseMsg("Error")
                setPopUpResponse(true)
                setResponse(0)
        }
    }, [response])
    
    return (<>
        <Popup show={showPopUp} title={""} msg={""} btnOk={true} close={() => {setShowPopUp(false)}}/>
        <Popup show={popUpResponse} title={responseTitle} msg={responseMsg} btnOk={true} close={() => {setPopUpResponse(false)}}></Popup>
        <div className=" bg-tertiary h-144">
            <div className="grid">
                <div className="grid grid-cols-2 justify-between">
                    <div className="text-4xl text-tertiary bg-primary font-urbanist uppercase py-5 cursor-pointer px-10"
                        onClick={() => {setIsDeposit(true)}}>Deposit</div>
                    <div className="text-4xl text-blue font-urbanist uppercase p-5 cursor-pointer px-10"
                        onClick={() => {setIsDeposit(false)}}>Withdraw</div>
                </div>   
                {isDeposit ? <>
                        <div className="bg-primary text-tertiary grid grid-cols-2 px-10 pb-10 pt-32">
                            <div className="py-5 mr-10">
                                How to proceed:
                                <li>Copy your unique code</li>
                                <li>Select an amount and confirm</li>
                                <li>Send the requested amount to our bank:</li>
                                <br></br>
                            </div>
                            <div>
                                <LabelAndInput label={'Amount'} value={amountD} fun={handleSetAmountD} type={'number'} size="md"/>
                                <LabelAndInput label={'Unique code'} value={user.unique_code} fun={undefined} type={'no-input'} size="md"/>
                                <div className="grid justify-center">
                                    <LightButton label={"confirm"} onClick={handleDeposit} alternate={true} className="rounded-xl px-2" fontClasses='text-lg'/>
                                </div>
                            </div>
                        </div>
                        <div className="bg-primary text-tertiary grid px-10 pb-10">
                            <div className="flex gap-2"><TitleColor1>SWIFT:</TitleColor1> PKBSCH22<br/></div>
                            <div className="flex gap-2"><TitleColor1>IBAN:</TitleColor1> CH87 0866 3610 0416 3000 0<br/></div>
                            <div className="flex gap-2"><TitleColor1>BENEFICIARY:</TitleColor1> LL DEXX SA<br/></div>
                        </div>
                        
                    </> : <>
                        <div className="bg-tertiary text-primary grid grid-cols-2 pb-20 px-20 pt-32">
                            <div>
                                <LabelAndInput label={'Amount'} value={amountW} fun={handleSetAmountW} type={'number'} color={"blue"} size="md"/>
                                <LabelAndInput label={'SWIFT code'} value={swift} fun={setSwift} type={''} color={"blue"} size="md"/>
                                <LabelAndInput label={'IBAN'} value={iban} fun={setIban} type={''} color={"blue"} size="md"/>
                            </div>           
                            <div className="pt-5 ml-10">
                                <div className="grid justify-items-center mt-5">
                                    How to proceed:
                                    <li>Fill the form with the required information</li>
                                    <li>Confirm the requested operation</li>
                                    <li>We will let you know when the operation is executed</li>
                                    <LightButton label={"confirm"} className="rounded-xl px-2 mt-5" onClick={handleWithdraw} fontClasses="text-lg"/>
                                </div>
                            </div>
                        </div>
                    </>}         
            </div>
        </div>
    </>)
}
