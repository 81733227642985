import { useContext, useEffect, useState } from "react"
import { UserContext, defaultUserState, UserAccount } from '../UserContext'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux"
import { bindActionCreators } from "redux";
import { actionCreators, State } from "../state";
import LabelAndInput from "../components/LabelAndInput"
import { LightButton } from "../components/light/LightButton"
import Popup from '../components/Popup'
import { PageDescription, TitleColor1 } from "../components/typography/Typogtaphy";
import { formValidationClearErrors, formValidationCheckObject, removeTimezoneOffset, isValidHttpUrl } from '../utils'
import check from "../media/check.png"
import dlt from "../media/delete.png"
import { ITimezoneOption } from "react-timezone-select";

export default function ProfileRoute(): JSX.Element {

    const userCtx = useContext(UserContext)
    let history = useHistory()
    let location = useLocation()

    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const detailAccountState = useSelector((state: State) => state.detailAccount)
    const issuerInfoState = useSelector((state: State) => state.issuerInfo)
    const [user, setUser] = useState<UserAccount>(defaultUserState)

    // Fields for registration:
    const [firstName, setFirstName] = useState<string>("")
    const [middleName, setMiddleName] = useState<string>("")
    const [lastName, setLastName] = useState<string>("")
    const [streetNumber, setStreetNumber] = useState<string>("")
    const [streetName, setStreetName] = useState<string>("")
    const [birthDate, setBirthDate] = useState<string>("")
    const [telephone, setTelephone] = useState<string>("")
    const [city, setCity] = useState<string>("")
    const [postalCode, setPostalCode] = useState<string>("")
    const [countryN, setCountry] = useState<string>("")
    const [taxResidence, setTaxResidence] = useState<string>("")
    const [timezone, setTimezone] = useState<ITimezoneOption>(
        {value: Intl.DateTimeFormat().resolvedOptions().timeZone, label: "", offset: 0})
    const[issuerDescr, setIssuerDescr] = useState<string>("")
    const[webSite, setWebSite] = useState<string>("")
    
    const [showPopUp, setShowPopUp] = useState<boolean>(false)
    const [titlePopUp, setTitlePopUp] = useState<string>("")
    const [msgPopUp, setMsgPopUp] = useState<string>("")
    function handleClosePopUp() {
        setTitlePopUp("")
        setMsgPopUp("")
        setShowPopUp(false)
    }

    function changeIfExists(field: string | undefined): string {
        return field ? field : ""
    }

    useEffect(() => {
        if (userCtx.email !== "" /*&& userCtx.is_investor*/) {
            setFirstName(changeIfExists(userCtx.first_name))
            setMiddleName(changeIfExists(userCtx.middle_name))
            setLastName(changeIfExists(userCtx.last_name))
            setBirthDate(changeIfExists(userCtx.date_of_birth))
            setTelephone(changeIfExists(userCtx.telephone))
            setStreetName(changeIfExists(userCtx.street_name))
            setStreetNumber(changeIfExists(userCtx.street_number))
            setCountry(changeIfExists(userCtx.country))
            setCity(changeIfExists(userCtx.city))
            setPostalCode(changeIfExists(userCtx.postal_code))
            setTaxResidence(changeIfExists(userCtx.tax_residence))
            setIssuerDescr(changeIfExists(userCtx.description))
            setWebSite(changeIfExists(userCtx.web_site))
            setTimezone({value: userCtx.timezone_value ? userCtx.timezone_value : 
                Intl.DateTimeFormat().resolvedOptions().timeZone, offset: userCtx.timezone_offset, label: ""})
        }
    }, [userCtx])

    useEffect(() => {   // signup process -> next is additional info
        //console.log(detailAccountState.payload)
        if (detailAccountState.payload) {
            if (detailAccountState.type === 'set' && JSON.stringify(detailAccountState.payload) === '[200,204]') {
                actions.getAccountDetails()
                if (location.pathname !== '/profile') {
                    history.push('/account/almost-done')
                } else {
                    setTitlePopUp("Success")
                    setMsgPopUp('Data Submitted')
                    setShowPopUp(true)
                }
            } else if(detailAccountState.type === 'set') {
                setTitlePopUp("Process Error")
                setMsgPopUp('error setting investor details')
                setShowPopUp(true)
                actions.getAccountDetails()
            }

            if (detailAccountState.type === 'get') {  // after post additional details in signup or during sign in
                var entireUser = { ...user, ...detailAccountState.payload };
                setUser(entireUser)
            }
        }
    }, [detailAccountState.payload])

    useEffect(() => { 
        //console.log(issuerInfoState.payload)
        if (issuerInfoState.payload) {
            if (issuerInfoState.type === 'set' && JSON.stringify(issuerInfoState.payload) === '[200,204]') {
                actions.getIssuerInfo()
            } else if(issuerInfoState.type === 'set') {
                //console.log(issuerInfoState.payload[1].error)
                setTitlePopUp("Process Error")
                setMsgPopUp('error setting issuer details')
                setShowPopUp(true)
            }
            if(issuerInfoState.type === 'get'){
                var entireUser = {...user, ...issuerInfoState.payload};
                setUser(entireUser)
            }
        }
    }, [issuerInfoState.payload])

    useEffect(() => {
        //console.log(user)
    }, [user])

    function handleEditProfile(e: React.MouseEvent) {

        formValidationClearErrors()
        const newUser = {
            first_name: firstName,
            middle_name: middleName,
            last_name: lastName,
            street_name: streetName,
            street_number: streetNumber,
            date_of_birth: removeTimezoneOffset(birthDate, timezone.offset ? timezone.offset : 0),
            telephone: telephone !== '' && telephone.substring(0, 1) !== '+' ? '+' + telephone : telephone,
            city: city,
            postal_code: postalCode,
            country: countryN,
            tax_residence: taxResidence,
            username: userCtx.email,
            email: userCtx.email,
            //password: userCtx.password,
            is_issuer: false,
            is_investor: false,
            is_employee: false,
            timezone_value: timezone.value,
            timezone_offset: timezone.offset
        }
        //console.log(newUser)

        let count = formValidationCheckObject(newUser)
        if(count > 0){
            setTitlePopUp("Warning")
            setMsgPopUp("Highlighted fields are required!")
            setShowPopUp(true)
        } else {
            if(webSite !== "" || issuerDescr!==""){
                if(isValidHttpUrl(webSite)){
                    actions.updateAccountDetails(newUser)
                    let info = {
                        webSite: webSite, 
                        issuerDescr: issuerDescr
                    }
                    actions.setIssuerInfo(info)
                } else {
                    setTitlePopUp("Warning")
                    setMsgPopUp("Website must be a valid URL")
                    setShowPopUp(true)
                }
            }
            else actions.updateAccountDetails(newUser)
        }
    }

    function accountType(condition: boolean | undefined, label: string): JSX.Element {
        return <>
            <div className={"col-span-3 font-roboto py-4 tracking-wider flex items-center"}>
                {condition ? <img src={check} className="w-3" /> : <img src={dlt} className="w-3" />}
                <div className="w-full text-tertiary text-base ml-2">
                    {label}</div>
            </div>
        </>
    }

    let isIssuerOrInvestor = userCtx.is_investor || userCtx.is_issuer

    return (<>
            <Popup show={showPopUp} title={titlePopUp} msg={msgPopUp} btnOk={true} close={handleClosePopUp} ></Popup>
            <div className="grid md:grid-cols-4 ">
                <div className="grid justify-items-center md:mt-40 mt-20 text-center">
                    <PageDescription title={"Edit"} description={"Your profile"} />
                </div>
                <div className="grid md:px-20 px-5 grid-cols-12 mt-10 col-span-3 xl:col-span-2" >
                    <div className="col-span-12 text-lg"><TitleColor1>/ Account Type</TitleColor1></div>
                    {isIssuerOrInvestor ? <></> : accountType(true, "Basic")}
                    {isIssuerOrInvestor ? <></> : accountType(userCtx.kyc_submitted, "KYC Submitted")}
                    {accountType(userCtx.is_investor, "Investor")}
                    {accountType(userCtx.is_issuer, "Issuer")}
                    
                    <div className="col-span-12 text-lg"><TitleColor1>/ Profile Information</TitleColor1></div>
                    <LabelAndInput id={"firstName"} label={"First"} value={firstName} fun={setFirstName} type={"text"} className={"col-span-4"} required/>
                    <LabelAndInput label={"Middle"} value={middleName} fun={setMiddleName} type={"text"} className={"col-span-4"} />
                    <LabelAndInput id={"lastName"} label={"Last"} value={lastName} fun={setLastName} type={"text"} className={"col-span-4"} required/>
                    <LabelAndInput id={"telephone"} label={"Cell Phone"} value={telephone} fun={setTelephone} type={"phone"} className={"col-span-6"} required />
                    <LabelAndInput id={"dateOfBirth"} label={"Date of birth"} value={birthDate} fun={setBirthDate} type={"date"} className={"col-span-6"} required/>
                    <LabelAndInput id={"streetName"} label={"Street"} value={streetName} fun={setStreetName} type={"text"} className={"col-span-6"} required/>
                    <LabelAndInput id={"streetNumber"} label={"Civic number"} value={streetNumber} fun={setStreetNumber} type={"text"} className={"col-span-3"} required/>
                    <LabelAndInput id={"city"} label="City" value={city} fun={setCity} type="text" className="col-span-3" required/>
                    <LabelAndInput id={"postalCode"} label="Postal code" value={postalCode} fun={setPostalCode} type="text" className="col-span-4" required />
                    <LabelAndInput id={"country"} label={"Country"} value={countryN} fun={setCountry} type={"select"} className="col-span-4"
                        options={["", "Italy", "Switzerland", "USA"].map((v) => {return {value: v, text: v}})}  required/>
                    <LabelAndInput id={"taxResidence"} label={"Country Tax"} value={taxResidence} fun={setTaxResidence} type={"select"} className="col-span-4"
                        options={["", "Italy", "Switzerland", "USA"].map((v) => {return {value: v, text: v}})}  required/>
                    <LabelAndInput label={"Timezone"} value={timezone.value} fun={setTimezone} type={"timezone"} className="col-span-12" />
                    { userCtx.is_issuer ? (<>
                        <div className="col-span-12 text-lg"><TitleColor1>/ Issuer Information</TitleColor1></div>
                        <LabelAndInput label={"Issuer Description"} value={issuerDescr} fun={setIssuerDescr} type="textarea" rows={3} className="col-span-12" required={true} helpTip="This description will be available for investors who want to buy your issued tokens"/>
                        <LabelAndInput label={"Website"} value={webSite} fun={setWebSite} type={"text"} className={"col-span-12"} helpTip="Specify the protocol 'http://' or 'https://'" />
                    </>) : ""}

                <div className="grid col-span-12 mt-5 justify-items-center">
                    <div className="flex ">
                        <div className="my-2 mr-2">
                            <LightButton onClick={() => { history.push("/menu") }} className="rounded-full px-5"
                                label="Back to menu" alternate={true} />
                        </div>
                        <div className="my-2 ml-2">
                            <LightButton label={"Submit"} onClick={handleEditProfile}
                                alternate={true} className="rounded-full px-5 py-2" />
                        </div>
                        {userCtx.kyc_submitted ? <></> :
                            <div className="my-2">
                                <LightButton onClick={() => { history.push("/kyc") }} className="rounded-full px-5"
                                    label="Complete the KYC" alternate={true} />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>)
}
