import { createContext, useContext } from 'react'

interface AssetBalance {
  uuid      : string,
  name    : string,
  amount  : number
}

export interface UserAccount {
  first_name?    : string,
  middle_name?   : string,
  last_name?     : string,
  street_number? : string,
  street_name?   : string,
  date_of_birth?  : string,
  city?           : string,
  country?      : string,
  postal_code?   : string,
  telephone?     : string,
  tax_residence? : string,
  username?      : string,
  email         : string,
  password?      : string,
  password_salt? : string,
  address?      : string,
  assets?       : AssetBalance[],
  logged_in?     : boolean,
  is_issuer     : boolean,
  is_investor    : boolean,
  is_employee    : boolean,
  kyc_submitted? : boolean,
  is_verified?   : boolean,
  confirm_password? : string,
  unique_code?    : string,
  description?   : string,
  web_site?       : string,
  timezone_value?  : string, 
  timezone_offset? : number,
}

export interface strUserInvestor {
  firstName    : string,
  middleName   : string,
  lastName     : string,
  streetNumber : string,
  streetName   : string,
  dateOfBirth  : string,
  city         : string,
  country      : string,
  postalCode   : string,
  telephone    : string,
  taxResidence : string,
  email        : string,
  password     : string
}

export interface userInvestor {
  first_name    : string,
  middle_name   : string,
  last_name     : string,
  street_number : string,
  street_name   : string,
  date_of_birth  : string,
  city         : string,
  country      : string,
  postal_code   : string,
  telephone    : string,
  tax_residence : string,
  username     : string,
  email        : string,
  password     : string,
  address      : string,
  assets       : AssetBalance[],
  logged_in    : boolean
}

export interface strLoginInfo {
  username     : string,
  password     : string
}

export interface IssuerInfo {
  description? : string,
  web_site?    : string
}

export const defaultUserState: UserAccount = {
  email        : '',
  password     : '',
  logged_in    : false,
  is_issuer    : false,
  is_investor  : false,
  is_employee  : false,
  unique_code  : "",
  timezone_value : Intl.DateTimeFormat().resolvedOptions().timeZone,
  timezone_offset : 0
}

export const defaultUserInvestorState: userInvestor = {
  first_name    : '',
  middle_name   : '',
  last_name     : '',
  street_number : '',
  street_name   : '',
  date_of_birth  : '',
  city         : '',
  country      : '',
  postal_code   : '',
  telephone    : '',
  tax_residence : '',
  username     : '',
  email        : '',
  password     : '',
  address      : '',
  assets       : [],
  logged_in     : false
}

export const loginInfo = {
  username: "",
  password: ""
}

export const defaultIssuerInfoState: IssuerInfo = {
  description : "",
  web_site    : ""
}

export const UserContext = createContext<UserAccount>(defaultUserState)
export const useUserContext = () => useContext(UserContext)



/*export interface LoginState {
  loggedIn: boolean
}

export const defaultLoginState: LoginState = {
  loggedIn: false,
}


export const LoginContext = createContext<LoginState>(defaultLoginState)
export const useLoginContext = () => useContext(LoginContext)*/
