import { AssetInfo, defaultPoolState, defaultSecurityToken } from "../../PoolContext";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { actionCreators, State } from "../../state";
import { useEffect, useState } from "react"
import LabelAndDescription from "../LabelAndDescription";
import TabsMenu from "../TabsMenu";
import { getChartData } from "../../utils";
import { LightButton } from "../light/LightButton";
import LightTable from "../light/LightTable";
import TradeInterface from "../TradeInterface";
import { ArtInvestingExtraInfo, ArtLendingExtraInfo } from "../../AssetContext";

export default function ArtTokenBody (props: {assetInfo: AssetInfo, media: any, tokenSymbol: String}): JSX.Element {
    var artInfo: ArtInvestingExtraInfo | ArtLendingExtraInfo= {
        art_location: [],
        artist: "",
        artwork_title: "",
        description: "",
        estimate: "",
        insurance: "",
        //interest_structure: "",
        //loan_amount: "",
        owners: [],
        //right_of_first_refusal: "",
        signed: "",
        size: "",
        subtype: "",
        technique: "",
        //term_in_months: "",
        //time_span: "",
        year: ""
    }
    var artInfoArray = []
    const dispatch = useDispatch()
    const [documents, setDocuments] = useState<{url:string, filename: string}[]>([])
    const [images, setImages] = useState<{url:string, filename: string}[]>([])
    const actions = bindActionCreators(actionCreators, dispatch)
    const [viewTradeInterface, setViewTradeInterface] = useState<boolean>(false)
    try {
        artInfoArray = props.assetInfo.asset_type === "ART Lending" ? 
            props.assetInfo.extra_info.art_lending.sub_assets : 
            props.assetInfo.extra_info.art_investing.sub_assets
    } catch (e) {
        artInfoArray = []
    }
    artInfo = artInfoArray.length > 0 ? artInfoArray[0] : {}
    
    useEffect(() => {
        if(props.media){
            actions.getDocuments(props.media, setDocuments)
            actions.getImages(props.media, setImages)
        }
        
    }, [props.media])

    function docDownload(documents: any) {
        var tmp = []
        try {
            tmp = documents.map((doc: any, i: number) => 
            <div className="" key={(i+1).toString()}>
                <label className="art-doc-item">
                    <a href={doc.url} download>
                        <div className="text-tertiary font-code-pro tracking-wider uppercase hover:bg-tertiary hover:text-primary
                            font-base text-lg border-solid border-1 border-tertiary rounded-xl px-4">
                                {"Document "+(i+1).toString()}</div>
                    </a>
                </label>
            </div>)
        } catch (e) {}
        const res = tmp.length > 0 ? <div className="flex flex-wrap gap-2">{tmp}</div> : <></>
            //<div className="text-tertiary font-code-pro tracking-wider uppercase font-base text-xl">No documents provided</div>
        return res
    }

    const headers = ["Artwork", "Trading", "Token terms cond", "Provenance", "Investment Limits", "Loan terms", "Data room"] 
    function showArtDetails (info: any) {
        if(info) {
            const labels = ["artist", "Artwork Title", "Location Of Art", "Signed", "Technique", "size", "Year", "estimate",
                    "insurance", "percentage tokenized", "subtype", "owners", "loan amount", "term in months"]
            const descr = [info.artist, info.artwork_title, info.art_location, info.signed, info.technique, info.size, info.year,
                    info.estimate, info.insurance, info.pct_tokenized, info.subtype,
                    (info.owners  ? info.owners.map((i: any) => i+" ") : undefined), info.loan_amount, info.term_in_months]
            return labels.map((l, i) => <LabelAndDescription alternate={true} label={l} description={descr[i]} className="mb-10" key={i}/>)
        }
        return <></>
    }

    function showTrading() {
        return <>
            <div className="grid grid-cols-6 mx-20">
                <div></div>
                <div className="col-span-4">
                    {getChartData()}
                </div>
                <div></div>
            </div>
            <div className="grid grid-cols-6 mt-20">
                <div></div>
                <div className="col-span-4">
                    <div className="font-roboto font-bold text-2xl uppercase text-succ2 mb-5">Peer to Peer</div>
                    <TradeInterface hide={() => setViewTradeInterface(false)}
                                isVisible={viewTradeInterface} assetPool={defaultPoolState} tokenInfo={defaultSecurityToken} />
                    <LightTable headers={["Quantity", "price", ""]}
                        rows={[["1.500", "€0,40", <LightButton className="rounded px-5" label={"buy"} onClick={() => setViewTradeInterface(!viewTradeInterface)}/>],
                            ["12.000", "€0,39", <LightButton className="rounded px-5" label={"buy"} onClick={() => setViewTradeInterface(!viewTradeInterface)}/>],
                            ["5.000", "€0,41", <LightButton className="rounded px-5" label={"buy"} onClick={() => setViewTradeInterface(!viewTradeInterface)}/>]]} />
                </div>
                <div></div>
            </div>
            <div className="grid grid-cols-6 mt-20">
                <div></div>
                <div className="col-span-4">
                    <div className="font-roboto font-bold text-2xl uppercase text-succ2 mb-5">trade with Liquidity Pool</div>
                    <TradeInterface hide={() => setViewTradeInterface(false)}
                                isVisible={viewTradeInterface} assetPool={defaultPoolState} tokenInfo={defaultSecurityToken} />
                    <LightTable headers={["1 token price", "tokens in lp", ""]}
                        rows={[["€0,40", "900.000", <LightButton className="rounded px-5" label={"buy"}
                                onClick={() => setViewTradeInterface(!viewTradeInterface)}/>]]} />
                </div>
                <div></div>
            </div>
            <div className="grid grid-cols-6 mt-20">
                <div></div>
                <div className="col-span-4">
                    <div className="font-roboto font-bold text-2xl uppercase text-succ2 mb-5">Earn with Liquidity Pool</div>
                    <TradeInterface hide={() => setViewTradeInterface(false)}
                                isVisible={viewTradeInterface} assetPool={defaultPoolState} tokenInfo={defaultSecurityToken} />
                    <LightTable headers={["your tokens", "your stable coins", ""]}
                        rows={[["500", "26.690,00", <LightButton className="rounded px-5" label={"earn"}
                                onClick={() => setViewTradeInterface(!viewTradeInterface)}/>]]} />
                </div>
                <div></div>
            </div>
        </>
    }

    function showArtwork() : JSX.Element {
        return <>
            <div className="grid md:grid-cols-2 pt-5 lg:mx-20">
                <div className="">
                    <LabelAndDescription alternate={true} label={"Description"} description={artInfo.description} className="mt-10"/>
                </div>
                <div className="">
                    <div className="grid justify-items-center mb-2">
                        {images.length > 0 ?
                            <img src={images[0].url} alt={props.assetInfo.name} className="h-96 text-tertiary tex-sm font-roboto" 
                                loading="eager" decoding="async"/> :
                            <div className="h-96"></div>}
                    </div>
                    <div className="list-art-docs row my-5">{}
                        {docDownload(documents)}
                    </div>
                </div>
                <div className="grid grid-cols-3 col-span-2">
                    {showArtDetails(artInfo)}
                </div>
            </div>
        </>
    }

    return <>
        <div className="">
            <TabsMenu headers={headers} contents={[showArtwork(), showTrading()]} />
        </div>
    </>
}