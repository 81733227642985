import React, { useContext, useState } from "react"
import FileInput from "../components/FileInput"
import { PoolListContext } from "../PoolContext"
import { UserContext } from "../UserContext"
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../state";

interface NewAsset {
    symbol: string
    name: string
    asset_type: string
    share_price_initial: number
    total_supply: number
}

interface NewPool {
    asset_a: NewAsset
    asset_b: NewAsset
    amount_a: number
    amount_b: number
    description: string
    precision: number
    creator: string
}

export default function TokenizationOldRoute(): JSX.Element {
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const user = useContext(UserContext)

    const [assetName, setAssetName] = useState<string>("")
    const [creator, setCreator] = useState<string>(user.username ? user.username : "")
    const [ticker, setTicker] = useState<string>("")
    const [initialSupply, setInitialSupply] = useState<number>(0)
    const [tokenPrecision, setTokenPrecision] = useState<number>(0)
    const [tokenType, setTokenType] = useState<string>("")
    const [assetDescription, setAssetDescription] = useState<string>("")
    const [price, setPrice] = useState<number>(0)


    function handleSetPools(e: React.MouseEvent) {
        let A: NewAsset = {
            symbol: ticker,
            name: assetName,
            asset_type: tokenType,
            share_price_initial: price,
            total_supply: initialSupply
        }
        let B: NewAsset = {
            symbol: "EUR",
            name: "Euro",
            asset_type: "Fiat",
            share_price_initial: 1,
            total_supply: 0
        }
        let newPool: NewPool = {
            asset_a: A,
            asset_b: B,
            amount_a: 0,
            amount_b: 0,
            description: assetDescription,
            precision: tokenPrecision,
            creator: creator
        }
        //TODO: update setPools API
        //actions.setPools(newPool)
    }

    return (
        <>
            <div className="page-container">
                <div className="flex flex-wrap w-1/2 justify-center pt-3">
                    <div className="w-full rad-input-container">
                        <label className="rad-input-label">Asset Name</label>
                        <input value={assetName} onChange={e => setAssetName(e.target.value)}
                            className="rad-input" type="text" placeholder="Name your Asset" />
                    </div>
                    <div className="w-full rad-input-container">
                        <label className="rad-input-label">Asset Description</label>
                        <input value={assetDescription} onChange={e => setAssetDescription(e.target.value)}
                            className="rad-input" type="text" placeholder="Describe your Asset" />
                    </div>
                    <div className="w-full rad-input-container">
                        <label className="rad-input-label">Type</label>
                        <input value={tokenType} onChange={e => setTokenType(e.target.value)}
                            className="rad-input" type="text" placeholder="Token Type" />
                    </div>
                    <div className="w-1/2 rad-input-container">
                        <label className="rad-input-label">Supply</label>
                        <input value={initialSupply} onChange={e => setInitialSupply(parseInt(e.target.value))}
                            className="rad-input" type="number" placeholder="Initial Supply" />
                    </div>
                    <div className="w-1/2 rad-input-container">
                        <label className="rad-input-label">Price</label>
                        <input value={price} onChange={e => setPrice(parseInt(e.target.value))}
                            className="rad-input" type="number" placeholder="" />
                    </div>
                    <div className="w-1/2 rad-input-container">
                        <label className="rad-input-label">Ticker</label>
                        <input value={ticker} onChange={e => setTicker(e.target.value)}
                            className="rad-input" type="text" placeholder="ie: ETH" />
                    </div>
                    <div className="w-1/2 rad-input-container">
                        <label className="rad-input-label">Token Precision</label>
                        <input value={tokenPrecision} onChange={e => setTokenPrecision(parseInt(e.target.value))}
                            className="rad-input" type="number" placeholder="Token Precision" />
                    </div>
                    <div className="w-1/2 rad-input-container">
                        <label className="rad-input-label">File Upload</label>
                        <FileInput />
                    </div>
                    <div className="w-1/2 rad-input-container">
                        <label className="rad-input-label">Creator</label>
                        <input value={creator} onChange={e => setCreator(e.target.value)}
                            className="rad-input bg-green-400 font-bold" type="text" disabled={true} placeholder="Error?" />
                    </div>
                    <div className="flex justify-center w-full bg-transparent pt-3">
                        <button type="submit" className="rad-button" onClick={handleSetPools}>Commit</button>
                    </div>
                </div>
            </div>
        </>
    )
}