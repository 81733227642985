import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import arrowUp from "../media/arrow2.png";
import { actionCreators, State } from "../state";
import Popup from './Popup'
import fileImg from '../media/file.png'
import videoImg from '../media/play-button.png'
import { FileSrc } from "../AssetContext";
    
export default function LARF(props: {names: FileSrc[], setNames: any, type: string, tbr: any[], setTbr: any, tbu: [File, number][], setTbu: any}): JSX.Element {
    var acceptedFormat: string[], alertMsg: string, uploadName: string
    const acceptedDocFormats = ["application/pdf", "text/plain"]
    const acceptedImgFormats = ["image/png", "image/jpeg"]
    const acceptedVidFormats = ["video/mp4", "video/quicktime"]

    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const types = useSelector((state: State) => state.mediaTypes)
    const [allTypes, setAllTypes] = useState<number[]>([])
    let t = 0

    const [showPopUp, setShowPopUp] = useState<boolean>(false)
    const [titlePopUp, setTitlePopUp] = useState<string>("")
    const [msgPopUp, setMsgPopUp] = useState<string>("")

    var list : any[] = []

    function handleClosePopUp(){
        setTitlePopUp("")
        setMsgPopUp("")
        setShowPopUp(false)
    }

    useEffect(() => {
        actions.getAllMediaTypes()
    }, [])

    useEffect(() => {
        if(types.payload && types.payload[1] && types.payload[1].media_types){
            setAllTypes(types.payload[1].media_types.map((i: any) => {
                return i
            }))
        }
    }, [types])

    switch(props.type) {
        case "img":
            acceptedFormat = acceptedImgFormats
            alertMsg = "Accepted formats are .png and .jpg"
            uploadName = "Images"
            if(allTypes.length > 0) t = allTypes[0]
            list = props.names.map((i) => {return i.src})
            break
        case "vid":
            acceptedFormat = acceptedVidFormats
            alertMsg = "Accepted formats are .mp4 and .mov"
            uploadName = "Videos"
            if(allTypes.length > 1) t = allTypes[1]
            list = props.names.map((i) => {return i.src})
            break
        case "doc":
            acceptedFormat = acceptedDocFormats
            alertMsg = "Accepted format are .pdf and .txt"
            uploadName = "Documents"
            if(allTypes.length > 2) t = allTypes[2]
            list = props.names.map((i) => {return i.src})
            break
        default:
            acceptedFormat = []
            alertMsg = "Error uploading the file"
            uploadName = "Picture"
    }
    
    return <>
        <Popup show={showPopUp} title={titlePopUp} msg={msgPopUp} btnOk={true} close={handleClosePopUp} ></Popup>
        <div className="uppercase flex py-2 items-center">
            <label>
                <img alt="" src={arrowUp} className="transform -rotate-90 cursor-pointer" />
                <input className="hidden" type="file" onChange={ (e) => {
                    if(e.target.files && e.target.files.length){
                        var tmp = [...props.names]
                        var file = e.target.files[0]
                        if(acceptedFormat.indexOf(file.type) > -1){
                            if(props.names.filter((s: {name: string, uploaded: boolean}) => s.name === file.name).length < 1){
                                var tmp_ = [...props.tbu]
                                tmp_.push([file, t])
                                props.setTbu(tmp_)
                                tmp.push({name: file.name, uploaded: false, src: URL.createObjectURL(file)})
                                props.setNames(tmp)
                            } else {
                                setTitlePopUp("Warning")
                                setMsgPopUp("A file named "+file.name+" was already uploaded")
                                setShowPopUp(true)
                            }
                        } else {
                            setTitlePopUp("Warning")
                            setMsgPopUp(alertMsg)
                            setShowPopUp(true)
                        }
                    }
                }} />
            </label>
            <label className="text-white">{"Upload " + uploadName}</label>
        </div>
        <div className="text-blue mb-5 flex gap-4">
            {props.names.map((s: {name: string, uploaded: boolean, src?: string}, i: number) => {
                const urlFile = Array.isArray(list) ? 
                    list.filter( (file :any) => file.includes(s.name)) :
                    []
                var url = "" 
                if(urlFile.length > 0) {
                    try {
                        url = URL.createObjectURL(urlFile[0])
                    } catch(e) {
                        url = urlFile[0]
                    }
                } else {
                    url = s.src ? s.src : ""
                }
                return (
                <div key={i} className={"flex items-center p-2 flex-col w-fit bg-tertiary rounded-xl"}>
                    <div className="w-fit">
                        {url ?
                            <a href={url} target="_blank" className="h-24">
                                {props.type === "img" ? <img src={url} className="h-24"/> :
                                    props.type === "vid" ? <img src={videoImg} className="h-24"/> :
                                        <img src={fileImg} className="h-24"></img>}
                            </a> 
                            : 
                            <img src={fileImg} className="h-24"></img>}
                    </div>
                    <div className="flex">
                        <div className={"mx-2 cursor-pointer text-lg "+(s.uploaded ? "text-blue" : "text-purple-600")} >
                                {s.name.substring(s.name.lastIndexOf("/")+1, s.name.length)}
                        </div>
                        <div className="text-xl cursor-pointer mr-5 font-bold"
                            onClick={() => {
                                if(s.uploaded){
                                    var tmp_ = [...props.tbr]
                                    tmp_.push(s.name)
                                    props.setTbr(tmp_)
                                } else {
                                    var tmp__ = [...props.tbu]
                                    tmp_ = tmp__.filter((i) => i[0].name !== s.name)
                                    props.setTbu(tmp_)
                                }
                                var tmp = [...props.names]
                                tmp.splice(i,1)
                                props.setNames(tmp)
                            }}>x</div>
                    </div> 
                </div>)
                })}
        </div>
    </>
}