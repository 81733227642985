import LabelAndDescription from "../LabelAndDescription";
import SlideShow from "../SlideShow";
import { LightButton } from "../light/LightButton";
import { euroFormat, getChartData } from "../../utils";
import TabsMenu from "../TabsMenu";
import LightTable from "../light/LightTable";
import { useEffect, useState } from "react";
import TradeInterface from "../TradeInterface";
import { defaultPoolState, defaultSecurityToken } from "../../PoolContext";
import VideoPlayer from "../VideoPlayer";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators, State } from "../../state";
import { bindActionCreators } from "redux";
import { HorseExtraInfo } from "../../AssetContext";

export default function HorseTokenBody(props: {assetInfo: any, media: any}): JSX.Element {

    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const [documents, setDocuments] = useState<{url:string, filename: string}[]>([])
    const [images, setImages] = useState<{url:string, filename: string}[]>([])
    const [videos, setVideos] = useState<{url:string, filename: string}[]>([])

    const [viewTradeInterface, setViewTradeInterface] = useState<boolean>(false)
    
    const extra = props.assetInfo.extra_info ? props.assetInfo.extra_info : {}
    var horseEmpty: HorseExtraInfo = {
        age: 0,
        name: "",
        sire: "",
        color: "",
        rider: "",
        gender: "",
        madame: "",
        breeder: "",
        est_value: "",
        discipline: "",
        fei_number: "",
        best_result: "",
        description: "",
        date_of_birth: 0,
        sport_results: [],
        total_winning: "",
        prize_earnings: [],
        rating_scuderia: ""
    }
    const horses = extra.equestrian ? extra.equestrian.sub_assets : [horseEmpty]
    var horseInfo = horses[0]

    const labels = ["Name", "Birthdate", "Years/Months", "Breeder", "Gender", "Sire",
            "Dame", "Est. Value Euro", "Opening_Price", "Minimum_Price", "Maximum_Price",
            "Target Price", "Probability of Sale", "Total Winnings Euro", "SC1918 Rating", "Discipline"]

    const descr = horseInfo ? [horseInfo.name, horseInfo.date_of_birth, horseInfo.age, horseInfo.breeder, horseInfo.gender, horseInfo.sire,
            horseInfo.madame, euroFormat(horseInfo.est_value), "Unknown", "Unknown", "Unknown", "Unknown", "",
            euroFormat(horseInfo.total_winning), horseInfo.rating_scuderia, horseInfo.discipline] : []

    const sportResults = horseInfo.sport_results ? horseInfo.sport_results : []
    const prizeEarnings = horseInfo.prize_earnings ? horseInfo.prize_earnings : []
    
    const slidesImages = images.length > 0 ? 
        images.map((i: {url:string, filename: string}, idx) => <img src={i.url} className="max-w-full max-h-full" key={idx}/>) : []
    const slidesVideos = videos.length > 0 ? 
        videos.map((i: {url:string, filename: string}, idx) => <VideoPlayer videoSrc={i.url} width="auto" height="auto" key={idx} />) :[]
    const slides = slidesImages.concat(slidesVideos)
    useEffect(() => {
        actions.getDocuments(props.media, setDocuments)
        actions.getImages(props.media, setImages)
        actions.getVideos(props.media, setVideos)
    }, [props.media])

    function docDownload(documents: any) {
        var tmp = []
        try {
            tmp = documents.map((doc: any, i: number) =>
            <div className="" key={i}>
                <label className="art-doc-item">
                <a href={doc.url} download>
                    <div className="text-tertiary font-code-pro tracking-wider uppercase hover:bg-tertiary hover:text-primary
                            font-base text-lg border-solid border-1 border-tertiary rounded-xl px-4">
                                {"Document "+(i+1).toString()}</div>
                    </a>
                </label>
            </div>)
        } catch (e) {}
        const res = tmp.length > 0 ? <div className="flex flex-wrap gap-2">{tmp}</div> : <></>
            //<div className="text-tertiary font-code-pro tracking-wider uppercase font-base text-xl">No documents provided</div>
        return res
    }

    function showOverview() {
        return <div className="grid grid-cols-2 lg:mx-20">
            <div className=" my-5">
                <LabelAndDescription label="description" description={horseInfo.description} alternate={true} />
            </div>
            
            <div>
                <SlideShow slides={slides} maxHeightClass="h-96"/>
                <div className="divider mt-5"></div>
                <div className="">
                    <div className="list-art-docs row">
                        {docDownload(documents)}
                    </div>
                </div>
            </div>
            <div className="col-span-2 grid grid-cols-4 mt-10">
                {labels.map((l, i) => <LabelAndDescription label={l} description={descr[i]} className="mt-10" key={i} alternate={true}/>)}
            </div>
        </div>
    }

    function showTrading() {
        return <>
            <div className="grid grid-cols-6 mx-20">
                <div></div>
                <div className="col-span-4">
                    {getChartData()}
                </div>
                <div></div>
            </div>
            <div className="grid grid-cols-6 mt-20">
                <div></div>
                <div className="col-span-4">
                    <div className="font-roboto font-bold text-2xl uppercase text-succ2 mb-5">Peer to Peer</div>
                    <TradeInterface hide={() => setViewTradeInterface(false)}
                                isVisible={viewTradeInterface} assetPool={defaultPoolState} tokenInfo={defaultSecurityToken} />
                    <LightTable headers={["Quantity", "price", ""]}
                        rows={[["1.500", "€0,40", <LightButton className="rounded px-5" label={"buy"} onClick={() => setViewTradeInterface(!viewTradeInterface)}/>],
                            ["12.000", "€0,39", <LightButton className="rounded px-5" label={"buy"} onClick={() => setViewTradeInterface(!viewTradeInterface)}/>],
                            ["5.000", "€0,41", <LightButton className="rounded px-5" label={"buy"} onClick={() => setViewTradeInterface(!viewTradeInterface)}/>]]} />
                </div>
                <div></div>
            </div>
            <div className="grid grid-cols-6 mt-20">
                <div></div>
                <div className="col-span-4">
                    <div className="font-roboto font-bold text-2xl uppercase text-succ2 mb-5">trade with Liquidity Pool</div>
                    <TradeInterface hide={() => setViewTradeInterface(false)}
                                isVisible={viewTradeInterface} assetPool={defaultPoolState} tokenInfo={defaultSecurityToken} />
                    <LightTable headers={["1 token price", "tokens in lp", ""]}
                        rows={[["€0,40", "900.000", <LightButton className="rounded px-5" label={"buy"}
                                onClick={() => setViewTradeInterface(!viewTradeInterface)}/>]]} />
                </div>
                <div></div>
            </div>
            <div className="grid grid-cols-6 mt-20">
                <div></div>
                <div className="col-span-4">
                    <div className="font-roboto font-bold text-2xl uppercase text-succ2 mb-5">Earn with Liquidity Pool</div>
                    <TradeInterface hide={() => setViewTradeInterface(false)}
                                isVisible={viewTradeInterface} assetPool={defaultPoolState} tokenInfo={defaultSecurityToken} />
                    <LightTable headers={["your tokens", "your stable coins", ""]}
                        rows={[["500", "26.690,00", <LightButton className="rounded px-5" label={"earn"}
                                onClick={() => setViewTradeInterface(!viewTradeInterface)}/>]]} />
                </div>
                <div></div>
            </div>
        </>
    }

    function showSportOverview() {
        const rows = sportResults.map((i: any) => {return [i.rank, i.year, i.competition, i.height, i.rider]})
        const res = prizeEarnings.map((p : {year: string, prize: string}) => {
            return <>
                <div className="font-roboto uppercase font-semibold text-2xl text-blue my-10">
                    {p.year + ": "+euroFormat(p.prize)+"€"}</div>
                <LightTable headers={["Rank", "Year", "Competition", "Height", "Rider"]} 
                    rows={rows.filter((sp: any[]) => sp[1].toString() === p.year)} />
            </>

        })
        return res.reverse()
    }

    function showFinancial() {
        return <LightTable headers={["year", "Total earnings"]} rows={
            prizeEarnings.map((p : {year: string, prize: string}) => {return [p.year, euroFormat(p.prize)+"€"]}).reverse()
        } />
    }

    
    return <>
        <TabsMenu headers={["Overview", "Trading", "Token terms cond", "Sport overview and results","Financial overview info", 
                "Investment limits", "Data room"]}
            contents={[showOverview(), showTrading(), <></>, showSportOverview(), showFinancial()]} />
        <div className={"grid pt-5"}>
            <div className="pl-10 py-5 grid">
                
            </div>
            <div className="pl-3 col-span-2">
                
            </div>
        </div>
    </>
}