import { bindActionCreators } from "@reduxjs/toolkit"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { DexxButton } from "../components/light/LightButton"
import { defaultAssetInfo, defaultPrimarySaleGet, defaultSecurityToken, defaultBalance } from "../PoolContext"
import { actionCreators, State } from "../state"
import ArtTokenBody from "../components/tokenBody/ArtTokenBody";
import AifTokenBody from "../components/tokenBody/AifTokenBody";
import HorseTokenBody from "../components/tokenBody/HorseTokenBody";
import PrimarySaleDescriptionTable from "../components/PrimarySaleDescriptionTable";
import BuyPSInterface from "../components/BuyPSInterface";
import { BigTitle, TabTitle } from "../components/typography/Typogtaphy"
import { formatDate, getHourFromDate, addTimezoneOffset } from "../utils"
import Popup from "../components/Popup"

export default function PrimarySaleRoute(props: any): JSX.Element {

    const uuid = props.match.params.uuid
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const [asset, setAsset] = useState(defaultAssetInfo)
    const [token, setToken] = useState(defaultSecurityToken)
    const [primarySaleDetails, setPSDetails] = useState(defaultPrimarySaleGet)
    const [media, setMedia] = useState("")
    const [showPopUp, setShowPopUp] = useState<boolean>(false)
    const detailAccountState = useSelector((state: State) => state.detailAccount)
    const userCtx = detailAccountState.payload
    const offset = userCtx?.timezone_offset ? userCtx.timezone_offset : 0

    useEffect(() => {
        actions.getPrimarySale(uuid, setPSDetails)
    }, [])

    useEffect(() => {
        if (primarySaleDetails.uuid !== '') {
            actions.getAssetInfo(primarySaleDetails.asset_uuid, setAsset)
            actions.getTokenInfo(primarySaleDetails.asset_uuid, setToken)
            actions.getAssetMedia(primarySaleDetails.asset_uuid, setMedia)
        }
    }, [primarySaleDetails])


    if (!primarySaleDetails.uuid || primarySaleDetails.uuid === "0") {
        <div className="grid justify-center text-white text-4xl font-urbanist">Asset not found</div>
    }
    const psDetailsRows = { primary_sales: [primarySaleDetails] }
    var tokenBody: JSX.Element = <></>
    switch (primarySaleDetails.asset_category) {
        case "ART":
            tokenBody = <ArtTokenBody assetInfo={asset} media={media} tokenSymbol={token.ticker}></ArtTokenBody>
            break
        case "AIF":
            tokenBody = <AifTokenBody assetInfo={asset}></AifTokenBody>
            break
        case "SPORTEX":
            tokenBody = <HorseTokenBody assetInfo={asset} media={media}></HorseTokenBody>
            break
        default:
            tokenBody = <></>

    }

    let issuerFees = ""
    let investorFees = ""
    switch (asset.asset_type) {
        case "ART Lending":
            issuerFees = "ISSUER: a fee equal to the 2% of the amount will be charged for each txn"
            investorFees = "INVESTOR: a fee equal to the 0.5% of the amount will be charged for each txn"
            break
        case "ART Investing":
            issuerFees = "ISSUER: a fee equal to the 2% of the amount will be charged for each txn"
            investorFees = "INVESTOR: a fee equal to the 0.5% of the amount will be charged for each txn"
            break
        case "Equestrian":
            issuerFees = "ISSUER: to define"
            investorFees = "INVESTOR: to define"
            break
        case "Private Debt":
            issuerFees = "ISSUER: to define"
            investorFees = "INVESTOR: to define"
            break
        case "Private Equity":
            issuerFees = "ISSUER: to define"
            investorFees = "INVESTOR: to define"
            break
    }
    
    return (<>
        <Popup show={showPopUp} title={""} msg={""} btnOk={true} close={() => {setShowPopUp(false)}}
            body={<BuyPSInterface  asset={asset} token={token} primary_sale={primarySaleDetails} />} />
        <div className="font-roboto">
            <TabTitle className="flex place-content-center">{primarySaleDetails.asset_category}</TabTitle>
            <BigTitle title={primarySaleDetails.asset_name} />
            <div className="flex flex-wrap w-full justify-between px-10 mb-5">
                <div>
                    <DexxButton label={"Sell"} className="rounded-full px-16 py-2 font-bold text-xl bg-succ2 opacity-25 " 
                        color1="succ2" color2="primary"/>
                </div>
                
                <div className="flex flex-wrap gap-4 text-tertiary">
                    <div className="flex flex-col text-lg text-right">
                        <div className="uppercase font-bold ">Starts</div>
                        <div>{formatDate(primarySaleDetails.date_start, offset)}</div>
                        <div>{getHourFromDate(primarySaleDetails.date_start, offset)}</div>
                    </div>
                    <div className="flex flex-col text-lg">
                        <div className="uppercase font-bold">Ends</div>
                        <div>{formatDate(primarySaleDetails.date_end, offset)}</div>
                        <div>{getHourFromDate(primarySaleDetails.date_end, offset)}</div>
                    </div>
                </div>
                <div>
                    {primarySaleDetails.status !== "Started" ?
                        <DexxButton label={"Buy"} className="rounded-full px-16 py-2 font-bold text-xl bg-succ2 opacity-25" 
                            color1="succ2" color2="primary"/> :
                        <DexxButton label={"Buy"} className="rounded-full px-16 py-2 font-bold text-xl bg-succ2 " 
                            color1="succ2" color2="primary" onClick={() => setShowPopUp(!showPopUp)}/>}
                </div>
            </div>
            <PrimarySaleDescriptionTable primarySalesList={psDetailsRows} timezone={offset}/>
            {tokenBody}
        </div>
    </>)
}
