import arrow from "../media/arrow2.png"

export function HeaderButton(props: {click: any, className: string, name: string, 
        color1: string, color2: string, id?: any, shown?: boolean}) {
    const toBeShown = props.shown ? props.shown : false 
    return <div onClick={props.click} id={props.id} key={props.id}
        className={"uppercase py-4 flex items-center "+
        //bg-seafoam text-white ": " text-tertiary hover:text-seafoam"
             
            (" hover:text-"+props.color1+" text-tertiary ") +
            props.className}
        >
            <img src={arrow} className={"transform rotate-45 "+(toBeShown ? "" : " invisible ")}/>
            
            {props.name}</div>
}