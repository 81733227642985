import { ActionType } from '../action-types'
import { Actions } from '../actions'
import { UserAccount, defaultUserState } from "../../UserContext"

interface detailAccountState {
    loading: boolean
    payload?: UserAccount
    type: string
    operation: string
}

const defaultState: detailAccountState = {
    loading: false,
    payload: defaultUserState,
    type: 'null',
    operation: 'null'
}

const detailAccountReducer = (state: detailAccountState = defaultState, action: Actions): detailAccountState => {
    switch(action.type) {
        
        case ActionType.GET_ACCOUNT_PROFILE_REQUEST:
            return {
                loading: true,
                type: 'get',
                operation: 'null'
            }
        case ActionType.GET_ACCOUNT_PROFILE_FAILURE:
            return {
                loading: false,
                payload: action.error,
                type: 'get',
                operation: 'null'
            }
        case ActionType.GET_ACCOUNT_PROFILE_SUCCESS:
            return {
                loading: false,
                payload: action.payload.account_details,
                type: 'get',
                operation: 'null'
            }
        
        case ActionType.SET_ACCOUNT_REQUEST:
            return {
                loading: true,
                type: 'set',
                operation: 'insert'
            }
        case ActionType.SET_ACCOUNT_FAILURE:
            return {
                loading: false,
                payload: action.error,
                type: 'set',
                operation: 'insert'
            }
        case ActionType.SET_ACCOUNT_SUCCESS:
            return {
                loading: false,
                payload: action.payload,
                type: 'set',
                operation: 'insert'
            }
        case ActionType.UPDATE_ACCOUNT_REQUEST:
            return {
                loading: true,
                type: 'set',
                operation: 'edit'
            }
        case ActionType.UPDATE_ACCOUNT_FAILURE:
            return {
                loading: false,
                payload: action.error,
                type: 'set',
                operation: 'edit'
            }
        case ActionType.UPDATE_ACCOUNT_SUCCESS:
            return {
                loading: false,
                payload: action.payload,
                type: 'set',
                operation: 'edit'
            }
        case ActionType.CLEAR_ALL:
            return {
                loading: false,
                payload: undefined,
                type: 'null',
                operation: 'null'
            }

        default:
            return state
    }
}

export default detailAccountReducer

