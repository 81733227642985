import { Link } from "react-router-dom";

export default function NavbarItem(props: {label: string, id: string, to: string, className?: string, onClick?: any}) {
    return <>
        <Link key={props.id} to={props.to} onClick={props.onClick}
            className={"font-roboto font-base text-tertiary py-5 px-5 hover:text-succ2 "+
                props.className} >
                    {props.label}
        </Link>
    </>
}

export function FooterItem(props: {label: string, id: string, to: string, className?: string, onClick?: any}) {
    return <>
        <Link key={props.id} to={props.to} onClick={props.onClick}
            className={"font-roboto font-base text-tertiary py-5 px-5 hover:text-succ2 "+
                props.className} >
                    {props.label}
        </Link>
    </>
}