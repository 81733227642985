export default function HomeRoute(): JSX.Element {

    return (<>
        <div className=" w-full flex flex-col place-content-center text-center">
            <div className="">
                <h3 className="text-white mt-neg20">Alternative</h3>
                <div className="uppercase text-white mt-neg40">Investment Funds</div>
            </div>
            <div>
                <h3 className="text-white">Fimart</h3>
                <div className="uppercase text-white mt-neg40">Fine Art</div>
            </div>
            <div>
                <h3 className="text-white">Sportex</h3>
                <div className="uppercase text-white mt-neg40">Sport Digital Security</div>
            </div>
        </div>
    </>)
}
