import { SecurityToken } from "../PoolContext";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { actionCreators, State } from "../state";
import { useEffect, useState } from "react"
import TabsMenu from "./TabsMenu";
import { euroFormat, getChartData, formatDate, formatDatetime } from "../utils";
import { LightButton } from "./light/LightButton";
import LightTable from "./light/LightTable";
import PieAndList from "./PieAndList"
import { PrimarySaleGet } from "../PoolContext";
import { Link } from "react-router-dom"

export default function IssuerTokenBody (props: {tokenInfo: SecurityToken, media: any,}): JSX.Element {
    
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const [tokenStats, setTokenStats] = useState<any>()
    const [issuedPrimarySales, setIssuedPrimarySales] = useState<PrimarySaleGet[]>([])
    const [tokenPSs, setTokenPSs] = useState<PrimarySaleGet[]>([])
    const hdsPrimarySales = ["ticker", "status", "start", "end", "listed supply", "circulating supply", "on sale", "price"]
    const hdsBuyBack = ["investor", "Q.ty", "purchase price", "value", "market price", "Calculated Price", "Total value"]
    const valueBuyBack = ["simone.baroni83@gmail.com", "20", "1.20", "24.00", "1.32", "1.26", "26.40"]
    const hdsLocked = ["ticker", "Status", "Q.ty", "Unlock Date"]
    const detailAccountState = useSelector((state: State) => state.detailAccount)
    const userCtx = detailAccountState.payload
    const offset = userCtx?.timezone_offset ? userCtx.timezone_offset : 0
    const isDev = window.sessionStorage.getItem("env") !== "production"
    
    useEffect(() => {
        if(props.tokenInfo.uuid){
            actions.getTokenStats(props.tokenInfo.uuid, setTokenStats)
        }
        actions.getIssuerPrimarySales(setIssuedPrimarySales)
    }, [props.tokenInfo])

    useEffect(() => {
        if(props.tokenInfo.uuid){
            const tmp = issuedPrimarySales.filter(v => v.token_uuid === props.tokenInfo.uuid)
            setTokenPSs(tmp)
        }
    }, [issuedPrimarySales])

    function ColoredTab(props: {label: string, color: string, index: number, headers: any, rows: any}): JSX.Element {
        return <div>
            <>
            {props.rows.length > 0 ?
                <LightTable headers={props.headers} rows={props.rows}
                    colorHd={"gradient-to-r from-"+props.color+" to-gray3"}
                    color1={"gradient-to-r from-"+props.color+"_lighter  to-gray1"}
                    color2={"gradient-to-r from-"+props.color+"_light to-gray2"} /> 
                :
                <div className="bg-white">
                    <div className={"font-roboto uppercase text-gray pl-10 py-2 bg-"+props.color+"_light"}>None</div>
                </div>}
                
            </>
        </div>
    }

    function primarySaleRows(l: any[], btnUrls: string[], btnLabels: string[]): any[] {
        return l ?
            l.map(t => addBtns([t.token_ticker, t.status, formatDate(t.date_start, offset), formatDate(t.date_end, offset), 
                euroFormat(t.total_supply), euroFormat(t.shares_issued), euroFormat(t.total_supply - t.shares_issued), 
                euroFormat(t.price)],btnLabels, btnUrls, t.uuid)
            ) : []
    }

    function addBtns(row: any[], btnLabels: string[], btnUrls: string[], uuid: any) {
        let tmp = btnLabels.map((b, i) => {return <div><TableButton label={b} link={btnUrls[i] + uuid} key={i}/></div>})
        return [...row, ...tmp]
    }

    function TableButton(props: { label: string, link: string }): JSX.Element {
        return <>
            <Link className="grid justify-center" to={props.link}><LightButton label={props.label} className="px-2 rounded m-1 font-normal" /></Link>
        </>
    }

    function showIssuanceStatus() { 
        return (<>
            <div className="">
                <div className="grid grid-cols-6 mt-20">
                    <div></div>
                    <div className="col-span-4">
                    <div className="font-roboto font-bold text-2xl text-tertiary mb-5">
                        Asset's and Token's details
                    </div>
                    </div>
                    <div></div>
                </div>
                <div className="grid grid-cols-2 cover my-10">
                    <div className="my-10 flex flex-col text-left">
                        <div className="font-urbanist uppercase text-tertiary text-4xl ml-20">
                                Asset History
                        </div>
                        <div className="mt-20 ml-20 text-left text-gray4 text-xl">
                            {
                                tokenStats ? (
                                    tokenStats.asset_history.map((s:any) => <>
                                        <div className="text-succ2">{s.status}</div>
                                        <div>{formatDatetime(s.timestamp)} {}</div>
                                    </>

                                    )
                                ) : ""
                            }
                        </div>
                    </div>
                    <div className="my-10 text-4xl flex flex-col text-left">
                        <div className="font-urbanist uppercase text-tertiary text-4xl ml-20">
                                Token History
                        </div>
                        <div className="mt-20 ml-20 text-left text-gray4 text-xl">
                        {
                            tokenStats ? (
                                tokenStats.token_history.map((s:any) => <>
                                    <div className="text-succ2">{s.status}</div>
                                    <div>{formatDatetime(s.timestamp)} {}</div>
                                </>

                                )
                            ) : ""
                        }
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-6 mt-20">
                    <div></div>
                    <div className="col-span-4">
                        <div className="font-urbanist uppercase text-tertiary text-4xl">
                            Primary Sales
                        </div>
                        <ColoredTab label={""} color={"succ4"} index={0} headers={[...hdsPrimarySales,...[""]]} 
                                rows={primarySaleRows(tokenPSs ? tokenPSs : [], ["/issuer/primary-sale/"], ["P. S. details"])} />
                    </div>
                    <div></div>
                </div>
            </div>
        </>)
    }
    
    function showInvestorBreakdown() {

        return <>
            <div className="grid grid-cols-6 mt-20">
                <div></div>
                <div className="col-span-4">
                    <div className="font-roboto font-bold text-2xl text-tertiary mb-5">
                        Investors from Primary Sales
                    </div>
                </div>
                <div></div>
            </div>
            <PieAndList tokenStats={tokenStats && tokenStats.investor_breakdown ? tokenStats.investor_breakdown : {}} />
        </>
    }
    
    function showCapTable() {
        return <>
        <div className="grid grid-cols-6 mt-20">
            <div></div>
            <div className="col-span-4">
            <div className="font-roboto font-bold text-2xl text-tertiary mb-5">
                Actual Token's Holders
            </div>
            </div>
            <div></div>
        </div>
        <PieAndList tokenStats={tokenStats && tokenStats.cap_table ? tokenStats.cap_table : {}} />
    </>
    }

    /*function showReporting() { 
        return (<>
            <div className="grid md:px-20 px-5 grid-cols-12 mt-10" >
                <div className="col-span-12 mt-5">
                    <UploadFilesForm 
                        images={{names: images, setNames: setImages,
                                tbr: props.tbr, tbu: props.tbu, setTbr: props.setTbr, setTbu: props.setTbu}}
                        videos={{names: videos, setNames: setVideos,
                                tbr: props.tbr, tbu: props.tbu, setTbr: props.setTbr, setTbu: props.setTbu}}
                        documents={{names: documents, setNames: setDocuments,
                                tbr: props.tbr, tbu: props.tbu, setTbr: props.setTbr, setTbu: props.setTbu}}/>
                </div>
            </div>
        </>)
    }*/

    function showSMStatistics() { 
        return <>
            <div className="grid grid-cols-6 mx-20">
                <div></div>
                    <div className="col-span-4">
                        {getChartData()}
                    </div>
                <div></div>
            </div>
        </>
    }

    function showLP() { 
        return (
            <div className="font-roboto uppercase text-tertiary text-center text-2xl pl-20 pb-20 mt-10">
                no data available yet
            </div> 
        )
    }

    function showTokenControlFunction() { 
        return (<>
            <div className="">
                <div className="grid grid-cols-6 mt-20">
                    
                    <div></div>
                    <div className="col-span-4">
                        <div className="font-urbanist uppercase text-tertiary text-4xl">
                            Buyback Option
                        </div>
                        <div className="font-urbanist text-tertiary text-xl">Actual Market Price: &euro;1.32 </div>
                        <div className="font-urbanist text-tertiary text-xl">Percentage buyback: 5%</div>
                        
                        <ColoredTab label={""} color={"succ4"} index={0} headers={[...hdsBuyBack]} 
                                rows={[valueBuyBack]} />

                        <div className="font-urbanist uppercase text-tertiary text-4xl mt-10">
                            Delivery Dividend
                        </div>

                        <div className="font-urbanist uppercase text-tertiary text-4xl mt-10">
                            Pay Fees
                        </div>

                        <div className="font-urbanist uppercase text-tertiary text-4xl mt-10">
                            Freeze / Unlock Tokens
                        </div>
                        <ColoredTab label={""} color={"succ4"} index={0} headers={[...hdsLocked, ...[]]} 
                                rows={[]} />
                        <div className="font-urbanist uppercase text-tertiary text-4xl mt-10">
                            Put for sale
                        </div>

                        <div className="font-urbanist uppercase text-tertiary text-4xl mt-10">
                            Fund Liquidity Pool
                        </div>
                    </div>
                    <div></div>

                </div>
            </div>
        </>)
    }

    function showPerformanceDetails() { 
        return (
            <div className="font-roboto uppercase text-tertiary text-center text-2xl pl-20 pb-20 mt-10">
                no data available yet
            </div> 
        )
    }

    const headers = [... ["Issuance Status","Investor breakdown","Cap table"], 
        ... (isDev ? ["Secondary market statistics","Liquidity pool","Token control functions",
            "Performance details"] : [])]
    return <>
        <div className="">
            <TabsMenu 
                headers={headers} 
                contents={[... [showIssuanceStatus(), showInvestorBreakdown(), showCapTable()], ...
                    isDev ? [showSMStatistics(), showLP(), showTokenControlFunction(), showPerformanceDetails()] : []]} />
        </div>
    </>
}