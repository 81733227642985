import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { bindActionCreators } from "redux";
import { defaultAssetInfo, defaultPoolState, defaultSecurityToken, PoolState } from "../PoolContext";
import { actionCreators, State } from "../state";
import ArtTokenBody from "../components/tokenBody/ArtTokenBody";
import AifTokenBody from "../components/tokenBody/AifTokenBody";
import TokenDescriptionTable from "../components/TokenDescriptionTable";
import HorseTokenBody from "../components/tokenBody/HorseTokenBody";
import { DexxButton, LightButton } from "../components/light/LightButton";
import { useHistory } from "react-router"
import Popup from "../components/Popup";
import TradePopup from "../components/TradePopup";
import { BigTitle } from "../components/typography/Typogtaphy";

function getPoolByAssetID(pools: PoolState[], uuid: number): PoolState {
    if(pools){
        for (var pool of pools) {
            if (pool.token_a.uuid === uuid) {
                return pool
            }
        }
        return defaultPoolState
    }
    return defaultPoolState
}

export default function SecurityTokenRoute(props: any): JSX.Element {
    let history = useHistory();
    
    const uuid = props.match.params.uuid
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)

    const poolsState = useSelector((state: State) => state.poolToken)

    const [tokenInfo, setTokenInfo] = useState(defaultSecurityToken)
    const [assetInfo, setAssetInfo] = useState(defaultAssetInfo)
    const [assetPool, setAssetPool] = useState(defaultPoolState)
    const [viewTradeInterface, setViewTradeInterface] = useState<boolean>(false)
    const [media, setMedia] = useState("")


    useEffect(() => {
        actions.getTokenInfo(uuid, setTokenInfo)
        actions.getAssetInfo(uuid, setAssetInfo)
        //actions.getPoolTokens()
        actions.getAssetMedia(uuid, setMedia)
    }, [])

    useEffect(() => {
        if (poolsState.payload ) {
            setAssetPool(getPoolByAssetID(poolsState.payload, uuid))
        }
    }, [poolsState.payload])

    // If the token doesn't have an uuid, then it isn't invalid and nothing is shown.
    if(!tokenInfo.uuid || tokenInfo.uuid==="0") {
        <div className="grid justify-center text-white text-4xl font-urbanist">Asset not found</div>
    }
    const assetDetailsRows = {tokens : [tokenInfo]}
    var tokenBody: JSX.Element = <></>
    switch(tokenInfo.asset_class) {
        case "ART":
            tokenBody = <ArtTokenBody assetInfo={assetInfo} media={media} tokenSymbol={tokenInfo.ticker}></ArtTokenBody>
            break
        case "AIF":
            tokenBody = <AifTokenBody assetInfo={assetInfo}></AifTokenBody>
            break
        case "SPORTEX":
            tokenBody = <HorseTokenBody assetInfo={assetInfo} media={media}></HorseTokenBody>
            break
        default:
            tokenBody = <></>

    }
    
    return (
        <>
            <Popup show={viewTradeInterface} title={""} msg={""} btnOk={true} close={() => setViewTradeInterface(false)} 
                body={<TradePopup tokenInfo={tokenInfo} />}/>
            <div>
                <BigTitle title={tokenInfo.asset_name}/>
            </div>
            <div className="grid justify-center">
                <DexxButton label={"buy"} color1="succ2" color2="primary" onClick={() => setViewTradeInterface(!viewTradeInterface)}
                    className="rounded-full px-16 py-2 font-bold text-xl bg-succ2 "/>
            </div>
            <div className="">
                <TokenDescriptionTable tokensList={assetDetailsRows} />
            </div>
            <div className="grid pb-10">
                {tokenBody}
            </div>
        </>)
}