import { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux"
import { bindActionCreators } from "redux"
import { actionCreators, State } from "../../state"
import { formatDate } from "../../utils"

export default function Card(props: { assetInfo: any, id: string | number }): JSX.Element {

    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const asset = props.assetInfo
    const [media, setMedia] = useState()
    const [images, setImages] = useState<any[] | null>(null)
    const detailAccountState = useSelector((state: State) => state.detailAccount)
    const userCtx = detailAccountState.payload

    useEffect(() => {
        actions.getAssetMedia(asset.asset_uuid, setMedia)
    }, [asset.id])

    useEffect(() => {
        if(media)
            actions.getImages(media, setImages)
    }, [media])

    var img = images ? (images.length ? images[0].url : null) : null
    var color = ""
    switch (asset.asset_category) {
        case "ART":
            color = " from-succ8 to-succ7 "
            break
        case "AIF":
            color = " from-succ2 to-succ1 "
            break
        case "SPORTEX":
            color = " from-succ4 to-succ3 "
            break
        default:
            color = " from-succ4 " 
    }
    const offset = userCtx?.timezone_offset ? userCtx.timezone_offset : 0

    return <>
        <div>
            <div className="m-1">
                <div className="flex flex-col w-96 font-roboto h-96">
                    <div className="h-72  bg-black hover:opacity-80 opacity-0 rounded-md">
                        <div className="px-2 rounded-md m-2 z-0 text-tertiary uppercase 
                                border-solid border-tertiary border-1 w-fit py-1 text-md">{asset.status}</div>
                        <div className="flex flex-col text-tertiary place-items-center text-center ">
                            <div className="text-xl leading-6 tracking-wider font-light">{asset.token_ticker}</div>
                            <div className="text-lg leading-6 tracking-wider font-light mt-5">PRICE:</div>
                            <div className="font-normal text-2xl">{asset.price}</div>
                            {asset.status === "Started" ? <Link to={"/primary_sale/"+asset.uuid}>
                            <div className={"text-xs uppercase font-medium leading-4 tracking-widest "+
                                     " px-14 py-2 rounded-full text-black mt-2 cursor-pointer "+"bg-succ2 "}>
                                Buy</div></Link> : <div className={"text-xs uppercase font-medium leading-4 tracking-widest "+
                                     " px-14 py-2 rounded-full text-black mt-2 "+"bg-gray4 "}>
                                Buy</div>}
                        </div>
                        <div className="flex justify-between text-tertiary px-4 font-light mt-5">
                            <div className="text-xs flex uppercase items-center">Total supply
                                <div className="text-base tracking-wider pl-2">{asset.total_supply}</div></div>
                            <div className="text-xs flex uppercase items-center">% sold
                                <div className="text-base tracking-wider pl-2">{Math.round(asset.pct_sold *100)/100}</div></div>
                        </div>
                        
                        <div className="flex justify-between text-tertiary px-4 font-light mt-5">
                            <div className="text-xs flex uppercase items-center">Starting 
                                <div className="text-base tracking-wider pl-2">{formatDate(asset.date_start, offset)}</div></div>
                            <div className="text-xs flex uppercase items-center">Ending 
                                <div className="text-base tracking-wider pl-2">{formatDate(asset.date_end, offset)}</div></div>
                        </div>
                    </div>
                    {img ? 
                        <img src={img} className="object-cover w-96 h-72 mt-neg288 rounded-md"/> : 
                        <div className={"w-96 h-72 mt-neg288 rounded-md text-white font-roboto font-light text-4xl place-content-center flex"+
                            " bg-gradient-to-r "+color}>
                                <div className="mt-28">{asset.asset_category}</div></div>}
                    <div className="flex justify-between align-middle items-center">
                        <div className=" text-lg ml-1 font-roboto uppercase text-white font-bold tracking-wider leading-10">
                            {asset.asset_name}</div>
                        <div className="px-2 rounded-md m-2 z-0 text-tertiary uppercase 
                            border-solid border-tertiary border-1 w-fit py-1 text-md">{asset.asset_category}</div>
                    </div>
               </div>
            </div>
        </div>
    </>
}