import React, { useEffect, useState } from "react";
import LabelAndInput from "./LabelAndInput"
import { defaultPrizeEarnings } from "../AssetContext";

export default function PrizeEarningsRow(props: {info?: any, className?: string, rows: any, setRows: any, idx: number}): JSX.Element {
    
    var values = props.info ? props.info : defaultPrizeEarnings

    const [year, setYear] = useState(values.year)
    const [prize, setPrize] = useState(values.prize)

    useEffect(() => {
        let tmp :any[] = []
        if(props.rows) tmp = [...props.rows]
        tmp[props.idx] = {"year": year, "prize": prize}
        props.setRows(tmp)

    }, [year, prize])

    useEffect(() => {
        setYear(values.year)
        setPrize(values.prize)
    }, [props.info])

    return <>
        <div className={props.className+" font-urbanist py-2 tracking-wider grid grid-cols-2"}>
            <LabelAndInput label={"Year"} value={year} fun={setYear} type={"number"} className={""}/>
            <LabelAndInput label={"Prize"} value={prize} fun={setPrize} type="text" className="" />
        </div>
    </>
}