import { bindActionCreators } from "@reduxjs/toolkit"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router"
import { Link } from "react-router-dom"
import LabelAndInput from "../components/LabelAndInput"
import { LightButton } from "../components/light/LightButton"
import LightTable from "../components/light/LightTable"
import { defaultSecurityToken, Token, defaultBalance } from "../PoolContext"
import { actionCreators, State } from "../state"
import Popup from '../components/Popup'
import {euroFormat} from "../utils"
import { defaultAsset } from "../AssetContext"
import { BigTitle } from "../components/typography/Typogtaphy";

export default function TokenizationRoute(props: any): JSX.Element {
    let history = useHistory();

    const uuid = props.match.params.uuid
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    
    const [asset, setAsset] = useState(defaultAsset)
    const [token, setToken] = useState(defaultSecurityToken)
    const [ticker, setTicker] = useState("")
    const [totalSupply, setTotalSupply] = useState(0)
    const [pctTokenized, setPctTokenized] = useState<number>(100)
    const [proposedPrice, setProposedPrice] = useState<number>()
    const p2pFlag = false
    const lpFlag = true //[lpFlag, setLpFlag] = useState(false)
    const toBeListed = true //[toBeListed, setToBeListed] = useState(false)
    const [saved, setSaved] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    
    const [tokenUuid, setTokenUuid] = useState("")
    const [response, setResponse] = useState<any[]>([0, undefined])
    const [balance, setBalance] = useState(defaultBalance)

    var assetName = asset.name
    var assetCategory = asset.asset_category
    var assetType = asset.asset_type
    var newToken: Token = {
        asset_uuid: uuid,
        ticker: ticker.toUpperCase(),
        total_supply: totalSupply,
        pct_tokenized: asset.asset_category === "ART" ? 100 : pctTokenized,
        proposed_price: proposedPrice ? proposedPrice : 0,
        p2p_flag: p2pFlag,
        lp_flag: lpFlag,
        to_be_listed: toBeListed
    }

    const [showPopUp, setShowPopUp] = useState<boolean>(false)
    const [titlePopUp, setTitlePopUp] = useState<string>("")
    const [msgPopUp, setMsgPopUp] = useState<string>("")
    function handleClosePopUp(){
        setTitlePopUp("")
        setMsgPopUp("")
        setShowPopUp(false)
    }

    useEffect(() => {
        actions.getAssetInfo(uuid, setAsset)
        actions.getTokenInfo(uuid, setToken)
        actions.getActualBalance(setBalance)
    }, [])

    useEffect(() => {
        setPctTokenized(parseFloat(token.pct_tokenized !== undefined ? token.pct_tokenized : "0"))
        setProposedPrice(parseFloat(token.proposed_price !== undefined ? token.proposed_price : "0"))
        setTicker(token.ticker)
        setTotalSupply(parseFloat(token.total_supply !== undefined ? token.total_supply : "0"))
        if(token.uuid !== "0") setTokenUuid(token.uuid)
        //setLpFlag(token.lp_flag)
        //setToBeListed(token.to_be_listed)
    }, [token])

    async function handleSave() {
        let t = newToken
        if(tokenUuid !== "")
            t.uuid = tokenUuid
        if(ticker && ticker.length > 0) {
            setSaved(true)
            actions.createToken({
                token : t,
                submit: false
            }, setResponse)
        }
    }

    function handleSubmit() {
        let t = newToken
        t.uuid = tokenUuid
        if(ticker.length > 0) {
            setSubmitted(true)
            console.log(t)
            actions.createToken({
                token: t,
                submit: true
            }, setResponse)
        }
    }

    useEffect(() => {
        if(response && (saved || submitted)){
            switch(response[0]) {
                case 200:
                    setTokenUuid(response[1].token_uuid)
                    setTitlePopUp("Success")
                    if(saved) setMsgPopUp("Saved!")
                    else setMsgPopUp("Submitted!")
                    setShowPopUp(true)
                    setSaved(false)
                    if(submitted) history.goBack()
                    break
                case 0:
                    break
                default:
                    setTitlePopUp("Error saving the token")
                    setMsgPopUp(response[1].error)
                    setShowPopUp(true)
                    setSaved(false)
            }
        }
    }, response)

    function setUppercaseTicker(tckr: string) {
        setTicker(tckr.toUpperCase())
    }

    function checkFirstZero(num: number) {
        const str = num.toString()
        if(str.substring(0,1)==="0"){
            if(str.substring(1,2)==="." || str.substring(1,2)==="," || str.substring(1,2)==="") return num
            else return parseFloat(str.substring(1))
        } else return num
    }

    console.log(pctTokenized)

    return <>
        <Popup show={showPopUp} title={titlePopUp} msg={msgPopUp} btnOk={true} close={handleClosePopUp} />
        <div>
            <BigTitle title={asset.name} />
            <div className="flex place-content-center mb-5 justify-between flex-wrap gap-4 md:mx-20">
                <div className=" text-succ2 text-2xl uppercase tracking-wider font-normal flex place-content-center">
                    Tokenize
                </div>
                <div className=" text-succ2 text-xl tracking-wider font-normal flex place-content-center">
                    {"€"+euroFormat(balance.payment_tokens.total)}
                </div>
            </div>
            <LightTable headers={["Asset name", "Asset category", "Asset type"]} 
                rows={[[assetName, assetCategory, assetType]]} noBorders={true} colorHd="primary text-tertiary"/>
        </div>
        <div className="grid justify-center xl:mx-36 p-10">
            <div className="grid md:grid-cols-2 p-5">
                <div className="mx-2">
                    <LabelAndInput label={"Ticker"} value={ticker} fun={setUppercaseTicker} type={"text"} className="mr-1.5" color="white"/>
                    {
                        asset.asset_category === "ART" ? (<>
                            <LabelAndInput label={"Pct Tokenized"} value={100.0} helpTip={"Just for ART Assets, you must tokenize 100% of the asset, "+
                                "and then you will be able to sell at maximun 49% of it."}
                                fun={() => {}} type={"number"} className="mx-1.5" color="white"/>
                        </>) : (
                            <LabelAndInput label={"Pct Tokenized"} value={parseFloat(pctTokenized.toString())} 
                        fun={(v: string) => setPctTokenized(parseFloat(v ? v : "0"))} type={"number"} className="mx-1.5" color="white"/>
                        )
                    }
                </div>
                <div className="mx-2">
                    <LabelAndInput label={"Total Supply"} value={totalSupply} 
                        fun={(v: string) => setTotalSupply(parseFloat(v ? v : "0"))} type={"number"} className="mx-1.5" color="white"/>
                    <LabelAndInput label={"Proposed Price"} value={((proposedPrice))} 
                        fun={(v: string) => setProposedPrice(parseFloat(v ? v : ""))} type={"float"} step={0.01} className="ml-1.5" color="white"/>
                </div>

            </div>
            <div className="grid grid-cols-4 mb-10">

            </div>
            <div className="text-primary p-5 bg-tertiary text-center font-urbanist uppercase text-1xl mb-10 tracking-wider">
                Tokenization's fees are temporary free</div>
            
            <div className="flex justify-center flex-wrap gap-4 grid-cols-3">
                <Link to="/issuer/dashboard" className="">
                    <LightButton label={"back"} alternate={true} className="float-left rounded-full py-2 px-5"/></Link>
                <LightButton label={"save"} alternate={true} className="rounded-full py-2 px-5" onClick={() => handleSave()}/>
                { tokenUuid === "" ? "" : <LightButton label={"submit"} alternate={true} className="rounded-full py-2 px-5" onClick={() => handleSubmit()}/>}
            </div>
        </div>
    </>
}