import { bindActionCreators } from "@reduxjs/toolkit"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { AifFundTerms, AifInvestmentLimits, AifIrr, AifOther, AifOverview, AifPrivateDebtCharacteristics, AifPrivateDebtExtraInfo, defaultAifPrivateDebtExtraInfo, FileSrc } from "../../AssetContext"
import { actionCreators } from "../../state"
import { MediaFile } from "../../state/action-creators"
import { TitleColor1 } from "../typography/Typogtaphy"
import UploadFilesForm from "../UploadFilesForm"
import LabelAndInput from "./../LabelAndInput"

export default function ExtraInfoPrivateDebtForm(props: {assetInfo: any, setUpdateFun: any, media: any,
        tbr: any[], setTbr: any, tbu: any[], setTbu: any}): JSX.Element {
    
    const dispatch = useDispatch() 
    const actions = bindActionCreators(actionCreators, dispatch)
    
    // Asset ExtraInfo fields
    const[assetName, setAssetName] = useState<string>("")
    //irr
    const[targetGrossIrr, setTargetGrossIrr] = useState<string>("")
    const[targetCashYield, setTargetCashYield] = useState<string>("")
    const[distributionPolicy, setDistributionPolicy] = useState<string>("")
    //other
    const[aifm, setAifm] = useState<string>("")
    const[auditor, setAuditor] = useState<string>("")
    const[custodian, setCustodian] = useState<string>("")
    const[reporting, setReporting] = useState<string>("")
    const[distributor, setDistributor] = useState<string>("")
    const[administrator, setAdministrator] = useState<string>("")
    const[navFrequency, setNavFrequency] = useState<string>("")
    const[shareClasses, setShareClasses] = useState<string>("")
    const[incentiveFees, setIncentiveFees] = useState<string>("")
    const[generalPartner, setGeneralPartner] = useState<string>("")
    const[legalStructure, setLegalStructure] = useState<string>("")
    const[managementFees, setManagementFees] = useState<string>("")
    const[subDistributor, setSubDistributor] = useState<string>("")
    const[portfolioManager, setPortfolioManager] = useState<string>("")
    const[subsequentSubscriptions, setSubsequentSubscriptions] = useState<string>("")
    //overview
    const[dealType, setDealType] = useState<string>("")
    const[fundName, setFundName] = useState<string>("")
    const[targetFundSize, setTargetFundSize] = useState<string>("")
    const[overviewInvestmentPeriod, setOverviewInvestmentPeriod] = useState<string>("")
    const[riskReturnCharacteristics, setRiskReturnCharacteristics] = useState<string>("")
    const[targetAllocation, setTargetAllocation] = useState<string>("")
    //fund terms
    const[rampUp, setRampUp] = useState<string>("")
    const[fundTerm, setFundTerm] = useState<string>("")
    const[finalClose, setFinalClose] = useState<string>("")
    const[firstClose, setFirstClose] = useState<string>("")
    const[capitalCalls, setCapitalCalls] = useState<string>("")
    const[fundTermsInvestmentPeriod, setFundTermsInvestmentPeriod] = useState<string>("")
    const[capitalRestitutions, setCapitalRestitutions] = useState<string>("")
    //characteristics
    const[covenant, setCovenant] = useState<string>("")
    const[dealCurrency, setDealCurrency] = useState<string>("")
    const[fundCurrency, setFundCurrency] = useState<string>("")
    const[averageRating, setAverageRating] = useState<string>("")
    const[loansOrBonds, setLoansOrBonds] = useState<string>("")
    const[minimumRating, setMinimumRating] = useState<string>("")
    const[numberOfDeals, setNumberOfDeals] = useState<string>("")
    const[borrowerProfile, setBorrowerProfile] = useState<string>("")
    const[targetDealSize, setTargetDealSize] = useState<string>("")
    const[floatingOrFixed, setFloatingOrFixed] = useState<string>("")
    const[typeOfCollateral, setTypeOfCollateral] = useState<string>("")
    const[speedOfDeployment, setSpeedOfDeployment] = useState<string>("")
    const[targetCompanySize, setTargetCompanySize] = useState<string>("")
    const[bulletOrAmortizing, setBulletOrAmortizing] = useState<string>("")
    const[primarySecondary, setPrimarySecondary] = useState<string>("")
    const[sponsorOrCorporate, setSponsorOrCorporate] = useState<string>("")
    const[typicalLoanToValue, setTypicalLoanToValue] = useState<string>("")
    const[expectedTermPerDeal, setExpectedTermPerDeal] = useState<string>("")
    const[maxAverageNetLeverage, setMaxAverageNetLeverage] = useState<string>("")
    const[maxTypicalTermPerDeal, setMaxTypicalTermPerDeal] = useState<string>("")
    //Investment Limits
    const[leverage, setLeverage] = useState<string>("")
    const[derivatives, setDerivatives] = useState<string>("")
    const[geographicArea, setGeographicArea] = useState<string>("")
    const[maxIssuerLimit, setMaxIssuerLimit] = useState<string>("")
    const[maxSectorLimit, setMaxSectorLimit] = useState<string>("")
    const[esgConsiderations, setEsgConsiderations] = useState<string>("")
    const[excludedProfiles, setExcludedProfiles] = useState<string>("")
    const[maxCountryLimit, setMaxCountryLimit] = useState<string>("")

    const[imagesSrc, setImagesSrc] = useState<{url:string, filename: string}[]>([])
    const[videosSrc, setVideosSrc] = useState<{url:string, filename: string}[]>([])
    const[documentsSrc, setDocumentsSrc] = useState<{url:string, filename: string}[]>([])
    const[images, setImages] = useState<FileSrc[]>([])
    const[videos, setVideos] = useState<FileSrc[]>([])
    const[documents, setDocuments] = useState<FileSrc[]>([])
    const[download, setDownload] = useState<boolean>(true)
    
    var newIrrInfo: AifIrr = {
        //target_moic : targetMoic,
        target_gross_irr : targetGrossIrr,
        target_cash_yield : targetCashYield,
        distribution_policy : distributionPolicy
    }

    var newOtherInfo: AifOther = {
        AIFM : aifm,
        auditor : auditor,
        custodian : custodian,
        reporting : reporting,
        distributor : distributor,
        //gp_clawback : gpClawback,
        //hurdle_rate : hurdleRate,
        administrator : administrator,
        nav_frequency : navFrequency,
        share_classes : shareClasses,
        incentive_fees : incentiveFees,
        general_partner : generalPartner,
        //legal_counseler : legalCounseler,
        legal_structure : legalStructure,
        management_fees : managementFees,
        sub_distributor : subDistributor,
        portfolio_manager : portfolioManager,
        //type_of_waterfall : typeOfWaterfall,
        subsequent_subscriptions : subsequentSubscriptions
    }
    
    var newOverviewInfo: AifOverview = {
        deal_type : dealType,
        fund_name : fundName,
        target_fund_size : targetFundSize,
        investment_period : overviewInvestmentPeriod,
        risk_return_characteristics : riskReturnCharacteristics,
        target_allocation : targetAllocation,
        //investment_strategy_description : investmentStrategyDescription
    }
    
    var newfund_termsrmsInfo: AifFundTerms = {
        ramp_up : rampUp,
        fund_term : fundTerm,
        final_close : finalClose,
        first_close : firstClose,
        capital_calls : capitalCalls,
        investment_period : fundTermsInvestmentPeriod,
        capital_restitutions : capitalRestitutions
    }

    var newCharacteristicsInfo: AifPrivateDebtCharacteristics = {
        covenant : covenant,
        deal_currency : dealCurrency,
        fund_currency : fundCurrency,
        average_rating : averageRating,
        loans_or_bonds : loansOrBonds,
        minimum_rating : minimumRating,
        number_of_deals : numberOfDeals,
        borrower_profile : borrowerProfile,
        target_deal_size : targetDealSize,
        floating_or_fixed : floatingOrFixed,
        type_of_collateral : typeOfCollateral,
        speed_of_deployment : speedOfDeployment,
        target_company_size : targetCompanySize,
        bullet_or_amortizing : bulletOrAmortizing,
        primary_or_secondary : primarySecondary,
        sponsor_or_corporate : sponsorOrCorporate,
        typical_loan_to_value : typicalLoanToValue,
        expected_term_per_deal : expectedTermPerDeal,
        max_average_net_leverage : maxAverageNetLeverage,
        max_typical_term_per_deal : maxTypicalTermPerDeal
    }

    var newInvestmentLimitsInfo: AifInvestmentLimits = {
        leverage : leverage,
        derivatives : derivatives,
        geographic_area : geographicArea,
        max_issuer_limit : maxIssuerLimit,
        max_sector_limit : maxSectorLimit,
        esg_considerations : esgConsiderations,
        excluded_profiles : excludedProfiles,
        max_country_limit : maxCountryLimit
    }
    
    var newPrivateDebtInfo: AifPrivateDebtExtraInfo = {
        irr: newIrrInfo,
        other: newOtherInfo,
        overview: newOverviewInfo,
        fund_terms: newfund_termsrmsInfo,
        characteristics: newCharacteristicsInfo,
        investment_limits: newInvestmentLimitsInfo
    }

    useEffect(() => {
        if(props.media && download) {
            actions.getDocuments(props.media, setDocumentsSrc)
            actions.getImages(props.media, setImagesSrc)
            actions.getVideos(props.media, setVideosSrc)
            setDownload(false)
        }
    }, [props.media])

    useEffect(() => {
        setImages(imagesSrc.map((u) => {return {name: u.filename, uploaded: true, src: u.url}}))
    }, [imagesSrc])

    useEffect(() => {
        setVideos(videosSrc.map((u) => {return {name: u.filename, uploaded: true, src: u.url}}))
    }, [videosSrc])

    useEffect(() => {
        setDocuments(documentsSrc.map((u) => {return {name: u.filename, uploaded: true, src: u.url}}))
    }, [documentsSrc])

    useEffect(() => {

        if(props.assetInfo.payload && props.assetInfo.payload.extra_info) {
            let aifPrivateDebt = defaultAifPrivateDebtExtraInfo
            if(props.assetInfo.payload.extra_info.private_debt){
                aifPrivateDebt = props.assetInfo.payload.extra_info.private_debt.sub_assets ? 
                    props.assetInfo.payload.extra_info.private_debt.sub_assets[0] : 
                    defaultAifPrivateDebtExtraInfo
            }
            setAssetName(props.assetInfo.payload.name)
            if(aifPrivateDebt) {
                
                //setTargetMoic(aifPrivateDebt.irr.target_moic)
                setTargetGrossIrr(aifPrivateDebt.irr.target_gross_irr)
                setTargetCashYield(aifPrivateDebt.irr.target_cash_yield ? aifPrivateDebt.irr.target_cash_yield : "")
                setDistributionPolicy(aifPrivateDebt.irr.distribution_policy)
                
                setAifm(aifPrivateDebt.other.AIFM)
                setAuditor(aifPrivateDebt.other.auditor)
                setCustodian(aifPrivateDebt.other.custodian)
                setReporting(aifPrivateDebt.other.reporting)
                setDistributor(aifPrivateDebt.other.distributor)
                //setGpClawback(aifPrivateDebt.other.gp_clawback)
                //setHurdleRate(aifPrivateDebt.other.hurdle_rate)
                setAdministrator(aifPrivateDebt.other.administrator)
                setNavFrequency(aifPrivateDebt.other.nav_frequency)
                setShareClasses(aifPrivateDebt.other.share_classes)
                setIncentiveFees(aifPrivateDebt.other.incentive_fees)
                setGeneralPartner(aifPrivateDebt.other.general_partner ? aifPrivateDebt.other.general_partner : "")
                //setLegalCounseler(aifPrivateDebt.other.legal_counseler)
                setLegalStructure(aifPrivateDebt.other.legal_structure ? aifPrivateDebt.other.legal_structure : "")
                setManagementFees(aifPrivateDebt.other.management_fees)
                setSubDistributor(aifPrivateDebt.other.sub_distributor)
                setPortfolioManager(aifPrivateDebt.other.portfolio_manager)
                //setTypeOfWaterfall(aifPrivateDebt.other.type_of_waterfall)
                setSubsequentSubscriptions(aifPrivateDebt.other.subsequent_subscriptions)
                
                setDealType(aifPrivateDebt.overview.deal_type)
                setFundName(aifPrivateDebt.overview.fund_name)
                setTargetFundSize(aifPrivateDebt.overview.target_fund_size)
                setOverviewInvestmentPeriod(aifPrivateDebt.overview.investment_period ? aifPrivateDebt.overview.investment_period : "")
                setRiskReturnCharacteristics(aifPrivateDebt.overview.risk_return_characteristics)
                setTargetAllocation(aifPrivateDebt.overview.target_allocation)
                //setInvestmentStrategyDescription(aifPrivateDebt.overview.investment_strategy_description)
                
                setRampUp(aifPrivateDebt.fund_terms.ramp_up)
                setFundTerm(aifPrivateDebt.fund_terms.fund_term)
                setFinalClose(aifPrivateDebt.fund_terms.final_close)
                setFirstClose(aifPrivateDebt.fund_terms.first_close)
                setCapitalCalls(aifPrivateDebt.fund_terms.capital_calls)
                setFundTermsInvestmentPeriod(aifPrivateDebt.fund_terms.investment_period)
                setCapitalRestitutions(aifPrivateDebt.fund_terms.capital_restitutions)

                setCovenant(aifPrivateDebt.characteristics.covenant)
                setDealCurrency(aifPrivateDebt.characteristics.deal_currency)
                setFundCurrency(aifPrivateDebt.characteristics.fund_currency)
                setAverageRating(aifPrivateDebt.characteristics.average_rating)
                setLoansOrBonds(aifPrivateDebt.characteristics.loans_or_bonds)
                setMinimumRating(aifPrivateDebt.characteristics.minimum_rating)
                setNumberOfDeals(aifPrivateDebt.characteristics.number_of_deals)
                setBorrowerProfile(aifPrivateDebt.characteristics.borrower_profile)
                setTargetDealSize(aifPrivateDebt.characteristics.target_deal_size)
                setFloatingOrFixed(aifPrivateDebt.characteristics.floating_or_fixed)
                setTypeOfCollateral(aifPrivateDebt.characteristics.type_of_collateral)
                setSpeedOfDeployment(aifPrivateDebt.characteristics.speed_of_deployment)
                setTargetCompanySize(aifPrivateDebt.characteristics.target_company_size)
                setBulletOrAmortizing(aifPrivateDebt.characteristics.bullet_or_amortizing)
                setPrimarySecondary(aifPrivateDebt.characteristics.primary_or_secondary)
                setSponsorOrCorporate(aifPrivateDebt.characteristics.sponsor_or_corporate)
                setTypicalLoanToValue(aifPrivateDebt.characteristics.typical_loan_to_value)
                setExpectedTermPerDeal(aifPrivateDebt.characteristics.expected_term_per_deal)
                setMaxAverageNetLeverage(aifPrivateDebt.characteristics.max_average_net_leverage)
                setMaxTypicalTermPerDeal(aifPrivateDebt.characteristics.max_typical_term_per_deal)

                setLeverage(aifPrivateDebt.investment_limits.leverage)
                setDerivatives(aifPrivateDebt.investment_limits.derivatives)
                setGeographicArea(aifPrivateDebt.investment_limits.geographic_area)
                setMaxIssuerLimit(aifPrivateDebt.investment_limits.max_issuer_limit)
                setMaxSectorLimit(aifPrivateDebt.investment_limits.max_sector_limit)
                setEsgConsiderations(aifPrivateDebt.investment_limits.esg_considerations)
                setExcludedProfiles(aifPrivateDebt.investment_limits.excluded_profiles)
                setMaxCountryLimit(aifPrivateDebt.investment_limits.max_country_limit)
                
            }
        }                  
    }, [props.assetInfo.payload])

    function updateEI() {
        var subAsset :any[] = []
        subAsset.push(newPrivateDebtInfo)
        const assetExtraInfo = {
            private_debt: {
                sub_assets: subAsset
            }
        }
        return assetExtraInfo 
    }

    useEffect(() => {
        props.setUpdateFun(updateEI)
    }, [
        assetName, targetGrossIrr,targetCashYield,distributionPolicy, aifm,auditor,custodian,reporting,distributor,administrator,
        navFrequency,shareClasses,incentiveFees, generalPartner,legalStructure,managementFees,subDistributor,portfolioManager,
        subsequentSubscriptions,dealType,fundName,targetFundSize,overviewInvestmentPeriod,riskReturnCharacteristics,targetAllocation,
        rampUp,fundTerm,finalClose,firstClose, capitalCalls,fundTermsInvestmentPeriod,capitalRestitutions,covenant,dealCurrency,
        fundCurrency, averageRating,loansOrBonds, minimumRating,numberOfDeals,borrowerProfile,targetDealSize, floatingOrFixed,
        typeOfCollateral, speedOfDeployment, targetCompanySize, bulletOrAmortizing,primarySecondary, sponsorOrCorporate,
        typicalLoanToValue,expectedTermPerDeal,maxAverageNetLeverage,maxTypicalTermPerDeal,leverage,derivatives,geographicArea,
        maxIssuerLimit,maxSectorLimit,esgConsiderations,excludedProfiles, maxCountryLimit])  
          
    return <>
        <div className="grid md:px-20 px-5 grid-cols-12 mt-10" >
            <LabelAndInput id={"assetName"} label={"Asset Name"} value={assetName} fun={setAssetName} type={"disabled"} className={"col-span-12"} required/>
            
            <TitleColor1 className="text-lg mt-5 col-span-12">/ Overview</TitleColor1>

            <LabelAndInput id={"dealType"} label={"Deal Type"} value={dealType} fun={setDealType} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"fundName"} label={"Fund Name"} value={fundName} fun={setFundName} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"targetFundSize"} label={"Target Fund Size"} value={targetFundSize} fun={setTargetFundSize} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"overviewInvestmentPeriod"} label={"Investment Period"} value={overviewInvestmentPeriod} fun={setOverviewInvestmentPeriod} type={"text"} className={"col-span-4"} required />
            <LabelAndInput id={"riskReturnCharacteristics"} label={"Risk Return Characteristics"} value={riskReturnCharacteristics} fun={setRiskReturnCharacteristics} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"targetAllocation"} label={"Target Allocation Seniority"} value={targetAllocation} fun={setTargetAllocation} type={"text"} className={"col-span-4"} required />

            <TitleColor1 className="text-lg mt-5 col-span-12">/ Characteristics</TitleColor1>
                    
            <LabelAndInput id={"covenant"} label={"Covenant"} value={covenant} fun={setCovenant} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"dealCurrency"} label={"Deal Currency"} value={dealCurrency} fun={setDealCurrency} type={"text"} className={"col-span-4"} required />
            <LabelAndInput id={"fundCurrency"} label={"Fund Currency"} value={fundCurrency} fun={setFundCurrency} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"averageRating"} label={"Average Rating"} value={averageRating} fun={setAverageRating} type={"text"} className={"col-span-4"} required />
            <LabelAndInput id={"loansOrBonds"} label={"Loans Or Bonds"} value={loansOrBonds} fun={setLoansOrBonds} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"minimumRating"} label={"Minimum Rating"} value={minimumRating} fun={setMinimumRating} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"numberOfDeals"} label={"Number Of Deals"} value={numberOfDeals} fun={setNumberOfDeals} type={"text"} className={"col-span-6"} required/>
            <LabelAndInput id={"borrowerProfile"} label={"Borrower Profile"} value={borrowerProfile} fun={setBorrowerProfile} type={"text"} className={"col-span-6"} required />
            <LabelAndInput id={"targetDealSize"} label={"Target Deal Size"} value={targetDealSize} fun={setTargetDealSize} type={"text"} className={"col-span-6"} required/>
            <LabelAndInput id={"floatingOrFixed"} label={"Floating Or Fixed"} value={floatingOrFixed} fun={setFloatingOrFixed} type={"text"} className={"col-span-6"} required/>
            <LabelAndInput id={"typeOfCollateral"} label={"Type Of Collateral"} value={typeOfCollateral} fun={setTypeOfCollateral} type={"textarea"} rows={3} className={"col-span-12"} required/>
            <LabelAndInput id={"speedOfDeployment"} label={"Speed Of Deployment"} value={speedOfDeployment} fun={setSpeedOfDeployment} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"targetCompanySize"} label={"Target Company Size"} value={targetCompanySize} fun={setTargetCompanySize} type={"text"} className={"col-span-4"} required />
            <LabelAndInput id={"bulletOrAmortizing"} label={"Bullet Or Amortizing"} value={bulletOrAmortizing} fun={setBulletOrAmortizing} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"primaryOrSecondary"} label={"Primary Secondary"} value={primarySecondary} fun={setPrimarySecondary} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"sponsorOrCorporate"} label={"Sponsor Or Corporate"} value={sponsorOrCorporate} fun={setSponsorOrCorporate} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"typicalLoanToValue"} label={"Typical Loan To Value"} value={typicalLoanToValue} fun={setTypicalLoanToValue} type={"text"} className={"col-span-4"} required />
            <LabelAndInput id={"expectedTermPerDeal"} label={"Expected Term Per Deal"} value={expectedTermPerDeal} fun={setExpectedTermPerDeal} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"maxAverageNetLeverage"} label={"Max Average Net Leverage"} value={maxAverageNetLeverage} fun={setMaxAverageNetLeverage} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"maxTypicalTermPerDeal"} label={"Max Typical Term Per Deal"} value={maxTypicalTermPerDeal} fun={setMaxTypicalTermPerDeal} type={"text"} className={"col-span-4"} required />
            
            <TitleColor1 className="text-lg mt-5 col-span-12">/ Investment Limits</TitleColor1>
            
            <LabelAndInput id={"leverage"} label={"Leverage"} value={leverage} fun={setLeverage} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"derivatives"} label={"Derivatives"} value={derivatives} fun={setDerivatives} type={"text"} className={"col-span-4"} required />
            <LabelAndInput id={"geographicArea"} label={"Geographic Area"} value={geographicArea} fun={setGeographicArea} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"maxIssuerLimit"} label={"Max Issuer Limit"} value={maxIssuerLimit} fun={setMaxIssuerLimit} type={"text"} className={"col-span-6"} required/>
            <LabelAndInput id={"maxSectorLimit"} label={"Max Sector Limit"} value={maxSectorLimit} fun={setMaxSectorLimit} type={"text"} className={"col-span-6"} required/>
            <LabelAndInput id={"esgConsiderations"} label={"ESG Consideration"} value={esgConsiderations} fun={setEsgConsiderations} type={"textarea"} rows={3} className={"col-span-12"} required/>
            <LabelAndInput id={"excludedProfiles"} label={"Excluded Profiles"} value={excludedProfiles} fun={setExcludedProfiles} type={"text"} className={"col-span-6"} required/>
            <LabelAndInput id={"maxCountryLimit"} label={"Max Country Limit"} value={maxCountryLimit} fun={setMaxCountryLimit} type={"text"} className={"col-span-6"} required/>

            <TitleColor1 className="text-lg mt-5 col-span-12">/ IRR</TitleColor1>

            <LabelAndInput id={"targetGrossIrr"} label={"Target Gross IRR"} value={targetGrossIrr} fun={setTargetGrossIrr} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"targetCashYield"} label={"Target Chash Yield"} value={targetCashYield} fun={setTargetCashYield} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"distributionPolicy"} label={"Distribution Policy"} value={distributionPolicy} fun={setDistributionPolicy} type={"text"} className={"col-span-4"} required/>

            <TitleColor1 className="text-lg mt-5 col-span-12">/ Fund Terms</TitleColor1>

            <LabelAndInput id={"rampUp"} label={"Ramp Up"} value={rampUp} fun={setRampUp} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"fundTerm"} label={"Fund Term"} value={fundTerm} fun={setFundTerm} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"finalClose"} label={"Final Close"} value={finalClose} fun={setFinalClose} type={"text"} className={"col-span-4"} required />
            <LabelAndInput id={"firstClose"} label={"First Close"} value={firstClose} fun={setFirstClose} type={"text"} className={"col-span-6"} required/>
            <LabelAndInput id={"capitalCalls"} label={"Capitals Class"} value={capitalCalls} fun={setCapitalCalls} type={"text"} className={"col-span-6"} required />
            <LabelAndInput id={"fundTermsInvestmentPeriod"} label={"Investment Period"} value={fundTermsInvestmentPeriod} fun={setFundTermsInvestmentPeriod} type={"text"} className={"col-span-6"} required/>
            <LabelAndInput id={"capitalRestitutions"} label={"Capital Restitutions"} value={capitalRestitutions} fun={setCapitalRestitutions} type={"text"} className={"col-span-6"} required/>

            <TitleColor1 className="text-lg mt-5 col-span-12">/ Others</TitleColor1>

            <LabelAndInput id={"AIFM"} label={"AIFM"} value={aifm} fun={setAifm} type={"text"} className={"col-span-4"} required />
            <LabelAndInput id={"auditor"} label={"Auditor"} value={auditor} fun={setAuditor} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"custodian"} label={"Custodian"} value={custodian} fun={setCustodian} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"reporting"} label={"Reporting"} value={reporting} fun={setReporting} type={"textarea"} rows={3} className={"col-span-12"} required/>
            <LabelAndInput id={"distributor"} label={"Distributor"} value={distributor} fun={setDistributor} type={"text"} className={"col-span-4"} required />
            <LabelAndInput id={"administrator"} label={"Administrator"} value={administrator} fun={setAdministrator} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"navFrequency"} label={"Nav Frequency"} value={navFrequency} fun={setNavFrequency} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"shareClasses"} label={"Share Classes"} value={shareClasses} fun={setShareClasses} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"incentiveFees"} label={"Incentive Fees"} value={incentiveFees} fun={setIncentiveFees} type={"text"} className={"col-span-4"} required />
            <LabelAndInput id={"generalPartner"} label={"General Partner"} value={generalPartner} fun={setGeneralPartner} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"legalStructure"} label={"Legal Structure"} value={legalStructure} fun={setLegalStructure} type={"text"} className={"col-span-12"} required/>
            <LabelAndInput id={"managementFees"} label={"Management Fees"} value={managementFees} fun={setManagementFees} type={"text"} className={"col-span-6"} required/>
            <LabelAndInput id={"subDistributor"} label={"Sub Distributor"} value={subDistributor} fun={setSubDistributor} type={"text"} className={"col-span-6"} required/>
            <LabelAndInput id={"portfolioManager"} label={"Portfolio Manager"} value={portfolioManager} fun={setPortfolioManager} type={"text"} className={"col-span-6"} required />
            <LabelAndInput id={"subsequentSubscriptions"} label={"Subsequent Subscriptions"} value={subsequentSubscriptions} fun={setSubsequentSubscriptions} type={"text"} className={"col-span-6"} required />
            <div className="col-span-12 mt-5">
                <UploadFilesForm 
                    images={{names: images, setNames: setImages,
                            tbr: props.tbr, tbu: props.tbu, setTbr: props.setTbr, setTbu: props.setTbu}}
                    videos={{names: videos, setNames: setVideos,
                            tbr: props.tbr, tbu: props.tbu, setTbr: props.setTbr, setTbu: props.setTbu}}
                    documents={{names: documents, setNames: setDocuments,
                            tbr: props.tbr, tbu: props.tbu, setTbr: props.setTbr, setTbu: props.setTbu}}/>
            </div>

        </div>
    </>
}