import { ActionType } from '../action-types'
import { Actions } from '../actions'
import { UserContext, UserAccount, defaultUserState } from "../../UserContext"

interface kycAccountState {
    loading: boolean
    payload?: UserAccount
    type: string
}

const defaultState: kycAccountState = {
    loading: false,
    payload: defaultUserState,
    type: 'null'
}

const kycAccountReducer = (state: kycAccountState = defaultState, action: Actions): kycAccountState => {
    switch(action.type) {
        
        case ActionType.GET_ISSUER_KYC_DETAILS_REQUEST:
            return {
                loading: true,
                type: 'get'
            }
        case ActionType.GET_ISSUER_KYC_DETAILS_FAILURE:
            return {
                loading: false,
                payload: action.error,
                type: 'get'
            }
        case ActionType.GET_ISSUER_KYC_DETAILS_SUCCESS:
            return {
                loading: false,
                payload: action.payload.account_details,
                type: 'get'
            }

        case ActionType.SET_ISSUER_KYC_DETAILS_REQUEST:
            return {
                loading: true,
                type: 'set'
            }
        case ActionType.SET_ISSUER_KYC_DETAILS_FAILURE:
            return {
                loading: false,
                payload: action.error,
                type: 'set'
            }
        case ActionType.SET_ISSUER_KYC_DETAILS_SUCCESS:
            return {
                loading: false,
                payload: action.payload.account_details,
                type: 'set'
            }
        
        
        case ActionType.CLEAR_ALL:
            return {
                loading: false,
                payload: undefined,
                type: 'null'
            }

        default:
            return state
    }
}

export default kycAccountReducer

