import { ActionType } from "../action-types"
import { Actions } from "../actions"

interface issuedAssetsState {
    loading: boolean
    payload?: any
}

const defaultIssuedAssetsState = {
    loading: false,
    payload: {
        "tokenization_in_progress_assets": [],
        "in_process_assets": [],
        "submitted_assets": [],
        "rejected_assets": [],
        "denied_assets": [],
        "approved_assets": [],
        "tokenized_assets": []
    }
}

const issuedAssetsReducer = (state: issuedAssetsState = defaultIssuedAssetsState, action: Actions): issuedAssetsState => {
    switch (action.type) {
        case ActionType.GET_ISSUED_ASSETS_REQUEST:
            return {
                loading: true,
            }
        case ActionType.GET_ISSUED_ASSETS_SUCCESS:
            return {
                loading: false,
                payload: action.payload
            }
        case ActionType.GET_ISSUED_ASSETS_FAILURE:
            return {
                loading: false,
                payload: action.error
            }
        case ActionType.CLEAR_ALL:
            return {
                loading: false,
                payload: undefined
            }
        default:
            return state
    }
}

export default issuedAssetsReducer