import { useHistory  } from 'react-router-dom'
import { LightButton } from '../components/light/LightButton';

export default function VerifyEmailRoute(): JSX.Element {

    let history = useHistory();
    
    return <>
        <div className="grid md:grid-cols-1 justify-center justify-items-center">
            <div className="font-thin text-white font-jost uppercase md:text-5xl text-4xl tracking-wide md:pl-20 pl-2">
                <div className="md:text-6xl text-5xl "> We sent you a verification email,</div>
                please follow the link you received to confirm your email adress.
                
            </div>
            <LightButton label={"I understood"} alternate={true} className="rounded-full px-10 mt-20" onClick={() => {history.push('/')}}/>
        </div>
    </>
}
