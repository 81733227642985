import { Chart } from "react-google-charts"
import { useSelector } from "react-redux"
import { LightButton } from "./components/light/LightButton"
import { State } from "./state"

export const makeCurrency = (n: number) => n.toLocaleString('en-US', {
  style: 'currency',
  currency: 'USD'
})

export function euroFormat(num: any): string {
  if (typeof num == "string") {
    return parseFloat(num).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  }
  if (typeof num == "number") {
    return num.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  }
  return ""
}

export function quantityFormat(num: any): string {
  if (typeof num == "string") {
    return parseFloat(num).toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 5 })
  }
  if (typeof num == "number") {
    return num.toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 5 })
  }
  return ""
}

export function roundIfNumber(num: string, nDecimals: number): string {
  try {
    return parseFloat(num).toFixed(nDecimals)
  } catch (e) {
    return num
  }
}

export function checkRegexEmail(email: string) {
  let reg_exp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (reg_exp.test(email)) return true;
  else return false;
}

export function checkStrongPassword(email: string, password: string) {
  let count_strong = 0;
  let surname = email.substring(0, email.indexOf('@'))

  if (password.includes(surname)) return false;
  if (password.length < 8) return false;

  if (/[a-z]/.test(password)) count_strong++;
  if (/[A-Z]/.test(password)) count_strong++;
  if (/[0-9]/.test(password)) count_strong++;
  if (/(?=.*[!@#$%^&*])/.test(password)) count_strong++;

  if (count_strong >= 3) return true;
  else return false;
}

export function addTimezoneOffset(isoDate: string, offset: number): Date {
  if(offset) {
    var targetTime = new Date(isoDate);
    var tzDifference = offset * 60 + targetTime.getTimezoneOffset();
    var newDate = new Date(targetTime.getTime() + tzDifference * 60 * 1000);
    return newDate
  }
  return new Date(isoDate)
}

export function removeTimezoneOffset(isoDate: string, offset: number): Date {
  if(offset) {
    var targetTime = new Date(isoDate);
    var tzDifference = offset * 60 + targetTime.getTimezoneOffset();
    var newDate = new Date(targetTime.getTime() - tzDifference * 60 * 1000);
    console.log(newDate)
    return newDate
  }
  return new Date(isoDate)
}

function parseHour(s: string): string {
  return s.substring(11, 16)
}

export function formatDate(isoDate: string, offset: number){
  const date = addTimezoneOffset(isoDate, offset);
  date.setDate(date.getDate())
  return date.toLocaleDateString()
}

export function formatDatetime(isoDate: any){
  const date = new Date(isoDate);
  date.setDate(date.getDate())
  return date.toLocaleDateString() + " " + date.toLocaleTimeString()
}

export function formatExpiringDate(isoDate: any, offset: number){
  const date = addTimezoneOffset(isoDate, offset);
  date.setDate(date.getDate() + 30)
  return date.toLocaleDateString()
}

export function getHourFromDate(isoDate: any, offset: number) {
  const date = addTimezoneOffset(isoDate, offset);
  date.setDate(date.getDate())
  return (date.getHours() + ":" + date.getMinutes())
}

export function convertDateToUTC(dateTime: any, offset: number){
  if(dateTime === "") return ""
  const date = addTimezoneOffset(dateTime, offset);
  console.log(date)
  console.log(date.toISOString())
  return date.toISOString()
}

export function getProfileUrl(basicAccountState : any, detailAccountState: any): string {
  if(basicAccountState.payload && basicAccountState.payload.kyc_submitted)
      return "/profile"
  else if(detailAccountState.payload && detailAccountState.payload.last_name && detailAccountState.payload.last_name?.length > 0) {
      return "/account/almost-done"
  }       
  return "/account/additional-info"
}

function LittleButton(props: { label: String, onClick?: any, alternate?: boolean, className?: string, fontClasses?: string }): JSX.Element {
  var isAlternate = false
  if (props.alternate)
    isAlternate = props.alternate

  return <>
    <div className={"font-urbanist border border-solid" +
      " not-italic text-center uppercase  cursor-pointer " +
      " w-fit " +
      (props.fontClasses ? props.fontClasses : " text-xl ") +
      (isAlternate ? " text-white border-white hover:bg-background hover:text-blue hover:border-blue " :
        " border-blue text-blue hover:bg-primary hover:text-white ") +
      (props.className ? props.className : "")}
      onClick={props.onClick}>{props.label}</div>
  </>
}

export function getChartData() {
  const minute = [
    {
      'time': '2022-04-11T12:28:00Z',
      'interval': '1 minute',
      'token_id': 2,
      'open': '6.3',
      'high': '6.8',
      'close': '6.7',
      'low': '6.0',
      'volume': 2
    },
    {
      'time': '2022-04-11T12:29:00Z',
      'interval': '1 minute',
      'token_id': 2,
      'open': '6.6',
      'high': '6.7',
      'close': '6.7',
      'low': '6.4',
      'volume': 0
    },
    {
      'time': '2022-04-11T12:30:00Z',
      'interval': '1 minute',
      'token_id': 2,
      'open': '6.7',
      'high': '7.7',
      'close': '7.4',
      'low': '6.7',
      'volume': 0
    },
    {
      'time': '2022-04-11T12:31:00Z',
      'interval': '1 minute',
      'token_id': 2,
      'open': '7.1',
      'high': '7.2',
      'close': '6.7',
      'low': '6.7',
      'volume': 0
    },
    {
      'time': '2022-04-11T12:32:00Z',
      'interval': '1 minute',
      'token_id': 2,
      'open': '6.7',
      'high': '6.7',
      'close': '6.7',
      'low': '6.7',
      'volume': 0
    },
    {
      'time': '2022-04-11T12:33:00Z',
      'interval': '1 minute',
      'token_id': 2,
      'open': '6.7',
      'high': '6.7',
      'close': '6.7',
      'low': '6.7',
      'volume': 0
    },
    {
      'time': '2022-04-11T12:34:00Z',
      'interval': '1 minute',
      'token_id': 2,
      'open': '6.7',
      'high': '6.9',
      'close': '6.0',
      'low': '5.7',
      'volume': 1
    },
    {
      'time': '2022-04-11T12:35:00Z',
      'interval': '1 minute',
      'token_id': 2,
      'open': '6.3',
      'high': '6.9',
      'close': '6.9',
      'low': '6.0',
      'volume': 0
    },
    {
      'time': '2022-04-11T12:36:00Z',
      'interval': '1 minute',
      'token_id': 2,
      'open': '6.7',
      'high': '9.7',
      'close': '8.7',
      'low': '6.7',
      'volume': 0
    },
    {
      'time': '2022-04-11T12:37:00Z',
      'interval': '1 minute',
      'token_id': 2,
      'open': '6.7',
      'high': '6.9',
      'close': '5.7',
      'low': '3.2',
      'volume': 0
    }]

  const candleData = minute.map((i) => [parseHour(i['time']), parseFloat(i['low']), parseFloat(i['open']), parseFloat(i['close']), parseFloat(i['high'])])
  const ji = minute.map((i) => [parseHour(i['time']), i['volume'], "#0A3E62"])
  const jjj = [["name", "a", "b", "c", "d"], ...candleData]
  const jii = [["name", "a", { role: 'style' }], ...ji]

  const options = {
    backgroundColor: '#E5E5E5',
    legend: "none",
    height: 500,
    bar: { groupWidth: "100%" }, // Remove space between bars.
    candlestick: {
      fallingColor: { strokeWidth: 0, fill: "#a52714"}, // red
      risingColor: { strokeWidth: 0, fill: "#0A3E62" }, // blue
    },
    hAxis: {
      format: 'short',
      baselineColor: 'white',
      gridLines: {
        color: 'red',
      },
    },
    vAxis: {

      baselineColor: 'white',
      gridLines: {
        color: 'red',
      },
    },
    chartArea: {
      left: '8%', right: '2%', top: '2%', width: "100%", height: "95%"
    },
    tooltip: { trigger: 'selection' },
    selectionMode: 'multiple',
    aggregationTarget: 'category',
    animation: {
      duration: 1000,
      easing: 'out',
    },
    //vAxis: {minValue:0, maxValue:10}

  }

  const optionsBar = {
    backgroundColor: '#E5E5E5',
    legend: "none",
    height: 500,
    chartArea: {
      left: '8%', right: '2%', top: '2%', width: "100%", height: "90%"
    },
  }
  return <><Chart
    chartType='CandlestickChart'
    data={jjj}
    options={options}
  />
  <Chart 
    chartType="ColumnChart"
    data={jii}
    options={optionsBar}
  />
    <div className="flex justify-center mt-5">
      <LightButton label={"1 day"} className="rounded-xl px-2 mx-2 bg-primary" fontClasses="font-bold text-sm" alternate={true}/>
      <LightButton label={"1 week"} className="rounded-xl px-2  mx-2 bg-primary" fontClasses="font-bold text-sm" alternate={true}/>
      <LightButton label={"1 month"} className="rounded-xl px-2 mx-2 bg-primary" fontClasses="font-bold text-sm" alternate={true}/>
      <LightButton label={"1 year"} className="rounded-xl px-2  mx-2 bg-primary" fontClasses="font-bold text-sm" alternate={true}/>
    </div> </>
}



/*    FORM VALIDATION UTILS    */

export function formValidationClearErrors(){
  var elems = document.querySelectorAll(".error")
    elems.forEach(el=> {
        el.classList.remove("error");
    });
}

export function formValidationCheckObject(obj: any, type?: string){
  let count = 0
  Object.entries(obj).forEach(([key, value]) => {
      var camelCased = key.replace(/_([a-z])/g, function (g) { return g[1].toUpperCase(); });
      if(value === '' || value === "1999-10-15T00:00:00Z"){ 
        if(key === 'investment_period'){
          camelCased = type === "overview" ? "overviewInvestmentPeriod" : "fundTermsInvestmentPeriod"
        }
        if(document && document.getElementById(camelCased)){
          document.getElementById(camelCased)!.classList.add('error')
          count ++
      }}
  })
  return count
}

export function isValidHttpUrl(string: string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}