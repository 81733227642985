import { useState } from "react";
import { HeaderButton } from "./HeaderButton";
import { TitleColor1 } from "./typography/Typogtaphy";


export default function TabsMenu(props: {headers: string[], contents: JSX.Element[], classNameHds?: string, 
        classNameHdsSelected?: string, classNameHdsNonSelected?: string}) : JSX.Element {
    const [toBeShown, setToBeShown] = useState(props.headers.map((_,i) => i===0 ? true : false))
    
    function handleClick(i: number) {
        var tmp = props.headers.map((_,i) => false)
        tmp[i] = true
        setToBeShown(tmp)
    }
    
    return <>
        <div className={"grid grid-cols-"+props.headers.length+" menu-head mt-5"}>
            {props.headers.map((e, i) => <HeaderButton name={e} click={() => handleClick(i)} color1="" color2=""
                className={(props.classNameHds ? props.classNameHds : "uppercase cursor-pointer py-5 pr-1 pl-5 "+
                        "border-solid border-1 border-transparent mx-0.5 font-roboto font-medium flex place-content-center h-32 ")+
                    (toBeShown[i] ? (props.classNameHdsSelected ? " "+props.classNameHdsSelected : " bg-transparent text-tertiary rounded-xl text-xl") : 
                        (props.classNameHdsNonSelected ? " "+props.classNameHdsNonSelected : 
                            " text-gray4 hover:text-xl hover:text-tertiary rounded-xl text-base"))} 
                id={i} key={i}/> )}
        </div>
        {props.contents.map((c, i) => toBeShown[i] ? <div key={i}>{c}</div> : <div key={i}></div>)}
    </>
}

export function TabsMenuWithFilters(props: {headers: string[], filters: JSX.Element[], showFilters: boolean, contents: JSX.Element[], classNameHds?: string, 
    classNameHdsSelected?: string, classNameHdsNonSelected?: string, tabToShow?: string}) : JSX.Element {

    let tabToShow = props.tabToShow && props.tabToShow !== "" ? props.tabToShow : "Assets"
    const [toBeShown, setToBeShown] = useState(props.headers.map((name,i) => name===tabToShow ? true : false))

    function handleClick(i: number) {
        var tmp = props.headers.map((_,i) => false)
        tmp[i] = true
        setToBeShown(tmp)
    }

    return <>
        <div className={"grid grid-cols-"+props.headers.length+" menu-head mt-5"}>
            {props.headers.map((e, i) => <HeaderButton name={e} click={() => handleClick(i)}
            className={(props.classNameHds ? props.classNameHds : "uppercase cursor-pointer py-5 px-1 text-center " +
                "border-solid border-1 border-transparent mx-0.5 cursor-pointer flex place-content-center") +
                (toBeShown[i] ? (props.classNameHdsSelected ? " " + props.classNameHdsSelected : " bg-transparent text-tertiary rounded-xl text-xl") :
                    (props.classNameHdsNonSelected ? " " + props.classNameHdsNonSelected :
                        " text-gray4 hover:text-xl hover:text-tertiary rounded-xl text-base "))}
            id={i} key={i} color1={""} color2={""}/> )}
        </div>
    
    { props.showFilters? (
        <div className="grid md:grid-cols-8">
            <div className="grid md:px-20 px-5 grid-cols-1 mt-10 col-span-12 bg-primary">
                <div className="title col-span-12 text-tertiary"><TitleColor1>/ Filters</TitleColor1></div>
                {props.filters.map((c, i) => toBeShown[i] ? <div key={i} className="grid md:grid-cols-12">{c}</div> : ""/*<div key={i}></div>*/)}
            </div>
        </div>
        )  : ""
    }
    { 
    props.contents.map((c, i) => toBeShown[i] ? <div key={i}>{c}</div> : <div key={i}></div>)}
</>
}
