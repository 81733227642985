import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import { bindActionCreators } from "redux"
import ExtraInfoArtInvestingForm from "../components/extraInfoForm/ExtraInfoArtInvestingForm"
import ExtraInfoArtLendingForm from "../components/extraInfoForm/ExtraInfoArtLendingForm"
import ExtraInfoEquestrianForm from "../components/extraInfoForm/ExtraInfoEquestrianForm"
import ExtraInfoPrivateDebtForm from "../components/extraInfoForm/ExtraInfoPrivateDebtForm"
import ExtraInfoPrivateEquityForm from "../components/extraInfoForm/ExtraInfoPrivateEquityForm"
import { LightButton } from "../components/light/LightButton"
import { actionCreators, State } from "../state"
import { ActionType } from "../state/action-types"
import Popup from '../components/Popup'
import { Asset, defaultAsset } from "../AssetContext"
import {formValidationClearErrors, formValidationCheckObject} from '../utils'
import { PageDescription } from "../components/typography/Typogtaphy"

export default function NewAssetExtraInfoRoute(props: any): JSX.Element {
    const uuid = props.match.params.uuid
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)

    const assetInfo = useSelector((state: State) => state.assetInfo)
    const createAsset = useSelector((state: State) => state.createAsset)
    const submitAsset = useSelector((state: State) => state.submitAsset)
    const uploadMediaState = useSelector((state: State) => state.uploadMedia)
    const [asset, setAsset] = useState<Asset>(defaultAsset)

    let history = useHistory()

    const[saved, setSaved] = useState(false)
    const[submitted, setSubmitted] = useState(false)
    const[updateFun, setUpdateFun] = useState(() => {return null})
    const[documentGroups, setDocumentGroups] = useState<any>()

    // Asset Fields:
    const[assetCategory, setAssetCategory] = useState<string>("")
    const[assetTypeName, setAssetTypeName] = useState<string>("")
    const[currencyCode, setCurrencyCode] = useState<string>("0")
    const[media, setMedia] = useState("")
    const[uploaded, setUploaded] = useState<string[]>([])

    const[toBeRemoved, setToBeRemoved] = useState([])
    const[toBeUploaded, setToBeUploaded] = useState<[File, number][]>([])

    //SHA1
    let sha1 = require("sha1")
    const [hashname, setHashname] = useState("")

    const [showPopUp, setShowPopUp] = useState<boolean>(false)
    const [titlePopUp, setTitlePopUp] = useState<string>("")
    const [msgPopUp, setMsgPopUp] = useState<string>("")

    function handleClosePopUp(){
        setTitlePopUp("")
        setMsgPopUp("")
        setShowPopUp(false)
    }

    useEffect(() => {
        if(uuid !== "0") {
            actions.getAssetInfo(uuid, setAsset)
            actions.getAssetMedia(uuid, setMedia)
        }
    }, [])

    useEffect(() => {
        actions.getAllAssetCategories()
        actions.getDocumentGroups(setDocumentGroups)
    }, [])

    useEffect(() => {
        if(asset && asset.name) {
            setHashname(sha1(asset.name)+"/")
            setAssetTypeName(asset.asset_type)
            setAssetCategory(asset.asset_category)
            setCurrencyCode(asset.currency_cd)
        }
    }, [assetInfo.payload, assetInfo.loading])
    

    useEffect(() => {
        if(uploadMediaState.payload) {
            if(uploadMediaState.payload[0] && (uploadMediaState.payload[0] === 200)) {
                if(uploadMediaState.extra) {
                    let p = uploadMediaState.extra
                    if(p.length > 1 && uploaded.filter((i: string) => i=== p[0]).length === 0){
                        p[1] === "Document" ? actions.addMedia(p[0], uuid, p[1], "General Info") : actions.addMedia(p[0], uuid, p[1], "General Info")
                        let tmp = [...uploaded]
                        tmp.push(p[0])
                        setUploaded(tmp)
                        dispatch({type: ActionType.GET_FILE_UPLOAD_LINK_DONE})
                    }
                } else {
                    setTitlePopUp("Warning")
                    setMsgPopUp("Error uploading media")
                    setShowPopUp(true)
                }
            } else {
                setTitlePopUp("Warning")
                setMsgPopUp("Error uploading media")
                setShowPopUp(true)
            }
        }
    }, [uploadMediaState])

    function handleSaveExtraInfo(e: React.MouseEvent) {
        formValidationClearErrors()
        if(hashname.length > 0){
            toBeUploaded.map((f: [File, number]) => {
                let name = hashname+f[0].name
                if(f[0].size < 52428800)
                    actions.uploadMedia(name, f[0], f[1])
                else {
                    setTitlePopUp("Warning")
                    setMsgPopUp(f[0].name+" exceeds the limit of 50 MB")
                    setShowPopUp(true)
                }
            })
            if(toBeRemoved.length > 0)
                toBeRemoved.map((name: string) => {
                    actions.removeMedia(name)
                })
            setToBeRemoved([])
        }

        const editingAsset: Asset = {
            uuid: uuid,
            issuer_uuid: asset.issuer_uuid,
            asset_category: assetCategory,
            name: asset.name,
            asset_type: assetTypeName,
            currency_cd: currencyCode,
            issuer_name: asset.issuer_name,
            extra_info: updateFun
        }
        setSaved(true)
        actions.createAsset(editingAsset)

    }
    
    function handleSubmitAsset(e: any) {
        formValidationClearErrors()
        
        

        const editingAsset: Asset = {
            uuid: uuid,
            issuer_uuid: asset.issuer_uuid,
            asset_category: assetCategory,
            name: asset.name,
            asset_type: assetTypeName,
            currency_cd: currencyCode,
            issuer_name: asset.issuer_name,
            extra_info: updateFun
        }
        let count = 0 
        var extraInfo
        switch(assetTypeName) {
            case "Equestrian":
                if(editingAsset.extra_info && editingAsset.extra_info.equestrian){
                    extraInfo = editingAsset.extra_info.equestrian.sub_assets[0]
                    count = formValidationCheckObject(extraInfo)
                }
                break
            case "ART Lending":
                if(editingAsset.extra_info && editingAsset.extra_info.art_lending){
                    extraInfo = editingAsset.extra_info.art_lending.sub_assets[0]
                    count = formValidationCheckObject(extraInfo)
                }
                break
            case "ART Investing":
                if(editingAsset.extra_info && editingAsset.extra_info.art_investing){
                    extraInfo = editingAsset.extra_info.art_investing.sub_assets[0]
                    count = formValidationCheckObject(extraInfo)
                }
                break
            case "Private Debt":
                if(editingAsset.extra_info && editingAsset.extra_info.private_debt){
                    extraInfo = editingAsset.extra_info.private_debt.sub_assets[0]
                    count += formValidationCheckObject(extraInfo.overview, "overview")
                    count += formValidationCheckObject(extraInfo.characteristics)
                    count += formValidationCheckObject(extraInfo.irr)
                    count += formValidationCheckObject(extraInfo.fund_terms, "fund_terms")
                    count += formValidationCheckObject(extraInfo.investment_limits)
                    count += formValidationCheckObject(extraInfo.other)
                }
                break
            case "Private Equity":
                if(editingAsset.extra_info && editingAsset.extra_info.private_equity){
                    extraInfo = editingAsset.extra_info.private_equity.sub_assets[0]
                    count += formValidationCheckObject(extraInfo.overview, "overview")
                    count += formValidationCheckObject(extraInfo.characteristics)
                    count += formValidationCheckObject(extraInfo.irr)
                    count += formValidationCheckObject(extraInfo.fund_terms, "fund_terms")
                    count += formValidationCheckObject(extraInfo.investment_limits)
                    count += formValidationCheckObject(extraInfo.other)
                }
                break
            default:
        }

        if(count === 0){
            setSubmitted(true)
            actions.submitAsset(editingAsset)
        } else {
            setTitlePopUp("Warning")
            setMsgPopUp("Highlighted fields are required!")
            setShowPopUp(true)
        }
    }
    useEffect(() => {
        var alertMsg = ""
        if(saved) {
            if(createAsset.payload){
                if(createAsset.payload[1].error)
                    alertMsg = createAsset.payload[1].error
                else {
                    switch(createAsset.payload[0]) {
                        case 200:
                            alertMsg = "Saved!"
                            setSaved(false)
                            break
                        case 400:
                            alertMsg = "Invalid data!"
                            setSaved(false)
                            break
                        case 403:
                            alertMsg = "Error saving the asset"
                            setSaved(false)
                            break
                        case 500:
                            alertMsg = "Server error"
                            setSaved(false)
                            break
                        default:
                            alertMsg = "Error saving the asset"
                            setSaved(false)
                    }
                }
                if(createAsset.payload[0] === 200)
                    setTitlePopUp("Success")
                else
                    setTitlePopUp("Warning")
                setMsgPopUp(alertMsg)
                setShowPopUp(true)
            }
            if(uploadMediaState.payload && uploadMediaState.payload[1]) {
                if(uploadMediaState.payload[1].error)
                    alertMsg = uploadMediaState.payload[1].error
                else {
                    switch(uploadMediaState.payload[0]) {
                        case 200:
                            alertMsg = "Uploaded!"
                            setSaved(false)
                            break
                        case 400:
                            alertMsg = "Invalid data!"
                            setSaved(false)
                            break
                        case 403:
                            alertMsg = "Error saving the asset"
                            setSaved(false)
                            break
                        case 500:
                            alertMsg = "Server error"
                            setSaved(false)
                            break
                        default:
                            alertMsg = "Error saving the asset"
                            setSaved(false)
                    }
                }
                if(createAsset.payload[0] === 200)
                    setTitlePopUp("Success")
                else
                    setTitlePopUp("Warning")
                setMsgPopUp(alertMsg)
                setShowPopUp(true)
            }
        }
    }, [createAsset.payload])

    useEffect(() => {
        var alertMsg = ""
        if(submitted && submitAsset.payload){
            if(submitAsset.payload[1].error)
                alertMsg = submitAsset.payload[1].error
            else {
                switch(submitAsset.payload[0]) {
                    case 200:
                        alertMsg = "Submitted!"
                        setSaved(false)
                        break
                    case 400:
                        alertMsg = "Invalid data!"
                        setSaved(false)
                        break
                    case 403:
                        alertMsg = "Error submitting the asset"
                        setSaved(false)
                        break
                    case 500:
                        alertMsg = "Server error"
                        setSaved(false)
                        break
                    default:
                        alertMsg = "Error submitting the asset"
                        setSaved(false)
                }
            }
            if(createAsset.payload[0] === 200)
                    setTitlePopUp("Success")
                else
                    setTitlePopUp("Warning")
            setMsgPopUp(alertMsg)
            setShowPopUp(true)
        }
    }, [submitAsset.payload])

    
    let extraInfoForm
    switch(assetTypeName) {
        case "Equestrian":
            extraInfoForm = <ExtraInfoEquestrianForm assetInfo={assetInfo} setUpdateFun={setUpdateFun} media={media}
                tbr={toBeRemoved} setTbr={setToBeRemoved} tbu={toBeUploaded} setTbu={setToBeUploaded} />
            break
        case "ART Lending":
            extraInfoForm = <ExtraInfoArtLendingForm assetInfo={assetInfo} setUpdateFun={setUpdateFun} media={media}
                tbr={toBeRemoved} setTbr={setToBeRemoved} tbu={toBeUploaded} setTbu={setToBeUploaded}/>
            break
        case "ART Investing":
            extraInfoForm = <ExtraInfoArtInvestingForm assetInfo={assetInfo} setUpdateFun={setUpdateFun} media={media}
                tbr={toBeRemoved} setTbr={setToBeRemoved} tbu={toBeUploaded} setTbu={setToBeUploaded}/>
            break
        case "Private Debt":
            extraInfoForm = <ExtraInfoPrivateDebtForm assetInfo={assetInfo} setUpdateFun={setUpdateFun} media={media}
                tbr={toBeRemoved} setTbr={setToBeRemoved} tbu={toBeUploaded} setTbu={setToBeUploaded}/>
            break
        case "Private Equity":
            extraInfoForm = <ExtraInfoPrivateEquityForm assetInfo={assetInfo} setUpdateFun={setUpdateFun} media={media}
                tbr={toBeRemoved} setTbr={setToBeRemoved} tbu={toBeUploaded} setTbu={setToBeUploaded}/>
            break
        default:
            extraInfoForm = <></>
    }

    return <>
        <Popup show={showPopUp} title={titlePopUp} msg={msgPopUp} btnOk={true} close={handleClosePopUp}></Popup>
        <div className="grid lg:grid-cols-4">
            <div className="grid justify-items-center md:mt-40 mt-20 text-center mx-5 md:mx-10">
                <PageDescription title={assetTypeName+" Asset"} description={"Add the required information"} />
            </div>
            <div className="grid xl:col-span-2 lg:col-span-3">
                {extraInfoForm}

                <div className="grid mt-10 justify-items-center">
                    <div className="grid grid-cols-3 mt-5 place-items-center">
                        <LightButton label={"Save"} onClick={handleSaveExtraInfo}
                            alternate={true} className="rounded-xl px-10"/>
                        <LightButton onClick={() => {history.push("/issuer/asset/manage/" + uuid)}} className="rounded-xl px-10"
                            label="General info" alternate={true} />
                        <LightButton label={"Submit"} onClick={handleSubmitAsset}
                            alternate={true} className="rounded-xl px-10"/>
                    </div>
                    <div className="mt-5 mb-5">
                        <LightButton label={"My dashboard"} onClick={() => {history.push("/issuer/dashboard")}}
                                alternate={true} className="rounded-xl px-10"/>
                    </div>
                </div>
            </div>
        </div>
    </>
}