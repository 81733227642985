import React, { useEffect, useState} from "react"
import { useDispatch, useSelector } from "react-redux"
import { bindActionCreators } from "redux";
import { actionCreators, State } from "../state";
import { useHistory  } from 'react-router-dom'
import { LightButton } from "../components/light/LightButton"
import { PageDescription } from "../components/typography/Typogtaphy";

export default function IssueNewRoute(): JSX.Element {
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    let history = useHistory()   

    const [assetCategories, setAssetCategories] = useState<any>([])
   
    useEffect(() => {
        actions.getAllAssetCategories(setAssetCategories)
    }, [])

    function handleCreateIssue(category:any){
        history.push({
            pathname: '/issuer/asset/manage',
            state: { detail: {name: category } }
        });
    }
    return (<>           
        <div className="grid">
            <div className="grid justify-center justify-items-center items-center">
                <PageDescription title={"Issue a new asset"} description={"Which kind of asset do you want to create?"}/>
            </div>
            
            <div className="grid md:px-20 px-5 mt-10" >
                <div className="font-roboto py-2">
                    <div  className="flex flex-wrap place-content-center gap-4">                        
                        {
                            (assetCategories === undefined || assetCategories.asset_categories === undefined) ? null 
                                : assetCategories.asset_categories.map((cat: any, i: number) => 
                            <div className="" key={i}>
                                <LightButton onClick={() => {handleCreateIssue(cat)}} className="rounded-full px-10 my-2 py-4 w-32"
                                label={cat + " ASSET"} alternate={true}/>
                            </div>
                            )
                        }
                    </div>
                </div>
            </div>
            <div className=" flex place-content-center mt-10">
                {<LightButton onClick={() => {history.push("/issuer/dashboard")}} className="rounded-full px-10 my-2 py-2"
                            label={"My dashboard"} alternate={true}/>                         
                    }
            </div>   
        </div>
        
    </>)
}
