import { ActionType } from '../action-types'
import { Actions } from '../actions'
import { PoolState } from '../../PoolContext'

interface tokenState {
    loading: boolean
    payload?: PoolState[]
}

const defaultTokensState: tokenState = {
    loading: false,
    payload: []
}

const tokenReducer = (state: tokenState = defaultTokensState, action: Actions): tokenState => {
    switch(action.type) {
        case ActionType.GET_TOKENS_REQUEST:
            return {
                loading: true
            }
        case ActionType.GET_TOKENS_FAILURE:
            return {
                loading: false,
                payload: action.error
            }
        case ActionType.GET_TOKENS_SUCCESS:
            return {
                loading: false,
                payload: action.payload
            }
        case ActionType.CLEAR_ALL:
            return {
                loading: false,
                payload: undefined
            }
        default:
            return state
    }
}

export default tokenReducer