import { useEffect, useState } from "react"
import { LightButton } from "./light/LightButton"

export default function UpdatableTable(props: {rowsJSX: any[], rows: any[], setRows: any, newRow: any}) : JSX.Element {
    const [rowElements, setRowElements] = useState<any[]>([])

    useEffect(() => {
        setRowElements(props.rowsJSX)
    }, [props.rowsJSX])

    return <div>
        { rowElements.length > 0 ? 
            rowElements.map((r, i) => {
            return <div  className="flex w-full" key={i}>
                    {r}
                    <div className="text-white font-urbanist text-xl cursor-pointer" onClick={() => {
                        const tmp = [...props.rows]
                        props.setRows(tmp.filter((_, j) => j !== i))
                    }}>x</div>
                </div>}) 
        : null}
        <div className="grid mt-10 justify-items-center">
            <div className="mt-5">
                <LightButton label={"Add New"} onClick={() => {
                        const tmp = props.rows ? [...props.rows] : []
                        tmp.push(props.newRow)
                        props.setRows(tmp)
                    }}
                    alternate={true} className="rounded-xl px-10"/>
            </div>
        </div>
    </div>
}