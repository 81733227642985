export default function LabelAndDescription(props: {label: string, description: string, className?: string, alternate?: boolean}) : JSX.Element {

    return props.description ?
        <div className={props.className + (props.alternate ? " text-background" : " text-blue")}>
            
            <div className="uppercase font-code-pro font-bold text-lg leading-7 tracking-wider">
                {props.label}
            </div>
            <div className="uppercase font-code-pro text-lg leading-7 tracking-wider">
                {props.description}
            </div>
        </div>
        : <></>
}