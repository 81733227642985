export default function LightTable(props: {headers: any[], rows: any[][], color1?: string, color2?: string, colorHd?: string, noBorders?: boolean}) {
    const c1 = props.color1 ? props.color1 : ""
    const c2 = props.color2 ? props.color2 : c1
    const chd = props.colorHd ? props.colorHd : c1
    return <>
        <table className={"uppercase font-urbanist w-full text-primary bg-tertiary text-center border-collapse "}>
            <thead>
                <tr className={"leading-7 font-medium py-2 text-sm bg-"+chd}>
                    {props.headers.map((header, headerIndex) => 
                        <th key={headerIndex.toString()+header}
                            className={"border-solid border-0 border-secondary py-1 "+(props.noBorders ? "" : " border-r-1 ")}>
                                {header}</th>)}
                </tr>
            </thead>
            <tbody>
                {props.rows.map((row, rowIndex) =>
                    
                    <tr className={"font-code-pro font-medium text-base py-2 bg-"+(rowIndex % 2 === 0 ? c1 : c2)} 
                        key={rowIndex.toString()+row}>
                        {row.map((cell, colIndex) =>
                            <td key={rowIndex-colIndex+cell} className={"border-0 "+(props.noBorders ? " " : " border-r-1 ")+
                                " border-solid border-wild_blue_yonder leading-10 "+
                                    (colIndex%2===1 ? "text-primary" : "text-primary") }> 
                                {cell}
                            </td>
                        )}
                    </tr>                    
                )}
            </tbody>
        </table>
    </>
}