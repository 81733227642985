import { bindActionCreators } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AifIrr, AifOther, AifOverview, AifFundTerms, AifPrivateEquityCharacteristics, AifInvestmentLimits, AifPrivateEquityExtraInfo, defaultAifPrivateEquityExtraInfo, FileSrc } from "../../AssetContext";
import { actionCreators } from "../../state";
import { MediaFile } from "../../state/action-creators";
import LabelAndInput from "../LabelAndInput";
import { TitleColor1 } from "../typography/Typogtaphy";
import UploadFilesForm from "../UploadFilesForm";

export default function ExtraInfoPrivateEquityForm(props: {assetInfo: any, setUpdateFun: any, media: any,
        tbr: any[], setTbr: any, tbu: any[], setTbu: any}): JSX.Element {
    
    const dispatch = useDispatch() 
    const actions = bindActionCreators(actionCreators, dispatch)
    
    // Asset ExtraInfo fields
    const[assetName, setAssetName] = useState<string>("")
    //irr
    const[targetMoic, setTargetMoic] = useState<string>("")
    const[targetGrossIrr, setTargetGrossIrr] = useState<string>("")
    //const[targetCashYield, setTargetCashYield] = useState<string>("")
    const[distributionPolicy, setDistributionPolicy] = useState<string>("")
    //other
    const[aifm, setAifm] = useState<string>("")
    const[auditor, setAuditor] = useState<string>("")
    const[custodian, setCustodian] = useState<string>("")
    const[reporting, setReporting] = useState<string>("")
    const[distributor, setDistributor] = useState<string>("")
    const[gpClawback, setGpClawback] = useState<string>("")
    const[hurdleRate, setHurdleRate] = useState<string>("")
    const[administrator, setAdministrator] = useState<string>("")
    const[navFrequency, setNavFrequency] = useState<string>("")
    const[shareClasses, setShareClasses] = useState<string>("")
    const[incentiveFees, setIncentiveFees] = useState<string>("")
    const[generalPartner, setGeneralPartner] = useState<string>("")
    const[legalCounselor, setLegalCounselor] = useState<string>("")
    const[legalStructure, setLegalStructure] = useState<string>("")
    const[managementFees, setManagementFees] = useState<string>("")
    const[subDistributor, setSubDistributor] = useState<string>("")
    const[portfolioManager, setPortfolioManager] = useState<string>("")
    const[typeOfWaterfall, setTypeOfWaterfall] = useState<string>("")
    const[subsequentSubscriptions, setSubsequentSubscriptions] = useState<string>("")
    //overview
    const[dealType, setDealType] = useState<string>("")
    const[fundName, setFundName] = useState<string>("")
    const[targetFundSize, setTargetFundSize] = useState<string>("")
    //const[overviewInvestmentPeriod, setOverviewInvestmentPeriod] = useState<string>("")
    const[riskReturnCharacteristics, setRiskReturnCharacteristics] = useState<string>("")
    const[targetAllocation, setTargetAllocation] = useState<string>("")
    const[investmentStrategyDescription, setInvestmentStrategyDescription] = useState<string>("")
    //fund terms
    const[rampUp, setRampUp] = useState<string>("")
    const[fundTerm, setFundTerm] = useState<string>("")
    const[finalClose, setFinalClose] = useState<string>("")
    const[firstClose, setFirstClose] = useState<string>("")
    const[capitalCalls, setCapitalCalls] = useState<string>("")
    const[fundTermsInvestmentPeriod, setFundTermsInvestmentPeriod] = useState<string>("")
    const[capitalRestitutions, setCapitalRestitutions] = useState<string>("")
    //characteristics
    const[warranty, setWarranty] = useState<string>("")
    const[coInvestment, setCoInvestment] = useState<string>("")
    const[dealCurrency, setDealCurrency] = useState<string>("")
    const[fundCurrency, setFundCurrency] = useState<string>("")
    const[targetSectors, setTargetSectors] = useState<string>("")
    const[targetDealSize, setTargetDealSize] = useState<string>("")
    const[numberOfDeals, setNumberOfDeals] = useState<string>("")
    const[primarySecondary, setPrimarySecondary] = useState<string>("")
    const[speedOfDeployment, setSpeedOfDeployment] = useState<string>("")
    const[targetCompanySize, setTargetCompanySize] = useState<string>("")
    const[portfolioCompanyProfile, setPortfolioCompanyProfile] = useState<string>("")
    const[typicalStakeInPortfolioCompanies, setTypicalStakeInPortfolioCompanies] = useState<string>("")
    //Investment Limits
    const[leverage, setLeverage] = useState<string>("")
    const[derivatives, setDerivatives] = useState<string>("")
    const[geographicArea, setGeographicArea] = useState<string>("")
    const[maxIssuerLimit, setMaxIssuerLimit] = useState<string>("")
    const[maxSectorLimit, setMaxSectorLimit] = useState<string>("")
    const[esgConsiderations, setEsgConsiderations] = useState<string>("")
    const[excludedProfiles, setExcludedProfiles] = useState<string>("")
    const[maxCountryLimit, setMaxCountryLimit] = useState<string>("")

    const[imagesSrc, setImagesSrc] = useState<{url:string, filename: string}[]>([])
    const[videosSrc, setVideosSrc] = useState<{url:string, filename: string}[]>([])
    const[documentsSrc, setDocumentsSrc] = useState<{url:string, filename: string}[]>([])
    const[images, setImages] = useState<FileSrc[]>([])
    const[videos, setVideos] = useState<FileSrc[]>([])
    const[documents, setDocuments] = useState<FileSrc[]>([])
    const[download, setDownload] = useState<boolean>(true)

    var newIrrInfo: AifIrr = {
        target_moic : targetMoic,
        target_gross_irr : targetGrossIrr,
        //target_cash_yield : targetCashYield,
        distribution_policy : distributionPolicy
    }

    var newOtherInfo: AifOther = {
        AIFM : aifm,
        auditor : auditor,
        custodian : custodian,
        reporting : reporting,
        distributor : distributor,
        gp_clawback : gpClawback,
        hurdle_rate : hurdleRate,
        administrator : administrator,
        nav_frequency : navFrequency,
        share_classes : shareClasses,
        incentive_fees : incentiveFees,
        general_partner : generalPartner,
        legal_counselor : legalCounselor,
        legal_structure : legalStructure,
        management_fees : managementFees,
        sub_distributor : subDistributor,
        portfolio_manager : portfolioManager,
        type_of_waterfall : typeOfWaterfall,
        subsequent_subscriptions : subsequentSubscriptions
    }
    
    var newOverviewInfo: AifOverview = {
        deal_type : dealType,
        fund_name : fundName,
        target_fund_size : targetFundSize,
        //investment_period : overviewInvestmentPeriod,
        risk_return_characteristics : riskReturnCharacteristics,
        target_allocation : targetAllocation,
        investment_strategy_description : investmentStrategyDescription
    }
    
    var newfund_termsrmsInfo: AifFundTerms = {
        ramp_up : rampUp,
        fund_term : fundTerm,
        final_close : finalClose,
        first_close : firstClose,
        capital_calls : capitalCalls,
        investment_period : fundTermsInvestmentPeriod,
        capital_restitutions : capitalRestitutions
    }

    var newCharacteristicsInfo: AifPrivateEquityCharacteristics = {
        warranty : warranty,
        co_investment : coInvestment,
        deal_currency : dealCurrency,
        fund_currency : fundCurrency,
        target_sectors : targetSectors,
        number_of_deals : numberOfDeals,        
        target_deal_size : targetDealSize,
        primary_secondary : primarySecondary,
        speed_of_deployment : speedOfDeployment,
        target_company_size : targetCompanySize,
        portfolio_company_profile : portfolioCompanyProfile,    
        typical_stake_in_portfolio_companies : typicalStakeInPortfolioCompanies,
        
    }

    var newInvestmentLimitsInfo: AifInvestmentLimits = {
        leverage : leverage,
        derivatives : derivatives,
        geographic_area : geographicArea,
        max_issuer_limit : maxIssuerLimit,
        max_sector_limit : maxSectorLimit,
        esg_considerations : esgConsiderations,
        excluded_profiles : excludedProfiles,
        max_country_limit : maxCountryLimit
    }
    
    var newPrivateEquityInfo: AifPrivateEquityExtraInfo = {
        irr: newIrrInfo,
        other: newOtherInfo,
        overview: newOverviewInfo,
        fund_terms: newfund_termsrmsInfo,
        characteristics: newCharacteristicsInfo,
        investment_limits: newInvestmentLimitsInfo
    }

    useEffect(() => {
        if(props.media && download) {
            actions.getDocuments(props.media, setDocumentsSrc)
            actions.getImages(props.media, setImagesSrc)
            actions.getVideos(props.media, setVideosSrc)
            setDownload(false)
        }
    }, [props.media])

    useEffect(() => {
        setImages(imagesSrc.map((u) => {return {name: u.filename, uploaded: true, src: u.url}}))
    }, [imagesSrc])

    useEffect(() => {
        setVideos(videosSrc.map((u) => {return {name: u.filename, uploaded: true, src: u.url}}))
    }, [videosSrc])

    useEffect(() => {
        setDocuments(documentsSrc.map((u) => {return {name: u.filename, uploaded: true, src: u.url}}))
    }, [documentsSrc])

    
    useEffect(() => {

        if(props.assetInfo.payload && props.assetInfo.payload.extra_info) {
            let aifPrivateEquity = defaultAifPrivateEquityExtraInfo
            if(props.assetInfo.payload.extra_info.private_equity){
                aifPrivateEquity = props.assetInfo.payload.extra_info.private_equity.sub_assets ? 
                    props.assetInfo.payload.extra_info.private_equity.sub_assets[0] : 
                    defaultAifPrivateEquityExtraInfo
            }
            setAssetName(props.assetInfo.payload.name)
            if(aifPrivateEquity) {
                
                setTargetMoic(aifPrivateEquity.irr.target_moic ? aifPrivateEquity.irr.target_moic : "")
                setTargetGrossIrr(aifPrivateEquity.irr.target_gross_irr)
                //setTargetCashYield(aifPrivateEquity.irr.target_cash_yield)
                setDistributionPolicy(aifPrivateEquity.irr.distribution_policy)
                
                setAifm(aifPrivateEquity.other.AIFM)
                setAuditor(aifPrivateEquity.other.auditor)
                setCustodian(aifPrivateEquity.other.custodian)
                setReporting(aifPrivateEquity.other.reporting)
                setDistributor(aifPrivateEquity.other.distributor)
                setGpClawback(aifPrivateEquity.other.gp_clawback ? aifPrivateEquity.other.gp_clawback : "")
                setHurdleRate(aifPrivateEquity.other.hurdle_rate ? aifPrivateEquity.other.hurdle_rate : "")
                setAdministrator(aifPrivateEquity.other.administrator)
                setNavFrequency(aifPrivateEquity.other.nav_frequency)
                setShareClasses(aifPrivateEquity.other.share_classes)
                setIncentiveFees(aifPrivateEquity.other.incentive_fees)
                setGeneralPartner(aifPrivateEquity.other.general_partner ? aifPrivateEquity.other.general_partner : "")
                setLegalCounselor(aifPrivateEquity.other.legal_counselor ? aifPrivateEquity.other.legal_counselor : "")
                setLegalStructure(aifPrivateEquity.other.legal_structure ? aifPrivateEquity.other.legal_structure : "")
                setManagementFees(aifPrivateEquity.other.management_fees)
                setSubDistributor(aifPrivateEquity.other.sub_distributor)
                setPortfolioManager(aifPrivateEquity.other.portfolio_manager)
                setTypeOfWaterfall(aifPrivateEquity.other.type_of_waterfall ? aifPrivateEquity.other.type_of_waterfall : "")
                setSubsequentSubscriptions(aifPrivateEquity.other.subsequent_subscriptions)
                
                setDealType(aifPrivateEquity.overview.deal_type)
                setFundName(aifPrivateEquity.overview.fund_name)
                setTargetFundSize(aifPrivateEquity.overview.target_fund_size)
                //setOverviewInvestmentPeriod(aifPrivateEquity.overview.investment_period)
                setRiskReturnCharacteristics(aifPrivateEquity.overview.risk_return_characteristics)
                setTargetAllocation(aifPrivateEquity.overview.target_allocation)
                setInvestmentStrategyDescription(aifPrivateEquity.overview.investment_strategy_description ? 
                    aifPrivateEquity.overview.investment_strategy_description : "")
                
                setRampUp(aifPrivateEquity.fund_terms.ramp_up)
                setFundTerm(aifPrivateEquity.fund_terms.fund_term)
                setFinalClose(aifPrivateEquity.fund_terms.final_close)
                setFirstClose(aifPrivateEquity.fund_terms.first_close)
                setCapitalCalls(aifPrivateEquity.fund_terms.capital_calls)
                setFundTermsInvestmentPeriod(aifPrivateEquity.fund_terms.investment_period)
                setCapitalRestitutions(aifPrivateEquity.fund_terms.capital_restitutions)

                setWarranty(aifPrivateEquity.characteristics.warranty)
                setCoInvestment(aifPrivateEquity.characteristics.co_investment)
                setDealCurrency(aifPrivateEquity.characteristics.deal_currency)
                setFundCurrency(aifPrivateEquity.characteristics.fund_currency)
                setTargetSectors(aifPrivateEquity.characteristics.target_sectors)
                setTargetDealSize(aifPrivateEquity.characteristics.target_deal_size)
                setNumberOfDeals(aifPrivateEquity.characteristics.number_of_deals)
                setPrimarySecondary(aifPrivateEquity.characteristics.primary_secondary)
                setSpeedOfDeployment(aifPrivateEquity.characteristics.speed_of_deployment)
                setTargetCompanySize(aifPrivateEquity.characteristics.target_company_size)
                setPortfolioCompanyProfile(aifPrivateEquity.characteristics.portfolio_company_profile)
                setTypicalStakeInPortfolioCompanies(aifPrivateEquity.characteristics.typical_stake_in_portfolio_companies)
                

                setLeverage(aifPrivateEquity.investment_limits.leverage)
                setDerivatives(aifPrivateEquity.investment_limits.derivatives)
                setGeographicArea(aifPrivateEquity.investment_limits.geographic_area)
                setMaxIssuerLimit(aifPrivateEquity.investment_limits.max_issuer_limit)
                setMaxSectorLimit(aifPrivateEquity.investment_limits.max_sector_limit)
                setEsgConsiderations(aifPrivateEquity.investment_limits.esg_considerations)
                setExcludedProfiles(aifPrivateEquity.investment_limits.excluded_profiles)
                setMaxCountryLimit(aifPrivateEquity.investment_limits.max_country_limit)
                
                
            }
        }                  
    }, [props.assetInfo.payload])
    
    function updateEI() {
        var subAsset :any[] = []
        subAsset.push(newPrivateEquityInfo)
        const assetExtraInfo = {
            private_equity: {
                sub_assets: subAsset
            }
        }
        return assetExtraInfo 
    }

    useEffect(() => {
        props.setUpdateFun(updateEI)
    }, [assetName,targetMoic,targetGrossIrr,distributionPolicy,aifm,auditor,custodian,reporting,distributor,
        gpClawback,hurdleRate,administrator,navFrequency,shareClasses,incentiveFees,generalPartner,legalCounselor,
        legalStructure,managementFees,subDistributor,portfolioManager,typeOfWaterfall,subsequentSubscriptions,dealType,
        fundName,targetFundSize,riskReturnCharacteristics,targetAllocation,investmentStrategyDescription,rampUp,fundTerm,
        finalClose,firstClose,capitalCalls,fundTermsInvestmentPeriod,capitalRestitutions,warranty,coInvestment,
        dealCurrency,fundCurrency,targetSectors,targetDealSize,numberOfDeals,primarySecondary,speedOfDeployment,
        targetCompanySize,portfolioCompanyProfile,typicalStakeInPortfolioCompanies,leverage,derivatives,geographicArea,
        maxIssuerLimit,maxSectorLimit,esgConsiderations,excludedProfiles,maxCountryLimit])

    return <>
        <div className="grid md:px-20 px-5 grid-cols-12 mt-10" >
            <LabelAndInput label={"Asset Name"} value={assetName} fun={setAssetName} type={"disabled"} className={"col-span-12"} />
            
            <TitleColor1 className="text-lg mt-5 col-span-12">/ Overview</TitleColor1>

            <LabelAndInput id={"dealType"} label={"Deal Type"} value={dealType} fun={setDealType} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"fundName"} label={"Fund Name"} value={fundName} fun={setFundName} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"targetFundSize"} label={"Target Fund Size"} value={targetFundSize} fun={setTargetFundSize} type={"text"} className={"col-span-4"} required/>
            {/*<LabelAndInput label={"Investment Period"} value={overviewInvestmentPeriod} fun={setOverviewInvestmentPeriod} type={"text"} className={"col-span-6"} />*/}
            <LabelAndInput id={"riskReturnCharacteristics"} label={"Risk Return Characteristics"} value={riskReturnCharacteristics} fun={setRiskReturnCharacteristics} type={"text"} className={"col-span-12"} required/>
            <LabelAndInput id={"targetAllocation"} label={"Target Allocation Seniority"} value={targetAllocation} fun={setTargetAllocation} type={"text"} className={"col-span-12"} required/>
            <LabelAndInput id={"investmentStrategyDescription"} label={"Investment Strategy Description"} value={investmentStrategyDescription} fun={setInvestmentStrategyDescription} type={"textarea"} rows={3} className={"col-span-12"} required/>

            <TitleColor1 className="text-lg mt-5 col-span-12">/ Characteristics</TitleColor1>
                
            <LabelAndInput id={"warranty"} label={"Warranty"} value={warranty} fun={setWarranty} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"coInvestment"} label={"Co Investment"} value={coInvestment} fun={setCoInvestment} type={"text"} className={"col-span-4"} required />                    
            <LabelAndInput id={"dealCurrency"} label={"Deal Currency"} value={dealCurrency} fun={setDealCurrency} type={"text"} className={"col-span-4"} required />
            <LabelAndInput id={"fundCurrency"} label={"Fund Currency"} value={fundCurrency} fun={setFundCurrency} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"targetSectors"} label={"Target Sectors"} value={targetSectors} fun={setTargetSectors} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"numberOfDeals"} label={"Number Of Deals"} value={numberOfDeals} fun={setNumberOfDeals} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"targetDealSize"} label={"Target Deal Size"} value={targetDealSize} fun={setTargetDealSize} type={"text"} className={"col-span-6"} required/>
            <LabelAndInput id={"primarySecondary"} label={"Primary Secondary"} value={primarySecondary} fun={setPrimarySecondary} type={"text"} className={"col-span-6"} required/>
            <LabelAndInput id={"speedOfDeployment"} label={"Speed Of Deployment"} value={speedOfDeployment} fun={setSpeedOfDeployment} type={"text"} className={"col-span-6"} required/>
            <LabelAndInput id={"targetCompanySize"} label={"Target Company Size"} value={targetCompanySize} fun={setTargetCompanySize} type={"text"} className={"col-span-6"} required/>
            <LabelAndInput id={"portfolioCompanyProfile"} label={"Portfolio Company Profile"} value={portfolioCompanyProfile} fun={setPortfolioCompanyProfile} type={"textarea"} rows={3} className={"col-span-12"} required/>
            <LabelAndInput id={"typicalStakeInPortfolioCompanies"} label={"Typical Stake In Portfolio Companies"} value={typicalStakeInPortfolioCompanies} fun={setTypicalStakeInPortfolioCompanies} type={"text"} className={"col-span-12"} required />

            <TitleColor1 className="text-lg mt-5 col-span-12">/ Investment Limits</TitleColor1>
                
            <LabelAndInput id={"leverage"} label={"Leverage"} value={leverage} fun={setLeverage} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"derivatives"} label={"Derivatives"} value={derivatives} fun={setDerivatives} type={"text"} className={"col-span-4"} required />
            <LabelAndInput id={"geographicArea"} label={"Geographic Area"} value={geographicArea} fun={setGeographicArea} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"maxIssuerLimit"} label={"Max Issuer Limit"} value={maxIssuerLimit} fun={setMaxIssuerLimit} type={"text"} className={"col-span-6"} required/>
            <LabelAndInput id={"maxSectorLimit"} label={"Max Sector Limit"} value={maxSectorLimit} fun={setMaxSectorLimit} type={"text"} className={"col-span-6"} required/>
            <LabelAndInput id={"esgConsiderations"} label={"ESG Consideration"} value={esgConsiderations} fun={setEsgConsiderations} type={"textarea"} rows={3} className={"col-span-12"} required/>
            <LabelAndInput id={"excludedProfiles"} label={"Excluded Profiles"} value={excludedProfiles} fun={setExcludedProfiles} type={"text"} className={"col-span-6"} required/>
            <LabelAndInput id={"maxCountryLimit"} label={"Max Country Limit"} value={maxCountryLimit} fun={setMaxCountryLimit} type={"text"} className={"col-span-6"} required/>

            <TitleColor1 className="text-lg mt-5 col-span-12">/ IRR</TitleColor1>
                    
            <LabelAndInput id={"targetMoic"}  label={"Target Moic"} value={targetMoic} fun={setTargetMoic} type={"text"} className={"col-span-4"} required />
            <LabelAndInput id={"targetGrossIrr"} label={"Target Gross IRR"} value={targetGrossIrr} fun={setTargetGrossIrr} type={"text"} className={"col-span-4"} required/>
            {/*<LabelAndInput label={"Target Chash Yield"} value={targetCashYield} fun={setTargetCashYield} type={"text"} className={"col-span-12"}/>*/}
            <LabelAndInput id={"distributionPolicy"} label={"Distribution Policy"} value={distributionPolicy} fun={setDistributionPolicy} type={"text"} className={"col-span-4"} required/>
                
            <TitleColor1 className="text-lg mt-5 col-span-12">/ Fund Terms</TitleColor1>

            <LabelAndInput id={"rampUp"} label={"Ramp Up"} value={rampUp} fun={setRampUp} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"fundTerm"} label={"Fund Term"} value={fundTerm} fun={setFundTerm} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"finalClose"} label={"Final Close"} value={finalClose} fun={setFinalClose} type={"text"} className={"col-span-4"} required />
            <LabelAndInput id={"firstClose"} label={"First Close"} value={firstClose} fun={setFirstClose} type={"text"} className={"col-span-6"} required/>
            <LabelAndInput id={"capitalCalls"} label={"Capitals Class"} value={capitalCalls} fun={setCapitalCalls} type={"text"} className={"col-span-6"} required />
            <LabelAndInput id={"fundTermsInvestmentPeriod"} label={"Investment Period"} value={fundTermsInvestmentPeriod} fun={setFundTermsInvestmentPeriod} type={"text"} className={"col-span-6"} required/>
            <LabelAndInput id={"capitalRestitutions"} label={"Capital Restitutions"} value={capitalRestitutions} fun={setCapitalRestitutions} type={"text"} className={"col-span-6"} required/>

            <TitleColor1 className="text-lg mt-5 col-span-12">/ Others</TitleColor1>

            <LabelAndInput id={"AIFM"} label={"AIFM"} value={aifm} fun={setAifm} type={"text"} className={"col-span-4"} required />
            <LabelAndInput id={"auditor"} label={"Auditor"} value={auditor} fun={setAuditor} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"custodian"} label={"Custodian"} value={custodian} fun={setCustodian} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"reporting"} label={"Reporting"} value={reporting} fun={setReporting} type={"textarea"} rows={3} className={"col-span-12"} required/>
            <LabelAndInput id={"distributor"} label={"Distributor"} value={distributor} fun={setDistributor} type={"text"} className={"col-span-4"} required />
            <LabelAndInput id={"gpClawback"} label={"Gp Clawback"} value={gpClawback} fun={setGpClawback} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"hurdleRate"} label={"Hurdle Rate"} value={hurdleRate} fun={setHurdleRate} type={"text"} className={"col-span-4"} required />
            <LabelAndInput id={"administrator"} label={"Administrator"} value={administrator} fun={setAdministrator} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"navFrequency"} label={"Nav Frequency"} value={navFrequency} fun={setNavFrequency} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"shareClasses"} label={"Share Classes"} value={shareClasses} fun={setShareClasses} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"incentiveFees"} label={"Incentive Fees"} value={incentiveFees} fun={setIncentiveFees} type={"text"} className={"col-span-4"} required />
            <LabelAndInput id={"generalPartner"} label={"General Partner"} value={generalPartner} fun={setGeneralPartner} type={"text"} className={"col-span-4"} required/>
            <LabelAndInput id={"legalCounselor"} label={"Legal Counselor"} value={legalCounselor} fun={setLegalCounselor} type={"text"} className={"col-span-4"} required />
            <LabelAndInput id={"legalStructure"} label={"Legal Structure"} value={legalStructure} fun={setLegalStructure} type={"text"} className={"col-span-12"} required/>
            <LabelAndInput id={"managementFees"} label={"Management Fees"} value={managementFees} fun={setManagementFees} type={"text"} className={"col-span-6"} required/>
            <LabelAndInput id={"subDistributor"} label={"Sub Distributor"} value={subDistributor} fun={setSubDistributor} type={"text"} className={"col-span-6"} required/>
            <LabelAndInput id={"portfolioManager"} label={"Portfolio Manager"} value={portfolioManager} fun={setPortfolioManager} type={"text"} className={"col-span-6"} required />
            <LabelAndInput id={"typeOfWaterfall"} label={"Type Of Waterfall"} value={typeOfWaterfall} fun={setTypeOfWaterfall} type={"text"} className={"col-span-6"} required/>
            <LabelAndInput id={"subsequentSubscriptions"} label={"Subsequent Subscriptions"} value={subsequentSubscriptions} fun={setSubsequentSubscriptions} type={"text"} className={"col-span-6"} required />
                <div className="col-span-12 mt-5">
                <UploadFilesForm 
                    images={{names: images, setNames: setImages,
                            tbr: props.tbr, tbu: props.tbu, setTbr: props.setTbr, setTbu: props.setTbu}}
                    videos={{names: videos, setNames: setVideos,
                            tbr: props.tbr, tbu: props.tbu, setTbr: props.setTbr, setTbu: props.setTbu}}
                    documents={{names: documents, setNames: setDocuments,
                            tbr: props.tbr, tbu: props.tbu, setTbr: props.setTbr, setTbu: props.setTbu}}/>
            </div>
        </div>
    </>
}