import React, { useState } from 'react'
import { PoolState, SecurityToken } from '../PoolContext'
import { State } from '../state'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { useSelector } from 'react-redux'
import { actionCreators } from '../state'
import './TradeInterface.css'

import infoImg from '../media/info-circle-outline.png'
import { LightButton } from './light/LightButton'
import Popup from './Popup'

export enum tradeType {
    BUY = "BUY",
    SELL = "SELL",
    NULL = "NULL"
}

export interface TradeInterfaceProps {
    assetPool: PoolState
    isVisible: boolean
    hide: Function
    tokenInfo: SecurityToken
}

export interface AMMTrade {
    pool_id: number
    txn_type: tradeType
    amount_b: number
    /*
    price: number
    amount: number
    */
}

export const defaultAMMTrade: AMMTrade = {
    pool_id: 0,
    txn_type: tradeType.NULL,
    amount_b: 0
}

export default function TradeInterface(props: TradeInterfaceProps) {
    useEffect(() => {
        actions.getBalance()
    }, [])
    const balanceState = useSelector((state: State) => state.balance)
    const payment_tokens = balanceState.payload?.payment_tokens
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)

    const [buyAmountLP, setBuyAmountLP] = useState<number>(0)
    const [buyCalculatedPriceLP, setBuyCalculatedPriceLP] = useState<number>(0)
    const [buyAmountP2P, setBuyAmountP2P] = useState<number>(0)
    const [buyLimitPriceP2P, setBuyLimitPriceP2P] = useState<number>(0)
    const [buyOrderTypeP2P, setBuyOrderTypeP2P] = useState<string>("")

    //const [proposedPrice, setProposedPrice] = useState<number>(getCurrentPrice(props.assetPool))

    const [sellAmountLP, setSellAmountLP] = useState<number>(0)
    const [sellCalculatedPriceLP, setSellCalculatedPriceLP] = useState<number>(0)
    const [sellAmountP2P, setSellAmountP2P] = useState<number>(0)
    const [sellPriceP2P, setSellPriceP2P] = useState<number>(0)

    const [showPopUp, setShowPopUp] = useState<boolean>(false)
    const [titlePopUp, setTitlePopUp] = useState<string>("")
    const [msgPopUp, setMsgPopUp] = useState<string>("")
    function handleClosePopUp(){
        setTitlePopUp("")
        setMsgPopUp("")
        setShowPopUp(false)
    }

    const [tradeOperation, setTradeOperation] = useState<string>("BUY")

    const p2pQuotes = [
        { num: 1, qty: 1500, price: "€0.40" },
        { num: 2, qty: 12000, price: "€0.39" },
        { num: 3, qty: 5000, price: "€0.41" }
    ]

    function handleBuyLP(qty: number){
        setBuyAmountLP(qty);
        if(qty) {
            setBuyCalculatedPriceLP(qty * parseFloat(props.tokenInfo.price))
        }
    }

    function handleSellLP(qty: number){
        setSellAmountLP(qty);
        setSellCalculatedPriceLP(qty * parseFloat(props.tokenInfo.price))
    }

    // CONFIRM BUY LP
    function handleBuyLPCommit(e: React.MouseEvent) {
        //let currentPrice = getCurrentPrice(props.assetPool)
        let currentPrice = parseFloat(props.tokenInfo.price)

        if (buyAmountLP > props.assetPool.amount_a) {
            setTitlePopUp("Warning")
            setMsgPopUp("Not enough liquidity!")
            setShowPopUp(true)
        } else {
            if (payment_tokens ) {
                if (parseFloat(payment_tokens.total) < buyAmountLP * currentPrice) {
                    setTitlePopUp("Warning")
                    setMsgPopUp("Not enough funds!")
                    setShowPopUp(true)
                } else {
                    let trade: AMMTrade = {
                        pool_id: props.assetPool.uuid,
                        txn_type: tradeType.BUY,
                        amount_b: currentPrice * buyAmountLP
                    }
                    actions.sendTrade(trade)
                    actions.getBalance()

                    setTitlePopUp("Success")
                    setMsgPopUp("The transaction has been sent!")
                    setShowPopUp(true)
                }
            }
        }
        //props.hide()
    }

    function handleBuyP2PSearch(e: React.MouseEvent) {
        props.hide()
    }

    function handleBuyP2PAcceptMarketPrice(e: React.MouseEvent) {
        props.hide()
    }

    // CONFIRM BUY P2P
    function handleBuyP2PCommit(e: React.MouseEvent) {
        props.hide()
    }

    function handleSellLPCommit(e: React.MouseEvent) {
        //let currentPrice = getCurrentPrice(props.assetPool)
        let currentPrice = parseFloat(props.tokenInfo.price)

        if (sellAmountLP > props.assetPool.amount_a) {
            setTitlePopUp("Warning")
            setMsgPopUp("Not enough liquidity!")
            setShowPopUp(true)
        }
        if (payment_tokens ) {
            if (parseFloat(payment_tokens.total) < sellAmountLP*currentPrice) {
                setTitlePopUp("Warning")
                setMsgPopUp("Not enough funds!")
                setShowPopUp(true)
            } else {
                let trade: AMMTrade = {
                    pool_id: props.assetPool.uuid,
                    txn_type: tradeType.BUY,
                    amount_b: currentPrice*sellAmountLP
                }
                actions.sendTrade(trade)
                setTitlePopUp("Success")
                setMsgPopUp("The transaction has been sent!")
                setShowPopUp(true)
            }
        }
        props.hide()
    }

    function handleSellP2PCommit(e: React.MouseEvent) {
        props.hide()
    }

    function formLabelAndEntries(label: string, inputValue: any, inputType: string, isDisabled: boolean): JSX.Element{
        return <>
            <div>
                <label className="col-12 field-popup">{label}</label>
                <input value={inputValue}
                    className="input-form blue-input col-12 popup-input" type={inputType} placeholder="" disabled />
            </div>
        </>
    }

    return (<>
        <Popup show={showPopUp} title={titlePopUp} msg={msgPopUp} btnOk={true} close={handleClosePopUp}  ></Popup>
        { props.isVisible ?
        <div>
            <div className="modal">
                <div className="row">
                    <div className="col-12 pu-container popup-container">
                        <div className="col-12 rounded-t-md">
                            <button className="close-btn pr-10" onClick={() => props.hide()}>X</button>
                        </div>
                        <div className="pu-inner">
                            <div className="pu-header col-12">
                                <div className={"label-top label-left "+(tradeOperation === "BUY" ? "label-active" : "label-disabled")} >
                                    <a href="#" onClick={() => setTradeOperation("BUY")} className="text-blue">BUY</a>
                                </div>
                                <div className={"label-top label-right "+(tradeOperation === "SELL" ? "label-active" : "label-disabled")}>
                                    <a href="#" onClick={() => setTradeOperation("SELL")} className="text-blue">SELL</a>
                                </div>
                            </div>

                            <div>
                                <label className="col-12 title-popup-buysell">{tradeOperation}</label>
                            </div>

                            {
                                tradeOperation === "BUY" ?
                                (
                                    <div>
                                        <div className="col-6 border-right">
                                            <div className="px-10">
                                                <div>
                                                    <label className="col-12 title-popup-buysell">Peer-To-Peer <img src={infoImg} className="i-info-round" /></label>
                                                </div>

                                                <div>
                                                    <label className="col-12 field-popup">Quantity</label>
                                                    <input value={buyAmountP2P} onChange={e => setBuyAmountP2P(parseFloat(e.target.value))}
                                                        className="input-form blue-input col-10 popup-input" type="number" placeholder="" />
                                                </div>

                                                <div className="col-12">
                                                    <label className="col-12 field-popup">Offers Available</label>
                                                    {
                                                        p2pQuotes.map((quote, i) => (
                                                            <div className="col-12 " key={i}>
                                                                <div className="col-2"></div>
                                                                <div className="col-8 quote-def">
                                                                    <label className="blue-label label-form cb-container">quote {quote.num}
                                                                    <input className="blue-input input-form" type="radio" name="radio" defaultChecked />
                                                                    <span className="checkmark"></span>
                                                                    </label>
                                                                    <div className="p2p-quote-fields p2p-underline">Quantity
                                                                    <span className="p2p-quote-values">{quote.qty}</span></div>
                                                                    <div className="p2p-quote-fields">Price <span className="p2p-quote-values">{quote.price}</span></div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                                <div className="col-12 grey-review ">
                                                    <div className="">
                                                        <div className="px-2">
                                                            <label className="col-12 field-popup">Limit Price</label>
                                                            <input value={buyLimitPriceP2P} onChange={e => setBuyLimitPriceP2P(parseFloat(e.target.value))}
                                                                className="input-form blue-input col-12 popup-input" type="number" placeholder="" />
                                                        </div>
                                                        <div className="px-2">
                                                            <label className="col-12 field-popup">Order type</label>
                                                            <select value={buyOrderTypeP2P} onChange={e => setBuyOrderTypeP2P(e.target.value)}
                                                                className="col-12 blue-input">
                                                                <option value=""></option>
                                                                <option value="fill-kill">Fill or Kill</option>
                                                                <option value="good-until-canceled">Good until canceled</option>
                                                                <option value="valid-until">Valid until?</option>
                                                            </select>
                                                        </div>

                                                    </div>
                                                    <div className="grid items-center w-full pt-5 justify-center pb-5">
                                                        <LightButton label={"Search"} className="rounded-full px-10 py-2"
                                                                onClick={handleBuyP2PSearch}/>
                                                    </div>

                                                </div>

                                                <div className="col-12 grey-review">
                                                    <div className="px-2">
                                                        {formLabelAndEntries("Current Market Price", props.tokenInfo.price, "number", true)}
                                                    </div>
                                                    <div className="grid items-center w-full pt-5 justify-center pb-5">
                                                        <LightButton label={"Accept"} className="rounded-full px-10 py-2"
                                                            onClick={handleBuyP2PAcceptMarketPrice}/>
                                                    </div>
                                                </div>
                                                <div className="col-12 grey-review">
                                                    <div>
                                                        <label className="col-12 title-review">Review</label>
                                                    </div>
                                                    <div>
                                                        <label className="col-12 text-base">Estimated Value Purchase: <strong>unknown</strong></label>
                                                        <label className="col-12 text-base">Estimated Fees: <strong>unknown</strong></label>
                                                    </div>
                                                </div>
                                                <div className="grid items-center w-full pt-5 justify-center pb-5">
                                                    <LightButton label="Confirm" onClick={handleBuyP2PCommit} className="rounded-full px-10 py-2"/>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="px-10">
                                                <div>
                                                    <label className="col-12 title-popup-buysell">Liquidity Pool <img src={infoImg} className="i-info-round" /></label>
                                                </div>
                                                {formLabelAndEntries("Token", props.tokenInfo.asset_name, "text", true)}
                                                {formLabelAndEntries("Symbol", props.tokenInfo.ticker, "text", true)}
                                                <div>
                                                    <label className="col-12 field-popup">Quantity</label>
                                                    <input value={buyAmountLP} onChange={e => handleBuyLP(parseFloat(e.target.value))}
                                                        className="input-form blue-input col-12 popup-input" type="number" placeholder="" min="1"/>
                                                </div>
                                                {formLabelAndEntries("Calculated Price", buyCalculatedPriceLP, "text", true)}
                                                {formLabelAndEntries("Extended Price Transaction", "unknown", "text", true)}

                                                <div className="col-12 grey-review">
                                                    <div>
                                                        <label className="col-12 title-review">Review</label>
                                                    </div>
                                                    <div>
                                                        <label className="col-12 text-base">Estimated Value Purchase: <strong>unknown</strong></label>
                                                        <label className="col-12 text-base">Estimated Fees: <strong>unknown</strong></label>
                                                    </div>
                                                </div>

                                                <div className="grid items-center w-full pt-5 justify-center">
                                                    <LightButton label="Confirm" className="rounded-full px-10 py-2" onClick={handleBuyLPCommit}/>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                ) : (

                                    <div>

                                        <div className="col-6 border-right">
                                            <div className="px-10">
                                                <div>
                                                    <label className="col-12 title-popup-buysell">Peer-To-Peer <img src={infoImg} className="i-info-round" /></label>
                                                </div>
                                                <div>
                                                    <label className="col-12 field-popup">Quantity Available</label>
                                                    <input value={props.assetPool.amount_a}
                                                        className="input-form blue-input col-12 popup-input" type="number" placeholder="" disabled />
                                                </div>
                                                <div>
                                                    <label className="col-12 field-popup">Quantity to sell </label>
                                                    <input value={sellAmountP2P} onChange={e => setSellAmountP2P(parseFloat(e.target.value))}
                                                        className="input-form blue-input col-12 popup-input" type="number" placeholder="" />
                                                </div>
                                                <div>
                                                    <label className="col-12 field-popup">Actual Price</label>
                                                    <input value={props.tokenInfo.price}
                                                        className="input-form blue-input col-12 popup-input" type="text" placeholder="" disabled />
                                                </div>
                                                <div>
                                                    <label className="col-12 field-popup">Requested Price</label>
                                                    <input value={sellPriceP2P}  onChange={e => setSellPriceP2P(parseFloat(e.target.value))}
                                                        className="input-form blue-input col-12 popup-input" type="text" placeholder="" />
                                                </div>

                                                <div className="grid items-center w-full pt-5 justify-center">
                                                    <LightButton label="Confirm" className="rounded-full px-10 py-2" onClick={handleSellP2PCommit}/>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="px-10">
                                                <div>
                                                    <label className="col-12 title-popup-buysell">Liquidity Pool <img src={infoImg} className="i-info-round" /></label>
                                                </div>
                                                <div>
                                                    <label className="col-12 field-popup">Token</label>
                                                    <input value={props.tokenInfo.asset_name}
                                                        className="input-form blue-input col-12 popup-input" type="text" placeholder="" disabled />
                                                </div>
                                                <div>
                                                    <label className="col-12 field-popup">Symbol</label>
                                                    <input value={props.tokenInfo.ticker}
                                                        className="input-form blue-input col-12 popup-input" type="text" placeholder="" disabled />
                                                </div>
                                                <div>
                                                    <label className="col-12 field-popup">Quantity</label>
                                                    <input value={sellAmountLP} onChange={e => handleSellLP(parseFloat(e.target.value))}
                                                        className="input-form blue-input col-12 popup-input" type="number" placeholder="" />
                                                </div>
                                                <div>
                                                    <label className="col-12 field-popup">Calculated Price</label>
                                                    <input value={sellCalculatedPriceLP}
                                                        className="input-form blue-input col-12 popup-input" type="text" placeholder="" disabled />
                                                </div>

                                                <div>
                                                    <label className="col-12 field-popup">Extended Price Transaction</label>
                                                    <input value={'unknown'}
                                                        className="input-form blue-input col-12 popup-input" type="text" placeholder="" disabled />
                                                </div>

                                                <div className="col-12 grey-review">
                                                    <div>
                                                        <label className="col-12 title-review">Review</label>
                                                    </div>
                                                    <div>
                                                        <label className="col-12 text-base">Estimated Value Purchase: <strong>unknown</strong></label>
                                                        <label className="col-12 text-base">Estimated Fees: <strong>unknown</strong></label>
                                                    </div>
                                                </div>

                                                <div className="grid items-center w-full pt-5 justify-center">
                                                    <LightButton label="Confirm" className="rounded-full px-10 py-2" onClick={handleSellLPCommit}/>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-overlay"></div>
        </div > : <></> }
    </>)
}

