import Navbar from './components/Navbar'
import Footer from './components/Footer'
import { Switch, Route, Redirect, useHistory  } from 'react-router-dom'
import './styles/index.css'
import TokenizationOldRoute from './routes/TokenizationOld.route'
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators, State, persistor } from './state'
import { UserContext, defaultUserState, UserAccount } from './UserContext'
import { PoolListContext, defaultPoolListState, PoolState } from './PoolContext'
import ProfileRoute from './routes/Profile.route'
import AssetRoute from './routes/Asset.route'
import SignUpRoute from './routes/SignUp.route'
import SecurityTokenRoute from './routes/SecurityToken.route'
import SignInRoute from './routes/SignIn.route'
import HomeRoute from './routes/Home.route'
import PortfolioRoute from './routes/Portfolio.route'
import Cookies from 'js-cookie'
import MenuRoute from './routes/Menu.route'
import AboutRoute from './routes/About.route'
import SupportRoute from './routes/Support.route'
import PrivPolRoute from './routes/PrivacyPolicy.route'
import AdditionalInfoRoute from './routes/AdditionalInfo.route'
import IssuerDashboard from './routes/IssuerDashboard.route'
import TokenDetailsRoute from './routes/IssuerTokenDetails.route'
import IssuerPortfolio from './routes/IssuerPortfolio.route'
import AlmostDoneRoute from './routes/AlmostDone.route'
import KYCRoute from './routes/KYC.route'
import VerifyEmailRoute from './routes/VerifyEmail.route'
import IssueNewRoute from './routes/IssueNew.route'
import IssueAssetRoute from './routes/IssueAsset.route'
import TokenizationRoute from './routes/Tokenization.route'
import NewAssetExtraInfoRoute from './routes/NewAssetExtraInfo.route'

import Popup from './components/Popup'
import BlockchainLookup from './routes/BlockchainLookup.route'
import IssuerPrimarySaleRoute from './routes/IssuerPrimarySale.route'
import PrimarySaleRoute from './routes/PrimarySale.route'
import { Auth } from 'aws-amplify';
import { useAuthenticator } from '@aws-amplify/ui-react'
import awsconfigdevelop from './aws-exports-develop';
import awsconfigstaging from './aws-exports-staging';
import awsconfigprod from './aws-exports-production';

switch(process.env.REACT_APP_ENV) {
  case "develop":
    Auth.configure(awsconfigdevelop);
    window.sessionStorage.setItem("env", "develop")
    break;
  case "staging":
    Auth.configure(awsconfigstaging);
    window.sessionStorage.setItem("env", "staging")
    break;
  case "main":
    Auth.configure(awsconfigprod);
    window.sessionStorage.setItem("env", "production")
    break;
  default:
    Auth.configure(awsconfigdevelop);
    window.sessionStorage.setItem("env", "develop")
}

function App() {
  const dispatch = useDispatch()
  const actions = bindActionCreators(actionCreators, dispatch)

  let history = useHistory();

  ///////////OLD/////////////
  const userLoggingIn = useSelector((state: State) => state.login)
  const basicAccountState = useSelector((state: State) => state.basicAccount)
  const detailAccountState = useSelector((state: State) => state.detailAccount)
  const issuerInfoState = useSelector((state: State) => state.issuerInfo)
  const poolState = useSelector((state: State) => state.poolToken)
  const [poolList, setPoolList] = useState<PoolState[]>(defaultPoolListState)
  const [user, setUser] = useState<UserAccount>(defaultUserState)

  ///////////NEW/////////////
  const { authStatus } = useAuthenticator(context => [context.authStatus])
  const logged = window.sessionStorage.getItem("logged_in") === "true"
  const [session, setSession] = useState<string | null>(null)
  ///////////////////////////

  // isBasicAccountOrIsLoggedOut must be true on loggedOut user or on basicAccount user, must be false on detailedAccountUser
  var tmp: boolean | undefined = !logged || 
    (basicAccountState.payload && !basicAccountState.payload.is_issuer && 
      !basicAccountState.payload.is_investor && !basicAccountState.payload.kyc_submitted)
  var isBasicAccountOrIsLoggedOut = tmp ? tmp : false
  const [showPopUp, setShowPopUp] = useState<boolean>(false)
  const [titlePopUp, setTitlePopUp] = useState<string>("")
  const [msgPopUp, setMsgPopUp] = useState<string>("")
  
  function handleClosePopUp(){
      setTitlePopUp("")
      setMsgPopUp("")
      setShowPopUp(false)
  }

  useEffect(() => {
    actions.getBasicAccountProfile()
  }, [])

  useEffect(() => {   // signout
    if(userLoggingIn.loggingOut){
      Cookies.remove('session')
      persistor.purge()
      setUser(defaultUserState)
      history.push('/')
    }
    if(userLoggingIn.loggedIn){
      var entireUser = user
      entireUser.logged_in = true
      setUser(entireUser)
    }
  }, [userLoggingIn.loggedIn])

  useEffect(() => {
    setSession(window.sessionStorage.getItem("token_id"))
  }, [window.sessionStorage.getItem("token_id")])
  
  useEffect(() => {
    if(session){
      actions.getAccountDetails()
      actions.getIssuerInfo()
    }
  }, [session])

  useEffect(() => {
    if(userLoggingIn.loggedIn){
      var entireUser

      if (basicAccountState.payload && detailAccountState.payload && issuerInfoState.payload) {
        if(detailAccountState.type === 'get' && issuerInfoState.type === 'get'){
          entireUser = {...user, ...basicAccountState.payload, ...detailAccountState.payload, ...issuerInfoState.payload};
          setUser(entireUser)
        }
      }
    }
  }, [basicAccountState.payload, detailAccountState.payload, issuerInfoState.payload])

  useEffect(() => {
    if (poolState.payload && poolState.payload  && poolState.payload.length) {
      setPoolList(poolState.payload)
    }
  }, [poolState.payload])

  function handleSignOut(e: React.MouseEvent) {
    window.sessionStorage.clear()
    actions.SignOutUserInvestor()
    actions.signOutCallback(session ? session : "")
  }

  function LoggedRoute(props: {path: string, component: any, logged: boolean}): JSX.Element {
    
    return props.logged ?
      <Redirect from={props.path} to='/' /> :
      <Route path={props.path} component={props.component}/>
  }

  useEffect(() => {
    let t = window.sessionStorage.getItem("token_id")
    if((!t || t === "expired") && (window.sessionStorage.getItem("expiredPU") === "true")) {
      window.sessionStorage.setItem("expiredPU", "false")
      setMsgPopUp("Your session expired, please log in again")
      setShowPopUp(true)
      history.push("/account/signin")
    }
  }, [authStatus, window.sessionStorage.getItem("token_id"), session])

  return (
    <><style>
      @import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@300;400;500;700;800&display=swap');
      @import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;700;800&display=swap');
      @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;800&display=swap');
      @import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300;400;500;700;800&display=swap');
    </style>
    <PoolListContext.Provider value={poolList}>
        <UserContext.Provider value={user}>
          <div className="min-h-screen grid content-between w-full">
            <div className="w-full">
              <Navbar handleSignOutInvestor={handleSignOut} showAssets={!isBasicAccountOrIsLoggedOut}></Navbar>
            </div>

            <Popup show={showPopUp} title={titlePopUp} msg={msgPopUp} btnOk={true} close={handleClosePopUp}  ></Popup>
            <Switch className="px-10">
              
              {/* PUBLIC ROUTES */}
              <Route exact path="/" component={HomeRoute} />
              <Route path={"/account/signin"} component={SignInRoute} />
              <Route path={"/account/signup"} component={SignUpRoute} />
              <Route path={"/about"} component={AboutRoute} />
              <Route path={"/support"} component={SupportRoute} />
              <Route path={"/privacy-policy"} component={PrivPolRoute} />
              <Route path={"/verify-email"} component={VerifyEmailRoute} />
              
              {/* LOGGED USERS ROUTES */}
              <LoggedRoute path={"/account/additional-info"} component={AdditionalInfoRoute} logged={!logged}/>
              <LoggedRoute path={"/account/almost-done"} component={AlmostDoneRoute} logged={!logged}/>
              <LoggedRoute path={"/menu"} component={MenuRoute} logged={!logged}/>
              <LoggedRoute path={"/kyc"} component={KYCRoute} logged={!logged}/>

              <LoggedRoute path={"/profile"} component={ProfileRoute} logged={isBasicAccountOrIsLoggedOut} />
              <LoggedRoute path={"/issuer/dashboard"} component={IssuerDashboard} logged={isBasicAccountOrIsLoggedOut} />
              <LoggedRoute path={"/issuer/portfolio"} component={IssuerPortfolio} logged={isBasicAccountOrIsLoggedOut} />
              
              <LoggedRoute path={"/issuer/tokens/:uuid"} component={TokenDetailsRoute} logged={isBasicAccountOrIsLoggedOut} />
              <LoggedRoute path={"/issuer/primary-sale/:uuid"} component={IssuerPrimarySaleRoute} logged={isBasicAccountOrIsLoggedOut} />
              <LoggedRoute path={"/issuer/asset/new"} component={IssueNewRoute} logged={isBasicAccountOrIsLoggedOut} />

              <LoggedRoute path={"/issuer/asset/manage/:uuid"} component={IssueAssetRoute} logged={isBasicAccountOrIsLoggedOut} />
              <LoggedRoute path={"/issuer/asset/manage"} component={IssueAssetRoute} logged={isBasicAccountOrIsLoggedOut} />

              <LoggedRoute path={"/issuer/extra-info/:uuid"} component={NewAssetExtraInfoRoute} logged={isBasicAccountOrIsLoggedOut} />
              <LoggedRoute path={"/issuer/asset/tokenize/:uuid"} component={TokenizationRoute} logged={isBasicAccountOrIsLoggedOut} />

              <LoggedRoute path={"/invest"} component={AssetRoute} logged={isBasicAccountOrIsLoggedOut} />
              <LoggedRoute path={"/tokenization"} component={TokenizationOldRoute} logged={isBasicAccountOrIsLoggedOut} />
              <LoggedRoute path={"/portfolio"} component={PortfolioRoute} logged={isBasicAccountOrIsLoggedOut} />

              <LoggedRoute path={"/blockchain/transaction/:trx"} component={BlockchainLookup} logged={isBasicAccountOrIsLoggedOut} />
              <Route path={"/asset/:uuid"} component={SecurityTokenRoute} />
              <Route path={"/primary_sale/:uuid"} component={PrimarySaleRoute} />

            </Switch>
            <Footer></Footer>
          </div>

        </UserContext.Provider>
      </PoolListContext.Provider></>
  )
}
export default App