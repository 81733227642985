import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import LightTable from "../components/light/LightTable";
import { defaultBalance, defaultTransactionsList, defaultBankTransactionsList } from "../PoolContext";
import { actionCreators, State } from "../state";
import { euroFormat, formatDate } from "../utils";
import { CSVLink } from 'react-csv';
import DepositWithdrawInterface from "../components/DepositWithdrawInterface";
import { PieChart } from 'react-minimal-pie-chart';
import { HeaderButton } from "../components/HeaderButton";
import { LightButton, DisabledButton } from "../components/light/LightButton";
import { Link } from 'react-router-dom'
import Popup from "../components/Popup";


export default function PortfolioRoute(): JSX.Element {
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const transactionsListState = useSelector((state: State) => state.transactions)
    const [transactionsList, setTransactionsList] = useState(defaultTransactionsList.transactions)
    const [bankTxnList, setBankTxnList] = useState(defaultBankTransactionsList.bank_transactions)
    const [balance, setBalance] = useState(defaultBalance)
    const [showPopUp, setShowPopUp] = useState<boolean>(false)
    const detailAccountState = useSelector((state: State) => state.detailAccount)
    const userCtx = detailAccountState.payload

    const offset = userCtx?.timezone_offset ? userCtx.timezone_offset : 0

    useEffect(() => {
        actions.getTransactions()
        actions.getBankTransactions(setBankTxnList)
        actions.getActualBalance(setBalance)
    }, [])
    useEffect(() => {
        if (transactionsListState.payload ) {
            setTransactionsList(transactionsListState.payload.transactions ? transactionsListState.payload.transactions : [])
        }
    }, [transactionsListState.payload])

    const headersSec = ["Cat", "Ticker", "Quantity", "Currency", "Value", "Portfolio weight", ""]
    const headersPool = ["Pool Name", "Quantity", "Currency", "Period lock", "APY"]
    const headersTransactions = ["Date", "Cat",  "Type of Security Token", "Token name", "Ticker",
         "Number of Tokens", "Price", "Fees", "Value", "Total","Trans Type", "From where", "Hash on Blockchain"]
    const headersBankTxns = ["Trans Type", "Status", "Date Request", "Requested Amount",  "Date Executed", "Minted Amount", "Hash on Blockchain"]
    const rowsSec = []
    const rowsPool = []
    const rowsTransactions = []
    const rowsBankTxns = []

    const formattedSec = []
    if(balance) {
        if(balance.balance)
        for (let r of balance.balance.security_entries) {
            for (let e of r.class_balance) {
                rowsSec.push([r.class, e.symbol, euroFormat(e.quantity), e.currency, 
                    euroFormat(parseFloat(e.quantity) * parseFloat(e.current_price)), euroFormat(e.pct), 
                    <Link to ={"/asset/"+e.asset_uuid}><LightButton label={"details"} className="px-2 rounded-md"/></Link>, 
                    <DisabledButton label={"sell"} className="px-2 rounded-md" />])
                formattedSec.push([r.class, e.symbol, euroFormat(e.quantity), e.currency_cd, 
                    euroFormat(parseFloat(e.quantity) * parseFloat(e.current_price)), e.pct])
            }
        }
        if(balance.liquidity) {
            for (let r of balance.liquidity.liquidity_entries) {
                for (let c of r.currency_cd){
                    rowsPool.push([r.lp_name, euroFormat(r.quantity), c, "128 days more", euroFormat(r.pct)])
                }
            }
        }
    }
    
    for (let t of transactionsList) {
        rowsTransactions.push([formatDate(t.timestamp, offset), t.asset_category, t.asset_type, t.asset_name, t.ticker, 
            euroFormat(t.quantity), "€"+euroFormat(t.price_per_unit), "€"+euroFormat(t.buyer_fee_amount), 
            "€"+euroFormat(t.quantity * t.price_per_unit), "€"+euroFormat(t.total_amount), 
            t.txn_type, t.origin, <Link to={"/blockchain/transaction/"+t.trx_identifier}>{t.trx_identifier}</Link>  ])
    }
    for (let t of bankTxnList) {
        rowsBankTxns.push([t.transaction_type, t.status, formatDate(t.requested_at, offset), "€"+euroFormat(t.requested_amount), 
        t.executed_at ? formatDate(t.executed_at, offset) : "-", t.minted_amount ? "€"+euroFormat(t.minted_amount) : "-", 
        <Link to={"/blockchain/transaction/"+t.trx_chain_identifier}>{t.trx_chain_identifier}</Link> ])
    }
    const headers = ['Asset / Security tokens', 'liquidity pool', 'transaction history', 'bank transaction history']
    const [toBeShown, setToBeShown] = useState(headers.map((_,i) => false))
    function handleClick(id: number) {
        var tmp = toBeShown.map((b,i) => i === id ? !b : b)
        setToBeShown(tmp)
    }

    function showTable(fileName: string, TableHeaders: string[], TableFormatted: any[], TableRows: any[], gradients: string[]) {
        return (   
        <div className="grid w-full bg-gray3" id={fileName}>
            <CSVLink className="border-solid py-2 px-5 border-crayola rounded-full uppercase text-primary text-sm m-5 
                w-fit align-middle bg-white border-1" 
                filename={fileName} headers={TableHeaders} 
                data={TableFormatted}>Download CSV </CSVLink>
            <LightTable headers={TableHeaders} rows={TableRows} 
                colorHd={gradients[0]}
                color1={gradients[1]}
                color2={gradients[2]}></LightTable>
        </div>)
    }

    return <>
        <Popup show={showPopUp} title={""} msg={""} btnOk={true} close={() =>{setShowPopUp(false)}} 
            body={<DepositWithdrawInterface />}></Popup>
        <div className="">
            <div className="grid md:grid-cols-2 cover my-10">
                <div className="md:border-solid border-tertiary border-l-0 border-t-0 border-b-0 border-r-1 my-10
                        grid grid-cols-2 md:grid-cols-1 lg:grid-cols-2">
                    <div className="flex place-content-center">
                        <div className="w-48 grid justify-items-center text-tertiary self-center">
                            <div className={"relative text-5xl font-base inset-0 top-30"}>
                                EUR
                            </div>
                            <PieChart className=""
                                data={[
                                    { title: 'Asset/Security Tokens', value: parseFloat(balance?.balance.total), color: '#80C8C4' },
                                    { title: 'Liquidity Pool Position', value: parseFloat(balance?.liquidity.total), color: '#6772CD' },
                                    { title: 'Currency', value: parseFloat(balance?.payment_tokens.total), color: '#4494CE' },
                                ]}
                                lineWidth={4}
                                paddingAngle={10}
                                animate={true}
                                animationDuration={1000}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col justify-items-center self-center mt-16 justify-center text-center">
                        <div className="font-urbanist text-tertiary text-4xl">
                            &euro;{balance ? euroFormat(balance.total) : euroFormat(0)}
                            <div className="font-roboto pt-2 text-tertiary text-2xl">My Portfolio</div>
                        </div>
                        <div className="depositWithdrawActions flex place-content-center">                      
                            <div className="mt-10">
                                <LightButton onClick={() => setShowPopUp(true)} label="deposit / withdraw"
                                    alternate={true} className="rounded-full py-2 px-5"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="font-urbanist uppercase text-tertiary my-10 text-4xl 
                        flex flex-col text-left place-content-center items-center
                        md:border-solid border-tertiary border-r-0 border-t-0 border-b-0 border-l-1">
                    <div>
                        <div className=" my-5">&euro;{euroFormat(balance?.balance.total)}
                            <div className="text-seafoam text-xl">Asset / Security tokens</div>
                        </div>
                        <div className=" my-5">&euro;{euroFormat(balance?.liquidity.total)}
                            <div className="text-succ5 text-xl">Liquidity pool position</div>
                        </div>
                        <div className="my-5">&euro;{euroFormat(balance?.payment_tokens.total)}
                            <div className="text-succ4 text-xl">Currency</div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <div className=" mt-5">
            {/*['Asset / Security tokens', 'liquidity pool', 'transaction history', 'bank transaction history']*/}
            <div className="">
                <div>
                    <HeaderButton name={"Asset / Security tokens ("+rowsSec.length+")"} click={() => handleClick(0)}
                        className={" port-link cursor-pointer bg-primary pl-10"}
                        color1="seafoam" color2="white" shown={toBeShown[0]}/>
                </div>
                {toBeShown[0] ? showTable("securities.csv", headersSec, formattedSec, rowsSec, 
                    ["gradient-to-r from-seafoam to-gray3", 
                        "gradient-to-r from-seafoam_lighter  to-gray1",
                        "gradient-to-r from-seafoam_light to-gray2"]) : <></>}
                <div>
                    <HeaderButton name={"Liquidity pool position ("+rowsPool.length+")"} 
                        click={() => handleClick(1)}
                        className={" port-link cursor-pointer bg-transparent pl-10"}
                        color1="succ4" color2="white" shown={toBeShown[1]}/>
                </div>
                {toBeShown[1] ? showTable("pool-liquidity.csv", headersPool, rowsPool, rowsPool, 
                    ["gradient-to-r from-succ4 to-gray3", 
                        "gradient-to-r from-succ4_lighter  to-gray1",
                        "gradient-to-r from-succ4_light to-gray2"]) : <></>}
                <div>
                    <HeaderButton name={"transaction history ("+rowsTransactions.length+")"} click={() => handleClick(2)}
                        className={" port-link cursor-pointer bg-primary pl-10"}
                        color1="seafoam" color2="white" shown={toBeShown[2]}/>
                </div>
                {toBeShown[2] ? showTable("history-transactions.csv", headersTransactions, rowsTransactions, rowsTransactions,
                    ["gradient-to-r from-seafoam to-gray3", 
                    "gradient-to-r from-seafoam_lighter  to-gray1",
                    "gradient-to-r from-seafoam_light to-gray2"]) : <></>}
                <div>
                    <HeaderButton name={"bank transaction history ("+rowsBankTxns.length+")"} click={() => handleClick(3)}
                        className={" port-link cursor-pointer bg-transparent pl-10"}
                        color1="succ4" color2="white" shown={toBeShown[3]}/>
                </div>
                {toBeShown[3] ? showTable("history-bank-transactions.csv", headersBankTxns, rowsBankTxns, rowsBankTxns,
                    ["gradient-to-r from-succ4 to-gray3", 
                    "gradient-to-r from-succ4_lighter  to-gray1",
                    "gradient-to-r from-succ4_light to-gray2"]) : <></>}
            </div>
        </div>
    </>
}