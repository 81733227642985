import { ActionType } from '../action-types'
import { Actions } from '../actions'
import { strPoolState } from '../../PoolContext'

interface poolsState {
    loading: boolean
    payload?: strPoolState[]
}

const defaultPoolsState: poolsState = {
    loading: false,
    payload: []
}

const poolsReducer = (state: poolsState = defaultPoolsState, action: Actions): poolsState => {
    switch(action.type) {
        case ActionType.GET_POOLS_REQUEST:
            return {
                loading: true
            }
        case ActionType.GET_POOLS_FAILURE:
            return {
                loading: false,
                payload: action.error
            }
        case ActionType.GET_POOLS_SUCCESS:
            return {
                loading: false,
                payload: action.payload
            }
        case ActionType.SET_POOLS_REQUEST:
            return {
                loading: true
            }
        case ActionType.SET_POOLS_FAILURE:
            return {
                loading: false,
                payload: action.error
            }
        case ActionType.SET_POOLS_SUCCESS:
            return {
                loading: false,
                payload: action.payload
            }
        case ActionType.UPDATE_POOL_REQUEST:
            return {
                loading: true
            }
        case ActionType.UPDATE_POOL_FAILURE:
            return {
                loading: false,
                payload: action.error
            }
        case ActionType.UPDATE_POOL_SUCCESS:
            return {
                loading: false,
                payload: action.payload
            }
        case ActionType.CLEAR_ALL:
            return {
                loading: false,
                payload: undefined
            }
        default:
            return state
    }
}

export default poolsReducer