import { AssetInfo, defaultPoolState, defaultSecurityToken } from "../../PoolContext";
import LabelAndDescription from "../LabelAndDescription";
import { getChartData } from "../../utils";
import TabsMenu from "../TabsMenu";
import { useState } from "react";
import { LightButton } from "../light/LightButton";
import LightTable from "../light/LightTable";
import TradeInterface from "../TradeInterface";

export default function AifTokenBody (props: {assetInfo : AssetInfo}): JSX.Element {
    
    const [viewTradeInterface, setViewTradeInterface] = useState<boolean>(false)
    
    var asset = {overview: {}, characteristics: {}, investment_limits: {}, irr: {}, fund_terms: {}, other: {}}
    var overviewInfo = {}
    var dealCharInfo = {}
    var invLimitsInfo = {}
    var irrInfo = {}
    var fundTermsInfo = {}
    var otherInfo = {}
    
    try {
        if(props.assetInfo.asset_type === "Private Debt") {
            asset = props.assetInfo.extra_info.private_debt.sub_assets[0]
        } else {
            asset = props.assetInfo.extra_info.private_equity.sub_assets[0]
        }
    } catch (e) {
        asset = {overview: {}, characteristics: {}, investment_limits: {}, irr: {}, fund_terms: {}, other: {}}
    }
    
    overviewInfo = asset.overview 
    dealCharInfo = asset.characteristics
    invLimitsInfo = asset.investment_limits
    irrInfo = asset.irr
    fundTermsInfo = asset.fund_terms
    otherInfo = asset.other

    function showTable(title: string[], elements: any[], colSpec: string[]) {
        return title.map((e,i) => <LabelAndDescription alternate={true} label={e} description={elements[i]} className={colSpec[i]+" mb-5"} key={i} />)
    }
    
    function showOverview (info: any) {
        if(info) {
            const ovwTitles = ["Investment strategy description", "Target Allocation / Seniority", 
            "Main Risk And Return Characteristics", "Deal Type", "Target Fund Size", "Investment Period",
            "Fund Name"]
            const ovwElems = [info.investment_strategy_description,  info.target_allocation_seniority, 
                info.risk_return_characteristics, info.deal_type, info.target_fund_size, info.investment_period,
                info.fund_name]
            const ovwCols = ["col-span-12", "col-span-12", "col-span-12", "col-span-6", "col-span-6", "col-span-6", "col-span-6"]
            return <>{showTable(ovwTitles, ovwElems, ovwCols)}</>    
        }
        return <></>   
    }

    function showTrading() {
        return <>
            <div className="grid grid-cols-6 col-span-12">
                <div className="col-span-5">
                    {getChartData()}
                </div>
                <div className=" grid items-center align-middle font-bold">
                    <div>
                        <LightButton className="rounded-full px-10 my-5" label={"buy"} onClick={() => setViewTradeInterface(!viewTradeInterface)}/>
                        <LightButton className="rounded-full px-10 my-5" label={"earn"} onClick={() => setViewTradeInterface(!viewTradeInterface)}/>
                    </div>
                    
                </div>
            </div>
            <div className="grid grid-cols-6 mt-20 col-span-12">
                <div></div>
                <div className="col-span-4">
                    <div className="font-urbanist font-bold text-2xl uppercase text-blue mb-5">P2P</div>
                    <TradeInterface hide={() => setViewTradeInterface(false)} 
                                isVisible={viewTradeInterface} assetPool={defaultPoolState} tokenInfo={defaultSecurityToken} />
                    <LightTable headers={["Quantity", "price", ""]} 
                        rows={[["1.500", "€0,40", <LightButton className="rounded px-5" label={"buy"} onClick={() => setViewTradeInterface(!viewTradeInterface)}/>],
                            ["12.000", "€0,39", <LightButton className="rounded px-5" label={"buy"} onClick={() => setViewTradeInterface(!viewTradeInterface)}/>],
                            ["5.000", "€0,41", <LightButton className="rounded px-5" label={"buy"} onClick={() => setViewTradeInterface(!viewTradeInterface)}/>]]} />
                </div>
                <div></div>
            </div>
            <div className="grid grid-cols-6 mt-20 col-span-12">
                <div></div>
                <div className="col-span-4">
                    <div className="font-urbanist font-bold text-2xl uppercase text-blue mb-5">trade with LP</div>
                    <TradeInterface hide={() => setViewTradeInterface(false)} 
                                isVisible={viewTradeInterface} assetPool={defaultPoolState} tokenInfo={defaultSecurityToken} />
                    <LightTable headers={["1 token price", "tokens in lp", ""]} 
                        rows={[["€0,40", "900.000", <LightButton className="rounded px-5" label={"buy"} 
                                onClick={() => setViewTradeInterface(!viewTradeInterface)}/>]]} />
                </div>
                <div></div>
            </div>
            <div className="grid grid-cols-6 mt-20 col-span-12">
                <div></div>
                <div className="col-span-4">
                    <div className="font-urbanist font-bold text-2xl uppercase text-blue mb-5">Earn with LP</div>
                    <TradeInterface hide={() => setViewTradeInterface(false)} 
                                isVisible={viewTradeInterface} assetPool={defaultPoolState} tokenInfo={defaultSecurityToken} />
                    <LightTable headers={["your tokens", "your stable coins", ""]} 
                        rows={[["500", "26.690,00", <LightButton className="rounded px-5" label={"earn"} 
                                onClick={() => setViewTradeInterface(!viewTradeInterface)}/>]]} />
                </div>
                <div></div>
            </div>
            
        </>
    }

    function showTermsCond() {
        return <LabelAndDescription alternate={true} label="Informations about Token's Terms and conditions" description={" "} className="col-span-12"/>
    }

    function showDealChar(info: any) {
        const dealTitles = ["Borrower Profile", "Target Company size", "Loans / Bonds", "Average Rating",
            "Bullet / Amortizing", "Minimum Rating", "Floating / Fixed", "Typical Loan To Value",
            "Primary / Secondary", "Type of Collateral", "Sponsor / Corporate","Max / Average net leverage",
            "Covenant", "Speed of deployment", "Deal Currency", "Target Deal Size", "Fund Currency",
            "Number Of Deals","Max / Typical Term Per Deal","Expected Term Per Deal"]
        const dealElems = [info.borrower_profile, info.target_company_size, info.loans_or_bonds,
            info.average_rating, info.bullet_amortizing, info.minimum_rating, info.floating_or_fixed,
            info.typical_loan_to_value, info.primary_or_secondary, info.type_of_collateral,
            info.sponsor_or_corporate, info.max_average_net_leverage, info.covenant, info.speed_of_deployment,
            info.deal_currency, info.target_deal_size, info.fund_currency, info.number_of_deals,
            info.max_typical_term_per_deal, info.expected_term_per_deal]
        const dealCols = ["col-span-6", "col-span-6", "col-span-6", "col-span-6", "col-span-6", "col-span-6", 
            "col-span-6", "col-span-6", "col-span-6", "col-span-6", "col-span-6", "col-span-6", "col-span-6", 
            "col-span-6", "col-span-6", "col-span-6", "col-span-6", "col-span-6", "col-span-6", "col-span-6"]
        return info  ?
            <>
                {showTable(dealTitles, dealElems, dealCols)}
            </> : <></>
    }
    function showInvLimits (info: any) {
        const invLimitsTitles = ["Geographic Area","Max Sector Limit","Leverage","Max Country Limit",
            "Max Issuer Limit","Derivatives","ESG considerations","Max Issue Excluded"]
        const invLimitsElems = [info.geographic_area,info.max_sector_limit,info.leverage,
            info.max_country_limit,info.max_issuer_limit,info.derivatives,info.esg_considerations,
            info.excluded_profiles]
        const invLimitsCols = ["col-span-4", "col-span-4","col-span-4","col-span-4","col-span-4","col-span-4","col-span-6","col-span-6"]
        return info  ?
            <>
                {showTable(invLimitsTitles, invLimitsElems, invLimitsCols)}
            </> : <></>
    }
    function showIrr (info: any) {
        const irrTitles = ["Target Gross Irr","Target Cash Yield","Distribution Policy"]
        const irrElems = [info.target_gross_irr,info.target_cash_yield,info.distribution_policy]
        const irrCols = ["col-span-4","col-span-4","col-span-4"]
        return info  ?
            <>
                {showTable(irrTitles, irrElems, irrCols)}
            </> : <></>
    }
    function showFundTerms (info: any) {
        const fundTermsTitles = ["First Close","Final Close","Fund Term","Ramp-Up",
            "Investment Period","Capital Calls","Capital Restitutions"]
        const fundTermsElems = [info.first_close,info.final_close,info.fund_term,
            info.ramp_up,info.investment_period,info.capital_calls,info.capital_restitutions]
        const fundTermsCols = ["col-span-6", "col-span-6", "col-span-6", "col-span-6", "col-span-6", "col-span-6", "col-span-6"]
        return info  ?
            <>
                {showTable(fundTermsTitles, fundTermsElems, fundTermsCols)}
            </> : <></>
    }
    function showOther (info: any) {
        const otherTitles = ["AIFM","Auditor","Reporting","Distributor","Custiodian","Administrator",
            "Nav. Frequency","Share Classes","Incentive Fees","General Partner","Legal Structure",
            "Management Fees","Sub Distributor","Portfolio Manager","Subsequent Subscriptions"]
        const otherElems = [info.aifm,info.auditor,info.reporting,info.distributor,info.custodian,
            info.administrator,info.nav_frequency,info.share_classes,info.incentive_fees,info.general_partner,
            info.legal_structure,info.management_fees,info.sub_distributor,info.portfolio_manager,
            info.subsequent_subscriptions]
        const otherCols = ["col-span-6","col-span-6","col-span-12","col-span-6","col-span-6","col-span-6","col-span-6",
            "col-span-6","col-span-6","col-span-6","col-span-6","col-span-6","col-span-6","col-span-6","col-span-6"]
        return info  ?
            <>
                {showTable(otherTitles, otherElems, otherCols)}
            </> : <></>
    }
    const headers = ["Overview", "Trading", "Token terms cond","Deal", "Investment limits", "IRR / Fund terms", "Other", "Data room"]
    const bodyFuncs = [showOverview(overviewInfo), showTrading(), showTermsCond(), showDealChar(dealCharInfo), showInvLimits(invLimitsInfo),
        <>{showIrr(irrInfo)} {showFundTerms(fundTermsInfo)}</>, showOther(otherInfo),
        <><LabelAndDescription label={"No data for this token"} description={" "}/></>]
    

    return <>
        <TabsMenu headers={headers} contents={
            (props.assetInfo ?
            bodyFuncs.map((e, i) => 
                <div key={i} className="grid grid-cols-12">
                    <div className="col-span-2"></div>
                    <div className="col-span-8">
                        <div className="show-details" id="overview">    
                            <div className="grid grid-cols-12">{e}</div>
                        </div>
                    </div>
                    <div className="col-span-2"></div>
                </div>) : [])} />
            </>
}


