import { useHistory  } from 'react-router-dom'
import { LightButton } from "../components/light/LightButton"
import { PageDescription, TitleColor1 } from '../components/typography/Typogtaphy'

export default function AlmostDoneRoute(): JSX.Element {
    
    let history = useHistory()
    let leftTitle = ["Almost Done!","Regulations require that we","collect this information to","confirm your identity"]
    
    return (<>
            <div className="grid ">
                <div className="grid justify-items-center px-5">
                    <div className="font-roboto py-2 tracking-wider right-text">
                        <PageDescription title={"Almost done!"} description={
                            "Regulations require that we collect this information to confirm your identity"} />
                        <div>
                            <TitleColor1 className="text-xl">Know Your Custumer</TitleColor1>
                            
                            <label className="w-full text-tertiary text-xl font-light py-4">
                                To complete the KYC you will need your passport.<br/>
                                This will take about 5 minutes and must be done in a single sitting.</label>
                        </div>
                        <div  className="flex gap-4 justify-center flex-wrap mt-16 text-tertiary">                        
                            <div className="">
                                <LightButton onClick={() => {history.push("/account/additional-info", { action: 'edit' })}} 
                                className="rounded-full px-5 py-2"
                                label="Edit your information" alternate={true}/>
                            </div>
                            <div className="">
                                <LightButton onClick={() => {history.push("/kyc")}} className="rounded-full px-5 py-2"
                                label="Begin the KYC process" alternate={true}/>
                            </div>
                            <div className="">
                                <LightButton onClick={() => {history.push("/menu")}} className="rounded-full px-5 py-2"
                                label="Explore the platform" alternate={true}/>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
    </>)
}
