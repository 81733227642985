import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { LightButton } from './light/LightButton';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function Popup(props: {show: boolean, title: string, msg: string, btnOk: boolean, close: any, 
    type?: "success" | "warning" | "error", body?: JSX.Element, btnConfirm?: boolean, handleConfirm?: any}) : JSX.Element {

    let numBtn: number = 0;
    if(props.btnOk) numBtn++
    if(props.btnConfirm) numBtn++
    let colorClasses
    switch (props.type) {
      case "success":
        colorClasses = " text-succ1 "
        break;
      case "warning":
        colorClasses = " text-warning "
        break;
      case "error":
        colorClasses = " text-error1 "
        break;
      default:
        colorClasses = " text-primary "
        break;
    }

    return (
      <>
        <Dialog
          open={props.show}
          TransitionComponent={Transition}
          keepMounted
          onClose={props.close}
          aria-describedby="alert-dialog-slide-description"
        >
          {props.title ? 
            <div className={"font-roboto text-2xl uppercase px-8 pt-8 font-blue bg-tertiary text-primary w-128 "+colorClasses}>
              {props.title}
            </div> : <></>}
          {props.msg ? <DialogContent className="bg-tertiary">
            <div className="font-roboto text-xl text-primary font-thin px-8">
              {props.msg}
            </div> 
          </DialogContent> : <></>}
          <div className="bg-tertiary">
            {props.body ? props.body : <></>}
          </div>

          <div className={"grid justify-center grid-cols-"+numBtn+" "}>
            {props.btnConfirm ? 
              <DialogActions className={"bg-tertiary " + (numBtn === 2 ? "flex-col" : "justify-center")}>
                <LightButton onClick={props.handleConfirm} label={"confirm"} fontClasses="text-sm" className="rounded-xl px-2"/>
              </DialogActions> 
            : <></>}
            {props.btnOk ? 
              <DialogActions className={"bg-tertiary " + (numBtn === 2 ? "flex-col" : "justify-center")}>
                <LightButton onClick={props.close} label={"close"} fontClasses="text-sm" className="rounded-full px-4"/>
              </DialogActions> 
            : <></>}
          </div>
        </Dialog>
      </>
    );
  }
