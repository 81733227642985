import { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from '../state'
import { Authenticator, useAuthenticator, CheckboxField } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { ActionType } from "../state/action-types";
import { useHistory } from "react-router";
import Popup from "../components/Popup";

export default function SignInRoute(): JSX.Element {
  interface User {
    attributes?: any
    username?: any
    storage?: Storage
    signInUserSession?: any
  }
  var u : User | undefined = undefined
  const { user, signOut } = useAuthenticator((context) => [context.user])
  const { authStatus } = useAuthenticator((context) => [context.authStatus])
  const [userS, setUserS] = useState<User>()
  const [session, setSession] = useState<string | null>()
  const dispatch = useDispatch()
  let history = useHistory()
  
  const actions = bindActionCreators(actionCreators, dispatch)

  useEffect(() => {
    if(userS && userS.signInUserSession) {
      setSession(userS.signInUserSession.idToken.jwtToken)
      dispatch({type: ActionType.SIGNIN_USER_ACCOUNT_SUCCESS})
    } else {
      setSession(null)
    }
  }, [userS, userS?.signInUserSession])

  useEffect(() => {
    if(session && authStatus === "authenticated") {
      if(!window.sessionStorage.getItem("logged_in")) {
        actions.signInCallback(session)
      }
      window.sessionStorage.setItem("logged_in", "true")
      window.sessionStorage.setItem("token_id", session)
      actions.getBasicAccountProfile()
    }
  }, [session, authStatus])

  useEffect(() => {
      setUserS(user)
  }, [user])
  
  return <>
      <Authenticator loginMechanisms={['email']} signUpAttributes={[]} socialProviders={['google']}
                    components={{
                      SignUp: {
                        FormFields() {
                          const { validationErrors } = useAuthenticator();
                          return (
                            <>    
                              <Authenticator.SignUp.FormFields />
                              <CheckboxField
                                errorMessage={validationErrors.acknowledgement as string}
                                hasError={!!validationErrors.acknowledgement}
                                name="acknowledgement"
                                value="yes"
                                label="I agree with the Terms & Conditions"
                              />
                            </>);},},}}
                    services={{
                      async validateCustomSignUp(formData) {
                        if (!formData.acknowledgement) {
                          return {
                            acknowledgement: 'You must agree to the Terms & Conditions',
                          };}},}}>
          {({ signOut, user}) => {
              if(window.sessionStorage.getItem("logged_in") === "true" && authStatus === "authenticated") {
                history.push('/menu')
              }
              if(window.sessionStorage.getItem("token_id") === "expired") {
                if(signOut) signOut()
              }
                
              return <>
                <div className="grid place-content-center">
                  <div className="col-span-2 font-thin font-roboto tracking-wide md:pl-20 pl-2 text-tertiary">
                      <div className="text-succ2 text-2xl">Loading...</div>
                  </div>
                </div>
              </>
          }}
      </Authenticator>
    </>
}