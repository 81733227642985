import { ActionType } from '../action-types'
import { Actions } from '../actions'

interface emailVerificationState {
    loading: boolean
    payload?: []    
}

export const defaultEmailVerificationState: emailVerificationState = {
    loading: false,
    payload: []
}

const emailVerificationReducer = (state: emailVerificationState = defaultEmailVerificationState, action: Actions): emailVerificationState => {
    switch(action.type) {
            case ActionType.EMAIL_VERIFICATION_REQUEST:
            return {
                loading: true,
            }
        case ActionType.EMAIL_VERIFICATION_FAILURE:
            return {
                loading: false,
                payload: action.error
            }
        case ActionType.EMAIL_VERIFICATION_SUCCESS:
            return {
                loading: false,
                payload: action.payload
            }
        default:
            return state
    }
}
export default emailVerificationReducer