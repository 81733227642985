import { ActionType } from "../action-types"
import { Actions } from "../actions"

interface getAssetInfoState {
    loading: boolean
    payload?: any
}

const defaultGetAssetInfoState: getAssetInfoState = {
    loading: false,
    payload: []
}

const getAssetInfoReducer = (state: getAssetInfoState = defaultGetAssetInfoState, action: Actions): getAssetInfoState => {
    switch(action.type) {
        case ActionType.GET_ASSET_INFO_REQUEST:
            return {
                loading: true,
            }
        case ActionType.GET_ASSET_INFO_FAILURE:
            return {
                loading: false,
                payload: action.error
            }
        case ActionType.GET_ASSET_INFO_SUCCESS:
            return {
                loading: false,
                payload: action.payload
            }
        case ActionType.CLEAR_ALL:
            return {
                loading: false,
                payload: undefined
            }
        default:
            return state
    }
}

export default getAssetInfoReducer