import { ActionType } from "../action-types"
import { Actions } from "../actions"

interface createAssetState {
    loading: boolean
    payload?: any
}

const defaultCreateAssetState: createAssetState = {
    loading: false,
    payload: []
}

const createAssetReducer = (state: createAssetState = defaultCreateAssetState, action: Actions): createAssetState => {
    switch(action.type) {
        case ActionType.CREATE_ASSET_REQUEST:
            return {
                loading: true,
            }
        case ActionType.CREATE_ASSET_FAILURE:
            return {
                loading: false,
                payload: action.error
            }
        case ActionType.CREATE_ASSET_SUCCESS:
            return {
                loading: false,
                payload: action.payload
            }
        case ActionType.CLEAR_ALL:
            return {
                loading: false,
                payload: undefined
            }
        default:
            return state
    }
}

export default createAssetReducer